import { createBrowserHistory } from "history";
import { AnyAction, combineReducers, compose, configureStore } from "@reduxjs/toolkit";
import thunk, { ThunkAction, ThunkDispatch } from "redux-thunk";
import { createReduxHistoryContext } from "redux-first-history";
import { ApplicationListActions } from "./applicationList/actions";
import { applicationListReducer } from "./applicationList/reducer";
import { AuthActions } from "./auth/actions";
import { authReducer } from "./auth/reducer";
import { ErrorActions } from "./errors/actions";
import { errorReducer } from "./errors/reducer";
import { LocalizationActions } from "./localization/actions";
import { localizationReducer } from "./localization/reducer";

const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });

export type RootAction = ApplicationListActions | AuthActions | ErrorActions | LocalizationActions;

const rootReducer = combineReducers({
  applicationList: applicationListReducer,
  auth: authReducer,
  error: errorReducer,
  localization: localizationReducer,
  router: routerReducer,
});

declare global {
  /* tslint:disable:interface-name */
  // eslint-disable-next-line no-unused-vars
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: typeof compose;
  }
}

export const store = configureStore({
  reducer: rootReducer,
  middleware: [thunk, routerMiddleware],
});
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TypedDispatch = ThunkDispatch<RootState, any, AnyAction>;
export type TypedThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
export const history = createReduxHistory(store);
