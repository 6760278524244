import React, { Suspense } from "react";
import ReactDOM from "react-dom";
// import ReactDOM from "react-dom/client";
import "./index.css";
import "./i18n/config";
import { Provider } from "react-redux";
import { LicenseInfo } from "@mui/x-license-pro";
import { HistoryRouter as Router } from "redux-first-history/rr6";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store, history } from "./redux/store";
import Loader from "./components/utils/Loader";

if (process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY) {
  LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_X_PRO_LICENSE_KEY);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router history={history}>
        <Suspense fallback={<Loader />}>
          <App />
        </Suspense>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// For React 18
// const container = document.getElementById("root");
// const root = ReactDOM.createRoot(container as HTMLElement);
// root.render(
//     <React.StrictMode>
//         <Provider store={store}>
//             <Router history={history}>
//                 <Suspense fallback={<Loader />}>
//                     <App />
//                 </Suspense>
//             </Router>
//         </Provider>
//     </React.StrictMode>
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// unregister service worker
navigator.serviceWorker.getRegistrations().then((registrations) => {
  for (const registration of registrations) {
    registration.unregister();
  }
});
