import "./LoginPage.css";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { push } from "redux-first-history";
import { Alert, Button, Grid, Link, Snackbar } from "@mui/material";
import ReactGA from "react-ga4";
import { login } from "../../api/auth";
import ctfLogo from "../../images/CTF-logo.png";
import vpukLogo from "../../images/vpuk.png";
import { studentLoginSuccess } from "../../redux/auth/actions";
import { RoundTextField, State } from "../utils/CustomStyle";
import { RootState } from "../../redux/store";
import SwitchLanguageButton from "./SwitchLanguageButton";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

export interface LoginFormValues {
  email: string;
  password: string;
}

function LoginPage() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["common", "translation"]);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const isStudentAuthenticated = useAppSelector((state: RootState) => state.auth.isStudentAuthenticated);
  const isLoading = useAppSelector((state: RootState) => state.auth.isLoading);
  const user = useAppSelector((state: RootState) => state.auth.user);

  useEffect(() => {
    ReactGA.initialize([
      {
        trackingId: "G-JRQJSD1VZW",
      },
      {
        trackingId: "UA-127959897-4",
      },
      {
        trackingId: "G-TJMTN713LE",
      },
    ]);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (isStudentAuthenticated && user) {
        dispatch(push("list"));
      }
    }
  }, [dispatch, isLoading, isStudentAuthenticated, user]);

  const [state, setState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClose = () => {
    setState({ open: false, vertical: "top", horizontal: "right" });
  };

  const defaultValues: LoginFormValues = {
    email: "",
    password: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>({
    defaultValues,
  });

  const onSubmit: SubmitHandler<LoginFormValues> = async (data) => {
    setErrorMsg("");
    const result = await login(data);
    if (result.success) {
      dispatch(studentLoginSuccess(result.data, result.token));
      dispatch(push("list"));
    } else if (!result.success) {
      setErrorMsg(t(`errors.${result.error.userMessage}`, { ns: "translation" }));
      setState({ open: true, vertical: "top", horizontal: "right" });
    }
  };

  function onClickRegister() {
    dispatch(push("register"));
  }

  function onClickForgotPassword() {
    dispatch(push("forget_password"));
  }

  return (
    <Grid sx={{ m: 3 }}>
      {isLoading && isStudentAuthenticated && <>{t("loading", { ns: "common" })}</>}
      {!isLoading && !isStudentAuthenticated && (
        <>
          <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center" sx={{ m: 1 }}>
            <SwitchLanguageButton />
          </Grid>
          {lastSchool === "vpp" && (
            <Grid item xs={12} display="flex" justifyContent="center" sx={{ m: 5 }}>
              <img src={ctfLogo} alt="School Logo" width={300} height={86} />
            </Grid>
          )}
          {lastSchool === "vpuk" && (
            <Grid item xs={12} display="flex" justifyContent="center" sx={{ m: 5 }}>
              <img src={vpukLogo} alt="School Logo" width={300} height={86} />
            </Grid>
          )}
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                handleSubmit(onSubmit)();
                event.preventDefault();
              }
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: `${t("register.email", { ns: "translation" })}${t("forms.required", { ns: "translation" })}` }}
                  render={({ field }) => (
                    <RoundTextField
                      {...field}
                      fullWidth
                      size="small"
                      error={errors.email && true}
                      id="email"
                      label={t("register.email", { ns: "translation" })}
                      variant="outlined"
                      helperText={errors.email && `${errors.email.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}></Grid>

              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: `${t("register.password", { ns: "translation" })}${t("forms.required", { ns: "translation" })}`,
                  }}
                  render={({ field }) => (
                    <RoundTextField
                      {...field}
                      fullWidth
                      size="small"
                      error={errors.password && true}
                      id="password"
                      label={t("register.password", { ns: "translation" })}
                      variant="outlined"
                      type="password"
                      helperText={errors.password && `${errors.password.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}></Grid>

              <Grid item xs={4}></Grid>
              <Grid item xs={4} className="loginPageForgotPasswordContainer">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link component="button" className="loginPageForgotPasswordSpan" onClick={onClickForgotPassword}>
                  {t("register.forgetPassword", { ns: "translation" })}
                </Link>
              </Grid>
              <Grid item xs={4}></Grid>

              <Grid item xs={4}></Grid>
              <Grid item xs={4} display="flex" justifyContent="center">
                <Button variant="contained" type="submit" className="loginPageLoginBtn">
                  {t("register.login", { ns: "translation" })}
                </Button>
              </Grid>
              <Grid item xs={4}></Grid>

              <Grid item xs={4}></Grid>
              <Grid item xs={4} className="loginPageRegisterContainer">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link component="button" className="loginPageRegisterSpan" onClick={onClickRegister}>
                  {t("register.createAccount", { ns: "translation" })}
                </Link>
              </Grid>
              <Grid item xs={4}></Grid>
            </Grid>
          </form>
          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
            <Alert severity="error">
              <strong>{errorMsg}</strong>
            </Alert>
          </Snackbar>
        </>
      )}
    </Grid>
  );
}

export default LoginPage;
