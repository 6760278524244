import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import React from "react";
import { Interview } from "../../../../interfaces/interview";
import InterviewSettingModal from "../InterviewSettingModal";
import InterviewStatusMenu from "../InterviewStatusMenu";

interface InterviewToolbarProps {
  interviews: Interview[];
}

function InterviewToolbar(props: InterviewToolbarProps) {
  const { interviews } = props;

  return (
    <GridToolbarContainer sx={{ display: "block" }}>
      <Grid container>
        <Grid xs={6}>
          <Typography>
            <GridToolbarQuickFilter />
          </Typography>
        </Grid>
        <Grid xs={6} display="flex" justifyContent="flex-end">
          <Button
            className="buttonGroupSent"
            variant="outlined"
            size="small"
            // aria-controls={openCustomizeView ? "basic-menu" : undefined}
            // aria-haspopup="true"
            // aria-expanded={openCustomizeView ? "true" : undefined}
            // onClick={handleClick}
          >
            UPDATE
          </Button>
          <InterviewStatusMenu />
          <InterviewSettingModal
            button={
              <Button
                className="buttonGroupSent"
                variant="outlined"
                size="small"
                // aria-controls={openCustomizeView ? "basic-menu" : undefined}
                // aria-haspopup="true"
                // aria-expanded={openCustomizeView ? "true" : undefined}
              >
                CREATE INTERVIEW
              </Button>
            }
          />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default InterviewToolbar;
