import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Control, Controller, FieldErrors, FieldValues, FormProvider, SubmitHandler, useForm, UseFormGetValues, UseFormRegister, UseFormReturn } from "react-hook-form";
import { useParams } from "react-router-dom";
import { CreateInterviewFormInput, Interview } from "../../../interfaces/interview";
import CriteriaSettingModal from "./CriteriaSettingModal";
import ConnectForm from "./ConnectForm";
import modalStyle from "../../utils/modalStyle";
import { createInterview, editInterviewById } from "../../../api/interview";

interface InterviewSettingModalProps {
  interview?: Interview;
  button: ReactNode;
}

const defaultProps = {
  interview: undefined,
};

function InterviewSettingModal(props: InterviewSettingModalProps) {
  const { interview, button } = props;
  const { t } = useTranslation();
  const lastSchool = sessionStorage.getItem("lastSchool");
  const params = useParams();
  const methods = useForm<Interview>({
    defaultValues: interview,
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit: SubmitHandler<Interview> = async (input) => {
    console.log(input);
    const { id: _id, noOfCandidates: _noOfCandidates, arrangedCandidates: _arrangedCandidates, unarrangedCandidates: _unarrangedCandidates, ...data } = input;
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const stageId = parseInt(params.formId);
      if (interview) {
        await editInterviewById(listId, stageId, _id, data);
      } else {
        await createInterview(listId, stageId, data);
      }
    }
  };

  useEffect(() => {
    if (interview) {
      console.log("interview", interview);
      const fields = Object.keys(interview);

      fields.map((field) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        // methods.watch(field);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return methods.setValue(field, interview[field]);
      });
    }
  }, []);

  return (
    <>
      <Grid style={{ display: "inline" }} onClick={handleOpen}>
        {button}
      </Grid>
      <Modal open={open} onClose={onClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={modalStyle}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                  <div>{interview ? "Edit" : "Create"} Interview</div>
                  <Button variant="text" onClick={onClose} className="modalClearBtn">
                    <ClearIcon />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Controller
                      name={"colour" as const}
                      control={methods.control}
                      rules={{ required: "Interview Colour is required" }}
                      // defaultValue="#33CCCC"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Interview Colour"
                          error={methods.formState.errors?.colour && true}
                          helperText={methods.formState.errors?.colour && `${methods.formState.errors?.colour?.message}`}
                        />
                      )}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Controller
                      name={"titleEnUs" as const}
                      control={methods.control}
                      rules={{ required: "Interview Title (English) is required" }}
                      // defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Interview Title (English)"
                          error={methods.formState.errors?.titleEnUs && true}
                          helperText={methods.formState.errors?.titleEnUs && `${methods.formState.errors?.titleEnUs?.message}`}
                        />
                      )}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Controller
                      name={"titleZhHk" as const}
                      control={methods.control}
                      rules={{ required: "Interview Title (Traditional Chinese) is required" }}
                      // defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Interview Title (Traditional Chinese)"
                          error={methods.formState.errors?.titleZhHk && true}
                          helperText={methods.formState.errors?.titleZhHk && `${methods.formState.errors?.titleZhHk?.message}`}
                        />
                      )}
                    />
                  }
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button variant="text" onClick={onClose}>
                    {t("view.cancel")}
                  </Button>
                  {/* <CriteriaSettingModal criteria={interview?.criteria} /> */}
                  <CriteriaSettingModal criteria={methods.getValues().criteria} />
                  <Button variant="text" type="submit">
                    {interview ? "EDIT" : "CREATE"}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

InterviewSettingModal.defaultProps = defaultProps;

export default InterviewSettingModal;
