import { Button, Collapse, Grid, Popover } from "@mui/material";
import React, { useState, MouseEvent } from "react";
import { CandidateInfo, Interviewee, InterviewTimeSlot } from "../../../interfaces/interview";
import AddCandidateCollapse from "./AddCandidateCollapse";

interface AddCandidatePopoverProps {
  applicationList: Interviewee[];
  timeSlot: InterviewTimeSlot;
}

function AddCandidatePopover(props: AddCandidatePopoverProps) {
  const { applicationList, timeSlot } = props;
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedInterviewee, setSelectedInterviewee] = useState<number[]>([]);

  const handleOpen = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleAdd = () => {
    console.log(timeSlot.candidate.length + selectedInterviewee.length);
    if (timeSlot.candidate.length + selectedInterviewee.length > timeSlot.quota) {
      console.log("add failed");
    } else {
      console.log("add success");
    }
  };

  return (
    <>
      <Button className="buttonGroupSent" variant="outlined" size="small" onClick={handleOpen}>
        ADD CANDIDATE
      </Button>
      <Popover
        id="add-candidate-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Grid container sx={{ width: 480, height: 426 }}>
          {applicationList
            .filter((interviewee) => interviewee.interviews.length === 0)
            .map((interviewee) => (
              <AddCandidateCollapse
                key={interviewee.info.applicantId}
                interviewee={interviewee}
                checked={selectedInterviewee.some((value) => value === interviewee.info.applicantId)}
                setSelected={setSelectedInterviewee}
              />
            ))}
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <Button onClick={handleClose}>CANCEL</Button>
            <Button onClick={handleAdd}>ADD CANDIDATE</Button>
          </Grid>
        </Grid>
      </Popover>
    </>
  );
}

export default AddCandidatePopover;
