import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Divider, Grid, Modal, Typography } from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CandidateInfo, EvaluationForm, PointsTotal, Rating } from "../../../interfaces/interview";
import modalStyle from "../../utils/modalStyle";
import InterviewPerformanceRatingForm from "./InterviewPerformanceRatingForm";
import useWindowDimensions from "./useWindowDimensions";

interface EditCandidateStatusModalProps {
  candidate: CandidateInfo;
  evaluationForm: EvaluationForm[];
}

function EditCandidateStatusModal(props: EditCandidateStatusModalProps) {
  const { candidate, evaluationForm } = props;
  const { t } = useTranslation();
  const methods = useForm<Rating[][]>();
  const { height } = useWindowDimensions();

  // const methods = useForm();

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // const onSubmit = () => {
  //     console.log(methods.getValues());
  // };

  if (candidate.rating.length > 0) {
    candidate.rating.forEach((value, index) => {
      methods.setValue(`${candidate.applicantId}.${index}`, value);
    });
  } else {
    evaluationForm.forEach((value, index) => {
      methods.setValue(`${candidate.applicantId}.${index}.itemType`, value.itemType);
      methods.setValue(`${candidate.applicantId}.${index}.itemTitle`, value.itemTitle);
      methods.setValue(`${candidate.applicantId}.${index}.fullMarks`, value.fullMarks);
      methods.setValue(`${candidate.applicantId}.${index}.description`, value.description);
      methods.setValue(`${candidate.applicantId}.${index}.linearScale`, value.linearScale);
      methods.setValue(`${candidate.applicantId}.${index}.multipleChoice`, value.multipleChoice);
    });
  }

  return (
    <>
      <Button variant="contained" size="small" className="buttonAction buttonActionDarkBlue" onClick={handleOpen}>
        {t("view.edit")}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={{ ...modalStyle, p: 0, maxWidth: "90%" }}>
          <FormProvider {...methods}>
            <form>
              <Box sx={{ p: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                    <Typography style={{ fontWeight: 500, fontSize: "18px", lineHeight: "21px", letterSpacing: "0.15px" }}>Edit Status</Typography>
                    <Button variant="text" onClick={handleClose} className="modalClearBtn">
                      <ClearIcon />
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    Applicant ID: 00729
                  </Grid>
                  <Grid item xs={12} sx={{ background: "rgba(64, 158, 255, 0.05)" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        Candidate Performance
                      </Grid>

                      <Grid item xs={6}>
                        1. Answer Point
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        xs={6}
                        sx={{
                          color: "#1989FA",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "150%",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {`${candidate.answerPoints.points} / ${candidate.answerPoints.total} Points`}
                      </Grid>

                      <Grid item xs={6}>
                        2. Attendance Record
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        xs={6}
                        sx={{
                          color: "#1989FA",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "150%",
                          letterSpacing: "0.15px",
                        }}
                      >
                        {candidate.attendance}
                      </Grid>
                      <Grid item xs={6}>
                        3. Averager Interview Marks
                      </Grid>
                      <Grid
                        item
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        xs={6}
                        sx={{
                          color: "#1989FA",
                          fontWeight: 500,
                          fontSize: "14px",
                          lineHeight: "150%",
                          letterSpacing: "0.15px",
                        }}
                      >
                        60 / 130 Points
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid item xs={4}>
                        <Button variant="outlined" color="primary" fullWidth>
                          ACCEPT
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button variant="outlined" color="warning" fullWidth>
                          WAITING
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button variant="outlined" color="error" fullWidth>
                          REJECT
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <Divider sx={{ borderStyle: "dashed" }} />
              <Box>
                <Grid container sx={{ p: 3, maxHeight: `${height - 480}px`, overflow: "scroll" }}>
                  <Grid item xs={12}>
                    <Box>Evaluation Form Rated by:</Box>
                    <Box>Yeri Suen</Box>
                  </Grid>
                  <InterviewPerformanceRatingForm prefix={candidate.applicantId} rating={methods.getValues()[candidate.applicantId]} />
                </Grid>
              </Box>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default EditCandidateStatusModal;
