import { CreateApplicationStageFormInput } from "../components/applicationForm/create/CreateApplicationForm";
import client from "./interceptors";

export async function findApplicationStagesByApplicationListId(id: number) {
  const res = await client.get(`applicationList/${id}/applicationStage`);
  const form = res.data;
  return form;
}

export async function createApplicationStage(id: number, data: CreateApplicationStageFormInput) {
  const res = await client.post(`applicationList/${id}/applicationStage`, data);
  const result = res.data;
  return result;
}

export async function findApplicationStageById(listId: number, stageId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}`);
  const result = res.data;
  return result;
}

export async function editApplicationStageById(listId: number, stageId: number, data: CreateApplicationStageFormInput) {
  const res = await client.patch(`applicationList/${listId}/applicationStage/${stageId}`, data);
  const result = res.data;
  return result;
}

export async function findKeysForMailMergeFromAppicationStageId(listId: number, stageId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/mailMerge`);
  const form = res.data;
  return form;
}

export async function exportApplicationForm(listId: number, stageId: number, data: { selectedIds: number[] }) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/exportApplicationForm`, data, { responseType: "blob" });
  return res;
}
