import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { push } from "redux-first-history";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Button, Grid, InputLabel, Link, Switch, TextField, TextFieldProps, Typography } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getApplicationListById } from "../../../api/applicationList";
import { editApplicationStageById, findApplicationStageById } from "../../../api/applicationStage";
import { ApplicationList } from "../../../interfaces/applicationList";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";
import FieldsRootComponent from "./FieldsRootComponent";
import { useAppDispatch } from "../../../redux/hooks";

function EditApplicationForm() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const date = new Date();
  const [defaultValues, setDefaultValues] = useState<CreateApplicationStageFormInput>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listData, setListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setListData(result.data);
      setIsLoading(false);
    }
  }

  async function fetchApplicationForm(listId: number, stageId: number) {
    const result = await findApplicationStageById(listId, stageId);
    setDefaultValues(result.data);
  }

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm<CreateApplicationStageFormInput>({ defaultValues });

  useEffect(() => {
    if (params && params.applicationListId && params.formId) {
      const applicationListId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      fetchApplicationForm(applicationListId, formId);
    }

    if (params && params.applicationListId) {
      const applicationListId = parseInt(params.applicationListId);
      fetchApplicationList(applicationListId);
    }
  }, [params, setValue]);

  const onSubmit: SubmitHandler<CreateApplicationStageFormInput> = async (data) => {
    if (params && params.applicationListId && params.formId) {
      const applicationListId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);

      const result = await editApplicationStageById(applicationListId, formId, data);
      if (result.success) {
        dispatch(push(`/cms/school/${lastSchool}/application_list/${applicationListId}/summary`));
      }
    }
  };

  const onReturn = () => {
    navigate(-1);
  };

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId) {
      const listId = parseInt(params.applicationListId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/summary`));
    }
  }

  useEffect(() => {
    if (defaultValues) {
      setValue("fieldContent", defaultValues.fieldContent);
    }
  }, [defaultValues, setValue]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHK" ? listData.titleZhHk : i18n.resolvedLanguage === "enUS" ? listData.titleEnUs : null}
    </Link>,
    <Typography key="3" color="primary.main">
      Edit Application
    </Typography>,
  ];

  return (
    <>
      {isLoading && (
        <Grid sx={{ m: 2 }}>
          <div>Loading...</div>
        </Grid>
      )}
      {!isLoading && defaultValues && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid sx={{ m: 2 }}>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
            <Grid sx={{ my: 1 }}>
              <Grid container sx={{ p: 2 }} className="formInfoContainer">
                <Grid container>
                  <Grid item xs={12}>
                    <div className="pageTitle">{t("createForm.formInfo.formInformation")}</div>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={12} md={12} display="flex" alignItems="center">
                    <InputLabel>{t("createForm.formInfo.publish")}</InputLabel>
                    <Controller
                      name={"isPublished" as const}
                      control={control}
                      defaultValue={(defaultValues && defaultValues.isPublished) || false}
                      render={({ field }) => <Switch {...field} defaultChecked={(defaultValues && defaultValues.isPublished) || false} />}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name={"titleEnUs" as const}
                      control={control}
                      rules={{ required: "Form name (English) is required" }}
                      defaultValue={(defaultValues && defaultValues.titleEnUs) || ""}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={t("createForm.formInfo.formNameEng")}
                          error={errors.titleEnUs && true}
                          helperText={errors.titleEnUs && `${errors.titleEnUs.message}`}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name={"titleZhHk" as const}
                      control={control}
                      rules={{ required: "Form name (Chinese) is required" }}
                      defaultValue={(defaultValues && defaultValues.titleZhHk) || ""}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={t("createForm.formInfo.formNameCh")}
                          error={errors.titleZhHk && true}
                          helperText={errors.titleZhHk && `${errors.titleZhHk.message}`}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <Controller
                      name={"admissionLimit" as const}
                      control={control}
                      rules={{
                        required: "Application Limit is required",
                        validate: (value) => value >= 0,
                      }}
                      defaultValue={(defaultValues && defaultValues.admissionLimit) || ("" as unknown as number)}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label={t("createForm.formInfo.applicationLimit")}
                          error={errors.admissionLimit && true}
                          helperText={
                            errors.admissionLimit &&
                            (errors.admissionLimit.type === "required"
                              ? `${errors.admissionLimit.message}`
                              : errors.admissionLimit.type === "validate"
                              ? "A positive number is required"
                              : null)
                          }
                          inputProps={{ type: "number" }}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            if (parseInt(e.target.value) >= 0) {
                              field.onChange(parseInt(e.target.value));
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6}>
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                      <Controller
                        name="deadline"
                        control={control}
                        rules={{
                          required: t("forms.required"),
                          validate: (value) => value !== null && value > new Date(),
                        }}
                        defaultValue={(defaultValues && moment(defaultValues.deadline).toDate()) || moment(new Date(date.setDate(date.getDate() + 1))).toDate()}
                        render={({ field }) => (
                          <DesktopDatePicker
                            {...field}
                            inputFormat="DD/MM/yyyy"
                            onChange={(e) => {
                              field.onChange(moment(e).toDate());
                            }}
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            renderInput={(params: TextFieldProps) => (
                              <TextField
                                {...params}
                                fullWidth
                                label={t("createForm.formInfo.applicationDeadline")}
                                error={errors.deadline && true}
                                helperText={
                                  errors.deadline &&
                                  (errors.deadline.type === "required" ? `${errors.deadline.message}` : errors.deadline.type === "validate" ? t("forms.incorrectFormat") : null)
                                }
                              />
                            )}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid container alignItems="center">
              <Grid item xs={12}>
                <h1 className="pageTitle">{t("createForm.formContent.formContent")}</h1>
              </Grid>
            </Grid>

            <FieldsRootComponent
              {...{
                control,
                register,
                watch,
                setValue,
                getValues,
                errors,
              }}
            />
          </Grid>

          <div className="createApplicationFormFooter">
            <Grid sx={{ m: 2 }}>
              <Grid container>
                <Grid item xs={1}>
                  {/* <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={() => {
                                            console.log("fieldContent =", getValues().fieldContent);
                                        }}
                                    >
                                        {t("createForm.formContent.preview")}
                                    </Button> */}
                </Grid>
                <Grid item xs={9}></Grid>
                <Grid item xs={2} display="flex" justifyContent="end">
                  <Button variant="outlined" onClick={onReturn} className="formCancelBtn formFooterBtn">
                    {t("createForm.formContent.cancel")}
                  </Button>

                  <Button type="submit" variant="contained" color="primary" className="formFooterBtn">
                    {t("createForm.formContent.submit")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </form>
      )}
    </>
  );
}

export default EditApplicationForm;
