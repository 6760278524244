import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import NotFound from "../../utils/NotFound";
import ViewInterviewDashboard from "../view/ViewInterviewDashboard";
import EditInterview from "./EditInterview";

function InterviewRouter() {
  const lastSchool = sessionStorage.getItem("lastSchool");

  return (
    <Routes>
      <Route path="" element={<ViewInterviewDashboard />} />
      <Route path="/edit" element={<EditInterview />} />
      {lastSchool ? <Route path="*" element={<Navigate to={`/cms/school/${lastSchool}/application/register`} />} /> : <Route path="*" element={<NotFound />} />}
    </Routes>
  );
}

export default InterviewRouter;
