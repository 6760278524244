import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { editApplicationListById } from "../../../api/applicationList";
import { ApplicationListStatusEnum } from "../../../models/applicationListStatusEnum";
import modalStyle from "../../utils/modalStyle";

interface Props {
  status: string;
  titleEnUs: string;
  titleZhHk: string;
  reload: () => void;
}

export interface EditApplicationListForm {
  titleEnUs: string;
  titleZhHk: string;
  status: string;
}

function EditApplicationButton(props: Props) {
  const { status, titleEnUs, titleZhHk, reload } = props;
  const params = useParams();
  const { t } = useTranslation();
  const [open, setOpen] = useState<boolean>(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const defaultValues: EditApplicationListForm = {
    titleEnUs,
    titleZhHk,
    status,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<EditApplicationListForm>({ defaultValues });

  const onSubmit: SubmitHandler<EditApplicationListForm> = async (data) => {
    if (params && params.applicationListId) {
      const id = parseInt(params.applicationListId);

      const result = await editApplicationListById(id, data);

      if (result.success) {
        setOpen(false);
        reload();
      }
    }
  };

  const applicationListStatus = [
    {
      value: ApplicationListStatusEnum.INCOMPLETE_SETUP,
      label: t("incompletedSetup", { ns: "applicationListStatus" }),
    },
    {
      value: ApplicationListStatusEnum.NOT_PUBLISHED,
      label: t("unpublished", { ns: "applicationListStatus" }),
    },
    {
      value: ApplicationListStatusEnum.EXAMINATION,
      label: t("examination", { ns: "applicationListStatus" }),
    },
    {
      value: ApplicationListStatusEnum.CALL_FOR_REGISTRATION,
      label: t("callForRegistration", { ns: "applicationListStatus" }),
    },
    {
      value: ApplicationListStatusEnum.CALL_FOR_APPLICATION,
      label: t("callForApplication", { ns: "applicationListStatus" }),
    },
    {
      value: ApplicationListStatusEnum.COMPLETED,
      label: t("completed", { ns: "applicationListStatus" }),
    },
  ];

  return (
    <>
      <Button onClick={handleOpen} variant="contained">
        {t("view.edit")}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                <div>{t("view.editApplicationButton.editApplication")}</div>
                <Button variant="text" onClick={handleClose} className="modalClearBtn">
                  <ClearIcon />
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={"titleEnUs" as const}
                  control={control}
                  rules={{ required: "Application Title (English) is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t("view.editApplicationButton.applicationTitleEn")}
                      error={errors.titleEnUs && true}
                      helperText={errors.titleEnUs && `${errors.titleEnUs.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={"titleZhHk" as const}
                  control={control}
                  rules={{ required: "Application Title (Traditional Chinese) is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t("view.editApplicationButton.applicationTitleTs")}
                      error={errors.titleZhHk && true}
                      helperText={errors.titleZhHk && `${errors.titleZhHk.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="applicationStatusSelectLabel">{t("view.applicationStatus")}</InputLabel>
                  <Controller
                    name={"status" as const}
                    control={control}
                    render={({ field }) => (
                      <Select {...field} label="Application Status" labelId="applicationStatusSelectLabel" id="applicationStatusLabel" variant="outlined">
                        {applicationListStatus.map((listStatus) => {
                          return (
                            <MenuItem key={listStatus.value} value={listStatus.value}>
                              {listStatus.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={handleClose}>
                  {t("view.cancel")}
                </Button>
                <Button variant="text" type="submit">
                  {t("view.updateApplicantStatusButton.update")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </>
  );
}

export default EditApplicationButton;
