import { push } from "redux-first-history";
import { createSubmission, createSubmissionAttachment, saveSubmission } from "../../api/submission";
import { ApplicationStageFormStatus } from "../../models/applicationStageFormStatus";
import { RootState, AppDispatch } from "../store";

export interface submissionFormData {
  userId: number;
  fields: {
    [key: string]: string;
  };
  formStatus: ApplicationStageFormStatus;
  fileFields: string[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function fetchSubmitApplication(listId: number, stageId: number, data: any) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().auth.user;
    const lastSchool = sessionStorage.getItem("lastSchool");

    const formData = new FormData();
    const fileFields = [];

    for (const field in data) {
      if (data[field] instanceof FileList) {
        const fileObject = [];

        fileFields.push(field);

        for (let i = 0; i < data[field].length; i++) {
          formData.append(field, data[field][i]);
          fileObject.push(data[field][i]);
        }
        data[field] = fileObject;
      }
    }

    if (userId) {
      const formattedData = {
        userId,
        fields: data,
        formStatus: ApplicationStageFormStatus.COMPLETED,
        fileFields,
      };

      const result = await createSubmission(listId, stageId, formattedData);
      if (result.success) {
        const submissionId = result.data.id;
        if (fileFields.length !== 0) {
          const attachment = await createSubmissionAttachment(listId, stageId, submissionId, formData);
          if (attachment.success) {
            dispatch(push(`/cms/school/${lastSchool}/application/list`));
          } else {
            dispatch(push(`/cms/school/${lastSchool}/application/list`));
          }
        } else {
          dispatch(push(`/cms/school/${lastSchool}/application/list`));
        }
      } else {
        dispatch(push(`/cms/school/${lastSchool}/application/list`));
      }
    }
  };
}

export function fetchSaveApplication(listId: number, stageId: number, fields: { [key: string]: string }, fileFields: string[], formData: FormData) {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    const userId = getState().auth.user;
    const lastSchool = sessionStorage.getItem("lastSchool");

    if (userId) {
      const data = {
        userId,
        fields,
        formStatus: ApplicationStageFormStatus.INCOMPLETE,
        fileFields,
      };

      const result = await saveSubmission(listId, stageId, data);

      if (result.success) {
        const submissionId = result.data.id;

        if (fileFields.length !== 0) {
          const attachment = await createSubmissionAttachment(listId, stageId, submissionId, formData);

          if (attachment.success) {
            dispatch(push(`/cms/school/${lastSchool}/application/list`));
          } else {
            dispatch(push(`/cms/school/${lastSchool}/application/list`));
          }
        } else {
          dispatch(push(`/cms/school/${lastSchool}/application/list`));
        }
      } else {
        dispatch(push(`/cms/school/${lastSchool}/application/list`));
      }
    }
  };
}
