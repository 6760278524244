/* eslint-disable no-else-return */
/* eslint-disable react/require-default-props */
/* eslint-disable react/function-component-definition */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { Grid } from "@mui/material";
import { RootState } from "../../redux/store";

interface Props {
  // eslint-disable-next-line no-undef
  children: JSX.Element;
  path?: string;
}

// eslint-disable-next-line no-undef
export const PrivateRoute: React.FC<Props> = ({ children, path }) => {
  const isStudentAuthenticated = useSelector((state: RootState) => state.auth.isStudentAuthenticated);
  const lastSchool = sessionStorage.getItem("lastSchool");
  const isLoading = useSelector((state: RootState) => state.auth.isLoading);
  const user = useSelector((state: RootState) => state.auth.user);
  useEffect(() => {
    console.log("isStudentAuthenticated", isStudentAuthenticated);
  }, [isStudentAuthenticated]);

  if (!isLoading && isStudentAuthenticated && user) {
    return children;
  } else if (!isLoading && (!isStudentAuthenticated || !user)) {
    return <Navigate to={`/cms/school/${lastSchool}/application/login`} replace state={{ path }} />;
  } else {
    return <Grid sx={{ m: 3 }}>Loading...</Grid>;
  }
};

export default PrivateRoute;
