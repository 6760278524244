import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, InputLabel, MenuItem, Modal, Select, TextField, Typography } from "@mui/material";
import React, { SyntheticEvent, useEffect, useState, useTransition } from "react";
import { Controller, FormProvider, SubmitHandler, useForm, useFormContext, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { createInterviewCriteriaById } from "../../../api/interview";
import { Condition, ConditionFormInput, Criteria, Interview } from "../../../interfaces/interview";
import AnswerPoint from "./AnswerPoint";
import ConditionGroup from "./ConditionGroup";
import ConnectForm from "./ConnectForm";
import useWindowDimensions from "./useWindowDimensions";

interface EditCriteriaSettingModalProps {
  interviewId: number;
  criteria?: Criteria;
}

const defaultProps = {
  criteria: null,
};

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  p: 3,
  borderRadius: 0.5,
};

function EditCriteriaSettingModal(props: EditCriteriaSettingModalProps) {
  const { criteria, interviewId } = props;
  const { t } = useTranslation();
  const params = useParams();

  const [open, setOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [defaultValue, setDefaultValue] = useState<Condition>({
    operator: "QUEST",
    question: "",
    condition: "",
    answer: "",
    group: [],
  });
  const [expanded, setExpanded] = useState<string | false>(false);
  const [isLoadingCriteria, setIsLoadingCriteria] = useState(true);

  // const { addCondition, removeCondition } = useConditionFormField("criteria.condition");
  const methods = useForm<Criteria>();

  const {
    control: createControl,
    formState: { errors: createErrors },
    trigger: createTrigger,
    reset,
  } = useForm<ConditionFormInput>();
  const { height } = useWindowDimensions();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    methods.reset();
    methods.clearErrors();
    setOpen(false);
  };

  const handleAccordionChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const onSubmit: SubmitHandler<Criteria> = async (data) => {
    console.log("criteria data =", data);
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const stageId = parseInt(params.formId);
      await createInterviewCriteriaById(listId, stageId, interviewId, { criteria: data });
    }
  };

  useEffect(() => {
    if (criteria) {
      const fields = Object.keys(criteria);

      fields.map((field) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return methods.setValue(field, criteria[field]);
      });
      setIsLoadingCriteria(false);
    }
  }, []);

  return (
    <>
      <Button className="buttonGroupSent" variant="outlined" size="small" onClick={handleOpen}>
        ADD CRITERIA
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Box style={{ maxHeight: "80%" }} sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                  <div>Interview Criteria Setting</div>
                  <Button variant="text" onClick={handleClose} className="modalClearBtn">
                    <ClearIcon />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Controller
                      name={"title.zhHk" as const}
                      control={methods.control}
                      rules={{ required: "Criteria Title (Traditional Chinese) is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          margin="dense"
                          variant="standard"
                          fullWidth
                          label="Criteria Title (Traditional Chinese)"
                          error={methods.formState.errors?.title?.zhHk && true}
                          helperText={methods.formState.errors?.title?.zhHk && `${methods.formState.errors?.title?.zhHk?.message}`}
                        />
                      )}
                    />
                  }
                </Grid>
                <Grid item xs={6}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Controller
                      name={"title.enUs" as const}
                      control={methods.control}
                      rules={{ required: "Criteria Title (English) is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          margin="dense"
                          variant="standard"
                          fullWidth
                          label="Criteria Title (English)"
                          error={methods.formState.errors?.title?.enUs && true}
                          helperText={methods.formState.errors?.title?.enUs && `${methods.formState.errors?.title?.enUs?.message}`}
                        />
                      )}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  <Accordion expanded={expanded === "panel1"} onChange={handleAccordionChange("panel1")} sx={{ boxShadow: 0 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1" id="panel1">
                      <Typography sx={{ fontSize: 16, color: "#303133", fontWeight: 500, lineHeight: "150%" }}>Answer Point Setting</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ maxHeight: `${height - 600}px`, overflow: "scroll" }}>
                      {criteria && criteria.answerPoints && (
                        <Grid container spacing={1}>
                          {criteria.answerPoints.map((answerPointObject, index) => (
                            <AnswerPoint
                              key={`${answerPointObject.question.position}-${answerPointObject.question.question}`}
                              answerPoint={answerPointObject}
                              prefix={["answerPoints", index]}
                            />
                          ))}
                        </Grid>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === "panel2"} onChange={handleAccordionChange("panel2")} sx={{ boxShadow: 0 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2" id="panel2">
                      <Typography sx={{ fontSize: 16, color: "#303133", fontWeight: 500, lineHeight: "150%" }}>Condition Setting</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ maxHeight: `${height - 600}px`, overflow: "scroll" }}>
                      {!isLoadingCriteria && (
                        <ConditionGroup
                          condition={methods.getValues().condition}
                          index={0}
                          parentIndex={-1}
                          parentLength={-1}
                          // onRemove={removeCondition}
                          // onUpdateCondition={handleUpdateRoot}
                          prefix={["condition"]}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={handleClose}>
                  {t("view.cancel")}
                </Button>
                <Button variant="text" type="submit">
                  ADD CRITERIA
                </Button>
              </Grid>
            </Box>
          </form>
        </FormProvider>
      </Modal>
    </>
  );
}

EditCriteriaSettingModal.defaultProps = defaultProps;

export default EditCriteriaSettingModal;
