import React, { ReactNode } from "react";
import { Control, Controller, FieldErrors, FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControl, FormControlLabel, FormHelperText } from "@mui/material";
import { SingleQuestion } from "./SubmissionForm";
import QuestionComponent from "./QuestionComponent";

interface Props {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
  question: SingleQuestion;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SingleCheckBox({ register, errors, setValue, getValues, control, question }: Props) {
  const { t, i18n } = useTranslation();

  return (
    <>
      {question.mandatory === true ? (
        <FormControl error={errors[question.id] && true}>
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            rules={{ required: t("forms.required") }}
            render={({ field }) => (
              <FormControlLabel
                className="submissionNormalText"
                checked={getValues(question.id) || false}
                control={
                  <Checkbox
                    {...field}
                    sx={{
                      color: "#888888",
                      "&.Mui-checked": {
                        color: "#888888",
                      },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={i18n.resolvedLanguage === "zhHK" ? `${question.checkboxQuestions?.zhHK}` : `${question.checkboxQuestions?.enUS}`}
              />
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      ) : (
        <FormControl error={errors[question.id] && true}>
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            render={({ field }) => (
              <FormControlLabel
                className="submissionNormalText"
                checked={getValues(question.id) || false}
                control={
                  <Checkbox
                    {...field}
                    sx={{
                      color: "#888888",
                      "&.Mui-checked": {
                        color: "#888888",
                      },
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={i18n.resolvedLanguage === "zhHK" ? `${question.checkboxQuestions?.zhHK}` : `${question.checkboxQuestions?.enUS}`}
              />
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      )}
    </>
  );
}

export default SingleCheckBox;
