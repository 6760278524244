import { Button } from "@mui/material";
import React from "react";
import { Interviewee, InterviewTimeSlot } from "../../../../interfaces/interview";
import SwitchInterviewModal from "../SwtichInterviewModal";

interface AllCandidatesActionButtonsProps {
  interviewee: Interviewee;
  timeSlots: InterviewTimeSlot[];
}

function AllCandidatesActionButtons(props: AllCandidatesActionButtonsProps) {
  const { interviewee, timeSlots } = props;
  return (
    <>
      {interviewee.interviews.length > 0 && <SwitchInterviewModal timeSlots={timeSlots} interviewee={interviewee} />}
      {/* <Button>CANCEL</Button> */}
    </>
  );
}

export default AllCandidatesActionButtons;
