import React, { ChangeEvent } from "react";
import { Draggable } from "react-beautiful-dnd";
import { makeStyles } from "@mui/styles";
import { Box, Checkbox, IconButton, ListItem, ListItemText } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";

const useStyles = makeStyles({
  draggingListItem: {
    background: "rgb(235,235,235)",
  },
});

export type DraggableListItemProps = {
  def: GridColDef;
  visibility: boolean;
  index: number;
  onChange: (event: ChangeEvent, checked: boolean) => void;
};

function DraggableListItem(props: DraggableListItemProps) {
  const { def, visibility, index, onChange } = props;
  const classes = useStyles();
  return (
    <Draggable draggableId={def.field} index={index}>
      {(provided, snapshot) => (
        <ListItem ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className={snapshot.isDragging ? classes.draggingListItem : ""}>
          <Checkbox checked={visibility} onChange={onChange} />
          <ListItemText primary={def.headerName} />
          <IconButton color="primary" size="small">
            <EditIcon />
          </IconButton>
        </ListItem>
      )}
    </Draggable>
  );
}

export default DraggableListItem;
