import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid";
import React from "react";
import { DragDropContext, Droppable, OnDragEndResponder } from "react-beautiful-dnd";
import DraggableListItem from "./DraggableListItem";

export type DraggableListProps = {
  defs: GridColDef[];
  visibilityModel: GridColumnVisibilityModel;
  onDragEnd: OnDragEndResponder;
  onChange: (key: string, checked: boolean) => void;
};

function DraggableList(props: DraggableListProps) {
  const { defs, visibilityModel, onDragEnd, onChange } = props;

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {defs.map((def, index) => (
              <DraggableListItem key={def.field} def={def} visibility={visibilityModel[def.field]} index={index} onChange={(_, checked) => onChange(def.field, checked)} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default DraggableList;
