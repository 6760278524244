import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CreateMessage from "./CreateMessage";
import CreateTemplate from "./CreateTemplate";
import EditMessage from "./EditMessage";
import EditTemplate from "./EditTemplate";
import MessageTemplateList from "./MessageTemplateList";
import SendMessage from "./SendMessage";
import MessageDashboard from "./MessageDashboard";
import NotFound from "../../utils/NotFound";

function MessageRouter() {
  const lastSchool = sessionStorage.getItem("lastSchool");

  return (
    <Routes>
      <Route path="" element={<SendMessage />} />
      <Route path="create" element={<CreateMessage />} />
      <Route path=":messageId/edit" element={<EditMessage />} />
      <Route path=":messageId/dashboard" element={<MessageDashboard />} />
      <Route path="template" element={<MessageTemplateList />} />
      <Route path="template/create" element={<CreateTemplate />} />
      <Route path="template/:templateId/edit" element={<EditTemplate />} />
      {lastSchool ? <Route path="*" element={<Navigate to={`/cms/school/${lastSchool}/application/register`} />} /> : <Route path="*" element={<NotFound />} />}
    </Routes>
  );
}

export default MessageRouter;
