import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputAdornment, Rating as RatingUI, TextareaAutosize, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Controller, useFormContext, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PointsTotal, Rating } from "../../../interfaces/interview";
import translate from "../../utils/translate";

interface InterviewPerformanceRatingFormProps {
  prefix: number;
  rating: Rating[];
}

const useStyles = makeStyles({
  textarea: {
    resize: "vertical",
    minHeight: "23px",
  },
});

function InterviewPerformanceRatingForm(props: InterviewPerformanceRatingFormProps) {
  const { prefix, rating } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const methods = useFormContext<Rating[][]>();

  return (
    <>
      {rating.map((item, index) => (
        <Grid item xs={12} key={`${item.itemType}-${item.itemTitle.enUs}`}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sx={{
                    fontWeight: 400,
                    fontSize: "14px",
                    lineHeight: "21px",
                    letterSpacing: "0.15px",
                    color: "#606266",
                  }}
                >
                  <span>{`${index + 1}. ${translate(i18n, item.itemTitle)}`}</span>
                  {item.fullMarks && <span>{` (${item.fullMarks} Marks)`}</span>}
                </Grid>
                {item.description && (
                  <Grid item xs={12} sx={{ fontWeight: 300, fontSize: "12px", lineHeight: "18px", letterSpacing: "0.15px", color: "#606266" }}>
                    <span>{`${translate(i18n, item.description)}`}</span>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {item.fullMarks && item.fullMarks > 0 && (
              <Grid item xs={12}>
                <Controller
                  name={`${prefix}.${index}.marks` as const}
                  control={methods.control}
                  rules={{ required: "Marks is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="number"
                      // label="Marks"
                      error={methods.formState.errors?.[index]?.[index]?.marks && true}
                      helperText={methods.formState.errors?.[index]?.[index]?.marks && `${methods.formState.errors?.[index]?.[index]?.marks?.message}`}
                      onChange={(event) => {
                        if (event.target.value === "" || (item.fullMarks && parseInt(event.target.value) >= 0 && parseInt(event.target.value) <= item.fullMarks)) {
                          field.onChange(event.target.value);
                        }
                      }}
                      InputProps={{
                        type: "number",
                        endAdornment: (
                          <InputAdornment position="end">
                            <span
                              style={{
                                fontWeight: 400,
                                fontSize: "16px",
                                lineHeight: "28px",

                                letterSpacing: "0.15px",

                                color: "#9A9DA4",
                              }}
                            >
                              Mark(s)
                            </span>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            {item.itemType === "text" && (
              <Grid item xs={12}>
                <Controller
                  name={`${prefix}.${index}.comments` as const}
                  control={methods.control}
                  rules={{ required: " is required" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      sx={{ resize: "vertical", width: 1 }}
                      placeholder={t("interview.comments")}
                      multiline
                      inputProps={{ className: classes.textarea }}
                      onChange={(event) => field.onChange(event.target.value)}
                    />
                  )}
                />
              </Grid>
            )}
            {item.itemType === "linearScale" && (
              <Grid item xs={12}>
                <Controller
                  name={`${prefix}.${index}.rating` as const}
                  control={methods.control}
                  // rules={{ required: " is required" }}
                  render={({ field }) => (
                    <RatingUI
                      {...field}
                      onChange={(event, newValue) => field.onChange(newValue)}
                      icon={<StarIcon color="primary" sx={{ paddingRight: "20px" }} />}
                      emptyIcon={<StarBorderIcon sx={{ paddingRight: "20px" }} />}
                    />
                  )}
                />
              </Grid>
            )}
            {item.itemType === "multipleChoice" && (
              <Grid item xs={12}>
                <FormControl component="fieldset" variant="standard">
                  <FormGroup sx={{ ml: 2 }}>
                    <Controller
                      name={`${prefix}.${index}.options`}
                      control={methods.control}
                      defaultValue={[]}
                      render={({ field }) => (
                        <>
                          {item.multipleChoice.option.map((option) => (
                            <FormControlLabel
                              key={`${item.itemType}-${option.enUs}-${option.zhHk}`}
                              sx={{ height: "22px", mb: "8px" }}
                              label={
                                <Typography
                                  sx={{
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    lineHeight: "157%",
                                    letterSpacing: "0.15px",
                                    color: "#606266",
                                  }}
                                >
                                  {option.others ? t("forms.others") : translate(i18n, option)}
                                </Typography>
                              }
                              control={
                                <Checkbox
                                  sx={{ color: "#DCDFE6", pl: 1 }}
                                  value={translate(i18n, option)}
                                  checked={field.value.some((existingValue) => existingValue === translate(i18n, option))}
                                  onChange={(event, checked) => {
                                    if (checked) {
                                      field.onChange([...field.value, event.target.value]);
                                    } else {
                                      field.onChange(field.value.filter((value) => value !== event.target.value));
                                    }
                                  }}
                                />
                              }
                            />
                          ))}
                        </>
                      )}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
}

export default InterviewPerformanceRatingForm;
