import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Grid, IconButton, InputLabel, Modal, Tab } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { SyntheticEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { DataGridPro, GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import moment from "moment";
import { EvaluationForm, Info, Interview, Interviewee, InterviewTimeSlot } from "../../../interfaces/interview";
import RowAction from "./RowAction";
import CandidateListActionButtons from "./buttonGroup/CandidateListActionButtons";
import InterviewPerformanceRatingModal from "./InterviewPerformanceRatingModal";
import GroupInterviewPerformanceRatingModal from "./GroupInterviewPerformanceRatingModal";
import AddCandidatePopover from "./AddCandidatePopover";
import CustomizeViewMenu from "./DraggableMenu/CustomizeViewMenu";
import TimeSlotDetailToolbar from "./toolbar/TimeSlotDetailToolbar";

interface TimeSlotDetailModalProps {
  interview: Interview;
  evaluationForm: EvaluationForm[];
  timeSlot: InterviewTimeSlot;
  title: string;
  applicationList: Interviewee[];
}

const style = {
  position: "absolute",
  maxWidth: "90%",
  maxHeight: "90%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1410,
  height: 730,
  bgcolor: "background.paper",
  // p: 3,
  borderRadius: 0.5,
};

const useStyles = makeStyles({
  tab: {
    "&.Mui-selected": {
      color: "#1976d2",
    },
  },
  hideRightSeparator: {
    "& > .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
  },
  // modal: {
  //     maxWidth: "90%",
  //     maxHeight: "90%",
  //     position: "absolute",
  //     top: "50%",
  //     left: "50%",
  //     transform: "translate(-50%, -50%)",
  //     width: 1410,
  //     height: 730,
  //     bgcolor: "background.paper",
  //     // p: 3,
  //     borderRadius: 0.5,
  // },
});

function TimeSlotDetailModal(props: TimeSlotDetailModalProps) {
  const { interview, applicationList, evaluationForm, timeSlot, title } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState("1");
  const [openCustomizeView, setOpenCustomizeView] = useState(false);
  const [applicationHeadCells, setApplicationHeadCells] = useState<GridColDef[]>([
    {
      field: "applicantId",
      headerName: "Applicant ID",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
    },
    {
      field: "contactEmail",
      headerName: "Contact Email",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
    },
    {
      field: "relatedApplicantId",
      headerName: "Related Applicant",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
    },
    {
      field: "a",
      headerName: "A",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
    },
    {
      field: "b",
      headerName: "B",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
    },
    {
      field: "c",
      headerName: "C",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
    },
    {
      field: "d",
      headerName: "D",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
    },
    {
      field: "e",
      headerName: "E",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
    },
    {
      field: "attendance",
      headerName: "Attendance",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      renderCell: (params) => {
        if (params.row.attendance === "-") {
          return params.row.attendance;
        }
        return <InputLabel sx={{ fontSize: 14, color: "#67C23A", bgcolor: "#F0F9EB", width: 68, textAlign: "center", borderRadius: 30 }}>{params.row.attendance}</InputLabel>;
      },
    },
    {
      field: "rating",
      headerName: "Rating",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      renderCell: (params) => (
        <InterviewPerformanceRatingModal
          prefix={params.row.applicantId}
          applicantId={params.row.applicantId}
          answerPoints={params.row.answerPoints}
          rating={params.row.rating.length > 0 ? params.row.rating : evaluationForm}
        />
      ),
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      renderCell: (params) => {
        if (params.row.status === "Accept") {
          return params.row.status;
        }
        return <InputLabel sx={{ fontSize: 14, color: "#909399", bgcolor: "#F4F4F5", width: 68, textAlign: "center", borderRadius: 30 }}>{params.row.status}</InputLabel>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      renderCell: (params) => (
        <RowAction>
          <CandidateListActionButtons candidate={params.row} evaluationForm={evaluationForm} />
        </RowAction>
      ),
    },
  ]);
  const [timeSlotDetailColumnVisibilityModel, setTimeSlotDetailColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    ...applicationHeadCells.reduce((prev, curr) => ({ ...prev, [curr.field]: false }), {}),
    applicantId: true,
    attendance: true,
    rating: true,
    status: true,
    action: true,
  });

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeTab = (event: SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <>
      <Button variant="contained" size="small" className="buttonAction buttonActionGreen" onClick={handleOpen}>
        {t("interview.details")}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={style}>
          <Grid container>
            <Grid item xs={3}>
              <Box sx={{ bgcolor: "#FAFAFA", height: 666, p: 4 }}>
                <Grid container spacing={1.5}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      fontWeight: 500,
                      fontSize: 20,
                      lineHeight: "24px",
                      letterSpacing: 0.15,
                    }}
                  >
                    {title}
                  </Grid>
                  <Grid item xs={12}>
                    <Box
                      sx={{
                        width: 64,
                        height: 20,
                        borderRadius: "4px",
                        background: "#C3C3C3",
                        color: "#FFFFFF",
                        fontWight: 500,
                        fontSize: "12px",
                        lineHeight: "117%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        letterSpacing: 0.15,
                      }}
                    >
                      Preparing
                    </Box>
                  </Grid>
                  {/* <Grid item xs={6}>
                                        test
                                    </Grid>
                                    <Grid item xs={6}>
                                        test
                                    </Grid> */}
                  <Grid item xs={12}>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        sx={{
                          fontWeight: 500,
                          fontSize: 16,
                          lineHeight: "20px",
                          display: "flex",
                          alignItems: "center",
                          color: "#303133",
                        }}
                      >
                        Interview Information
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            sx={{
                              width: 336,
                              height: 62,
                              border: "1px solid #D8DCE6",
                              borderRadius: "4px",
                              bgcolor: "#FFFFFF",
                              py: 1.5,
                              px: 2,
                            }}
                            display="grid"
                            alignItems="center"
                          >
                            <Grid
                              sx={{
                                fontWeight: 300,
                                fontSize: 12,
                                lineHeight: "15px",
                                display: "flex",
                                alignItems: "center",
                                letterSpacing: "0.15px",
                                color: "#606266",
                              }}
                            >
                              Date
                            </Grid>

                            <Grid>{moment(timeSlot.date).format("YYYY-MM-DD")}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            sx={{
                              width: 336,
                              height: 62,
                              border: "1px solid #D8DCE6",
                              borderRadius: "4px",
                              bgcolor: "#FFFFFF",
                              py: 1.5,
                              px: 2,
                            }}
                            display="grid"
                            alignItems="center"
                          >
                            <Grid
                              sx={{
                                fontWeight: 300,
                                fontSize: 12,
                                lineHeight: "15px",
                                display: "flex",
                                alignItems: "center",
                                letterSpacing: "0.15px",
                                color: "#606266",
                              }}
                            >
                              Time
                            </Grid>
                            <Grid>{`${moment(timeSlot.startTime).format("HH:mm")} - ${moment(timeSlot.endTime).format("HH:mm")}`}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            sx={{
                              width: 336,
                              height: 62,
                              border: "1px solid #D8DCE6",
                              borderRadius: "4px",
                              bgcolor: "#FFFFFF",
                              py: 1.5,
                              px: 2,
                            }}
                            display="grid"
                            alignItems="center"
                          >
                            <Grid
                              sx={{
                                fontWeight: 300,
                                fontSize: 12,
                                lineHeight: "15px",
                                display: "flex",
                                alignItems: "center",
                                letterSpacing: "0.15px",
                                color: "#606266",
                              }}
                            >
                              Quota
                            </Grid>
                            <Grid>{timeSlot.quota}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            sx={{
                              width: 336,
                              height: 62,
                              border: "1px solid #D8DCE6",
                              borderRadius: "4px",
                              bgcolor: "#FFFFFF",
                              py: 1.5,
                              px: 2,
                            }}
                            display="grid"
                            alignItems="center"
                          >
                            <Grid
                              sx={{
                                fontWeight: 300,
                                fontSize: 12,
                                lineHeight: "15px",
                                display: "flex",
                                alignItems: "center",
                                letterSpacing: "0.15px",
                                color: "#606266",
                              }}
                            >
                              Course Centre
                            </Grid>

                            <Grid>{timeSlot.centre}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            sx={{
                              width: 336,
                              height: 62,
                              border: "1px solid #D8DCE6",
                              borderRadius: "4px",
                              bgcolor: "#FFFFFF",
                              py: 1.5,
                              px: 2,
                            }}
                            display="grid"
                            alignItems="center"
                          >
                            <Grid
                              sx={{
                                fontWeight: 300,
                                fontSize: 12,
                                lineHeight: "15px",
                                display: "flex",
                                alignItems: "center",
                                letterSpacing: "0.15px",
                                color: "#606266",
                              }}
                            >
                              Interviewer
                            </Grid>

                            <Grid>{timeSlot.interviewer.join(",")}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container>
                          <Grid
                            item
                            sx={{
                              width: 336,
                              height: 62,
                              border: "1px solid #D8DCE6",
                              borderRadius: "4px",
                              bgcolor: "#FFFFFF",
                              py: 1.5,
                              px: 2,
                            }}
                            display="grid"
                            alignItems="center"
                          >
                            <Grid
                              sx={{
                                fontWeight: 300,
                                fontSize: 12,
                                lineHeight: "15px",
                                display: "flex",
                                alignItems: "center",
                                letterSpacing: "0.15px",
                                color: "#606266",
                              }}
                            >
                              Room
                            </Grid>

                            <Grid>{timeSlot.room}</Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={9}>
              <Box sx={{ width: "100%", position: "relative" }}>
                <Button onClick={handleClose} className="modalClearBtn" variant="text" sx={{ position: "absolute", mt: "8px", mr: "8px", right: 0 }}>
                  <ClearIcon />
                </Button>
                <Grid container>
                  {/* <Grid item xs={12} display="flex" justifyContent="end" alignItems="center" className="modalTitleContainer">
                                        <Button
                                            onClick={handleClose}
                                            className="modalClearBtn"
                                            variant="text"
                                            sx={{ position: "absolute", mt: "40px", mr: "8px" }}
                                        >
                                            <ClearIcon />
                                        </Button>
                                    </Grid> */}
                  <Grid item xs={12}>
                    <TabContext value={tab}>
                      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                        <TabList onChange={handleChangeTab} aria-label="lab API tabs example">
                          <Tab classes={{ root: classes.tab }} label="CANDIDATE LIST" value="1" disabled />
                        </TabList>
                      </Box>
                      <TabPanel value="1">
                        {/* <Grid item xs={12}>
                                                    <Grid container columnSpacing={{ xs: 1, sm: 2, ms: 3 }}>
                                                        <Grid item xs={6} display="flex" alignItems="center">
                                                            <div>
                                                                Search: <input type="text" className="searchInput"></input>
                                                            </div>
                                                            <CustomizeViewMenu
                                                                columns={applicationHeadCells}
                                                                visibilityModel={timeSlotDetailColumnVisibilityModel}
                                                                onSelect={(key, checked) =>
                                                                    setTimeSlotDetailColumnVisibilityModel({
                                                                        ...timeSlotDetailColumnVisibilityModel,
                                                                        [key]: checked,
                                                                    })
                                                                }
                                                                onDragEnd={(result) => setApplicationHeadCells(result)}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={6}
                                                            display="flex"
                                                            justifyContent="flex-end"
                                                            alignItems="center"
                                                            className="buttonGroups"
                                                        >
                                                            <Button
                                                                className="buttonGroupSent"
                                                                variant="outlined"
                                                                size="small"
                                                                // aria-controls={openCustomizeView ? "basic-menu" : undefined}
                                                                // aria-haspopup="true"
                                                                // aria-expanded={openCustomizeView ? "true" : undefined}
                                                                // onClick={handleClick}
                                                            >
                                                                EXPORT
                                                            </Button>
                                                            <GroupInterviewPerformanceRatingModal
                                                                candidates={timeSlot.candidate}
                                                                evaluationForm={evaluationForm}
                                                            />
                                                            <AddCandidatePopover applicationList={applicationList} timeSlot={timeSlot} />
                                                        </Grid>
                                                    </Grid>
                                                </Grid> */}

                        <Grid item xs={12}>
                          <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                            <Grid item xs={12}>
                              <DataGridPro
                                rows={timeSlot.candidate}
                                columns={applicationHeadCells}
                                columnVisibilityModel={timeSlotDetailColumnVisibilityModel}
                                getRowId={(row: Info) => row.applicantId}
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                autoHeight
                                checkboxSelection
                                disableSelectionOnClick
                                disableColumnResize
                                disableColumnMenu
                                disableColumnFilter
                                disableColumnSelector
                                onColumnOrderChange={(params) => {
                                  const result = [...applicationHeadCells];
                                  const [removed] = result.splice(params.oldIndex - 1, 1);
                                  result.splice(params.targetIndex - 1, 0, removed);
                                  setApplicationHeadCells(result);
                                }}
                                components={{ Toolbar: TimeSlotDetailToolbar }}
                                componentsProps={{
                                  toolbar: {
                                    CustomizeViewMenu: (
                                      <CustomizeViewMenu
                                        columns={applicationHeadCells}
                                        visibilityModel={timeSlotDetailColumnVisibilityModel}
                                        onSelect={(key, checked) =>
                                          setTimeSlotDetailColumnVisibilityModel({
                                            ...timeSlotDetailColumnVisibilityModel,
                                            [key]: checked,
                                          })
                                        }
                                        onDragEnd={(result) => setApplicationHeadCells(result)}
                                      />
                                    ),
                                    applicationList,
                                    candidates: timeSlot.candidate,
                                    evaluationForm,
                                    timeSlot,
                                  },
                                }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </TabContext>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default TimeSlotDetailModal;
