import React, { ChangeEvent, useState } from "react";
import { Control, Controller, FieldErrors, useFieldArray, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Box, Button, Chip, FormControl, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Popover, Select, SelectChangeEvent, TextField } from "@mui/material";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";

interface Props {
  nestIndex: number;
  secondNestIndex: number;
  control: Control<CreateApplicationStageFormInput, object>;
  register: UseFormRegister<CreateApplicationStageFormInput>;
  setValue: UseFormSetValue<CreateApplicationStageFormInput>;
  getValues: UseFormGetValues<CreateApplicationStageFormInput>;
  watch: UseFormWatch<CreateApplicationStageFormInput>;
  errors: FieldErrors<CreateApplicationStageFormInput>;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function SingleChoiceSessionQuestions({ nestIndex, secondNestIndex, control, watch, setValue, getValues, errors }: Props) {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions`,
  });

  const onAppend = () => {
    append({
      enUS: "",
      zhHK: "",
      others: false,
      points: 0,
      linkedQuestion: [],
    });
  };

  const onAppendOthers = () => {
    append({
      enUS: "",
      zhHK: "",
      others: true,
      points: 0,
      linkedQuestion: [],
    });
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  // Functions for point calculation
  const [optionPoint, setOptionPoint] = useState<null | HTMLElement>(null);
  const [selectedIndexForPoint, setSelectedIndexForPoint] = useState(-1);
  const [selectedIndexForLinkedQuestion, setSelectedIndexForLinkedQuestion] = useState(-1);

  const handleClickForOptionPoint = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setOptionPoint(event.currentTarget);
    setSelectedIndexForPoint(index);
  };

  const handleCloseForOptionPoint = () => {
    setOptionPoint(null);
  };

  const openForOptionPoint = Boolean(optionPoint);
  const optionPointId = openForOptionPoint ? "simple-popover" : undefined;

  // Functions for question linkage
  const [openOptionLinked, setOptionLinked] = useState(false);
  const handleOpenOptionLinked = (index: number) => {
    setOptionLinked(true);
    setSelectedIndexForLinkedQuestion(index);
  };
  const handleCloseOptionLinked = () => setOptionLinked(false);
  const handleClearOptionLinked = (index: number) => {
    setSelectedIndexForLinkedQuestion(index);
  };

  const linked = getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions;

  const [selected, setSelected] = useState<number[]>([]);
  function setSelectedLinkedQuestion() {
    const linkedQuestions: number[] = [];
    const fieldContents = getValues().fieldContent;
    if (fieldContents.length !== 0) {
      for (const fieldContent of fieldContents) {
        for (const sectionQuestion of fieldContent.sectionQuestion) {
          const yesLinkedQuestions = sectionQuestion.yesNoQuestions.yes.linkedQuestion;
          const noLinkedQuestions = sectionQuestion.yesNoQuestions.no.linkedQuestion;
          for (const yesLinkedQuestion of yesLinkedQuestions) {
            linkedQuestions.push(parseInt(yesLinkedQuestion));
          }
          for (const noLinkedQuestion of noLinkedQuestions) {
            linkedQuestions.push(parseInt(noLinkedQuestion));
          }

          const { singleChoiceQuestions } = sectionQuestion;
          for (const singleChoiceQuestion of singleChoiceQuestions) {
            const singleChoiceLinkedQuestions = singleChoiceQuestion.linkedQuestion;
            for (const singleChoiceLinkedQuestion of singleChoiceLinkedQuestions) {
              linkedQuestions.push(parseInt(singleChoiceLinkedQuestion));
            }
          }
        }
      }
    }
    setSelected(linkedQuestions);
  }

  const handleChangeForLinkedQuestion = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;

    setValue(
      `fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions.${selectedIndexForLinkedQuestion}.linkedQuestion`,
      typeof value === "string" ? value.split(",") : value
    );
    setSelectedLinkedQuestion();
  };

  return (
    <Grid container>
      {fields.map((singleChoiceQuestion, k) => {
        return (
          <Grid item xs={12} key={singleChoiceQuestion.id} display="flex" alignItems="center" style={{ minHeight: 48 }}>
            <Grid container display="flex" alignItems="center" spacing={2}>
              <Grid item xs={1} sm={0.8} md={0.8} lg={0.6} className="singleChoiceRadioButtonUncheckedIcon">
                <RadioButtonUncheckedIcon />
              </Grid>

              {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[k].others === false && (
                <Grid item xs={5.5} sm={5.6} md={3} lg={linked[k].linkedQuestion.length !== 0 ? 3.8 : 4.6}>
                  <Controller
                    name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions.${k}.enUS` as const}
                    control={control}
                    rules={{ required: `Option ${k + 1} (English) is required` }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="standard"
                        label={`${t("createForm.formContent.option")} ${k + 1} ${t("createForm.formContent.english")}`}
                        error={errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.singleChoiceQuestions?.[k]?.enUS && true}
                        helperText={
                          errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.singleChoiceQuestions?.[k]?.enUS !== undefined &&
                          `${errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.singleChoiceQuestions?.[k]?.enUS?.message}`
                        }
                      />
                    )}
                  />
                </Grid>
              )}

              {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[k].others === true && (
                <Grid item xs={11} sm={11} md={6} lg={linked[k].linkedQuestion.length !== 0 ? 7.6 : 9.2} alignItems="center" display="flex">
                  {t("createForm.formContent.others")}
                </Grid>
              )}

              {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[k].others === false && (
                <Grid item xs={5.5} sm={5.6} md={3} lg={linked[k].linkedQuestion.length !== 0 ? 3.8 : 4.6}>
                  <Controller
                    name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions.${k}.zhHK` as const}
                    control={control}
                    rules={{ required: `Option ${k + 1} (Chinese) is required` }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="standard"
                        label={`${t("createForm.formContent.option")} ${k + 1} ${t("createForm.formContent.chinese")}`}
                        error={errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.singleChoiceQuestions?.[k]?.zhHK && true}
                        helperText={
                          errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.singleChoiceQuestions?.[k]?.zhHK !== undefined &&
                          `${errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.singleChoiceQuestions?.[k]?.zhHK?.message}`
                        }
                      />
                    )}
                  />
                </Grid>
              )}

              <Grid item xs={5} sm={3} md={2} lg={1.4} display="flex" alignItems="center" justifyContent="center">
                <Button className="yesNoAddPointBtnContainer" variant="outlined" size="small">
                  <Grid container className="yesNoAddPointBtnElem">
                    {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[k].points > 0 ? null : (
                      <Grid
                        item
                        xs={3}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          handleClickForOptionPoint(event, k);
                        }}
                      >
                        <div className="yesNoAddPointBtn">
                          <AddIcon fontSize="small" />
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={9}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        handleClickForOptionPoint(event, k);
                      }}
                    >
                      {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[k].points > 0
                        ? `${getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[k].points} point`
                        : t("createForm.formContent.addPoint")}
                    </Grid>
                    {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[k].points > 0 ? (
                      <Grid
                        item
                        xs={3}
                        className="yesNoRemovePointBtn"
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                          e.stopPropagation();
                          watch([`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions.${k}.points`]);
                          setValue(`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions.${k}.points`, 0);
                        }}
                      >
                        <ClearIcon fontSize="small" />
                      </Grid>
                    ) : null}
                  </Grid>
                </Button>
                <Popover
                  id={optionPointId}
                  open={openForOptionPoint}
                  anchorEl={optionPoint}
                  onClose={handleCloseForOptionPoint}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="yesNoQuestionsContainer">
                    <div>
                      <Controller
                        name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions.${selectedIndexForPoint}.points` as const}
                        control={control}
                        rules={{
                          required: "Point is required",
                        }}
                        defaultValue={0}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            size="small"
                            label={t("createForm.formContent.answerPoint")}
                            inputProps={{ type: "number" }}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                              if (parseInt(e.target.value) >= 0) {
                                field.onChange(parseInt(e.target.value));
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="yesNoQuestionsElem">
                      <Button variant="contained" onClick={handleCloseForOptionPoint}>
                        {t("createForm.formContent.confirm")}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </Grid>

              <Grid
                item
                xs={5}
                sm={5}
                md={2.7}
                lg={linked[k].linkedQuestion.length !== 0 ? 2 : 0.4}
                alignItems="center"
                display="flex"
                justifyContent="center"
                className="singleChoiceLinkRemoveIcon"
              >
                <LinkRoundedIcon
                  className={linked[k].linkedQuestion.length !== 0 ? "linkedDesc" : ""}
                  onClick={() => {
                    handleOpenOptionLinked(k);
                  }}
                />
                {linked[k].linkedQuestion.length !== 0 && (
                  <div className="singleChoiceDesc">
                    {t("createForm.formContent.linkedWithQuestions")}{" "}
                    {linked[k].linkedQuestion
                      .map((value) => value + 1)
                      .sort()
                      .toString()}
                    <div className="singleChoiceDescClearIcon">
                      <ClearIcon
                        fontSize="small"
                        onClick={() => {
                          handleClearOptionLinked(k);
                          watch([`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions.${selectedIndexForLinkedQuestion}.linkedQuestion`]);
                          setValue(`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions.${selectedIndexForLinkedQuestion}.linkedQuestion`, []);
                          setSelectedLinkedQuestion();
                        }}
                      />
                    </div>
                  </div>
                )}
                <Modal open={openOptionLinked} onClose={handleCloseOptionLinked} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                  <Box sx={style}>
                    <div className="yesNoModalTitle">{t("createForm.formContent.createLinkage")}</div>
                    <div className="yesNoModal">{t("createForm.formContent.linkageDes")}</div>
                    <div className="yesNoSelectedContainer">
                      <div className="yesNoSelectedTitle">{t("createForm.formContent.theSelectedAnswer")}</div>
                      {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[selectedIndexForLinkedQuestion] &&
                        getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[selectedIndexForLinkedQuestion].others === true && (
                          <div className="yesNoSelectedAns"> {t("createForm.formContent.others")}</div>
                        )}
                      {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[selectedIndexForLinkedQuestion] &&
                        getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[selectedIndexForLinkedQuestion].zhHK && (
                          <div className="yesNoSelectedAns">
                            {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[selectedIndexForLinkedQuestion] &&
                              getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[selectedIndexForLinkedQuestion].zhHK}
                          </div>
                        )}
                    </div>
                    <FormControl fullWidth required>
                      <InputLabel id="relatedQuestionLabel">{t("createForm.formContent.relatedSectionQuestion")}</InputLabel>
                      <Controller
                        name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.singleChoiceQuestions.${selectedIndexForLinkedQuestion}.linkedQuestion` as const}
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            labelId="demo-multiple-chip-label"
                            id="demo-multiple-chip"
                            variant="outlined"
                            input={<OutlinedInput id="select-multiple-chip" label={t("createForm.formContent.relatedSectionQuestion")} />}
                            multiple
                            value={getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].singleChoiceQuestions[selectedIndexForLinkedQuestion].linkedQuestion}
                            onChange={handleChangeForLinkedQuestion}
                            renderValue={(linkedQuestionSelected) => (
                              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                                {linkedQuestionSelected.map((value) => (
                                  <Chip key={value} label={`Question ${value + 1}`} />
                                ))}
                              </Box>
                            )}
                          >
                            {getValues().fieldContent[nestIndex].sectionQuestion.map((fieldOption, i) => (
                              <MenuItem
                                disabled={i <= secondNestIndex ? true : !!selected.includes(i)}
                                // eslint-disable-next-line react/no-array-index-key
                                key={i}
                                value={i}
                              >
                                <div>
                                  <span className="yesNoModalMenuItem">
                                    {t("createForm.formContent.question")} {i + 1}
                                  </span>
                                  {fieldOption !== undefined && fieldOption !== undefined && fieldOption.fieldQuestion !== undefined && fieldOption.fieldQuestion.zhHK}
                                </div>
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Modal>
              </Grid>

              <Grid item xs={1} sm={4} md={0.7} lg={0.4} alignItems="center" justifyContent="center" display="flex" className="singleChoiceLinkRemoveIcon">
                <div>
                  <ClearIcon
                    onClick={() => {
                      onRemove(k);
                    }}
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      <Grid container alignItems="center" style={{ minHeight: 48 }}>
        <Grid item xs={1} sm={0.8} md={0.8} lg={0.6} className="singleChoiceRadioButtonUncheckedIcon">
          <RadioButtonUncheckedIcon />
        </Grid>
        <Grid item xs={11} sm={11.2} md={11.2} lg={11.4}>
          <div className="singleChoiceAddMessageContainer">
            <Button onClick={onAppend} className="singleChoiceAddMessage">
              {t("createForm.formContent.addNewOption")}
            </Button>
            <span> {t("createForm.formContent.or")} </span>
            <Button className="singleChoiceAddOthersMessage" onClick={onAppendOthers}>
              {t("createForm.formContent.addOther")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default SingleChoiceSessionQuestions;
