import React from "react";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, IconButton, Menu, MenuItem } from "@mui/material";
// import * as locales from "@mui/material/locale";
import { changeLanguage } from "../../redux/localization/actions";
import { useAppDispatch } from "../../redux/hooks";
// type SupportedLocales = keyof typeof locales;

// const lngs = {
//     zhHK: "繁體中文",
//     enUS: "English",
// } as const;

function LanguageSwitcher() {
  const { t, i18n } = useTranslation(["common", "translation"]);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchToZhHk = () => {
    i18n.changeLanguage("zhHK");
    dispatch(changeLanguage("zhHK"));
    handleClose();
  };

  const switchToEnUs = () => {
    i18n.changeLanguage("enUS");
    dispatch(changeLanguage("enUS"));
    handleClose();
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
      }}
    >
      <IconButton
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        color="primary"
      >
        <LanguageIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={switchToZhHk}>{t("zhHK")}</MenuItem>
        <MenuItem onClick={switchToEnUs}>{t("enUS")}</MenuItem>
      </Menu>
    </Box>
  );

  // return (
  //     <div style={styles.container}>
  //         <ButtonGroup size="small" variant="contained" aria-label="outlined primary button group">
  //             {Object.keys(lngs).map((lng) => (
  //                 <Button
  //                     key={lng}
  //                     style={{
  //                         fontWeight: i18n.resolvedLanguage === lng ? "bold" : "normal",
  //                     }}
  //                     type="submit"
  //                     onClick={() => {
  //                         i18n.changeLanguage(lng);
  //                         dispatch(changeLanguage(lng as SupportedLocales));
  //                     }}
  //                 >
  //                     {(lngs as any)[lng]}
  //                 </Button>
  //             ))}
  //         </ButtonGroup>
  //     </div>
  // );
}

export default LanguageSwitcher;
