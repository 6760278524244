import { NewApplicationFormInput } from "../components/applicationForm/list/NewApplicationButton";
import { EditApplicationListForm } from "../components/applicationForm/view/EditApplicationButton";
import client from "./interceptors";

export async function findApplicationListsBySchoolCode() {
  const res = await client.get("applicationList");
  const result = res.data;
  return result;
}

export async function createApplicationList(data: NewApplicationFormInput) {
  const res = await client.post("applicationList", data);
  const result = res.data;
  return result;
}

export async function getApplicationListById(id: number) {
  try {
    const res = await client.get(`applicationList/${id}`);
    const result = res.data;
    return result;
  } catch (error) {
    return error;
  }
}

export async function editApplicationListById(id: number, data: EditApplicationListForm) {
  const res = await client.put(`applicationList/${id}`, data);
  const result = res.data;
  return result;
}

export async function deleteApplicationListById(id: number) {
  const res = await client.delete(`applicationList/${id}`);
  const result = res.data;
  return result;
}

export async function getApplicationListsWithApplicationStagesAndSubmissions() {
  const res = await client.get("applicationList/applicationStages/submissions");
  const result = res.data;
  return result;
}
