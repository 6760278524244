import { LoginFormValues } from "../components/user/LoginPage";
import { RegisterFormValues } from "../components/user/RegisterPage";
import { JWTPayload } from "../redux/auth/reducer";
import client from "./interceptors";

export async function login(data: LoginFormValues) {
  const res = await client.post("user/login", data);
  const result = res.data;
  return result;
}

export async function verify(data: JWTPayload) {
  const res = await client.post("user/verify", data);
  const result = res.data;
  return result;
}

export async function register(data: RegisterFormValues) {
  const res = await client.post("user/register", data);
  const result = res.data;
  return result;
}
