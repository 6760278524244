import { Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import React, { ReactNode } from "react";

interface AllCandidatesToolbarProps {
  CustomizeViewMenu: ReactNode;
}

function AllCandidatesToolbar(props: AllCandidatesToolbarProps) {
  const { CustomizeViewMenu } = props;

  return (
    <GridToolbarContainer sx={{ display: "block" }}>
      <Grid container>
        <Grid xs={6}>
          <GridToolbarQuickFilter />
          {CustomizeViewMenu}
        </Grid>
        <Grid xs={6} display="flex" justifyContent="flex-end" alignItems="center" className="buttonGroups">
          <Button
            className="buttonGroupSent"
            variant="outlined"
            size="small"
            // aria-controls={openCustomizeView ? "basic-menu" : undefined}
            // aria-haspopup="true"
            // aria-expanded={openCustomizeView ? "true" : undefined}
            // onClick={handleClick}
          >
            EXPORT
          </Button>
          <Button
            className="buttonGroupSent"
            variant="outlined"
            size="small"
            // aria-controls={openCustomizeView ? "basic-menu" : undefined}
            // aria-haspopup="true"
            // aria-expanded={openCustomizeView ? "true" : undefined}
            // onClick={handleClick}
          >
            UPDATE CANDIDATE STATUS
          </Button>
          <GridToolbarFilterButton />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default AllCandidatesToolbar;
