import Grid from "@mui/material/Unstable_Grid2";
import { GridToolbarFilterButton, GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import React, { ReactNode } from "react";

interface ApplicationToolBarProps {
  CustomizeViewMenu: ReactNode;
}

function ApplicationToolBar(props: ApplicationToolBarProps) {
  const { CustomizeViewMenu } = props;

  return (
    <GridToolbarContainer sx={{ display: "block" }}>
      <Grid container>
        <Grid xs={6}>
          <GridToolbarQuickFilter />
          {CustomizeViewMenu}
        </Grid>
        <Grid xs={6} display="flex" justifyContent="flex-end">
          {/* <TimeSlotFilterMenu timeSlots={timeSlots} onShowResult={onShowResult} /> */}
          <GridToolbarFilterButton />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default ApplicationToolBar;
