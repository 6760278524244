import { ForgotPasswordValues } from "../components/user/ForgotPasswordPage";
import { UserFormInput } from "../components/applicationForm/view/EditApplicantsButton";
import client from "./interceptors";

export async function updateUserById(id: number, data: UserFormInput) {
  const res = await client.patch(`user/${id}`, data);
  const result = res.data;
  return result;
}

export async function forgotPassword(data: ForgotPasswordValues) {
  const res = await client.post("user/forgotPassword", data);
  const result = res.data;
  return result;
}

export async function findApplicationStageAndSubmissionByUserId(userId: number) {
  const res = await client.get(`user/${userId}/applicationStage`);
  const form = res.data;
  return form;
}

export async function enrolUserToCms(data: { userIds: number[]; applicationStageId: number }) {
  const res = await client.post("user/enrolToCms", data);
  const result = res.data;
  return result;
}
