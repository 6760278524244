import * as React from "react";
import { Control, Controller, FieldErrors, SubmitHandler, UseFormClearErrors, UseFormGetValues, UseFormHandleSubmit, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Grid, Modal, TextField } from "@mui/material";
import { createTestMessage } from "../../../api/message";
import { CreateMessageData } from "./CreateMessage";
import modalStyle from "../../utils/modalStyle";

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emailEditorRef: React.MutableRefObject<any>;
  control: Control<CreateMessageData, object>;
  handleSubmit: UseFormHandleSubmit<CreateMessageData>;
  setValue: UseFormSetValue<CreateMessageData>;
  getValues: UseFormGetValues<CreateMessageData>;
  watch: UseFormWatch<CreateMessageData>;
  errors: FieldErrors<CreateMessageData>;
  clearErrors: UseFormClearErrors<CreateMessageData>;
}

export default function SentTestMessage({ emailEditorRef, control, handleSubmit, setValue, errors, clearErrors }: Props) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    clearErrors();
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const { t } = useTranslation();
  const params = useParams();
  const sendTestMessage = t("sendMessage.sendTestMessage");
  const sendTestMessageDes = t("sendMessage.sendTestMessageDes");
  const sendTo = t("sendMessage.sendTo");
  const cancel = t("sendMessage.cancel");
  const send = t("sendMessage.send");

  const onSubmit: SubmitHandler<CreateMessageData> = async (data) => {
    const { name, html, testRecipient, attachments } = data;
    const formData = new FormData();
    formData.append("name", name);
    formData.append("html", html);
    formData.append("recipient", testRecipient);

    if (attachments) {
      for (let i = 0; i < attachments.length; i++) {
        formData.append("attachments", attachments[i]);
      }
    }

    if (params && params.applicationListId && params.formId) {
      const applicationListId = parseInt(params.applicationListId);
      const applicationStageId = parseInt(params.formId);
      const result = await createTestMessage(applicationListId, applicationStageId, formData);
      if (result.success) {
        handleClose();
      }
    }
  };

  const exportHtml = () => {
    emailEditorRef.current?.editor.exportHtml(async (jsonData: { html: string; design: object }) => {
      const { html, design } = jsonData;
      setValue("type", "EMAIL");
      setValue("html", html);
      setValue("json", design);
      await handleSubmit(onSubmit)();
    });
  };

  return (
    <div>
      <Button onClick={handleOpen}>{sendTestMessage}</Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
              <div>{sendTestMessage}</div>
              <Button variant="text" onClick={handleClose} className="modalClearBtn">
                <ClearIcon />
              </Button>
            </Grid>
            <Grid item xs={12} className="scheduleDescription">
              {sendTestMessageDes}
            </Grid>
            <Grid item xs={12}>
              <Controller
                name={"testRecipient" as const}
                control={control}
                rules={{ required: "Recipient email is required" }}
                defaultValue=""
                render={({ field }) => (
                  <TextField {...field} fullWidth label={sendTo} error={errors.testRecipient && true} helperText={errors.testRecipient && `${errors.testRecipient.message}`} />
                )}
              />
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button variant="text" onClick={handleClose}>
                {cancel}
              </Button>
              <Button variant="text" type="submit" onClick={exportHtml}>
                {send}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
