import React, { ChangeEvent, ReactNode } from "react";
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormControl, FormControlLabel, FormHelperText, Radio, RadioGroup } from "@mui/material";
import { SingleChoiceQuestion } from "../../interfaces/createApplicationForm";
import { SingleQuestion } from "./SubmissionForm";
import { LanguageEnum } from "../../models/languageEnum";
import QuestionComponent from "./QuestionComponent";

interface Props {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
  question: SingleQuestion;
  setLinkedQuestion: (select: number[], deselect: number[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SingleChoice({ register, errors, watch, setValue, control, question, setLinkedQuestion }: Props) {
  const { i18n, t } = useTranslation();
  // console.log(question);
  return (
    <>
      {question.mandatory === true ? (
        <FormControl fullWidth error={errors[question.id] && true}>
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            rules={{ required: t("forms.required") }}
            defaultValue=""
            render={({ field }) => (
              <RadioGroup
                {...field}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(event.target.value);
                  const selectedIndex: number[] = [];
                  const deselectedIndexes: number[] = [];

                  for (const singleChoiceQuestion of question.singleChoiceQuestions) {
                    if (!singleChoiceQuestion.others && singleChoiceQuestion.enUS === event.target.value) {
                      const linkedQuestions = singleChoiceQuestion.linkedQuestion;
                      for (const linkedQuestion of linkedQuestions) {
                        selectedIndex.push(parseInt(linkedQuestion));
                      }
                    } else if (singleChoiceQuestion.others && event.target.value === "others") {
                      const linkedQuestions = singleChoiceQuestion.linkedQuestion;
                      for (const linkedQuestion of linkedQuestions) {
                        selectedIndex.push(parseInt(linkedQuestion));
                      }
                    } else {
                      const linkedQuestions = singleChoiceQuestion.linkedQuestion;
                      for (const linkedQuestion of linkedQuestions) {
                        deselectedIndexes.push(parseInt(linkedQuestion));
                      }
                    }
                  }
                  setLinkedQuestion(selectedIndex, deselectedIndexes);
                }}
              >
                {question.singleChoiceQuestions.map((singleChoiceQuestion: SingleChoiceQuestion, index: number) => {
                  return (
                    <FormControlLabel
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className="whiteText16"
                      value={singleChoiceQuestion.others === true ? "others" : singleChoiceQuestion.enUS}
                      control={
                        <Radio
                          sx={{
                            color: "#888888",
                            "&.Mui-checked": {
                              color: "#888888",
                            },
                          }}
                        />
                      }
                      label={
                        singleChoiceQuestion.others === true
                          ? t("forms.others")
                          : `${i18n.resolvedLanguage === LanguageEnum.ZH_HK ? `${singleChoiceQuestion.zhHK}` : `${singleChoiceQuestion.enUS}`}`
                      }
                    />
                  );
                })}
              </RadioGroup>
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      ) : (
        <FormControl fullWidth error={errors[question.id] && true}>
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <RadioGroup
                {...field}
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  field.onChange(event.target.value);
                  const selectedIndex: number[] = [];
                  const deselectedIndexes: number[] = [];

                  for (const singleChoiceQuestion of question.singleChoiceQuestions) {
                    if (singleChoiceQuestion.enUS === event.target.value || singleChoiceQuestion.zhHK === event.target.value) {
                      const linkedQuestions = singleChoiceQuestion.linkedQuestion;
                      for (const linkedQuestion of linkedQuestions) {
                        selectedIndex.push(parseInt(linkedQuestion));
                      }
                    } else {
                      const linkedQuestions = singleChoiceQuestion.linkedQuestion;
                      for (const linkedQuestion of linkedQuestions) {
                        deselectedIndexes.push(parseInt(linkedQuestion));
                      }
                    }
                  }
                  setLinkedQuestion(selectedIndex, deselectedIndexes);
                }}
              >
                {question.singleChoiceQuestions.map((singleChoiceQuestion: SingleChoiceQuestion, index: number) => {
                  return (
                    <FormControlLabel
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      className="whiteText16"
                      value={singleChoiceQuestion.others === true ? "others" : singleChoiceQuestion.enUS}
                      control={
                        <Radio
                          sx={{
                            color: "#888888",
                            "&.Mui-checked": {
                              color: "#888888",
                            },
                          }}
                        />
                      }
                      label={
                        singleChoiceQuestion.others === true
                          ? t("forms.others")
                          : `${i18n.resolvedLanguage === LanguageEnum.ZH_HK ? singleChoiceQuestion.zhHK : singleChoiceQuestion.enUS}`
                      }
                    />
                  );
                })}
              </RadioGroup>
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      )}
    </>
  );
}

export default SingleChoice;
