import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { CandidateInfo, EvaluationForm, Info, InterviewTimeSlot, PointsTotal, Rating } from "../../../../interfaces/interview";
import EditCandidateStatusModal from "../EditCandidateStatusModal";

interface CandidateListActionButtonsProps {
  candidate: CandidateInfo;
  evaluationForm: (EvaluationForm | Rating)[];
}

function CandidateListActionButtons(props: CandidateListActionButtonsProps) {
  const { candidate, evaluationForm } = props;
  const { t } = useTranslation();

  const onClickEdit = (id: number) => {
    console.log("onClickEdit");
  };

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onClickDelete = (id: number) => {
    console.log("onClickDelete");
  };

  return (
    <>
      <EditCandidateStatusModal candidate={candidate} evaluationForm={evaluationForm} />
      {/* <EditTimeSlotDetailModal /> */}
      {/* <Button
                variant="contained"
                size="small"
                className="buttonAction buttonActionRed"
                onClick={() => {
                    onClickDelete(1);
                }}
            >
                {t("sendMessage.delete")}
            </Button>
            <Button
                variant="contained"
                size="small"
                className="buttonAction buttonActionRed"
                onClick={() => {
                    onClickDelete(1);
                }}
            >
                {t("view.export")}
            </Button> */}
    </>
  );
}

export default CandidateListActionButtons;
