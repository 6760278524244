import "./CreateApplicationForm.css";
import moment from "moment";
import React, { ChangeEvent, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Button, InputLabel, Link, Switch, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { push } from "redux-first-history";
import { getApplicationListById } from "../../../api/applicationList";
import { createApplicationStage } from "../../../api/applicationStage";
import { ApplicationList } from "../../../interfaces/applicationList";
import { FieldContent } from "../../../interfaces/createApplicationForm";
import { FormTypeEnum } from "../../../models/formTypeEnum";
import FieldsRootComponent from "./FieldsRootComponent";
import { useAppDispatch } from "../../../redux/hooks";

export interface CreateApplicationStageFormInput {
  columnId: number;
  stage: string;
  titleEnUs: string;
  titleZhHk: string;
  isPublished: boolean;
  admissionLimit: number;
  deadline: Date | null;
  fieldContent: FieldContent[];
}

function CreateApplicationForm() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { applicationListId } = params;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [applicationListData, setApplicationListData] = useState<ApplicationList | null>(null);

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setApplicationListData(result.data);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      if (applicationListId) {
        await fetchApplicationList(parseInt(applicationListId));
        setIsLoading(false);
      }
    };
    fetchData();
  }, [params]);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm<CreateApplicationStageFormInput>();

  useEffect(() => {
    if (params) {
      const stage = params["*"];
      if (stage === FormTypeEnum.CREATE_APPLICATION) {
        setValue("columnId", 1);
        setValue("stage", FormTypeEnum.APPLICATION);
      } else if (stage === FormTypeEnum.CREATE_REGISTRATION) {
        setValue("columnId", 2);
        setValue("stage", FormTypeEnum.REGISTRATION);
      }
    }
  }, [params, setValue]);

  const onSubmit: SubmitHandler<CreateApplicationStageFormInput> = async (data) => {
    if (applicationListId) {
      const result = await createApplicationStage(parseInt(applicationListId), data);
      if (result.success) {
        dispatch(push("summary"));
      }
    }
  };

  const onReturn = () => {
    navigate(-1);
  };

  // const onReview = () => {
  //     console.log(getValues());
  // };

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId) {
      const listId = parseInt(params.applicationListId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/summary`));
    }
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHK" ? applicationListData?.titleZhHk : i18n.resolvedLanguage === "enUS" ? applicationListData?.titleEnUs : null}
    </Link>,
    <Typography key="3" color="primary.main">
      Create Application
    </Typography>,
  ];

  return (
    <Box>
      {isLoading && <Box>Loading...</Box>}
      {!isLoading && applicationListData === null && <Box>No Application List...</Box>}
      {!isLoading && applicationListData !== null && (
        <Box component="form" onSubmit={handleSubmit(onSubmit)}>
          <Grid>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
              {breadcrumbs}
            </Breadcrumbs>
            <Box sx={{ my: 1 }}>
              <Box>
                <div className="pageTitle">{t("createForm.formInfo.formInformation")}</div>
              </Box>
              <Grid container spacing={2}>
                <Grid xs={12}>
                  <Box className="formInfoContainer" sx={{ p: 2 }}>
                    <Grid container xs={12}>
                      <Grid xs={12} sm={12} md={12} display="flex" alignItems="center">
                        <InputLabel>{t("createForm.formInfo.publish")}</InputLabel>
                        <Controller name={"isPublished" as const} control={control} defaultValue={false} render={({ field }) => <Switch {...field} defaultChecked={false} />} />
                      </Grid>

                      <Grid xs={12} sm={6} md={6}>
                        <Controller
                          name={"titleEnUs" as const}
                          control={control}
                          rules={{ required: t("forms.required") }}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label={t("createForm.formInfo.formNameEng")}
                              error={errors.titleEnUs && true}
                              helperText={errors.titleEnUs && `${errors.titleEnUs.message}`}
                            />
                          )}
                        />
                      </Grid>

                      <Grid xs={12} sm={6} md={6}>
                        <Controller
                          name={"titleZhHk" as const}
                          control={control}
                          rules={{ required: t("forms.required") }}
                          defaultValue=""
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label={t("createForm.formInfo.formNameCh")}
                              error={errors.titleZhHk && true}
                              helperText={errors.titleZhHk && `${errors.titleZhHk.message}`}
                            />
                          )}
                        />
                      </Grid>

                      <Grid xs={12} sm={6} md={6}>
                        <Controller
                          name={"admissionLimit" as const}
                          control={control}
                          rules={{ required: t("forms.required"), validate: (value) => value >= 0 }}
                          defaultValue={"" as unknown as number}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              label={t("createForm.formInfo.applicationLimit")}
                              error={errors.admissionLimit && true}
                              helperText={
                                errors.admissionLimit &&
                                (errors.admissionLimit.type === "required"
                                  ? `${errors.admissionLimit.message}`
                                  : errors.admissionLimit.type === "validate"
                                  ? "Invalid number"
                                  : null)
                              }
                              inputProps={{ type: "number" }}
                              onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                if (parseInt(e.target.value) >= 0) {
                                  field.onChange(parseInt(e.target.value));
                                }
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid xs={12} sm={6} md={6}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                          <Controller
                            name="deadline"
                            control={control}
                            rules={{
                              required: t("forms.required"),
                              validate: (value) => value !== null && value > new Date(),
                            }}
                            defaultValue={null}
                            render={({ field }) => (
                              <DesktopDatePicker
                                {...field}
                                inputFormat="DD/MM/yyyy"
                                onChange={(date) => {
                                  field.onChange(moment(date).toDate());
                                }}
                                // eslint-disable-next-line @typescript-eslint/no-shadow
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    fullWidth
                                    label={t("createForm.formInfo.applicationDeadline")}
                                    error={errors.deadline && true}
                                    helperText={
                                      errors.deadline &&
                                      (errors.deadline.type === "required" ? `${errors.deadline.message}` : errors.deadline.type === "validate" ? t("forms.incorrectFormat") : null)
                                    }
                                  />
                                )}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <h1 className="pageTitle">{t("createForm.formContent.formContent")}</h1>
            </Box>

            <FieldsRootComponent
              {...{
                control,
                register,
                watch,
                setValue,
                getValues,
                errors,
              }}
            />
          </Grid>

          <div className="createApplicationFormFooter">
            <Grid sx={{ m: 2 }}>
              <Grid container>
                <Grid xs={1}>
                  {/* <Button variant="outlined" color="primary" onClick={onReview}>
                                        {t("createForm.formContent.preview")}
                                    </Button> */}
                </Grid>
                <Grid xs={9} />
                <Grid xs={2} display="flex" justifyContent="end">
                  <Button variant="outlined" onClick={onReturn} className="formCancelBtn formFooterBtn">
                    {t("createForm.formContent.cancel")}
                  </Button>

                  <Button type="submit" variant="contained" color="primary" className="formFooterBtn">
                    {t("createForm.formContent.submit")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </Box>
      )}
    </Box>
  );
}

export default CreateApplicationForm;
