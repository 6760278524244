import AddCircleIcon from "@mui/icons-material/AddCircle";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InterviewStatus } from "../../../interfaces/interview";
import modalStyle from "../../utils/modalStyle";

function AddInterviewStatusModal() {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  const methods = useForm<InterviewStatus>();

  const handleOpen = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    console.log("interview status =", methods.getValues());
  };

  return (
    <>
      <MenuItem onClick={handleOpen} sx={{ justifyContent: "center", display: "flex" }}>
        <AddCircleIcon style={{ color: "#409EFF" }} />
        <span>Interview Status</span>
      </MenuItem>
      <Modal open={open} onClose={onClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={modalStyle}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                  <div>Add Interview Status</div>
                  <Button variant="text" onClick={onClose} className="modalClearBtn">
                    <ClearIcon />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={"name.enUs" as const}
                    control={methods.control}
                    rules={{ required: "Interview Title (English) is required" }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Interview Title (English)"
                        error={methods.formState.errors?.name?.enUs && true}
                        helperText={methods.formState.errors?.name?.enUs && `${methods.formState.errors?.name?.enUs?.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name={"name.zhHk" as const}
                    control={methods.control}
                    rules={{ required: "Interview Title (Traditional Chinese) is required" }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Interview Title (Traditional Chinese)"
                        error={methods.formState.errors?.name?.zhHk && true}
                        helperText={methods.formState.errors?.name?.zhHk && `${methods.formState.errors?.name?.zhHk?.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth required>
                    <InputLabel id="select-colour-label">Select Colour</InputLabel>
                    <Controller
                      name={"colour" as const}
                      control={methods.control}
                      rules={{ required: "Interview Colour is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <Select
                          {...field}
                          labelId="select-colour-label"
                          label="Select Question"
                          fullWidth
                          onChange={(event) => {
                            field.onChange(event.target.value);
                          }}
                        >
                          <MenuItem value="blue">Blue</MenuItem>
                          <MenuItem value="red">Orange</MenuItem>
                          <MenuItem value="orange">Red</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button variant="text" onClick={onClose}>
                    {t("view.cancel")}
                  </Button>
                  <Button variant="text" type="submit">
                    ADD
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default AddInterviewStatusModal;
