import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { SetStateAction, useState } from "react";
import { Control, Controller, FieldErrors, useForm } from "react-hook-form";
import { Condition, ConditionFormInput } from "../../../interfaces/interview";

interface CreateConditionFormProps {
  control: Control<ConditionFormInput>;
  defaultValue: Condition;
  errors: FieldErrors<ConditionFormInput>;
  setDefaultValue: (value: SetStateAction<Condition>) => void;
}

function CreateConditionForm(props: CreateConditionFormProps) {
  const { control, defaultValue, errors, setDefaultValue } = props;
  // const {
  //     control,
  //     formState: { errors },
  // } = useForm<ConditionFormInput>();
  const [selectedQuestion, setSelectedQuestion] = useState(false);
  const [selectedCondition, setSelectedCondition] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(false);

  return (
    <Grid container xs={12} sx={{ my: 1 }} spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel id="select-question-label">Select Question</InputLabel>
          <Controller
            name={"question" as const}
            control={control}
            rules={{ required: "Application Title (Traditional Chinese) is required" }}
            defaultValue=""
            render={({ field }) => (
              <Select
                labelId="select-question-label"
                label="Select Question"
                onChange={(event) => {
                  field.onChange(event.target.value);
                  setDefaultValue({ ...defaultValue, question: `${event.target.value}` });
                  setSelectedQuestion(true);
                }}
                error={errors.question && true}
                // helperText={errors.question && `${errors.question}`}
                fullWidth
              >
                <MenuItem value="Section 1 - Question 1">Section 1 - Question 1</MenuItem>
                <MenuItem value="Section 1 - Question 1 (Yes - Linked Question)">Section 1 - Question 1 (Yes - Linked Question)</MenuItem>
                <MenuItem value="Section 2 - Question 3">Section 2 - Question 3</MenuItem>
                <MenuItem value="Section 1 - Question 4">Section 1 - Question 4</MenuItem>
                <MenuItem value="Section 2 - Question 3">Section 2 - Question 3</MenuItem>
                <MenuItem value="test1">test1</MenuItem>
                <MenuItem value="test2">test2</MenuItem>
                <MenuItem value="test3">test3</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      {selectedQuestion && (
        <>
          <Grid item xs={6}>
            <FormControl fullWidth required error={defaultValue.condition === ""}>
              <InputLabel id="condition-label">Condition</InputLabel>
              <Controller
                name={"condition" as const}
                control={control}
                rules={{ required: "Application Title (Traditional Chinese) is required" }}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    labelId="condition-label"
                    label="Condition"
                    fullWidth
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setDefaultValue({ ...defaultValue, condition: `${event.target.value}` });
                    }}
                    error={errors.condition && true}
                    // helperText={errors.condition && `${errors.condition}`}
                  >
                    <MenuItem value="lt">Less than</MenuItem>
                    <MenuItem value="le">Less than or equal to</MenuItem>
                    <MenuItem value="eq">Equals to</MenuItem>
                    <MenuItem value="ge">Greater than or equal to</MenuItem>
                    <MenuItem value="gt">Greater than</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required error={defaultValue.answer === ""}>
              <InputLabel id="select-answer-label">Select Answer</InputLabel>
              <Controller
                name={"answer" as const}
                control={control}
                rules={{ required: "Application Title (Traditional Chinese) is required" }}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    labelId="select-answer-label"
                    label="Select Answer"
                    fullWidth
                    onChange={(event) => {
                      field.onChange(event.target.value);
                      setDefaultValue({ ...defaultValue, answer: `${event.target.value}` });
                    }}
                    error={errors.answer && true}
                    // helperText={errors.answer && `${errors.answer}`}
                  >
                    <MenuItem value="Yes">Yes</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value="Banking">Banking</MenuItem>
                    <MenuItem value="Architecture and Construction">Architecture and Construction</MenuItem>
                    <MenuItem value="Girl">Girl</MenuItem>
                    <MenuItem value="Arts, Entertainment and Publishing">Arts, Entertainment and Publishing</MenuItem>
                    <MenuItem value="test1">test1</MenuItem>
                    <MenuItem value="test2">test2</MenuItem>
                    <MenuItem value="test3">test3</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default CreateConditionForm;
