import "./ForgotPassword.css";
import React, { useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { push } from "redux-first-history";
import { Alert, Button, FormControl, MenuItem, Snackbar, styled } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { forgotPassword } from "../../api/user";
import forgotPasswordImg from "../../images/forgetPassword.png";
import mail from "../../images/mail.png";
import { DocumentTypeEnum } from "../../models/documentTypeEnum";
import { RootState } from "../../redux/store";
import { CustomInputLabel, RoundSelect, RoundTextField, State } from "../utils/CustomStyle";
import SwitchLanguageButton from "./SwitchLanguageButton";
import { ApiError } from "../../interfaces/apiError";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

export interface ForgotPasswordValues {
  lastNameEnUs: string;
  firstNameEnUs: string;
  personalDocumentType: string;
  personalDocumentId: string;
}

function ForgotPasswordPage() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [isEmailSent, setIsEmailSent] = useState<boolean>(false);
  const [contactEmail, setContactEmail] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<null | ApiError>(null);
  const isStudentAuthenticated = useAppSelector((state: RootState) => state.auth.isStudentAuthenticated);
  const lastSchool = sessionStorage.getItem("lastSchool");
  const isLoading = useAppSelector((state: RootState) => state.auth.isLoading);
  const user = useAppSelector((state: RootState) => state.auth.user);

  const [state, setState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ open: false, vertical: "top", horizontal: "right" });
  };

  const Img = styled("img")({
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
  });

  const defaultValues: ForgotPasswordValues = {
    lastNameEnUs: "",
    firstNameEnUs: "",
    personalDocumentType: "",
    personalDocumentId: "",
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordValues>({
    defaultValues,
  });

  const onSubmit: SubmitHandler<ForgotPasswordValues> = async (data) => {
    if (lastSchool !== null) {
      const result = await forgotPassword(data);
      if (result.success) {
        const { email } = result.data;
        setContactEmail(email as string);
        setIsEmailSent(true);
      } else {
        setErrorMessage(result.error);
        setState({ open: true, vertical: "top", horizontal: "right" });
      }
    } else {
      setState({ open: true, vertical: "top", horizontal: "right" });
    }
  };

  function onClickLoginPage() {
    dispatch(push("login"));
  }

  useEffect(() => {
    if (!isLoading) {
      if (isStudentAuthenticated && user) {
        dispatch(push(`/cms/school/${lastSchool}/application/list`));
      }
    }
  }, [dispatch, isStudentAuthenticated, lastSchool, isLoading, user]);

  return (
    <Grid sx={{ m: 3 }}>
      {isLoading && isStudentAuthenticated && <>Loading...</>}
      {!isLoading && !isStudentAuthenticated && (
        <>
          <Grid xs={12} display="flex" justifyContent="flex-end" alignItems="center" sx={{ m: 1 }}>
            <SwitchLanguageButton />
          </Grid>
          {!isEmailSent && (
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid xs={4}></Grid>
                <Grid xs={4} className="forgotPasswordImageContainer">
                  <Img src={forgotPasswordImg} width="130" height="130" alt="Forgot Password" />
                  <div className="forgotPasswordTitle">{t("register.forgetPassword")}</div>
                  <div className="forgotPasswordExplain">
                    {t("register.forgetPasswordExplain", {
                      email: lastSchool === "vpp" ? "enquiry@victoriaplaypark.com" : "ukoffc@victoria.edu.hk",
                    })}
                  </div>
                </Grid>
                <Grid xs={4}></Grid>

                <Grid xs={4}></Grid>
                <Grid xs={4} className="forgotPasswordSubTitle">
                  {t("register.applicantInformation")}
                </Grid>
                <Grid xs={4}></Grid>

                <Grid xs={4}></Grid>
                <Grid xs={2}>
                  <Controller
                    name="lastNameEnUs"
                    control={control}
                    rules={{ required: t("register.lastNameEnUsIsRequired") }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        error={errors.lastNameEnUs && true}
                        id="lastNameEnUs"
                        label={t("register.lastNameEnUs")}
                        variant="outlined"
                        helperText={errors.lastNameEnUs && `${errors.lastNameEnUs.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={2}>
                  <Controller
                    name="firstNameEnUs"
                    control={control}
                    rules={{ required: t("register.firstNameEnUsIsRequired") }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        error={errors.firstNameEnUs && true}
                        id="firstNameEnUs"
                        label={t("register.firstNameEnUs")}
                        variant="outlined"
                        type="firstNameEnUs"
                        helperText={errors.firstNameEnUs && `${errors.firstNameEnUs.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={4}></Grid>

                <Grid xs={4}></Grid>
                <Grid xs={4}>
                  <FormControl fullWidth size="small" required>
                    <CustomInputLabel id="personalDocumentType">{t("register.personalDocumentType")}</CustomInputLabel>
                    <Controller
                      name="personalDocumentType"
                      control={control}
                      rules={{ required: "Personal Document Type is required" }}
                      render={({ field }) => (
                        <RoundSelect
                          {...field}
                          fullWidth
                          error={errors.personalDocumentType && true}
                          id="personalDocumentType"
                          label={t("register.personalDocumentType")}
                          variant="outlined"
                        >
                          <MenuItem value={DocumentTypeEnum.BIRTH_CERTIFICATE}>{t("register.hkBirthCertificate")}</MenuItem>
                          <MenuItem value={DocumentTypeEnum.IDENTITY_CARD}>{t("register.hkIdentityCard")}</MenuItem>
                          <MenuItem value={DocumentTypeEnum.PASSPORT}>{t("register.passport")}</MenuItem>
                          <MenuItem value={DocumentTypeEnum.RE_ENTRY_PERMIT}>{t("register.reEntryPermit")}</MenuItem>
                          <MenuItem value={DocumentTypeEnum.CERTIFICATE_OF_IDENTITY}>{t("register.certificateOfIdentity")}</MenuItem>
                        </RoundSelect>
                      )}
                    />
                  </FormControl>
                </Grid>
                <Grid xs={4}></Grid>

                <Grid xs={4}></Grid>
                <Grid xs={4}>
                  <Controller
                    name="personalDocumentId"
                    control={control}
                    rules={{ required: t("register.personalDocumentIdIsRequired") }}
                    render={({ field }) => (
                      <RoundTextField
                        {...field}
                        fullWidth
                        size="small"
                        error={errors.personalDocumentId && true}
                        id="personalDocumentId"
                        label={t("register.personalDocumentId")}
                        variant="outlined"
                        helperText={errors.personalDocumentId && `${errors.personalDocumentId.message}`}
                      />
                    )}
                  />
                </Grid>
                <Grid xs={4}></Grid>

                <Grid xs={4}></Grid>
                <Grid xs={4}>
                  <Button className="registerPageRegisterBtn" variant="outlined" type="submit">
                    {t("register.next")}
                  </Button>
                </Grid>
                <Grid xs={4}></Grid>
              </Grid>
            </form>
          )}

          {isEmailSent && (
            <Grid container spacing={2}>
              <Grid xs={4}></Grid>
              <Grid xs={4} className="forgotPasswordImageContainer">
                <Img src={mail} width="130" height="130" alt="Mail" />
                <div className="forgotPasswordTitle">{t("register.emailHasBeenSent")}</div>
                <div className="forgotPasswordExplain">
                  {t("register.emailHasBeenSentExplain1")} <span className="forgotPasswordTitle">{contactEmail}</span>
                  {t("register.emailHasBeenSentExplain2")}
                </div>
              </Grid>
              <Grid xs={4}></Grid>

              <Grid xs={4}></Grid>
              <Grid xs={4}>
                <Button className="registerPageRegisterBtn" variant="outlined" onClick={onClickLoginPage}>
                  {t("register.login")}
                </Button>
              </Grid>
              <Grid xs={4}></Grid>
            </Grid>
          )}

          <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} anchorOrigin={{ vertical, horizontal }}>
            <Alert severity="error">
              <strong>{t(`errors.${errorMessage?.errorCode}`)}</strong>
            </Alert>
          </Snackbar>
        </>
      )}
    </Grid>
  );
}

export default ForgotPasswordPage;
