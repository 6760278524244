import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import ApplicationRouter from "./components/user/ApplicationRouter";
import SchoolRouter from "./components/school/SchoolRouter";
import NotFound from "./components/utils/NotFound";

function App() {
  const lastSchool = sessionStorage.getItem("lastSchool");

  return (
    <Routes>
      <Route path="cms/school/:schoolCode/application/*" element={<ApplicationRouter />} />
      <Route path="cms/school/:schoolCode/*" element={<SchoolRouter />} />
      {lastSchool ? <Route path="*" element={<Navigate to={`/cms/school/${lastSchool}/application/register`} />} /> : <Route path="*" element={<NotFound />} />}
    </Routes>
  );
}

export default App;
