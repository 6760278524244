import React, { useEffect, useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { push } from "redux-first-history";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Button, Grid, Link, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";
import { getApplicationListById } from "../../../api/applicationList";
import { createMessageTemplate } from "../../../api/messageTemplate";
import { ApplicationList } from "../../../interfaces/applicationList";
import { MessageEmailEditorExport } from "../../../interfaces/message";

const cancelBtwStyle = {
  marginLeft: 2,
  color: "#606266",
  border: "1px solid #606266",
};

const createBtwStyle = {
  bgcolor: "#409EFF",
  marginLeft: 2,
  color: "#FFFFFF",
};

export interface CreateMessageTemplateInput {
  name: string;
  html: string;
  json: object;
  type: string;
}

function CreateTemplate() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emailEditorRef = useRef<any | null>(null) as React.MutableRefObject<any | null>;
  const lastSchool = sessionStorage.getItem("lastSchool");
  const createTemplate = t("sendMessage.createTemplate");
  const templateTitle = t("sendMessage.templateTitle");
  const cancel = t("sendMessage.cancel");
  const create = t("sendMessage.create");

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<CreateMessageTemplateInput>();

  const onSubmit: SubmitHandler<CreateMessageTemplateInput> = async (data) => {
    const result = await createMessageTemplate(data);
    if (result.success) {
      if (params && params.applicationListId && params.formId) {
        const listId = parseInt(params.applicationListId);
        const formId = parseInt(params.formId);
        dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message/template`));
      }
    }
  };

  const exportHtml = () => {
    emailEditorRef.current?.editor.exportHtml(async (jsonData: MessageEmailEditorExport) => {
      const { html, design } = jsonData;
      setValue("html", html);
      setValue("json", design);
      setValue("type", "EMAIL");
      await handleSubmit(onSubmit)();
    });
  };

  const onReady = () => {
    setIsLoading(false);
  };

  const onReturn = () => {
    navigate(-1);
  };

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId) {
      const listId = parseInt(params.applicationListId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/summary`));
    }
  }

  function handleClick3(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}`));
    }
  }

  function handleClick4(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message`));
    }
  }

  // function handleClick5(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  //     event.preventDefault();
  //     if (params && params.applicationListId && params.formId) {
  //         const listId = parseInt(params.applicationListId);
  //         const formId = parseInt(params.formId);
  //         dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message/create`));
  //     }
  // }

  function handleClick6(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message/template`));
    }
  }

  const [listData, setListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setListData(result.data);
    }
  }

  useEffect(() => {
    if (params && params.applicationListId) {
      const listId = parseInt(params.applicationListId);
      fetchApplicationList(listId);
    }
  }, [params]);

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHK" ? listData.titleZhHk : i18n.resolvedLanguage === "enUS" ? listData.titleEnUs : null}
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick3}>
      Application
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick4}>
      Send Message
    </Link>,
    // <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick5}>
    //     Create Message
    // </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick6}>
      Message Template
    </Link>,
    <Typography key="3" color="primary.main">
      Create Template
    </Typography>,
  ];

  return (
    <Grid sx={{ m: 2 }}>
      {!isLoading && (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid sx={{ my: 1, background: "white" }}>
          <Grid container sx={{ p: 2 }} className="outterContainer">
            {!isLoading && (
              <Grid item xs={12}>
                <div className="pageTitle">{createTemplate}</div>
                <Controller
                  name={"name" as const}
                  control={control}
                  rules={{ required: "Message Title is required" }}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth label={templateTitle} error={errors.name && true} helperText={errors.name && `${errors.name.message}`} />}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <EmailEditor ref={emailEditorRef} onReady={onReady} />
            </Grid>

            {!isLoading && (
              <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center" className="buttonGroups">
                <Button sx={cancelBtwStyle} variant="outlined" size="small" onClick={onReturn}>
                  {cancel}
                </Button>
                <Button sx={createBtwStyle} variant="outlined" size="small" onClick={exportHtml}>
                  {create}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default CreateTemplate;
