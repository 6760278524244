import "./ViewApplicationFormSummary.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { push } from "redux-first-history";
import EditIcon from "@mui/icons-material/Edit";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Button, createTheme, IconButton, Link, ThemeOptions, ThemeProvider, Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { TypographyOptions } from "@mui/material/styles/createTypography";
import { findApplicationStagesByApplicationListId } from "../../../api/applicationStage";
import { getApplicationListById } from "../../../api/applicationList";
import { findSubmissionsByApplicationStageId } from "../../../api/submission";
import { ApplicationList } from "../../../interfaces/applicationList";
import { ApplicationStage } from "../../../interfaces/applicationStage";
import { Submission } from "../../../interfaces/submission";
import { ApplicantStatusEnum } from "../../../models/applicantStatusEnum";
import { ApplicationListStatusEnum } from "../../../models/applicationListStatusEnum";
import { ApplicationStageFormStatus } from "../../../models/applicationStageFormStatus";
import { FormTypeEnum } from "../../../models/formTypeEnum";
import { LanguageEnum } from "../../../models/languageEnum";
import EditApplicationButton from "./EditApplicationButton";
import { useAppDispatch } from "../../../redux/hooks";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    summaryFormTitle: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    summaryFormTitle?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    summaryFormTitle: true;
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  summaryFormTitle: React.CSSProperties;
}

function ViewApplicationFormSummary() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const { t, i18n } = useTranslation(["applicationListStatus", "common", "translation"]);
  const dispatch = useAppDispatch();
  const params = useParams();

  const theme = createTheme({
    typography: {
      summaryFormTitle: {
        color: "#303133",
        fontWeight: 500,
        fontSize: 20,
      },
    } as ExtendedTypographyOptions,
  } as ThemeOptions);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [applicationListData, setApplicationListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleZhHk: "",
    titleEnUs: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  const [applicationStagesData, setApplicationStagesData] = useState<ApplicationStage[]>([]);

  const [applicationStageData, setApplicationStageData] = useState<ApplicationStage[]>([]);
  const [registrationStageData, setRegistrationStageData] = useState<ApplicationStage[]>([]);

  const [submissionApplication, setSubmissionApplication] = useState<Submission[]>([]);
  const [submissionRegistration, setSubmissionRegistration] = useState<Submission[]>([]);

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setApplicationListData(result.data);
    }
  }

  async function fetchApplicationStagesByApplicationListId(id: number) {
    const result = await findApplicationStagesByApplicationListId(id);
    if (result.success) {
      setApplicationStagesData(result.data.rows);
    }
  }

  async function fetchApplication(listId: number, stageId: number) {
    const result = await findSubmissionsByApplicationStageId(listId, stageId);
    if (result.success) {
      setSubmissionApplication(result.data);
    }
  }

  async function fetchRegistration(listId: number, stageId: number) {
    const result = await findSubmissionsByApplicationStageId(listId, stageId);
    if (result.success) {
      setSubmissionRegistration(result.data);
    }
  }

  function onClickViewDetails(id: number) {
    dispatch(push(`dashboard/${id}`));
  }

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      {t("eAdmission", { ns: "common" })}
    </Link>,
    <Typography key="3" color="primary.main">
      {i18n.resolvedLanguage === LanguageEnum.ZH_HK ? applicationListData.titleZhHk : i18n.resolvedLanguage === LanguageEnum.EN_US ? applicationListData.titleEnUs : null}
    </Typography>,
  ];

  useEffect(() => {
    const fetchData = async () => {
      if (params && params.applicationListId) {
        const applicationListId = parseInt(params.applicationListId);
        await fetchApplicationList(applicationListId);
        await fetchApplicationStagesByApplicationListId(applicationListId);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [params]);

  useEffect(() => {
    const fetchData = async () => {
      if (applicationStagesData.length !== 0) {
        const application = applicationStagesData.filter((stageData) => stageData.stage === FormTypeEnum.APPLICATION);
        setApplicationStageData(application);

        const registration = applicationStagesData.filter((stageData) => stageData.stage === FormTypeEnum.REGISTRATION);
        setRegistrationStageData(registration);

        if (params && params.applicationListId) {
          const applicationListId = parseInt(params.applicationListId);
          if (application.length !== 0) {
            await fetchApplication(applicationListId, application[0].id);
          } else {
            setSubmissionApplication([]);
          }
          if (registration.length !== 0) {
            await fetchRegistration(applicationListId, registration[0].id);
          } else {
            setSubmissionRegistration([]);
          }

          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      }
    };

    fetchData();
  }, [applicationStagesData, params]);

  return (
    <Box>
      {isLoading && <Box>Loading...</Box>}
      {!isLoading && !applicationListData && <Box>{t("view.recordNotExist")}</Box>}
      {!isLoading && applicationListData && (
        <Box>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <Box sx={{ my: 1 }}>
            <Grid container spacing={2}>
              <Grid xs={12}>
                <Box className="viewApplicationForm_FormTypeContainer">
                  {applicationListData && (
                    <Grid container xs={12} display="flex" alignItems="center">
                      <Grid container xs={11}>
                        <Grid xs={12}>
                          <ThemeProvider theme={theme}>
                            <Typography variant="summaryFormTitle">
                              {i18n.resolvedLanguage === LanguageEnum.ZH_HK
                                ? applicationListData.titleZhHk
                                : i18n.resolvedLanguage === LanguageEnum.EN_US
                                ? applicationListData.titleEnUs
                                : null}
                            </Typography>
                          </ThemeProvider>
                        </Grid>
                        <Grid xs={12}>
                          <Box
                            className={`statusTextWithBg ${
                              applicationListData.status === ApplicationListStatusEnum.INCOMPLETE_SETUP
                                ? "statusTextWithBgRed"
                                : applicationListData.status === ApplicationListStatusEnum.NOT_PUBLISHED
                                ? "statusTextWithBgGrey"
                                : applicationListData.status === ApplicationListStatusEnum.EXAMINATION
                                ? "statusTextWithBgOrange"
                                : applicationListData.status === ApplicationListStatusEnum.CALL_FOR_REGISTRATION
                                ? "statusTextWithBgBlue"
                                : applicationListData.status === ApplicationListStatusEnum.CALL_FOR_APPLICATION
                                ? "statusTextWithBgBlue"
                                : applicationListData.status === ApplicationListStatusEnum.COMPLETED
                                ? "statusTextWithBgGreen"
                                : null
                            }`}
                          >
                            {applicationListData.status === ApplicationListStatusEnum.INCOMPLETE_SETUP
                              ? t("incompletedSetup", { ns: "applicationListStatus" })
                              : applicationListData.status === ApplicationListStatusEnum.NOT_PUBLISHED
                              ? t("unpublished", { ns: "applicationListStatus" })
                              : applicationListData.status === ApplicationListStatusEnum.EXAMINATION
                              ? t("examination", { ns: "applicationListStatus" })
                              : applicationListData.status === ApplicationListStatusEnum.CALL_FOR_REGISTRATION
                              ? t("callForRegistration", { ns: "applicationListStatus" })
                              : applicationListData.status === ApplicationListStatusEnum.CALL_FOR_APPLICATION
                              ? t("callForApplication", { ns: "applicationListStatus" })
                              : applicationListData.status === ApplicationListStatusEnum.COMPLETED
                              ? t("completed", { ns: "applicationListStatus" })
                              : null}
                          </Box>
                        </Grid>
                      </Grid>
                      <Grid container xs={1} display="flex" justifyContent="flex-end">
                        <EditApplicationButton
                          titleEnUs={applicationListData.titleEnUs}
                          titleZhHk={applicationListData.titleZhHk}
                          status={applicationListData.status}
                          reload={async () => {
                            await fetchApplicationList(applicationListData.id);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Box>
              </Grid>

              {/* <Grid lg={4}>
                                <AddNewStageMenu />
                            </Grid> */}

              {/* <Grid xs={12} sm={12} md={6} lg={4}>
                                <Grid container> */}
              {applicationStageData.length !== 0 && (
                <Grid xs={12} sm={12} md={6} lg={4}>
                  <Box className="viewApplicationForm_FormTypeContainer">
                    <Grid xs={12} className="viewApplicationForm_FormTypeTitle">
                      {t("view.application", { ns: "translation" })}
                    </Grid>
                    <Grid xs={12} sx={{ my: 1 }} className="viewApplicationForm_InfoBoxSpecial">
                      <div className="viewApplicationForm_InfoBoxTitle">
                        {t("view.applicationForm", { ns: "translation" })}
                        <Tooltip
                          title="Edit"
                          onClick={() => {
                            dispatch(push(`edit_application/${applicationStageData[0].id}`));
                          }}
                        >
                          <IconButton>
                            <EditIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className="viewApplicationForm_FormTitle viewApplicationForm_RowMargin">
                        {i18n.resolvedLanguage === LanguageEnum.ZH_HK
                          ? applicationStageData[0].titleZhHk
                          : i18n.resolvedLanguage === LanguageEnum.EN_US
                          ? applicationStageData[0].titleEnUs
                          : null}
                      </div>
                      <div className={`statusTextWithBg ${applicationStageData[0].isPublished ? "statusTextWithBgGreen" : "statusTextWithBgRed"}`}>
                        {applicationStageData[0].isPublished ? t("view.publishing", { ns: "translation" }) : t("unpublished", { ns: "applicationListStatus" })}
                      </div>
                      <div className="viewApplicationForm_ApplicantLimitAndAvailableDate viewApplicationForm_RowMargin">
                        {t("view.applicantLimit", { ns: "translation" })}: {applicationStageData[0].admissionLimit}
                      </div>
                      <div className="viewApplicationForm_ApplicantLimitAndAvailableDate viewApplicationForm_RowMargin">
                        {t("view.availableDate", { ns: "translation" })}: {moment(applicationStageData[0].createdAt).format("YYYY/MM/DD")} {t("view.to", { ns: "translation" })}{" "}
                        {moment(applicationStageData[0].deadline).format("YYYY/MM/DD")}
                      </div>
                    </Grid>
                    <Grid xs={12} sx={{ my: 1 }} className="viewApplicationForm_InfoBox">
                      <div className="viewApplicationForm_InfoBoxTitle">{t("view.applicationInProgress", { ns: "translation" })}</div>
                      <div className="viewApplicationForm_InfoBoxProgressElem">
                        {t("view.noOfApplicant", { ns: "translation" })}:<div>{submissionApplication.length}</div>
                      </div>
                      <div className="viewApplicationForm_InfoBoxProgressElem">
                        {t("view.completedApplication", { ns: "translation" })}:
                        <div>{submissionApplication.filter((data) => data.formStatus === ApplicationStageFormStatus.COMPLETED).length}</div>
                      </div>
                      <div className="viewApplicationForm_InfoBoxProgressElem">
                        {t("view.incompleteApplication", { ns: "translation" })}:
                        <div>{submissionApplication.filter((data) => data.formStatus === ApplicationStageFormStatus.INCOMPLETE).length}</div>
                      </div>
                    </Grid>
                    <Grid xs={12} sx={{ my: 1 }} className="viewApplicationForm_InfoBox">
                      <div className="viewApplicationForm_InfoBoxTitle">{t("view.applicationStatus", { ns: "translation" })}</div>
                      <div className="viewApplicationForm_InfoBoxStatusContainer">
                        <div>
                          <div className="viewApplicationForm_InfoBoxStatusElem">
                            {submissionApplication.filter((data) => data.applicationStatus === ApplicantStatusEnum.ACCEPTED).length === 0
                              ? "-"
                              : submissionApplication.filter((data) => data.applicationStatus === ApplicantStatusEnum.ACCEPTED).length}
                          </div>
                          <div className="statusTextWithBg statusTextWithBgBlue">{t("view.accepted", { ns: "translation" })}</div>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div className="viewApplicationForm_InfoBoxStatusElem">
                            {submissionApplication.filter((data) => data.applicationStatus === ApplicantStatusEnum.WAITING).length === 0
                              ? "-"
                              : submissionApplication.filter((data) => data.applicationStatus === ApplicantStatusEnum.WAITING).length}
                          </div>
                          <div className="statusTextWithBg statusTextWithBgOrange">{t("view.waiting", { ns: "translation" })}</div>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div className="viewApplicationForm_InfoBoxStatusElem">
                            {submissionApplication.filter((data) => data.applicationStatus === ApplicantStatusEnum.REJECT).length === 0
                              ? "-"
                              : submissionApplication.filter((data) => data.applicationStatus === ApplicantStatusEnum.REJECT).length}
                          </div>
                          <div className="statusTextWithBg statusTextWithBgRed">{t("view.reject", { ns: "translation" })}</div>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div className="viewApplicationForm_InfoBoxStatusElem">
                            {submissionApplication.filter((data) => data.applicationStatus === ApplicantStatusEnum.PENDING).length === 0
                              ? "-"
                              : submissionApplication.filter((data) => data.applicationStatus === ApplicantStatusEnum.PENDING).length}
                          </div>
                          <div className="statusTextWithBg statusTextWithBgGrey">{t("view.pending", { ns: "translation" })}</div>
                        </div>
                      </div>
                    </Grid>
                    <Button
                      className="viewApplicationForm_ViewBtn"
                      onClick={() => {
                        onClickViewDetails(applicationStageData[0].id);
                      }}
                    >
                      {t("view.view", { ns: "translation" })}
                    </Button>
                  </Box>
                </Grid>
              )}

              {applicationStageData.length === 0 && (
                <Grid xs={12} sm={12} md={6} lg={4}>
                  <Box className="viewApplicationForm_EmptyFormTypeContainer">
                    <div className="viewApplicationForm_FormTypeTitle">{t("view.application", { ns: "translation" })}</div>
                    <div className="viewApplicationForm_CreateBtn">
                      <Button
                        variant="outlined"
                        fullWidth
                        size="small"
                        onClick={() => {
                          dispatch(push("create_application"));
                        }}
                      >
                        <span className="viewApplicationForm_CreateBtnText">{t("view.createForm", { ns: "translation" })}</span>
                      </Button>
                    </div>
                  </Box>
                </Grid>
              )}

              {registrationStageData.length !== 0 && (
                <Grid xs={12} sm={12} md={6} lg={4}>
                  <Box className="viewApplicationForm_FormTypeContainer">
                    <Grid xs={12} className="viewApplicationForm_FormTypeTitle">
                      {t("view.registration", { ns: "translation" })}
                    </Grid>
                    <Grid xs={12} sx={{ my: 1 }} className="viewApplicationForm_InfoBoxSpecial">
                      <div className="viewApplicationForm_InfoBoxTitle">
                        {t("view.registrationForm", { ns: "translation" })}
                        <Tooltip
                          title="Edit"
                          onClick={() => {
                            dispatch(push(`edit_registration/${registrationStageData[0].id}`));
                          }}
                        >
                          <IconButton>
                            <EditIcon color="primary" />
                          </IconButton>
                        </Tooltip>
                      </div>
                      <div className="viewApplicationForm_FormTitle viewApplicationForm_RowMargin">
                        {i18n.resolvedLanguage === LanguageEnum.ZH_HK
                          ? registrationStageData[0].titleZhHk
                          : i18n.resolvedLanguage === LanguageEnum.EN_US
                          ? registrationStageData[0].titleEnUs
                          : null}
                      </div>
                      <div className={`statusTextWithBg ${registrationStageData[0].isPublished ? "statusTextWithBgGreen" : "statusTextWithBgRed"}`}>
                        {registrationStageData[0].isPublished ? t("view.publishing", { ns: "translation" }) : t("view.unpublished", { ns: "translation" })}
                      </div>
                      <div className="viewApplicationForm_ApplicantLimitAndAvailableDate viewApplicationForm_RowMargin">
                        {t("view.availableDate", { ns: "translation" })}: {moment(registrationStageData[0].createdAt).format("YYYY/MM/DD")} {t("view.to", { ns: "translation" })}{" "}
                        {moment(registrationStageData[0].deadline).format("YYYY/MM/DD")}
                      </div>
                    </Grid>

                    <Grid xs={12} sx={{ my: 1 }} className="viewApplicationForm_InfoBox">
                      <div className="viewApplicationForm_InfoBoxTitle">{t("view.applicationInProgress", { ns: "translation" })}</div>
                      <div className="viewApplicationForm_InfoBoxProgressElem">
                        {t("view.noOfApplicant", { ns: "translation" })}:<div>{submissionRegistration.length}</div>
                      </div>
                      <div className="viewApplicationForm_InfoBoxProgressElem">
                        {t("view.completedApplication", { ns: "translation" })}:
                        <div>{submissionRegistration.filter((data) => data.formStatus === ApplicationStageFormStatus.COMPLETED).length}</div>
                      </div>
                      <div className="viewApplicationForm_InfoBoxProgressElem">
                        {t("view.incompleteApplication", { ns: "translation" })}:
                        <div>{submissionRegistration.filter((data) => data.formStatus === ApplicationStageFormStatus.INCOMPLETE).length}</div>
                      </div>
                    </Grid>

                    <Grid xs={12} sx={{ my: 1 }} className="viewApplicationForm_InfoBox">
                      <div className="viewApplicationForm_InfoBoxTitle">{t("view.enrollmentStatus", { ns: "translation" })}</div>
                      <div className="viewApplicationForm_InfoBoxStatusContainer">
                        <div>
                          <div className="viewApplicationForm_InfoBoxStatusElem">
                            {submissionRegistration.filter((data) => data.applicationStatus === ApplicantStatusEnum.ACCEPTED).length === 0
                              ? "-"
                              : submissionRegistration.filter((data) => data.applicationStatus === ApplicantStatusEnum.ACCEPTED).length}
                          </div>
                          <div className="statusTextWithBg statusTextWithBgBlue">{t("view.accepted", { ns: "translation" })}</div>
                        </div>
                        <div className="divider"></div>
                        <div>
                          <div className="viewApplicationForm_InfoBoxStatusElem">
                            {submissionRegistration.filter((data) => data.applicationStatus === ApplicantStatusEnum.PENDING).length === 0
                              ? "-"
                              : submissionRegistration.filter((data) => data.applicationStatus === ApplicantStatusEnum.PENDING).length}
                          </div>
                          <div className="statusTextWithBg statusTextWithBgGrey">{t("view.pending", { ns: "translation" })}</div>
                        </div>
                      </div>
                    </Grid>
                    <Button
                      className="viewApplicationForm_ViewBtn"
                      onClick={() => {
                        onClickViewDetails(registrationStageData[0].id);
                      }}
                    >
                      {t("view.view", { ns: "translation" })}
                    </Button>
                  </Box>
                </Grid>
              )}

              {registrationStageData.length === 0 && (
                <Grid xs={12} sm={12} md={6} lg={4}>
                  <Box className="viewApplicationForm_EmptyFormTypeContainer">
                    <div className="viewApplicationForm_FormTypeTitle">{t("view.registration", { ns: "translation" })}</div>
                    <div className="viewApplicationForm_CreateBtn">
                      <Button
                        variant="outlined"
                        fullWidth
                        size="small"
                        onClick={() => {
                          dispatch(push("create_registration"));
                        }}
                      >
                        <span className="viewApplicationForm_CreateBtnText">{t("list.createForm", { ns: "translation" })}</span>
                      </Button>
                    </div>
                  </Box>
                </Grid>
              )}
              {/* 
                                    {interviewStageData.length !== 0 && (
                                        <Grid xs={12}>
                                            <Box className="viewApplicationForm_FormTypeContainer">
                                                <Grid xs={12} className="viewApplicationForm_FormTypeTitle">
                                                    <Box>{t("view.interview", { ns: "translation" })}</Box>
                                                    <Box>Data Source: 2023 - 2024 Member Application Form (Accepted)</Box>
                                                </Grid>
                                                {statusData.map((data) => (
                                                    <Grid key={data.id} xs={12} sx={{ my: 1 }} className="viewApplicationForm_InfoBox">
                                                        <Box display="flex">
                                                            <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
                                                                <Box sx={{ p: 0.5, borderRadius: "50%", background: "#F7A233" }} />
                                                            </Box>
                                                            <Box
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    fontSize: 16,
                                                                    lineHeight: "125%",
                                                                    letterSpacing: "0.15px",
                                                                    color: "#606266",
                                                                }}
                                                            >
                                                                Chinese Dance
                                                            </Box>
                                                        </Box>
                                                        <Grid container display="flex" justifyContent="space-evenly">
                                                            {data.status.map((status, index) => (
                                                                <Fragment key={`${data.id}-${status.name}-${status.colour}`}>
                                                                    <Grid
                                                                        xs={2.96}
                                                                        display="flex"
                                                                        flexDirection="column"
                                                                        alignItems="center"
                                                                        justifyContent="center"
                                                                    >
                                                                        <Box className="viewApplicationForm_InfoBoxStatusElem">10</Box>
                                                                        <Box
                                                                            className="statusTextWithBg"
                                                                            sx={{ color: status.colour, background: `${status.colour}0D` }}
                                                                        >
                                                                            {status.name}
                                                                        </Box>
                                                                    </Grid>
                                                                    {(index + 1) % 4 !== 0 && (
                                                                        <Divider orientation="vertical" variant="middle" flexItem />
                                                                    )}
                                                                </Fragment>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                                <Button
                                                    className="viewApplicationForm_ViewBtn"
                                                    onClick={() => {
                                                        onClickViewDetails(applicationStageData[0].id);
                                                    }}
                                                >
                                                    {t("view.view", { ns: "translation" })}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    )}

                                    {messageStageData.length !== 0 && (
                                        <Grid xs={12} spacing={3}>
                                            <Box className="viewApplicationForm_FormTypeContainer">
                                                <Grid xs={12} className="viewApplicationForm_FormTypeTitle">
                                                    <Box>Message</Box>
                                                    <Box>Data Source: 2023 - 2024 Member Application Form (Accepted)</Box>
                                                </Grid>
                                                <Grid xs={12} sx={{ my: 1 }} className="viewApplicationForm_InfoBox">
                                                    <Box>[HKCCHOIR] Your Application is Under Review!</Box>
                                                    <Box>
                                                        <div className="statusTextWithBg statusTextWithBgBlue">In Review</div>
                                                    </Box>
                                                    <Box>Recipient: Completed Application (992)</Box>
                                                    <Box>Delivery Date: 2022/05/28, 12:32:09</Box>
                                                    <Box>
                                                        Read Rate: <span>8 / 992</span>
                                                    </Box>
                                                </Grid>
                                                <Button
                                                    className="viewApplicationForm_ViewBtn"
                                                    onClick={() => {
                                                        onClickViewDetails(applicationStageData[0].id);
                                                    }}
                                                >
                                                    {t("view.view", { ns: "translation" })}
                                                </Button>
                                            </Box>
                                        </Grid>
                                    )} */}
              {/* <Grid xs={12}>
                                        <AddNewStageMenu />
                                    </Grid> */}
              {/* </Grid>
                            </Grid> */}
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default ViewApplicationFormSummary;
