import { AxiosError } from "axios";

export function createError(response: Error | AxiosError) {
  return {
    type: "@@error/CREATE_ERROR" as const,
    response,
  };
}

export type ErrorActions = ReturnType<typeof createError>;
