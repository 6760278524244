/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from "react";
import { useTranslation } from "react-i18next";
import { Box, Link, Paper, Popover, Typography } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { GridRenderCellParams } from "@mui/x-data-grid";

interface CellExpandProps {
  value: string;
  width: number;
}

function isOverflown(element: HTMLElement) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignItems: "center",
      lineHeight: "24px",
      width: "100%",
      height: "100%",
      position: "relative",
      display: "flex",
      "& .cellValue": {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
    },
  })
);

const CellExpand = React.memo(function CellExpand(props: CellExpandProps) {
  const { value, width } = props;
  const classes = useStyles();

  const wrapper = React.useRef<HTMLDivElement | null>(null);
  const cellValueRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [isCurrentlyOverflown, setCurrentlyOverflown] = React.useState<null | boolean>(null);

  const { t } = useTranslation();
  const showAll = t("view.showAll");

  React.useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    setCurrentlyOverflown(isOverflown(cellValueRef.current!));
  }, [isCurrentlyOverflown, value]);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <div ref={wrapper} className={classes.root}>
      <div ref={cellValueRef} className="cellValue">
        {value}
      </div>
      {isCurrentlyOverflown && (
        <>
          <Link style={{ cursor: "pointer" }} onClick={handleClick}>
            {showAll}
          </Link>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Paper elevation={1}>
              <Box width={width} p={2}>
                <Typography variant="body2">{value}</Typography>
              </Box>
            </Paper>
          </Popover>
        </>
      )}
    </div>
  );
});

export function RenderCellExpand(params: GridRenderCellParams) {
  // eslint-disable-next-line react/destructuring-assignment
  return <CellExpand value={params.value ? params.value.toString() : ""} width={params.colDef.width as number} />;
}
