import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { createError } from "../redux/errors/actions";
import { store } from "../redux/store";

export const instance = axios.create({ withCredentials: true });
const { dispatch } = store;
const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  console.info(`[request] ${config.method?.toUpperCase()} - /${config.url}`, config);
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error("[request error]", error);
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  console.info(`[response] ${response.config.method?.toUpperCase()} - /${response.config.url}`, response);
  return response;
};

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  dispatch(createError(error));
  console.error("[response error]", error);
  return Promise.reject(error);
};

function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
}

const client = setupInterceptorsTo(instance);

export default client;

export const buildLink = (link: string) => {
  const { baseURL, params } = instance.defaults;
  const { timestamp, token } = params;

  return `${baseURL}${link}?token=${token}&timestamp=${timestamp}`;
  // return `${config.apiUrl}${link}?${qs.stringify(query)}`;
};
