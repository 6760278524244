import React from "react";
import { Grid, TextField } from "@mui/material";
import { Control, Controller, FieldErrors, useFieldArray, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";

interface Props {
  nestIndex: number;
  secondNestIndex: number;
  k: number;
  control: Control<CreateApplicationStageFormInput, object>;
  register: UseFormRegister<CreateApplicationStageFormInput>;
  setValue: UseFormSetValue<CreateApplicationStageFormInput>;
  getValues: UseFormGetValues<CreateApplicationStageFormInput>;
  watch: UseFormWatch<CreateApplicationStageFormInput>;
  errors: FieldErrors<CreateApplicationStageFormInput>;
}

function InnerMultipleAnswerSetSession({ nestIndex, secondNestIndex, k, control, register, watch, setValue, getValues, errors }: Props) {
  const { t } = useTranslation();
  const { fields, append } = useFieldArray({
    control,
    name: `fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answerSets.${k}.sets`,
  });

  const onAppend = () => {
    append({
      zhHK: "",
      enUS: "",
      others: false,
      points: 0,
    });
  };

  return (
    <>
      <Grid item xs={12} md={12}>
        {fields.map((set, l) => {
          return (
            <Grid key={set.id} sx={{ mb: 1 }}>
              <Grid container alignItems="center" spacing={2}>
                <Grid item xs={0.4} md={0.4} display="flex" justifyContent="center" alignItems="center">
                  <div className="multipleAnswerSetCircleNoContainer">{l + 1}</div>
                </Grid>

                <Grid item xs={5.8} md={5.8}>
                  <Controller
                    name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answerSets.${k}.sets.${l}.enUS` as const}
                    control={control}
                    rules={{ required: "English answer is required" }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField {...field} fullWidth variant="standard" label={`${t("createForm.formContent.option")} ${l + 1} ${t("createForm.formContent.english")}`} />
                    )}
                  />
                </Grid>

                <Grid item xs={5.8} md={5.8}>
                  <Controller
                    name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answerSets.${k}.sets.${l}.zhHK` as const}
                    control={control}
                    rules={{ required: "Chinese answer is required" }}
                    defaultValue=""
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        variant="standard"
                        label={`${t("createForm.formContent.option")} ${l + 1} ${t("createForm.formContent.traditionalChinese")}`}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          );
        })}
      </Grid>

      <Grid container alignItems="center" spacing={2}>
        <Grid item xs={0.4} md={0.4} display="flex" justifyContent="center" alignItems="center">
          <div className="multipleAnswerSetCircleNoContainer">
            {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].multipleAnswerSet.answerSets[k].sets.length + 1}
          </div>
        </Grid>
        <Grid item xs={11.6} md={11.6}>
          <div className="multipleAnswerSetContainer">
            <span onClick={onAppend} className="singleChoiceAddMessage">
              {t("createForm.formContent.addNewOption")}
            </span>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default InnerMultipleAnswerSetSession;
