import { FormLabel, FormHelperText, Link } from "@mui/material";
import React from "react";
import i18n from "../../i18n/config";
import { LanguageEnum } from "../../models/languageEnum";

interface Props {
  fieldQuestionZhHk: string;
  fieldQuestionEnUs: string;
  mandatory: boolean;
  descriptionZhHk: string | undefined;
  descriptionEnUs: string | undefined;
  hyperlink: string | undefined;
  error: boolean | undefined;
}

function QuestionComponent({ fieldQuestionZhHk, fieldQuestionEnUs, mandatory, descriptionZhHk, descriptionEnUs, hyperlink, error }: Props) {
  return (
    <>
      <FormLabel required={mandatory} className={error ? "questionTitleError" : "questionTitle"}>
        {i18n.resolvedLanguage === LanguageEnum.ZH_HK ? fieldQuestionZhHk : i18n.resolvedLanguage === LanguageEnum.EN_US ? fieldQuestionEnUs : ""}
      </FormLabel>
      <FormHelperText className="questionDescription">
        {descriptionZhHk && i18n.resolvedLanguage === LanguageEnum.ZH_HK ? descriptionZhHk : descriptionEnUs && i18n.resolvedLanguage === LanguageEnum.EN_US ? descriptionEnUs : ""}
      </FormHelperText>
      {hyperlink && (
        <div className="questionLink">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link
            component="button"
            onClick={() => {
              window.open(hyperlink);
            }}
          >
            {hyperlink}
          </Link>
        </div>
      )}
    </>
  );
}

export default QuestionComponent;
