/* eslint-disable react/no-array-index-key */
import React, { ReactNode, useEffect } from "react";
import { Control, Controller, FieldErrors, FieldValues, UseFormGetValues, UseFormSetValue, UseFormTrigger } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText } from "@mui/material";
import { SingleQuestion } from "./SubmissionForm";
import { MultipleChoiceQuestion } from "../../interfaces/createApplicationForm";
import { LanguageEnum } from "../../models/languageEnum";
import QuestionComponent from "./QuestionComponent";

interface Props {
  question: SingleQuestion;
  errors: FieldErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
  trigger: UseFormTrigger<FieldValues>;
}

function MultipleCheckBox({ question, errors, setValue, getValues, control, trigger }: Props) {
  const { i18n, t } = useTranslation();
  useEffect(() => {
    const submittedValue = getValues(question.id);

    const initialState: { [key: string]: boolean } = {};
    question.multipleChoiceQuestions.map((data: MultipleChoiceQuestion) => {
      if (data.others) {
        initialState.others = false;
      } else {
        initialState[data.enUS.toLowerCase()] = false;
      }
      return null;
    });

    if (!submittedValue) {
      setValue(question.id, initialState);
    }
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const originalState = getValues(question.id);
    const newState = { [event.target.name]: event.target.checked };
    const combinedState = { ...originalState, ...newState };
    setValue(question.id, combinedState);
    trigger(question.id);
  };

  function validateError() {
    const currentState = getValues(question.id);
    const multipleCheckBoxArray = [];
    for (const value in currentState) {
      if ({}.hasOwnProperty.call(currentState, value)) {
        multipleCheckBoxArray.push(currentState[value]);
      }
    }
    const isTrue = multipleCheckBoxArray.includes(true);
    return isTrue || t("forms.required");
  }

  return (
    <>
      {question.mandatory === true ? (
        <FormControl error={errors[question.id] && true} component="fieldset" variant="standard">
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            rules={{ required: t("forms.required"), validate: { check: validateError } }}
            render={({ field }) => (
              <FormGroup>
                {question.multipleChoiceQuestions.map((data: MultipleChoiceQuestion, index: number) => {
                  return (
                    <FormControlLabel
                      key={index}
                      checked={(data.others === false ? getValues(question.id)?.[data.enUS.toLowerCase()] : getValues(question.id)?.others) || false}
                      control={
                        <Checkbox
                          {...field}
                          sx={{
                            color: "#888888",
                            "&.Mui-checked": {
                              color: "#888888",
                            },
                          }}
                          onChange={handleChange}
                          name={data.others === false ? data.enUS.toLowerCase() : "others"}
                          value={data.others === false ? data.enUS.toLowerCase() : "others"}
                        />
                      }
                      label={
                        data.others === true
                          ? t("forms.others")
                          : i18n.resolvedLanguage === LanguageEnum.ZH_HK
                          ? data.zhHK
                          : i18n.resolvedLanguage === LanguageEnum.EN_US
                          ? data.enUS
                          : data.enUS
                      }
                    />
                  );
                })}
              </FormGroup>
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      ) : (
        <FormControl error={errors[question.id] && true} component="fieldset" variant="standard">
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            render={({ field }) => (
              <FormGroup>
                {question.multipleChoiceQuestions.map((data: MultipleChoiceQuestion, index: number) => {
                  return (
                    <FormControlLabel
                      key={index}
                      checked={(data.others === false ? getValues(question.id)?.[data.enUS.toLowerCase()] : getValues(question.id)?.others) || false}
                      control={
                        <Checkbox
                          {...field}
                          sx={{
                            color: "#888888",
                            "&.Mui-checked": {
                              color: "#888888",
                            },
                          }}
                          onChange={handleChange}
                          name={data.others === false ? data.enUS.toLowerCase() : "others"}
                          value={data.others === false ? data.enUS.toLowerCase() : "others"}
                        />
                      }
                      label={
                        data.others === true
                          ? t("forms.others")
                          : i18n.resolvedLanguage === LanguageEnum.ZH_HK
                          ? data.zhHK
                          : i18n.resolvedLanguage === LanguageEnum.EN_US
                          ? data.enUS
                          : data.enUS
                      }
                    />
                  );
                })}
              </FormGroup>
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      )}
    </>
  );
}

export default MultipleCheckBox;
