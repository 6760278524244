import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CandidateInfo, EvaluationForm, PointsTotal, Rating } from "../../../interfaces/interview";
import InterviewPerformanceRatingForm from "./InterviewPerformanceRatingForm";
import useWindowDimensions from "./useWindowDimensions";

interface GroupInterviewPerformanceRatingModalProps {
  candidates: CandidateInfo[];
  evaluationForm: EvaluationForm[];
}

const style = {
  position: "absolute",
  maxWidth: "90%",
  maxHeight: "90%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "auto",
  width: 776,
  bgcolor: "background.paper",
  // p: 3,
  borderRadius: 0.5,
};

function GroupInterviewPerformanceRatingModal(props: GroupInterviewPerformanceRatingModalProps) {
  const { candidates, evaluationForm } = props;
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const methods = useForm<Rating[][]>();
  console.log("height", height);

  const [open, setOpen] = useState(false);
  const [currentCandidate, setCurrentCandidate] = useState<CandidateInfo>();
  const [isLoading, setIsLoading] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeRatingForm = (info: CandidateInfo) => {
    console.log(info);
    setCurrentCandidate(info);
  };

  const onSubmit = () => {
    console.log("data =");
  };

  useEffect(() => {
    if (candidates.length > 0) {
      setCurrentCandidate(candidates[0]);
    }
    console.log("start", currentCandidate);
  }, []);

  useEffect(() => {
    const loading = async () => {
      console.log(currentCandidate);
      setIsLoading(true);
      if (currentCandidate?.rating.length === 0) {
        console.log("enpty rating");
        await evaluationForm.forEach((element, index) => {
          methods.setValue(`${currentCandidate.applicantId}.${index}.itemType`, element.itemType);
          methods.setValue(`${currentCandidate.applicantId}.${index}.itemTitle`, element.itemTitle);
          methods.setValue(`${currentCandidate.applicantId}.${index}.fullMarks`, element.fullMarks);
          methods.setValue(`${currentCandidate.applicantId}.${index}.description`, element.description);
          methods.setValue(`${currentCandidate.applicantId}.${index}.linearScale`, element.linearScale);
          methods.setValue(`${currentCandidate.applicantId}.${index}.multipleChoice`, element.multipleChoice);
        });
      } else if (currentCandidate) {
        await methods.setValue(`${currentCandidate.applicantId}`, currentCandidate.rating);
      }
      setIsLoading(false);
      console.log("changing", methods.getValues());
    };
    loading();
  }, [currentCandidate]);

  return (
    <>
      <Grid style={{ display: "inline" }}>
        <Button className="buttonGroupSent" variant="outlined" size="small" onClick={handleOpen}>
          {t("interview.groupRating")}
        </Button>
      </Grid>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={style}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container>
                <Grid item xs={3} sx={{ bgcolor: "#FAFAFA", p: 1.5, pt: 3 }}>
                  <Grid container spacing={1.5}>
                    {candidates.map((candidate, index) => (
                      <Grid item xs={12} key={index} onClick={() => handleChangeRatingForm(candidate)}>
                        <Grid
                          container
                          sx={
                            candidate.applicantId === currentCandidate?.applicantId
                              ? {
                                  background: "linear-gradient(0deg, rgba(25, 137, 250, 0.05), rgba(25, 137, 250, 0.05)), #FFFFFF",
                                  border: "1px solid rgba(25, 137, 250, 0.5)",
                                  borderRadius: "4px",
                                  px: 1.5,
                                  py: 1,
                                  color: "#1989FA",
                                }
                              : { background: "#FFFFFF", borderRadius: "4px", px: 1.5, py: 1 }
                          }
                        >
                          <Grid item xs={11}>
                            <Typography
                              sx={{
                                fontWeight: 300,
                                fontSize: "12px",
                                lineHeight: "125%",
                                letterSpacing: "0.15px",
                                mb: 0.5,
                              }}
                            >
                              CANDIDATE {index + 1}
                            </Typography>
                            <Typography sx={{ fontWeight: 500, fontSize: "14px", lineHeight: "121%", letterSpacing: "0.15px" }}>
                              ID: {`00000${candidate.applicantId}`.slice(-5)}
                            </Typography>
                          </Grid>
                          <Grid item xs={1} display="flex" justifyContent="flex-end" alignItems="center">
                            {candidate.rating.length > 0 && <CheckCircleOutlineIcon color="primary" fontSize="small" />}
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid item xs={9} sx={{ p: 3 }}>
                  <Grid container>
                    <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                      <div>{t("interview.performanceRating")}</div>
                      <Button variant="text" onClick={handleClose} className="modalClearBtn">
                        <ClearIcon />
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      {!isLoading && currentCandidate && methods.getValues()[currentCandidate.applicantId] && (
                        <Box sx={{ maxHeight: `${height - 200}px`, overflow: "scroll" }}>
                          <Grid container>
                            <Grid
                              item
                              xs={12}
                              display="flex"
                              alignItems="center"
                              sx={{
                                fontWeight: 500,
                                fontSize: "14px",
                                lineHeight: "21px",
                                letterSpacing: "0.15px",
                                color: "#606266",
                              }}
                            >
                              <span>
                                {t("sendMessage.applicantId")}
                                {": "}
                                {`00000${currentCandidate.applicantId}`.slice(-5)}
                              </span>
                            </Grid>
                            <Grid item xs={12}>
                              <Typography
                                sx={{
                                  bgcolor: "rgba(64, 158, 255, 0.05)",
                                  borderRadius: "4px",
                                  height: "28px",
                                  fontWeight: 400,
                                  fontSize: "12px",
                                  lineHeight: "18px",
                                  pl: 1.5,
                                }}
                                display="flex"
                                alignItems="center"
                              >
                                <span style={{ color: "#606266" }}>{t("interview.candidateAnswerPoint")}</span>
                                <span style={{ color: "#606266", paddingRight: "4px" }}>:</span>
                                <span style={{ color: "#1989FA" }}>{`${currentCandidate.answerPoints.points} / ${currentCandidate.answerPoints.total} ${t(
                                  "interview.points"
                                )}`}</span>
                              </Typography>
                            </Grid>
                            <InterviewPerformanceRatingForm prefix={currentCandidate.applicantId} rating={methods.getValues()[currentCandidate.applicantId]} />
                          </Grid>
                        </Box>
                      )}
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                      <Button variant="text" onClick={handleClose}>
                        {t("view.cancel")}
                      </Button>
                      <Button variant="text" type="submit" onClick={handleClose}>
                        {t("view.submit")}
                      </Button>
                      <Button onClick={() => console.log(methods.getValues())}>get</Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default GroupInterviewPerformanceRatingModal;
