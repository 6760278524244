import { useFieldArray, useFormContext } from "react-hook-form";
import { AnswerPointChoice, Choice, DatePoint, Interview, Value } from "../../../interfaces/interview";

// interface ConditionFormFieldValues {
//     operator: "QUEST" | "AND" | "OR";
//     question: string;
//     condition: string;
//     answer: string;
//     group?: { operator: "QUEST" | "AND" | "OR"; question: string; condition: string; answer: string; group?: [] }[];
// }

function useAnswerPointFormField(prefix: string) {
  const { control, register, trigger, setValue, watch } = useFormContext<AnswerPointChoice>();

  const pointsArrayInputPath = `${prefix}.points` as `points`;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { fields, append, remove } = useFieldArray({
    control,
    name: pointsArrayInputPath,
  });

  const addCondition = (value: Choice | DatePoint | Value) => {
    append(value);
  };

  // const addGroup = () => {
  //     append({
  //         operator: "AND",
  //         // question: "",
  //         // condition: "",
  //         // answer: "",
  //         group: [],
  //     });
  // };

  const removeCondition = (index: number) => {
    console.log("Remove Condition");
    remove(index);
  };

  return {
    control,
    fields,
    register,
    append,
    trigger,
    setValue,
    watch,
    addCondition,
    // addGroup,
    removeCondition,
    // removeGroup,
    // operatorInputPath,
    // questionInputPath,
    // conditionInputPath,
    // answerInputPath,
    pointsArrayInputPath,
  };
}

export default useAnswerPointFormField;
