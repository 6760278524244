import "./ApplicationFormList.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import LinkIcon from "@mui/icons-material/Link";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Button, styled, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DataGridPro, GridColDef, GridRenderCellParams } from "@mui/x-data-grid-pro";
import { deleteApplicationListById, getApplicationListsWithApplicationStagesAndSubmissions } from "../../../api/applicationList";
import { ApplicationListOuterJoinApplicationStage, ApplicationStageOuterJoinSubmission } from "../../../interfaces/applicationList";
import { ApplicationListStatusEnum } from "../../../models/applicationListStatusEnum";
import DuplicateButton from "./DuplicateButton";
import NewApplicationButton from "./NewApplicationButton";
import { FormTypeEnum } from "../../../models/formTypeEnum";
import { LanguageEnum } from "../../../models/languageEnum";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path className="ant-empty-img-2" d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z" />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

function ApplicationFormList() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation(["applicationListStatus", "common", "translation"]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const breadcrumbs = [
    <Typography key="3" color="primary.main">
      {t("eAdmission", { ns: "common" })}
    </Typography>,
  ];

  const [pageSize, setPageSize] = useState<number>(10);
  const [rows, setRows] = useState<ApplicationListOuterJoinApplicationStage[]>([]);

  async function fetchApplicationLists() {
    const result = await getApplicationListsWithApplicationStagesAndSubmissions();
    if (result.success) {
      setRows(result.data);
    } else {
      setRows([]);
    }
  }

  async function onDelete(id: number) {
    const result = await deleteApplicationListById(id);
    if (result.success) {
      fetchApplicationLists();
    }
  }

  function onView(id: number) {
    navigate(`${id}/summary`);
  }

  function renderApplicationTitle(params: GridRenderCellParams) {
    if (i18n.resolvedLanguage === LanguageEnum.ZH_HK) {
      return <div>{params.row.titleZhHk}</div>;
    }
    if (i18n.resolvedLanguage === LanguageEnum.EN_US) {
      return <div>{params.row.titleEnUs}</div>;
    }
    return <div></div>;
  }

  function renderSubmittedApplication(params: GridRenderCellParams) {
    const applications = params.row.applicationStages.filter((applicationForm: ApplicationStageOuterJoinSubmission) => applicationForm.stage === FormTypeEnum.APPLICATION);
    if (applications.length === 0) {
      return <div>-</div>;
    }
    if (applications.length > 0) {
      return <div>{`${applications[0].submissions.length} / ${applications[0].admissionLimit}`}</div>;
    }
    return <div>0 / 0</div>;
  }

  function renderSubmittedRegistration(params: GridRenderCellParams) {
    const registrations = params.row.applicationStages.filter((registrationForm: ApplicationStageOuterJoinSubmission) => registrationForm.stage === FormTypeEnum.REGISTRATION);
    if (registrations.length === 0) {
      return <div>-</div>;
    }
    if (registrations.length > 0) {
      return <div>{`${registrations[0].submissions.length} / ${registrations[0].admissionLimit}`}</div>;
    }
    return <div>0 / 0</div>;
  }

  function renderStatus(params: GridRenderCellParams) {
    return (
      <div
        className={`statusTextWithBg ${
          params.row.status === ApplicationListStatusEnum.INCOMPLETE_SETUP
            ? "statusTextWithBgRed"
            : params.row.status === ApplicationListStatusEnum.NOT_PUBLISHED
            ? "statusTextWithBgGrey"
            : params.row.status === ApplicationListStatusEnum.EXAMINATION
            ? "statusTextWithBgOrange"
            : params.row.status === ApplicationListStatusEnum.CALL_FOR_REGISTRATION
            ? "statusTextWithBgBlue"
            : params.row.status === ApplicationListStatusEnum.CALL_FOR_APPLICATION
            ? "statusTextWithBgBlue"
            : params.row.status === ApplicationListStatusEnum.COMPLETED
            ? "statusTextWithBgGreen"
            : null
        }`}
      >
        {params.row.status === ApplicationListStatusEnum.INCOMPLETE_SETUP
          ? t("incompletedSetup", { ns: "applicationListStatus" })
          : params.row.status === ApplicationListStatusEnum.NOT_PUBLISHED
          ? t("unpublished", { ns: "applicationListStatus" })
          : params.row.status === ApplicationListStatusEnum.EXAMINATION
          ? t("examination", { ns: "applicationListStatus" })
          : params.row.status === ApplicationListStatusEnum.CALL_FOR_REGISTRATION
          ? t("callForRegistration", { ns: "applicationListStatus" })
          : params.row.status === ApplicationListStatusEnum.CALL_FOR_APPLICATION
          ? t("callForApplication", { ns: "applicationListStatus" })
          : params.row.status === ApplicationListStatusEnum.COMPLETED
          ? t("completed", { ns: "applicationListStatus" })
          : null}
      </div>
    );
  }

  function renderDateCreated(params: GridRenderCellParams) {
    return <div>{moment(params.row.createdAt).format("YYYY-MM-DD HH:mm:ss")}</div>;
  }

  function renderActions(params: GridRenderCellParams) {
    return (
      <>
        <Button
          variant="contained"
          size="small"
          className="applicationFormListViewBtn"
          sx={{ m: 0.5 }}
          onClick={() => {
            onView(params.row.id);
          }}
        >
          {t("view", { ns: "common" })}
        </Button>
        <DuplicateButton id={params.row.id} titleEnUs={params.row.titleEnUs} titleZhHk={params.row.titleZhHk} reloadData={fetchApplicationLists} />
        <Button
          variant="contained"
          size="small"
          className="applicationFormListDeleteBtn"
          sx={{ m: 0.5 }}
          onClick={async () => {
            await onDelete(params.row.id);
          }}
        >
          {t("delete", { ns: "common" })}
        </Button>
      </>
    );
  }

  const columns: GridColDef[] = [
    { field: "applicantTitle", headerName: t("list.applicationTitle", { ns: "translation" }), width: 400, renderCell: renderApplicationTitle },
    {
      field: "submittedApplication",
      headerName: t("list.submittedApplication", { ns: "translation" }),
      width: 180,
      renderCell: renderSubmittedApplication,
    },
    {
      field: "submittedRegistration",
      headerName: t("list.submittedRegistration", { ns: "translation" }),
      width: 180,
      renderCell: renderSubmittedRegistration,
    },
    { field: "status", headerName: t("list.status", { ns: "translation" }), width: 170, renderCell: renderStatus },
    { field: "createdAt", headerName: t("list.createdAt", { ns: "translation" }), width: 200, renderCell: renderDateCreated },
    { field: "action", headerName: t("list.action", { ns: "translation" }), width: 400, renderCell: renderActions },
  ];

  useEffect(() => {
    const fetching = async () => {
      await fetchApplicationLists();
      setIsLoading(false);
    };

    fetching();
  }, []);

  return (
    <Box>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>

      <Box sx={{ my: 1, background: "white" }}>
        <Box className="outterContainer" sx={{ p: 2 }}>
          <Grid container spacing={2}>
            <Grid xs={12}>{/* TODO: Tabs */}</Grid>
            <Grid xs={6}>{/* TODO: Search Bar */}</Grid>

            <Grid xs={6} display="flex" justifyContent="flex-end">
              <Button
                className="applicationFormListButton"
                variant="outlined"
                size="small"
                onClick={() => {
                  window.open(`/cms/school/${lastSchool}/application/login`);
                }}
              >
                <LinkIcon className="applicatonFormListLinkIcon" />
                <span className="applicationFormListButtonText">{t("list.schoolLink", { ns: "translation" })}</span>
              </Button>

              <NewApplicationButton reloadData={fetchApplicationLists} />
            </Grid>

            <Grid xs={12}>
              {isLoading && <div>{t("loading", { ns: "common" })}</div>}
              {!isLoading && (
                <Box style={{ width: "100%" }}>
                  <DataGridPro
                    rows={rows}
                    components={{
                      NoRowsOverlay: CustomNoRowsOverlay,
                    }}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 20, 30]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    disableSelectionOnClick
                    autoHeight
                    disableColumnMenu
                  />
                </Box>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default ApplicationFormList;
