import styled from "@emotion/styled";
import { InputLabel, Select, SnackbarOrigin, TextField } from "@mui/material";

export const RoundTextField = styled(TextField)({
  "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
    borderRadius: 100,
    color: "#888888",
  },
  "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#888888",
  },
  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#888888",
    backgroundColor: "inherit",
    borderRadius: 100,
  },
  "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
    color: "#888888",
  },
  "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#888888",
  },
  "& label": {
    color: "#888888",
  },
  "& label.Mui-focused": {
    color: "#888888",
  },
});

export const RoundTextArea = styled(TextField)({
  "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root": {
    // borderRadius: 100,
    color: "#888888",
  },
  "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "#888888",
  },
  "& .css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    borderColor: "#888888",
    backgroundColor: "inherit",
  },
  "& .css-1pysi21-MuiFormLabel-root-MuiInputLabel-root": {
    color: "#888888",
  },
  "& .css-md26zr-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#888888",
  },
  "& label": {
    color: "#888888",
  },
  "& label.Mui-focused": {
    color: "#888888",
  },
});

export const RoundSelect = styled(Select)({
  "& label.Mui-focused": {
    color: "#888888",
  },
  "& .label": {
    color: "#888888",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: 50,
    borderColor: "#888888",
  },
  "& .Mui-focused": {
    borderColor: "#888888",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: 500,
      backgroundColor: "none",
      borderColor: "#888888",
    },
  },
});

export const CustomInputLabel = styled(InputLabel)({
  color: "#888888",
  "&.Mui-focused": {
    color: "rgba(0, 0, 0, 0.6)",
  },
});

export interface State extends SnackbarOrigin {
  open: boolean;
}

export interface StateForDataGrid extends SnackbarOrigin {
  openSnackbar: boolean;
}
