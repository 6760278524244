import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Breadcrumbs, Grid, InputLabel, Link, Tab, Typography } from "@mui/material";
import React, { Fragment, SyntheticEvent, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { DataGridPro, DataGridProProps, GridColDef, GridColumnVisibilityModel, GridFilterModel, GRID_DETAIL_PANEL_TOGGLE_FIELD } from "@mui/x-data-grid-pro";
import { makeStyles } from "@mui/styles";
import { ApplicationList } from "../../../interfaces/applicationList";
import { LanguageEnum } from "../../../models/languageEnum";
import { Interviewee, Interview } from "../../../interfaces/interview";
import translate from "../../utils/translate";
import ApplicationToolbar from "../interview/toolbar/ApplicationToolbar";
import RowAction from "../interview/RowAction";
import ApplicationActionButtons from "../interview/buttonGroup/ApplicationActionButtons";
import CustomizeViewMenu from "../interview/DraggableMenu/CustomizeViewMenu";
import DetailPanelContent from "../interview/DetailPanelContent";
import DetailPanelToggle from "../interview/DetailPanelToggle";
import InterviewActionButtons from "../interview/buttonGroup/InterviewActionButtons";
import EditCriteriaSettingModal from "../interview/EditCriteriaSettingModal";
import { findAllInterviewsByStageIdAndSchoolCode } from "../../../api/interview";
import InterviewDataGrid from "../interview/InterviewDataGrid";

const title = {
  fontSize: 20,
  color: "#303133",
  fontStyle: "Medium",
  marginBottom: 1,
};

// type Order = "asc" | "desc";

// const descendingComparator = (a: Interviewee, b: Interviewee, orderBy: string | number) => {
//     if (b.info[orderBy] < a.info[orderBy]) {
//         return -1;
//     }
//     if (b.info[orderBy] > a.info[orderBy]) {
//         return 1;
//     }
//     return 0;
// };

// const getComparator = (order: Order, orderBy: string | number): ((a: Interviewee, b: Interviewee) => number) => {
//     return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
// };

const useStyles = makeStyles({
  hideRightSeparator: {
    "& > .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
  },
});

function ViewInterviewDashboard() {
  const { t, i18n } = useTranslation();
  const urlParams = useParams();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [applicationListData, setApplicationListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });
  const [buttonValue, setValueButton] = useState<string>("1");
  const [buttonStatus, setButtonStatus] = useState<string>("ALL APPLICATION");
  const [filterBy, setFilterBy] = useState<string>("");
  const [applicationColumns, setApplicationColumns] = useState<GridColDef[]>([
    {
      field: "applicantId",
      headerName: "Applicant ID",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      valueGetter: (params) => params.row.info.applicantId,
    },
    {
      field: "contactEmail",
      headerName: "Contact Email",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      valueGetter: (params) => params.row.info.contactEmail,
    },
    {
      field: "relatedApplicantId",
      headerName: "Related Applicant Id",
      headerClassName: classes.hideRightSeparator,
      valueGetter: (params) => params.row.info.relatedApplicantId,
      flex: 1,
    },
    {
      field: "a",
      headerName: "A",
      headerClassName: classes.hideRightSeparator,
      valueGetter: (params) => params.row.info.a,
      flex: 1,
    },
    {
      field: "b",
      headerName: "B",
      headerClassName: classes.hideRightSeparator,
      valueGetter: (params) => params.row.info.a,
      flex: 1,
    },
    {
      field: "c",
      headerName: "C",
      headerClassName: classes.hideRightSeparator,
      valueGetter: (params) => params.row.info.a,
      flex: 1,
    },
    {
      field: "d",
      headerName: "D",
      headerClassName: classes.hideRightSeparator,
      valueGetter: (params) => params.row.info.a,
      flex: 1,
    },
    {
      field: "e",
      headerName: "E",
      headerClassName: classes.hideRightSeparator,
      valueGetter: (params) => params.row.info.a,
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      renderCell: (params) => (
        <RowAction>
          <ApplicationActionButtons interview={params.row} />
        </RowAction>
      ),
    },
    {
      field: GRID_DETAIL_PANEL_TOGGLE_FIELD,
      headerName: "Interview",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      renderCell: (params) => <DetailPanelToggle id={params.id} value={params.value} />,
    },
  ]);
  const [applicationColumnVisibilityModel, setApplicationColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    ...applicationColumns.reduce((prev, curr) => ({ ...prev, [curr.field]: false }), {}),
    applicantId: true,
    contactEmail: true,
    relatedApplicantId: true,
    action: true,
    [GRID_DETAIL_PANEL_TOGGLE_FIELD]: true,
  });

  const [applicationFilterModel, setApplicationFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  // const [applicationOrder, setApplicationOrder] = useState<Order>("asc");
  // const [interviewOrder, setInterviewOrder] = useState<Order>("asc");
  // const [applicationOrderBy, setApplicationOrderBy] = useState<string | number>("applicantId");
  // const [interviewOrderBy, setInterviewOrderBy] = useState<string | number>("title");
  // const [page, setPage] = useState(0);
  // const [rowsPerPage, setRowsPerPage] = useState(5);
  // const [openCustomizeView, setOpenCustomizeView] = useState(false);
  // const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // const [openCollapse, setClose] = useState(false);
  const [applicationRows, setApplicationRows] = useState<Interviewee[]>([]);
  const [applicationList, setApplicationList] = useState<Interviewee[]>([
    {
      info: {
        applicantId: 1,
        contactEmail: "xyz@efhdbjn.com",
        relatedApplicantId: ["kjqebfk", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 2,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "COMPLETED",
        },
        {
          id: 3,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 2,
        contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
        relatedApplicantId: ["ewjkfbd", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 2,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 3,
        contactEmail: "kduhflukqwbd@efhdbjn.com",
        relatedApplicantId: ["wejfbn", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 2,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 4,
        contactEmail: "wqoeurpioqwejfljkq@efhdbjn.com",
        relatedApplicantId: ["wejkbf", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 2,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 3,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 4,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 5,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 5,
        contactEmail: "sdkjagci@efhdbjn.com",
        relatedApplicantId: ["cxlihusdcg", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 6,
        contactEmail: "euoyhi@efhdbjn.com",
        relatedApplicantId: ["qkjbwk", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 2,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 3,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 7,
        contactEmail: "akdhfk@efhdbjn.com",
        relatedApplicantId: ["aiojasoidh", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
        {
          id: 2,
          title: "Ballet",
          time: "1995-12-17T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 8,
        contactEmail: "aksjbfd@efhdbjn.com",
        relatedApplicantId: ["bvoirhgoi", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
    {
      info: {
        applicantId: 9,
        contactEmail: "askdjhfioe@efhdbjn.com",
        relatedApplicantId: ["ewmnrb", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
    {
      info: {
        applicantId: 10,
        contactEmail: "ertwdgsahvjbk@efhdbjn.com",
        relatedApplicantId: ["dojgrv", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
    {
      info: {
        applicantId: 11,
        contactEmail: "poiweqihujbd@efhdbjn.com",
        relatedApplicantId: ["erjbv", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
    {
      info: {
        applicantId: 12,
        contactEmail: "gscayfugved@efhdbjn.com",
        relatedApplicantId: ["sadkhgf", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
    {
      info: {
        applicantId: 13,
        contactEmail: "sacjgyfewv@efhdbjn.com",
        relatedApplicantId: ["lerginu", "jdsn"],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
  ]);

  const [interviewRows, setInterviewRows] = useState<Interview[]>([
    {
      id: 1,
      colour: "#33CCCC",
      titleEnUs: "Ballet",
      titleZhHk: "芭蕾舞",
      criteria: {
        title: {
          enUs: "[7 - 8 yrs] Ballet for Girl",
          zhHk: "[7 - 8 歲] 女子芭蕾舞",
        },
        answerPoints: [
          {
            type: "Yes No Question",
            question: {
              position: "Section 1 - Question 1",
              question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
            },
            points: [
              { type: "CHOICE", answer: "Yes", points: 0 },
              { type: "CHOICE", answer: "No", points: 0 },
            ],
            linkedQuestion: [
              {
                type: "Yes No Question",
                question: {
                  position: "Section 1 - Question 2",
                  question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
                },
                points: [
                  { type: "CHOICE", answer: "Yes", points: 0 },
                  { type: "CHOICE", answer: "No", points: 0 },
                ],
                linkedQuestion: [
                  {
                    type: "Value",
                    question: {
                      position: "Section 1 - Question 9",
                      question: "How many brothers or sisters studying in HKCCHOIR?",
                    },
                    points: [
                      { type: "VALUE", answer: { compare: "le", value: { start: 1, end: 0 } }, points: 0 },
                      { type: "VALUE", answer: { compare: "btw", value: { start: 2, end: 4 } }, points: 10 },
                      { type: "VALUE", answer: { compare: "ge", value: { start: 5, end: 0 } }, points: 20 },
                    ],
                    linkedQuestion: [],
                  },
                ],
              },
            ],
          },
          {
            type: "Date",
            question: { position: "Section 1 - Question 1", question: "Date of Birth" },
            points: [
              {
                type: "DATE",
                answer: { start: "2016-07-01", end: "2017-07-31" },
                points: 0,
              },
            ],
            linkedQuestion: [],
          },
          {
            type: "Single Choice",
            question: { position: "Section 1 - Question 1", question: "Gender" },
            points: [
              { type: "CHOICE", answer: "Boy", points: 0 },
              { type: "CHOICE", answer: "Girl", points: 5 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Dropdown",
            question: { position: "Section 1 - Question 1", question: "Parent Job Industry" },
            points: [
              { type: "CHOICE", answer: "Accounting", points: 10 },
              { type: "CHOICE", answer: "Admin & Clerical", points: 0 },
              { type: "CHOICE", answer: "Architecture and Construction", points: 20 },
              { type: "CHOICE", answer: "Arts, Entertainment and Publishing", points: 0 },
              { type: "CHOICE", answer: "Banking", points: 30 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Multiple Choice",
            question: {
              position: "Section 1 - Question 1",
              question: "How do you know about HKCCHOIR? (Can select one or more options)",
            },
            points: [
              { type: "CHOICE", answer: "Newspaper / Magazine", points: 0 },
              { type: "CHOICE", answer: "Social Media", points: 0 },
              { type: "CHOICE", answer: "Search Engine", points: 0 },
              { type: "CHOICE", answer: "Advertisement", points: 0 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Apply Course",
            question: { position: "Section 1 - Question 1", question: "Programme(s) Applied" },
            points: [
              { type: "CHOICE", answer: "Chinese Dance", points: 5 },
              { type: "CHOICE", answer: "Ballet", points: 5 },
              { type: "CHOICE", answer: "Tap Dance", points: 5 },
              { type: "CHOICE", answer: "Jazz Dance", points: 5 },
              { type: "CHOICE", answer: "Hip Hop", points: 5 },
              { type: "CHOICE", answer: "Handbell", points: 5 },
              { type: "CHOICE", answer: "Putonghua", points: 5 },
            ],
            linkedQuestion: [],
          },
        ],
        condition: {
          operator: "ROOT",
          question: "",
          condition: "",
          answer: "",
          group: [
            {
              operator: "AND",
              question: "",
              condition: "",
              answer: "",
              group: [
                { operator: "QUEST", question: "Section 1 - Question 1", condition: "eq", answer: "Yes", group: [] },
                {
                  operator: "QUEST",
                  question: "Section 1 - Question 1 (Yes - Linked Question)",
                  condition: "le",
                  answer: 1,
                  group: [],
                },
                {
                  operator: "OR",
                  question: "",
                  condition: "",
                  answer: "",
                  group: [
                    { operator: "QUEST", question: "Section 2 - Question 3", condition: "eq", answer: "Banking", group: [] },
                    {
                      operator: "AND",
                      question: "",
                      condition: "",
                      answer: "",
                      group: [
                        { operator: "QUEST", question: "Section 1 - Question 4", condition: "eq", answer: "Girl", group: [] },
                        {
                          operator: "QUEST",
                          question: "Section 2 - Question 3",
                          condition: "eq",
                          answer: "Arts, Entertainment and Publishing",
                          group: [],
                        },
                      ],
                    },
                    {
                      operator: "QUEST",
                      question: "Section 2 - Question 3",
                      condition: "eq",
                      answer: "Architecture and Construction",
                      group: [],
                    },
                  ],
                },
              ],
            },
          ],
        },

        // elem: {
        //     op: "and",
        //     elem: [
        //         { question: "Section 1 - Question 1", cmp: "e", val: "Yes" },
        //         { question: "Section 1 - Question 1 (Yes - Linked Question)", cmp: "le", val: 1 },
        //         {
        //             op: "or",
        //             elem: [
        //                 { question: "Section 2 - Question 3", cmp: "e", val: "Banking" },
        //                 { question: "Section 2 - Question 3", cmp: "e", val: "Architecture and Construction" },
        //                 {
        //                     op: "and",
        //                     elem: [
        //                         { question: "Section 1 - Question 4", cmp: "e", val: "Girl" },
        //                         { question: "Section 2 - Question 3", cmp: "e", val: "Arts, Entertainment and Publishing" },
        //                     ],
        //                 },
        //             ],
        //         },
        //         { question: "Section 1 - Question 4", cmp: "e", val: "Girl" },
        //         { question: "Section 5 - Question 1", cmp: "e", val: "Ballet" },
        //     ],
        // },
      },
      noOfCandidates: -1,
      arrangedCandidates: -1,
      unarrangedCandidates: -1,
    },
    {
      id: 2,
      colour: "#33CCCC",
      titleEnUs: "Headball",
      titleZhHk: "手球",
      criteria: {
        title: { enUs: "", zhHk: "" },
        answerPoints: [
          {
            type: "Yes No Question",
            question: {
              position: "Section 1 - Question 1",
              question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
            },
            points: [
              { type: "CHOICE", answer: "Yes", points: 0 },
              { type: "CHOICE", answer: "No", points: 0 },
            ],
            linkedQuestion: [
              {
                type: "Yes No Question",
                question: {
                  position: "Section 1 - Question 2",
                  question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
                },
                points: [
                  { type: "CHOICE", answer: "Yes", points: 0 },
                  { type: "CHOICE", answer: "No", points: 0 },
                ],
                linkedQuestion: [
                  {
                    type: "Value",
                    question: {
                      position: "Section 1 - Question 3",
                      question: "How many brothers or sisters studying in HKCCHOIR?",
                    },
                    points: [],
                    linkedQuestion: [],
                  },
                ],
              },
            ],
          },
          {
            type: "Date",
            question: { position: "Section 1 - Question 4", question: "Date of Birth" },
            points: [],
            linkedQuestion: [],
          },
          {
            type: "Single Choice",
            question: { position: "Section 1 - Question 5", question: "Gender" },
            points: [
              { type: "CHOICE", answer: "Boy", points: 0 },
              { type: "CHOICE", answer: "Girl", points: 5 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Dropdown",
            question: { position: "Section 1 - Question 6", question: "Parent Job Industry" },
            points: [
              { type: "CHOICE", answer: "Accounting", points: 10 },
              { type: "CHOICE", answer: "Admin & Clerical", points: 0 },
              { type: "CHOICE", answer: "Architecture and Construction", points: 20 },
              { type: "CHOICE", answer: "Arts, Entertainment and Publishing", points: 0 },
              { type: "CHOICE", answer: "Banking", points: 30 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Multiple Choice",
            question: {
              position: "Section 1 - Question 7",
              question: "How do you know about HKCCHOIR? (Can select one or more options)",
            },
            points: [
              { type: "CHOICE", answer: "Newspaper / Magazine", points: 0 },
              { type: "CHOICE", answer: "Social Media", points: 0 },
              { type: "CHOICE", answer: "Search Engine", points: 0 },
              { type: "CHOICE", answer: "Advertisement", points: 0 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Apply Course",
            question: { position: "Section 1 - Question 8", question: "Programme(s) Applied" },
            points: [
              { type: "CHOICE", answer: "Chinese Dance", points: 5 },
              { type: "CHOICE", answer: "Ballet", points: 5 },
              { type: "CHOICE", answer: "Tap Dance", points: 5 },
              { type: "CHOICE", answer: "Jazz Dance", points: 5 },
              { type: "CHOICE", answer: "Hip Hop", points: 5 },
              { type: "CHOICE", answer: "Handbell", points: 5 },
              { type: "CHOICE", answer: "Putonghua", points: 5 },
            ],
            linkedQuestion: [],
          },
        ],
        condition: { operator: "ROOT", question: "", condition: "", answer: "", group: [] },
      },
      noOfCandidates: -1,
      arrangedCandidates: -1,
      unarrangedCandidates: -1,
    },
    {
      id: 3,
      colour: "#33CCCC",
      titleEnUs: "Chinese Dance",
      titleZhHk: "中國舞",
      criteria: {
        title: { enUs: "", zhHk: "" },
        answerPoints: [
          {
            type: "Yes No Question",
            question: {
              position: "Section 1 - Question 1",
              question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
            },
            points: [
              { type: "CHOICE", answer: "Yes", points: 0 },
              { type: "CHOICE", answer: "No", points: 0 },
            ],
            linkedQuestion: [
              {
                type: "Yes No Question",
                question: {
                  position: "Section 1 - Question 2",
                  question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
                },
                points: [
                  { type: "CHOICE", answer: "Yes", points: 0 },
                  { type: "CHOICE", answer: "No", points: 0 },
                ],
                linkedQuestion: [
                  {
                    type: "Value",
                    question: {
                      position: "Section 1 - Question 3",
                      question: "How many brothers or sisters studying in HKCCHOIR?",
                    },
                    points: [],
                    linkedQuestion: [],
                  },
                ],
              },
            ],
          },
          {
            type: "Date",
            question: { position: "Section 1 - Question 4", question: "Date of Birth" },
            points: [],
            linkedQuestion: [],
          },
          {
            type: "Single Choice",
            question: { position: "Section 1 - Question 5", question: "Gender" },
            points: [
              { type: "CHOICE", answer: "Boy", points: 0 },
              { type: "CHOICE", answer: "Girl", points: 5 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Dropdown",
            question: { position: "Section 1 - Question 6", question: "Parent Job Industry" },
            points: [
              { type: "CHOICE", answer: "Accounting", points: 10 },
              { type: "CHOICE", answer: "Admin & Clerical", points: 0 },
              { type: "CHOICE", answer: "Architecture and Construction", points: 20 },
              { type: "CHOICE", answer: "Arts, Entertainment and Publishing", points: 0 },
              { type: "CHOICE", answer: "Banking", points: 30 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Multiple Choice",
            question: {
              position: "Section 1 - Question 7",
              question: "How do you know about HKCCHOIR? (Can select one or more options)",
            },
            points: [
              { type: "CHOICE", answer: "Newspaper / Magazine", points: 0 },
              { type: "CHOICE", answer: "Social Media", points: 0 },
              { type: "CHOICE", answer: "Search Engine", points: 0 },
              { type: "CHOICE", answer: "Advertisement", points: 0 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Apply Course",
            question: { position: "Section 1 - Question 8", question: "Programme(s) Applied" },
            points: [
              { type: "CHOICE", answer: "Chinese Dance", points: 5 },
              { type: "CHOICE", answer: "Ballet", points: 5 },
              { type: "CHOICE", answer: "Tap Dance", points: 5 },
              { type: "CHOICE", answer: "Jazz Dance", points: 5 },
              { type: "CHOICE", answer: "Hip Hop", points: 5 },
              { type: "CHOICE", answer: "Handbell", points: 5 },
              { type: "CHOICE", answer: "Putonghua", points: 5 },
            ],
            linkedQuestion: [],
          },
        ],
        condition: { operator: "ROOT", question: "", condition: "", answer: "", group: [] },
      },
      noOfCandidates: -1,
      arrangedCandidates: -1,
      unarrangedCandidates: -1,
    },
    {
      id: 4,
      colour: "#33CCCC",
      titleEnUs: "Jazz Dance",
      titleZhHk: "爵士舞",
      criteria: {
        title: { enUs: "", zhHk: "" },
        answerPoints: [
          {
            type: "Yes No Question",
            question: {
              position: "Section 1 - Question 1",
              question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
            },
            points: [
              { type: "CHOICE", answer: "Yes", points: 0 },
              { type: "CHOICE", answer: "No", points: 0 },
            ],
            linkedQuestion: [
              {
                type: "Yes No Question",
                question: {
                  position: "Section 1 - Question 2",
                  question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
                },
                points: [
                  { type: "CHOICE", answer: "Yes", points: 0 },
                  { type: "CHOICE", answer: "No", points: 0 },
                ],
                linkedQuestion: [
                  {
                    type: "Value",
                    question: {
                      position: "Section 1 - Question 3",
                      question: "How many brothers or sisters studying in HKCCHOIR?",
                    },
                    points: [],
                    linkedQuestion: [],
                  },
                ],
              },
            ],
          },
          {
            type: "Date",
            question: { position: "Section 1 - Question 4", question: "Date of Birth" },
            points: [],
            linkedQuestion: [],
          },
          {
            type: "Single Choice",
            question: { position: "Section 1 - Question 5", question: "Gender" },
            points: [
              { type: "CHOICE", answer: "Boy", points: 0 },
              { type: "CHOICE", answer: "Girl", points: 5 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Dropdown",
            question: { position: "Section 1 - Question 6", question: "Parent Job Industry" },
            points: [
              { type: "CHOICE", answer: "Accounting", points: 10 },
              { type: "CHOICE", answer: "Admin & Clerical", points: 0 },
              { type: "CHOICE", answer: "Architecture and Construction", points: 20 },
              { type: "CHOICE", answer: "Arts, Entertainment and Publishing", points: 0 },
              { type: "CHOICE", answer: "Banking", points: 30 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Multiple Choice",
            question: {
              position: "Section 1 - Question 7",
              question: "How do you know about HKCCHOIR? (Can select one or more options)",
            },
            points: [
              { type: "CHOICE", answer: "Newspaper / Magazine", points: 0 },
              { type: "CHOICE", answer: "Social Media", points: 0 },
              { type: "CHOICE", answer: "Search Engine", points: 0 },
              { type: "CHOICE", answer: "Advertisement", points: 0 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Apply Course",
            question: { position: "Section 1 - Question 8", question: "Programme(s) Applied" },
            points: [
              { type: "CHOICE", answer: "Chinese Dance", points: 5 },
              { type: "CHOICE", answer: "Ballet", points: 5 },
              { type: "CHOICE", answer: "Tap Dance", points: 5 },
              { type: "CHOICE", answer: "Jazz Dance", points: 5 },
              { type: "CHOICE", answer: "Hip Hop", points: 5 },
              { type: "CHOICE", answer: "Handbell", points: 5 },
              { type: "CHOICE", answer: "Putonghua", points: 5 },
            ],
            linkedQuestion: [],
          },
        ],
        condition: { operator: "ROOT", question: "", condition: "", answer: "", group: [] },
      },
      noOfCandidates: -1,
      arrangedCandidates: -1,
      unarrangedCandidates: -1,
    },
    {
      id: 5,
      colour: "#000000",
      titleEnUs: "Tap Dance",
      titleZhHk: "踢躂舞",
      criteria: {
        title: { enUs: "", zhHk: "" },
        answerPoints: [
          {
            type: "Yes No Question",
            question: {
              position: "Section 1 - Question 1",
              question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
            },
            points: [
              { type: "CHOICE", answer: "Yes", points: 0 },
              { type: "CHOICE", answer: "No", points: 0 },
            ],
            linkedQuestion: [
              {
                type: "Yes No Question",
                question: {
                  position: "Section 1 - Question 2",
                  question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
                },
                points: [
                  { type: "CHOICE", answer: "Yes", points: 0 },
                  { type: "CHOICE", answer: "No", points: 0 },
                ],
                linkedQuestion: [
                  {
                    type: "Value",
                    question: {
                      position: "Section 1 - Question 3",
                      question: "How many brothers or sisters studying in HKCCHOIR?",
                    },
                    points: [],
                    linkedQuestion: [],
                  },
                ],
              },
            ],
          },
          {
            type: "Date",
            question: { position: "Section 1 - Question 4", question: "Date of Birth" },
            points: [],
            linkedQuestion: [],
          },
          {
            type: "Single Choice",
            question: { position: "Section 1 - Question 5", question: "Gender" },
            points: [
              { type: "CHOICE", answer: "Boy", points: 0 },
              { type: "CHOICE", answer: "Girl", points: 5 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Dropdown",
            question: { position: "Section 1 - Question 6", question: "Parent Job Industry" },
            points: [
              { type: "CHOICE", answer: "Accounting", points: 10 },
              { type: "CHOICE", answer: "Admin & Clerical", points: 0 },
              { type: "CHOICE", answer: "Architecture and Construction", points: 20 },
              { type: "CHOICE", answer: "Arts, Entertainment and Publishing", points: 0 },
              { type: "CHOICE", answer: "Banking", points: 30 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Multiple Choice",
            question: {
              position: "Section 1 - Question 7",
              question: "How do you know about HKCCHOIR? (Can select one or more options)",
            },
            points: [
              { type: "CHOICE", answer: "Newspaper / Magazine", points: 0 },
              { type: "CHOICE", answer: "Social Media", points: 0 },
              { type: "CHOICE", answer: "Search Engine", points: 0 },
              { type: "CHOICE", answer: "Advertisement", points: 0 },
            ],
            linkedQuestion: [],
          },
          {
            type: "Apply Course",
            question: { position: "Section 1 - Question 8", question: "Programme(s) Applied" },
            points: [
              { type: "CHOICE", answer: "Chinese Dance", points: 5 },
              { type: "CHOICE", answer: "Ballet", points: 5 },
              { type: "CHOICE", answer: "Tap Dance", points: 5 },
              { type: "CHOICE", answer: "Jazz Dance", points: 5 },
              { type: "CHOICE", answer: "Hip Hop", points: 5 },
              { type: "CHOICE", answer: "Handbell", points: 5 },
              { type: "CHOICE", answer: "Putonghua", points: 5 },
            ],
            linkedQuestion: [],
          },
        ],
        condition: { operator: "ROOT", question: "", condition: "", answer: "", group: [] },
      },
      noOfCandidates: -1,
      arrangedCandidates: -1,
      unarrangedCandidates: -1,
    },
  ]);

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps["getDetailPanelContent"]>>(({ row }) => <DetailPanelContent application={row} />, []);

  const handleClick1 = () => {
    console.log("handleClick1");
  };

  const handleClick2 = () => {
    console.log("handleClick2");
  };

  const interviewColumns: GridColDef<Interview>[] = [
    {
      field: "colour",
      headerName: "",
      headerClassName: classes.hideRightSeparator,
      width: 28,
      renderCell: (params) => <Box sx={{ p: 1.5, borderRadius: "50%", background: params.value }} />,
    },
    {
      field: "title",
      headerName: "Interview Title",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      valueGetter: (params) => translate(i18n, { enUs: params.row.titleEnUs, zhHk: params.row.titleZhHk }),
    },
    {
      field: "criteria",
      headerName: "Interview Criteria",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      renderCell: (params) =>
        params.value.title.enUs !== "" || params.value.title.zhHk !== "" ? (
          translate(i18n, params.value.title)
        ) : (
          <EditCriteriaSettingModal criteria={params.value} interviewId={params.row.id} />
        ),
    },
    {
      field: "noOfCandidates",
      headerName: "No. of Candidates",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      valueGetter: (params) => (params.value === -1 ? "-" : params.value),
    },
    {
      field: "arrangedCandidates",
      headerName: "Arranged Candidates",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      valueGetter: (params) => (params.value === -1 ? "-" : params.value),
    },
    {
      field: "unarrangedCandidates",
      headerName: "Unarranged Candidates",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      valueGetter: (params) => (params.value === -1 ? "-" : params.value),
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: classes.hideRightSeparator,
      headerAlign: "center",
      align: "center",
      width: 80,
      renderCell: (params) => (
        <RowAction>
          <InterviewActionButtons interview={params.row} />
        </RowAction>
      ),
    },
  ];

  useEffect(() => {
    const fetching = async () => {
      if (urlParams && urlParams.formId && urlParams.applicationListId) {
        const formId = parseInt(urlParams.formId);
        const applicationListId = parseInt(urlParams.applicationListId);
        const { data } = await findAllInterviewsByStageIdAndSchoolCode(applicationListId, formId);
        const transform: Interview[] = data.map((value: Interview) => ({
          id: value.id,
          titleEnUs: value.titleEnUs,
          titleZhHk: value.titleZhHk,
          colour: value.colour,
          criteria: value.criteria,
          noOfCandidates: 20,
          arrangedCandidates: ["test"],
          unarrangedCandidates: ["test1"],
        }));
        setInterviewRows(transform);
      }
      setIsLoading(false);
    };
    fetching();
  }, [urlParams]);

  useEffect(() => {
    if (!isLoading) {
      setApplicationRows(
        applicationList
          .filter((interviewee) => interviewee.interviews.filter((interview) => interview.status === filterBy).length > 0 || filterBy === "-" || filterBy === "")
          .map((interviewee) => ({
            ...interviewee,
            interviews: interviewee.interviews.filter((interview) => interview.status === filterBy || filterBy === "-" || filterBy === ""),
          }))
      );
    }
  }, [isLoading, filterBy]);

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValueButton(newValue);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === LanguageEnum.ZH_HK ? applicationListData.titleZhHk : i18n.resolvedLanguage === LanguageEnum.EN_US ? applicationListData.titleEnUs : null}
    </Link>,
    <Typography key="3" color="primary.main">
      {t("view.interview")}
    </Typography>,
  ];

  return (
    <Grid sx={{ m: 2 }}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <Grid sx={{ my: 1, background: "white" }}>
            <Grid container sx={{ p: 2 }} className="outterContainer">
              <Grid item xs={12}>
                <InputLabel sx={title}>{t("view.interview")}</InputLabel>
              </Grid>

              <Grid item xs={12}>
                <InputLabel sx={{ marginBottom: 1 }}>Data Source: 2023 - 2024 Member Application Form (Accepted)</InputLabel>
              </Grid>

              <Grid item xs={12}>
                <TabContext value={buttonValue}>
                  <Grid item xs={12}>
                    <Grid container sx={{ marginTop: 1 }}>
                      <Grid item xs={12} alignItems="left">
                        <Box sx={{ width: "100%", typography: "body1" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                              <Tab label="ALL APPLICATION" value="1" onClick={() => setButtonStatus("ALL APPLICATION")} />
                              <Tab label="INTERVIEW" value="2" onClick={() => setButtonStatus("INTERVIEW")} />
                            </TabList>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <TabPanel value="1" sx={{ p: 0, pt: 2 }}>
                    <Grid item xs={12} display="flex" alignItems="center">
                      <DataGridPro
                        sx={{ border: 0 }}
                        rows={applicationRows}
                        columns={applicationColumns}
                        columnVisibilityModel={applicationColumnVisibilityModel}
                        getRowId={(row) => row.info.applicantId}
                        pageSize={5}
                        rowsPerPageOptions={[5]}
                        localeText={{
                          toolbarFilters: "",
                        }}
                        components={{ Toolbar: ApplicationToolbar }}
                        componentsProps={{
                          toolbar: {
                            CustomizeViewMenu: (
                              <CustomizeViewMenu
                                columns={applicationColumns}
                                visibilityModel={applicationColumnVisibilityModel}
                                onSelect={(key, checked) =>
                                  setApplicationColumnVisibilityModel({
                                    ...applicationColumnVisibilityModel,
                                    [key]: checked,
                                  })
                                }
                                onDragEnd={(result) => setApplicationColumns(result)}
                              />
                            ),
                          },
                        }}
                        filterModel={applicationFilterModel}
                        onFilterModelChange={(model) => setApplicationFilterModel(model)}
                        onColumnOrderChange={(params) => {
                          const result = [...applicationColumns];
                          const [removed] = result.splice(params.oldIndex - 1, 1);
                          result.splice(params.targetIndex - 1, 0, removed);
                          setApplicationColumns(result);
                        }}
                        autoHeight
                        disableSelectionOnClick
                        disableColumnResize
                        disableColumnMenu
                        disableColumnSelector
                        getDetailPanelHeight={() => "auto"}
                        getDetailPanelContent={getDetailPanelContent}
                      />
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2">
                    <Grid item xs={12}>
                      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} display="flex" alignItems="center">
                          <InterviewDataGrid rows={interviewRows} columns={interviewColumns} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default ViewInterviewDashboard;
