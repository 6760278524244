import { CreateMessageTemplateInput } from "../components/applicationForm/message/CreateTemplate";
import { EditMessageTemplateInput } from "../components/applicationForm/message/EditTemplate";
import client from "./interceptors";

export async function getMessageTemplate() {
  const res = await client.get("messageTemplate");
  const result = res.data;
  return result;
}

export async function getMessageTemplateById(id: number) {
  const res = await client.get(`messageTemplate/${id}`);
  const result = res.data;
  return result;
}

export async function createMessageTemplate(data: CreateMessageTemplateInput) {
  const res = await client.post("messageTemplate", data);
  const result = res.data;
  return result;
}

export async function editMessageTemplateById(id: number, data: EditMessageTemplateInput) {
  const res = await client.put(`messageTemplate/${id}`, data);
  const result = res.data;
  return result;
}

export async function deleteMessageTemplate(id: number) {
  const res = await client.delete(`messageTemplate/${id}`);
  const result = res.data;
  return result;
}
