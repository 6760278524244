import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import PlaceIcon from "@mui/icons-material/Place";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import { Box, Button, Divider, FormControl, FormControlLabel, Modal, Radio, RadioGroup } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import moment, { Moment } from "moment";
import React, { ChangeEvent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import { Interviewee, InterviewTimeSlot } from "../../../interfaces/interview";
import modalStyle from "../../utils/modalStyle";
import SliceCalendar from "./SlideCalendar";
import useWindowDimensions from "./useWindowDimensions";

interface SwitchInterviewModalProps {
  interviewee: Interviewee;
  timeSlots: InterviewTimeSlot[];
}

function SwitchInterviewModal(props: SwitchInterviewModalProps) {
  const { interviewee, timeSlots } = props;
  const { height } = useWindowDimensions();
  const { t } = useTranslation();
  const targetDateList = timeSlots
    .map((timeSlot) => timeSlot.date)
    .filter((value, index, self) => self.indexOf(value) === index)
    .map((date) => moment(date));

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Moment>(moment(interviewee.interviews[0].time));
  const [selectedTimeSlot, setSelectedTimeSlot] = useState<InterviewTimeSlot>();
  const [radioValue, setRadioValue] = useState<number>(-1);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (date: Moment) => {
    setSelectedDate(date);
  };

  const handleSelect = (event: ChangeEvent) => {
    setRadioValue(parseInt((event.target as HTMLInputElement).value));
    setSelectedTimeSlot(timeSlots.filter((timeSlot) => timeSlot.id === parseInt((event.target as HTMLInputElement).value))[0]);
  };

  return (
    <>
      <Button variant="contained" size="small" className="buttonAction buttonActionOrange" onClick={handleOpen}>
        {t("interview.switch")}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={{ ...modalStyle, p: 0, maxWidth: "90%", width: 800 }}>
          <Grid container spacing={2}>
            <Grid xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer" sx={{ mx: 3, mt: 2 }}>
              <div>Add Interview Status</div>
              <Button variant="text" onClick={handleClose} className="modalClearBtn">
                <ClearIcon />
              </Button>
            </Grid>
            <Grid xs={12} sx={{ px: 5, fontWeight: 400, fontSize: "14px", lineHeight: "150%", letterSpacing: "0.15px" }}>
              Applicant ID: {`00000${interviewee.info.applicantId}`.slice(-5)}
            </Grid>
            {interviewee.info.relatedApplicant.map((applicant: Interviewee) => (
              <Grid
                xs={12}
                sx={{
                  mx: 5,
                  my: 0.5,
                  px: 2,
                  py: 1.5,
                  background: "rgba(64, 158, 255, 0.05)",
                  borderRadius: "4px",
                  fontWeight: 500,
                  fontSize: 14,
                  lineHeight: 1.43,
                  letterSpacing: "0.15px",
                }}
                key={applicant.info.applicantId}
              >
                <Box
                  sx={{
                    fontWeight: 300,
                    fontSize: 12,
                    lineHeight: "150%",
                    letterSpacing: "0.15px",
                    color: "#606266",
                  }}
                >
                  Related Applicant (ID: {`00000${applicant.info.applicantId}`.slice(-5)}):
                </Box>
                <Box>Interview: {applicant.interviews[0].title}</Box>
                <Box>Interview Time: {moment(applicant.interviews[0].time).format("LLL")}</Box>
                <Box>Interview Location: {applicant.interviews[0].location}</Box>
              </Grid>
            ))}
            <Grid xs={12} sx={{ px: 5 }}>
              <SliceCalendar initaialDate={selectedDate} targetDateList={targetDateList} onChange={handleChange} />
              <Divider />
              <FormControl fullWidth>
                <RadioGroup value={radioValue} onChange={handleSelect}>
                  <Grid container mt={1} sx={{ maxHeight: `${height - 717}px`, overflow: "scroll" }}>
                    {timeSlots
                      .filter((timeSlot) => moment(timeSlot.date).format("YYYY-MM-DD") === selectedDate.format("YYYY-MM-DD"))
                      .map((timeSlot) => (
                        <Grid
                          xs={12}
                          key={timeSlot.id}
                          sx={{
                            mb: 1,
                            border: "1px solid #D8DCE6",
                            borderRadius: "4px",
                            borderRight: `8px solid ${timeSlot.candidate.length < timeSlot.quota ? "#58CF69" : "#F56C6C"}`,
                          }}
                        >
                          <Grid container>
                            <Grid xs={1} display="flex" alignItems="center" justifyContent="center">
                              <FormControlLabel value={timeSlot.id} control={<Radio />} label="" />
                            </Grid>
                            <Grid xs={11}>
                              <Grid container>
                                <Grid xs={12} sx={{ p: 0 }}>{`${moment(timeSlot.startTime).format("HH:mm")} - ${moment(timeSlot.endTime).format("HH:mm")}`}</Grid>
                                <Grid xs={12} sx={{ p: 0 }}>
                                  <Grid container>
                                    <Grid xs={2} sx={{ p: 0 }} display="flex" alignItems="center">
                                      <SupervisorAccountIcon sx={{ color: "#646B70" }} />
                                      {`${timeSlot.candidate.length} / ${timeSlot.quota}`}
                                    </Grid>
                                    <Grid xs={4} sx={{ p: 0 }} display="flex" alignItems="center">
                                      <PersonIcon sx={{ color: "#646B70" }} />
                                      {timeSlot.interviewer.join(",")}
                                    </Grid>
                                    <Grid xs={6} sx={{ p: 0 }} display="flex" alignItems="center">
                                      <PlaceIcon sx={{ color: "#646B70" }} />
                                      {`${timeSlot.centre} (${timeSlot.room})`}
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      ))}
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid xs={12}>
              <Divider />
              <Grid container>
                <Grid xs={6} sx={{ px: 5, py: 1.5, fontWeight: 400, fontSize: 14, lineHeight: 1.43, letterSpacing: "0.15px" }}>
                  <Box sx={{ fontWeight: 500 }}>Original Interview:</Box>
                  <Box>{moment(interviewee.interviews[0].time).format("LLL")}</Box>
                  <Box>{interviewee.interviews[0].location}</Box>
                </Grid>
                <Grid
                  xs={6}
                  sx={{
                    px: 5,
                    py: 1.5,
                    fontWeight: 400,
                    fontSize: 14,
                    lineHeight: 1.43,
                    letterSpacing: "0.15px",
                    background: "rgba(64, 158, 255, 0.05)",
                    color: "#1989FA",
                  }}
                >
                  <Box>New Interview:</Box>
                  <Box>{selectedTimeSlot && moment(selectedTimeSlot.date).format("LLL")}</Box>
                  <Box>{selectedTimeSlot && `${selectedTimeSlot.centre} (${selectedTimeSlot.room})`}</Box>
                </Grid>
              </Grid>

              <Divider />
            </Grid>

            <Grid xs={12} sx={{ m: 1 }} display="flex" justifyContent="flex-end">
              <Button variant="text" onClick={handleClose}>
                {t("view.cancel")}
              </Button>
              <Button variant="text" type="submit" onClick={handleClose} disabled={!selectedTimeSlot}>
                {t("view.submit")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default SwitchInterviewModal;
