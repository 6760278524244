import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import CreateApplicationForm from "./create/CreateApplicationForm";
import EditApplicationForm from "./create/EditApplicationForm";
import MessageRouter from "./message/MessageRouter";
import ViewApplicantDetails from "./view/ViewApplicantDetails";
import ViewApplicationFormDashboard from "./view/ViewApplicationFormDashboard";
import ViewApplicationFormSummary from "./view/ViewApplicationFormSummary";
import InterviewRouter from "./interview/InterviewRouter";
import EditSubmissionForm from "../submission/EditSubmissionForm";
import NotFound from "../utils/NotFound";

function ApplicationStageRouter() {
  const lastSchool = sessionStorage.getItem("lastSchool");

  return (
    <Routes>
      <Route path="create_application" element={<CreateApplicationForm />} />
      <Route path="create_registration" element={<CreateApplicationForm />} />
      <Route path="edit_application/:formId" element={<EditApplicationForm />} />
      <Route path="edit_registration/:formId" element={<EditApplicationForm />} />
      <Route path="summary" element={<ViewApplicationFormSummary />} />
      <Route path="dashboard/:formId" element={<ViewApplicationFormDashboard />} />
      <Route path="dashboard/:stageId/submission/:submissionId/edit" element={<EditSubmissionForm />} />
      <Route path="dashboard/:formId/message/*" element={<MessageRouter />} />
      <Route path="dashboard/:formId/interview/*" element={<InterviewRouter />} />
      <Route path="dashboard/:formId/applicant/:applicantId" element={<ViewApplicantDetails />} />
      {lastSchool ? <Route path="*" element={<Navigate to={`/cms/school/${lastSchool}/application/register`} />} /> : <Route path="*" element={<NotFound />} />}
    </Routes>
  );
}

export default ApplicationStageRouter;
