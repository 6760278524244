import React, { ReactNode } from "react";
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { FormControl, FormHelperText, MenuItem, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import { RoundSelect } from "../utils/CustomStyle";
import { SingleQuestion } from "./SubmissionForm";
import QuestionComponent from "./QuestionComponent";

interface Props {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
  question: SingleQuestion;
  setLinkedQuestion: (select: number[], deselect: number[]) => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function SelectYesNo({ register, errors, watch, setValue, control, question, setLinkedQuestion }: Props) {
  const { t } = useTranslation();
  const parsedYesLinkedQuestions: number[] = [];
  const parsedNoLinkedQuestions: number[] = [];

  return (
    <>
      {question.mandatory === true ? (
        <FormControl size="small" fullWidth error={errors[question.id] && true}>
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            rules={{ required: t("forms.required") }}
            defaultValue=""
            render={({ field }) => (
              <RoundSelect
                {...field}
                onChange={(e: SelectChangeEvent<unknown>) => {
                  field.onChange(e.target.value);
                  if (e.target.value === "yes") {
                    const yesLinkedQuestions = question.yesNoQuestions.yes.linkedQuestion;
                    for (const yesLinkedQuestion of yesLinkedQuestions) {
                      parsedYesLinkedQuestions.push(parseInt(yesLinkedQuestion));
                    }
                    const noLinkedQuestions = question.yesNoQuestions.no.linkedQuestion;
                    for (const noLinkedQuestion of noLinkedQuestions) {
                      parsedNoLinkedQuestions.push(parseInt(noLinkedQuestion));
                    }
                    setLinkedQuestion(parsedYesLinkedQuestions, parsedNoLinkedQuestions);
                  } else if (e.target.value === "no") {
                    const yesLinkedQuestions = question.yesNoQuestions.yes.linkedQuestion;
                    for (const yesLinkedQuestion of yesLinkedQuestions) {
                      parsedYesLinkedQuestions.push(parseInt(yesLinkedQuestion));
                    }
                    const noLinkedQuestions = question.yesNoQuestions.no.linkedQuestion;
                    for (const noLinkedQuestion of noLinkedQuestions) {
                      parsedNoLinkedQuestions.push(parseInt(noLinkedQuestion));
                    }
                    setLinkedQuestion(parsedNoLinkedQuestions, parsedYesLinkedQuestions);
                  }
                }}
                fullWidth
                size="small"
                error={errors[question.id] && true}
              >
                <MenuItem value="yes">{t("submission.yes")}</MenuItem>
                <MenuItem value="no">{t("submission.no")}</MenuItem>
              </RoundSelect>
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      ) : (
        <FormControl size="small" fullWidth error={errors[question.id] && true}>
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <RoundSelect
                onChange={(e: SelectChangeEvent<unknown>) => {
                  field.onChange(e.target.value);
                  if (e.target.value === "yes") {
                    const yesLinkedQuestions = question.yesNoQuestions.yes.linkedQuestion;
                    for (const yesLinkedQuestion of yesLinkedQuestions) {
                      parsedYesLinkedQuestions.push(parseInt(yesLinkedQuestion));
                    }
                    const noLinkedQuestions = question.yesNoQuestions.no.linkedQuestion;
                    for (const noLinkedQuestion of noLinkedQuestions) {
                      parsedNoLinkedQuestions.push(parseInt(noLinkedQuestion));
                    }
                    setLinkedQuestion(parsedYesLinkedQuestions, parsedNoLinkedQuestions);
                  } else if (e.target.value === "no") {
                    const yesLinkedQuestions = question.yesNoQuestions.yes.linkedQuestion;
                    for (const yesLinkedQuestion of yesLinkedQuestions) {
                      parsedYesLinkedQuestions.push(parseInt(yesLinkedQuestion));
                    }
                    const noLinkedQuestions = question.yesNoQuestions.no.linkedQuestion;
                    for (const noLinkedQuestion of noLinkedQuestions) {
                      parsedNoLinkedQuestions.push(parseInt(noLinkedQuestion));
                    }
                    setLinkedQuestion(parsedNoLinkedQuestions, parsedYesLinkedQuestions);
                  }
                }}
                fullWidth
                size="small"
                error={errors[question.id] && true}
              >
                <MenuItem value="yes">{t("submission.yes")}</MenuItem>
                <MenuItem value="no">{t("submission.no")}</MenuItem>
              </RoundSelect>
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      )}
    </>
  );
}

export default SelectYesNo;
