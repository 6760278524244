import "./SendMessage.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { push } from "redux-first-history";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Button, Grid, Link, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { getApplicationListById } from "../../../api/applicationList";
import { duplicateMessage, deleteMessageById, getAllMessages, getMessageById } from "../../../api/message";
import { ApplicationList } from "../../../interfaces/applicationList";
import { MessageWithMessageAttachmentAndMessageRecipient, MessageWithMessageRecipient } from "../../../interfaces/message";
import { MessageStatusEnum } from "../../../models/messageStatusEnum";
import { useAppDispatch } from "../../../redux/hooks";

interface SelectedData {
  id: number;
  name: string;
  recipient: number;
  readRate: string;
  status: string;
  updatedAt: string;
}

function SendMessage() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { applicationListId, formId } = params;
  const [pageSize, setPageSize] = useState<number>(10);

  const messageTitle = t("sendMessage.messageTitle");
  const recipient = t("sendMessage.recipient");
  const readRate = t("sendMessage.readRate");
  const status = t("sendMessage.status");
  const deliveryDate = t("sendMessage.deliveryDate");
  const action = t("sendMessage.action");
  const duplicate = t("sendMessage.duplicate");
  const deleteButton = t("sendMessage.delete");
  const resendFailure = t("sendMessage.resendFailure");
  const edit = t("sendMessage.edit");
  const draft = t("sendMessage.draft");
  const scheduled = t("sendMessage.scheduled");
  const inReview = t("sendMessage.inReview");
  const notSendToAll = t("sendMessage.notSendToAll");
  const sent = t("sendMessage.sent");
  const declined = t("sendMessage.declined");
  const createMessageTranslation = t("sendMessage.createMessage");
  const messageTemplate = t("sendMessage.messageTemplate");
  // const search = t("sendMessage.search");
  // const published = t("sendMessage.published");
  // const allMessage = t("sendMessage.allMessage");
  // const reSchedule = t("sendMessage.reSchedule");

  function statusStyle(msgStatus: string) {
    switch (msgStatus) {
      case MessageStatusEnum.DRAFT:
        return "draftStatus";
      case MessageStatusEnum.SCHEDULED:
        return "scheduledStatus";
      case MessageStatusEnum.IN_REVIEW:
        return "inReviewStatus";
      case MessageStatusEnum.NOT_SEND_TO_ALL:
        return "notSendToAllStatus";
      case MessageStatusEnum.SENT:
        return "sentStatus";
      case MessageStatusEnum.DECLINED:
        return "declinedStatus";
      default:
        return "";
    }
  }

  function statusTranslate(msgStatus: string) {
    switch (msgStatus) {
      case MessageStatusEnum.DRAFT:
        return draft;
      case MessageStatusEnum.SCHEDULED:
        return scheduled;
      case MessageStatusEnum.IN_REVIEW:
        return inReview;
      case MessageStatusEnum.NOT_SEND_TO_ALL:
        return notSendToAll;
      case MessageStatusEnum.SENT:
        return sent;
      case MessageStatusEnum.DECLINED:
        return declined;
      default:
        return "";
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function messageStatus(params: GridRenderCellParams) {
    return <div className={statusStyle(params.row.status)}>{statusTranslate(params.row.status)}</div>;
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function formattedScheduledTime(params: GridRenderCellParams) {
    if (params.row.updatedAt !== null) {
      return <div>{moment(params.row.updatedAt).format("YYYY/MM/DD hh:mm a")}</div>;
    }
    return <div>-</div>;
  }

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [listData, setListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setListData(result.data);
    }
  }

  const [rows, setRows] = useState<SelectedData[]>([]);
  const [messageData, setMessageData] = useState<MessageWithMessageRecipient[]>([]);
  async function fetchGetAllMessages(listId: number, stageId: number) {
    const result = await getAllMessages(listId, stageId);
    if (result.success) {
      setMessageData(result.data);
    }
  }

  async function fetchDuplicateMessage(messageId: number) {
    if (applicationListId && formId) {
      const result = await getMessageById(parseInt(applicationListId), parseInt(formId), messageId);
      if (result.success) {
        const message: MessageWithMessageAttachmentAndMessageRecipient = result.data;

        const { type, html, json, scheduledTime, name } = message;
        const form = { type, html, json, scheduledTime, name: `${name} (Copy)` };

        const duplicatedMessage = await duplicateMessage(parseInt(applicationListId), parseInt(formId), form);
        if (duplicatedMessage.success) {
          await fetchGetAllMessages(parseInt(applicationListId), parseInt(formId));
        }
      }
    }
  }

  async function fetchDeleteMessageById(messageId: number) {
    if (applicationListId && formId) {
      const result = await deleteMessageById(parseInt(applicationListId), parseInt(formId), messageId);
      if (result.success) {
        await fetchGetAllMessages(parseInt(applicationListId), parseInt(formId));
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  function messageActionBtns(params: GridRenderCellParams) {
    if (params.row.status === MessageStatusEnum.DRAFT) {
      return (
        <div>
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionGreen"
            onClick={() => {
              navigate(`${params.row.id}/dashboard`);
            }}
          >
            {t("view.view")}
          </Button>
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionDarkBlue"
            onClick={async () => {
              const messageId = parseInt(params.row.id);
              await fetchDuplicateMessage(messageId);
            }}
          >
            {duplicate}
          </Button>
          {/* <Button variant="contained" size="small" className="buttonAction buttonActionOrange" disabled>
                            {reSchedule}
                        </Button> */}
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionBlue"
            onClick={async () => {
              navigate(`${params.row.id}/edit`);
            }}
          >
            {edit}
          </Button>
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionRed"
            onClick={async () => {
              const messageId = parseInt(params.row.id);
              await fetchDeleteMessageById(messageId);
            }}
          >
            {deleteButton}
          </Button>
        </div>
      );
    }

    if (params.row.status === MessageStatusEnum.SCHEDULED || params.row.status === MessageStatusEnum.DECLINED) {
      return (
        <div>
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionGreen"
            onClick={() => {
              navigate(`${params.row.id}/dashboard`);
            }}
          >
            {t("view.view")}
          </Button>
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionBlue"
            onClick={() => {
              navigate(`${params.row.id}/edit`);
            }}
          >
            {edit}
          </Button>
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionDarkBlue"
            onClick={async () => {
              const messageId = parseInt(params.row.id);
              await fetchDuplicateMessage(messageId);
            }}
          >
            {duplicate}
          </Button>
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionRed"
            onClick={async () => {
              const messageId = parseInt(params.row.id);
              await fetchDeleteMessageById(messageId);
            }}
          >
            {deleteButton}
          </Button>
        </div>
      );
    }

    if (params.row.status === MessageStatusEnum.IN_REVIEW || params.row.status === MessageStatusEnum.NOT_SEND_TO_ALL) {
      return (
        <div>
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionGreen"
            onClick={() => {
              navigate(`${params.row.id}/dashboard`);
            }}
          >
            {t("view.view")}
          </Button>

          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionDarkBlue"
            onClick={async () => {
              const messageId = parseInt(params.row.id);
              await fetchDuplicateMessage(messageId);
            }}
          >
            {duplicate}
          </Button>
          <Button variant="contained" size="small" className="buttonAction buttonActionGrey" disabled>
            {resendFailure}
          </Button>
        </div>
      );
    }

    if (params.row.status === MessageStatusEnum.SENT) {
      return (
        <div>
          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionGreen"
            onClick={() => {
              navigate(`${params.row.id}/dashboard`);
            }}
          >
            {t("view.view")}
          </Button>

          <Button
            variant="contained"
            size="small"
            className="buttonAction buttonActionDarkBlue"
            onClick={async () => {
              const messageId = parseInt(params.row.id);
              await fetchDuplicateMessage(messageId);
            }}
          >
            {duplicate}
          </Button>
        </div>
      );
    }
    return <div></div>;
  }

  const columns: GridColDef[] = [
    { field: "name", headerName: messageTitle, width: 300 },
    { field: "recipient", headerName: recipient, width: 150 },
    { field: "readRate", headerName: readRate, width: 150 },
    { field: "status", headerName: status, width: 150, renderCell: messageStatus },
    { field: "updatedAt", headerName: deliveryDate, width: 200, renderCell: formattedScheduledTime },
    {
      field: "actions",
      headerName: action,
      minWidth: 800,
      renderCell: messageActionBtns,
    },
  ];

  useEffect(() => {
    const fetching = async () => {
      if (applicationListId && formId) {
        await fetchApplicationList(parseInt(applicationListId));
        await fetchGetAllMessages(parseInt(applicationListId), parseInt(formId));
        setIsLoading(false);
      }
    };
    fetching();
  }, [params]);

  useEffect(() => {
    if (messageData && messageData.length !== 0) {
      const selectedData = [];
      for (const message of messageData) {
        const readCheck = message.messageRecipients.filter((msgRecipient) => msgRecipient.readCheck === true);
        selectedData.push({
          id: message.id,
          name: message.name,
          recipient: message.messageRecipients.length || 0,
          readRate: `${readCheck.length} / ${message.messageRecipients.length}`,
          status: message.state,
          updatedAt: message.updatedAt,
        });
      }
      setRows(selectedData);
    } else {
      setRows([]);
    }
  }, [messageData]);

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (applicationListId) {
      dispatch(push(`/cms/school/${lastSchool}/application_list/${parseInt(applicationListId)}/summary`));
    }
  }

  function handleClick3(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (applicationListId && formId) {
      dispatch(push(`/cms/school/${lastSchool}/application_list/${parseInt(applicationListId)}/dashboard/${parseInt(formId)}`));
    }
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHK" ? listData.titleZhHk : i18n.resolvedLanguage === "enUS" ? listData.titleEnUs : null}
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick3}>
      Application
    </Link>,
    <Typography key="3" color="primary.main">
      Send Message
    </Typography>,
  ];

  return (
    <Grid sx={{ m: 2 }}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <Grid sx={{ my: 1, background: "white" }}>
            <Grid container sx={{ p: 2 }} className="outterContainer">
              <Grid item xs={12} sx={{ my: 1 }}>
                <Grid container>
                  <Grid item xs={6} display="flex" alignItems="center">
                    {/* <div>
                                            {search} <input type="text" className="searchInput"></input>
                                        </div> */}
                  </Grid>

                  <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center" className="buttonGroups">
                    <Button
                      className="buttonGroupSent"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        navigate("template");
                      }}
                    >
                      {messageTemplate}
                    </Button>
                    <Button
                      className="buttonGroupSent"
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        navigate("create");
                      }}
                    >
                      {createMessageTranslation}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <div style={{ width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 20, 30]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    disableSelectionOnClick
                    autoHeight
                    disableColumnMenu
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default SendMessage;
