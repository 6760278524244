import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Popover, Typography } from "@mui/material";
import React, { useState, MouseEvent, ReactNode } from "react";

interface RowActionProps {
  children: ReactNode;
}

function RowAction(props: RowActionProps) {
  const { children } = props;

  const [openAction, setOpenAction] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handlePopover = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenAction(!openAction);
  };

  return (
    <>
      <Button variant="outlined" onClick={handlePopover}>
        <ArrowDropDownIcon />
      </Button>
      <Popover
        open={openAction}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
          setOpenAction(false);
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Typography sx={{ p: 2 }}>{children}</Typography>
      </Popover>
    </>
  );
}

export default RowAction;
