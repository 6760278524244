import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Checkbox, Collapse, Grid, IconButton } from "@mui/material";
import React, { SetStateAction, useState } from "react";
import { Interviewee } from "../../../interfaces/interview";

interface AddCandidateCollapseProps {
  interviewee: Interviewee;
  checked: boolean;
  setSelected: (value: SetStateAction<number[]>) => void;
}

function AddCandidateCollapse(props: AddCandidateCollapseProps) {
  const { interviewee, checked, setSelected } = props;
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <Grid item xs={12} sx={checked ? { bgcolor: "rgba(64, 158, 255, 0.05)" } : {}}>
      <Grid container>
        <Grid item xs={6} alignItems="center">
          <Checkbox
            checked={checked}
            onChange={(event, data) =>
              setSelected((state) => {
                console.log(data, data ? [...state, interviewee.info.applicantId] : state.filter((id) => id !== interviewee.info.applicantId));
                return data ? [...state, interviewee.info.applicantId] : state.filter((id) => id !== interviewee.info.applicantId);
              })
            }
          />
          <span>Applicant ID: {`00000${interviewee.info.applicantId}`.slice(-5)}</span>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center">
          <IconButton onClick={handleOpen}>{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
        </Grid>
        <Grid item xs={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {interviewee.info.relatedApplicant.length > 0 && (
              <Grid container display="flex" justifyContent="center" alignItems="center">
                {interviewee.info.relatedApplicant.map((related: Interviewee) => (
                  <Grid key={related.info.applicantId} item sx={{ borderRadius: "4px", border: "1px solid #D8DCE6", p: "12px" }}>
                    <Grid container>
                      <Grid item xs={12}>
                        Related Applicant (ID: {`00000${related.info.applicantId}`}):
                      </Grid>
                      {related.interviews.map((interview) => (
                        <>
                          <Grid item xs={12}>
                            Interview: {interview.title}
                          </Grid>
                          <Grid item xs={12}>
                            Interview Time: {interview.time}
                          </Grid>
                          <Grid item xs={12}>
                            Interview Location: {interview.location}
                          </Grid>
                        </>
                      ))}
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default AddCandidateCollapse;
