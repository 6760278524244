import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { Button, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Condition, ConditionFormInput, Criteria, Interview } from "../../../interfaces/interview";
import CreateConditionForm from "./CreateConditionForm";
import EditConditionForm from "./EditConditionForm";
import useConditionFormField from "./useConditionFormField";
import useOnClickOutside from "./useOnClickOutside";

interface ConditionGroupProps {
  condition: Condition;
  // eslint-disable-next-line react/require-default-props
  parentCondition?: Condition[];
  index: number;
  prefix: (string | number)[];
  parentOp?: string;
  parentLength: number;
  parentIndex: number;
  // eslint-disable-next-line react/require-default-props
  onRemove?: (index: number) => void;
  // eslint-disable-next-line react/require-default-props
  onRemoveParent?: (index: number) => void;
  // eslint-disable-next-line react/require-default-props
  onEditCondition?: (value: Condition, index: number) => void;
  // eslint-disable-next-line react/require-default-props
  onUpdateCondition?: (index: number, condition: Condition) => void;
  // eslint-disable-next-line react/require-default-props
  onUpdateParentCondition?: (index: number, condition: Condition) => void;
}

const inViewStyle = {
  fontSize: 14,
  height: 22,
  width: 46,
  paddingLeft: 2,
  paddingRight: 2,
  borderRadius: 4,
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

const defaultProps = {
  parentOp: "AND",
};

function ConditionGroup(props: ConditionGroupProps) {
  const { condition, parentCondition, index, prefix, onRemove, onRemoveParent, parentOp, parentLength, parentIndex, onEditCondition, onUpdateCondition, onUpdateParentCondition } =
    props;
  const { t } = useTranslation();
  const ref = useRef(null);
  const { control, fields, addCondition, addGroup, removeCondition, updateCondition, groupArrayInputPath } = useConditionFormField(
    parentIndex === -1 ? `${prefix.join(".")}` : `${prefix.join(".")}.${index}`
  );

  const [showEditForm, setShowEditForm] = useState(false);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [defaultValue, setDefaultValue] = useState<Condition>({
    operator: "QUEST",
    question: "",
    condition: "",
    answer: "",
    group: [],
  });
  const [currentIndex, setCurrentIndex] = useState(-1);
  const {
    control: createControl,
    formState: { errors: createErrors },
    trigger: createTrigger,
    reset,
  } = useForm<ConditionFormInput>();

  const { getValues } = useFormContext<Criteria | Interview>();

  const handleShowCreateForm = () => {
    if (!showCreateForm) {
      setShowEditForm(false);
      setShowCreateForm(true);
    } else {
      // createTrigger().then((result) => {
      //     if (result) {
      //         addCondition(defaultValue);
      //         setShowCreateForm(false);
      //         setDefaultValue({
      //             operator: "QUEST",
      //             question: "",
      //             condition: "",
      //             answer: "",
      //             group: [],
      //         });
      //         reset();
      //     }
      // });
      createTrigger().then((result) => {
        if (result && defaultValue) {
          addCondition(defaultValue);
          setShowCreateForm(false);
          setDefaultValue({
            operator: "QUEST",
            question: "",
            condition: "",
            answer: "",
            group: [],
          });
          reset();
          const obj = getValues();
          const group = "criteria" in obj ? obj.criteria.condition.group : obj.condition.group;
          if (condition.operator === "ROOT" && group.length > 1) {
            removeCondition(0);
            removeCondition(0);
            addCondition({
              operator: "AND" as const,
              question: "",
              condition: "",
              answer: "",
              group: [...group],
            });
          }
        }
      });
    }
  };

  const handleEditCondition = (value: Condition, i: number) => {
    setCurrentIndex(i);
    setDefaultValue(value);
    setShowCreateForm(false);
    setShowEditForm(true);
  };

  const handleAppendCondition = () => {
    createTrigger().then((result) => {
      if (result && defaultValue) {
        addCondition(defaultValue);
        const obj = getValues();
        const group = "criteria" in obj ? obj.criteria.condition.group : obj.condition.group;
        if (condition.operator === "ROOT" && group.length > 1) {
          removeCondition(0);
          removeCondition(0);
          addCondition({
            operator: "AND" as const,
            question: "",
            condition: "",
            answer: "",
            group: [...group],
          });
        }
      }
    });
  };

  const handleAppendGroup = () => {
    createTrigger().then((result) => {
      if (result) {
        addGroup();
      }
    });
    // createTrigger().then((result) => {
    //     console.log("handleAppendGroup", result, defaultValue);
    //     if (result && defaultValue) {
    //         addCondition(defaultValue);
    //         const obj = getValues();
    //         const group = "criteria" in obj ? obj.criteria.condition.group : obj.condition.group;
    //         if (condition.operator === "ROOT" && group.length > 1) {
    //             removeCondition(0);
    //             removeCondition(0);
    //             addCondition({
    //                 operator: "AND" as const,
    //                 question: "",
    //                 condition: "",
    //                 answer: "",
    //                 group: [...group],
    //             });
    //         }
    //     }
    // });
  };

  const handleRemove = () => {
    if (onRemove !== undefined) {
      onRemove(index);
    }

    if (parentLength <= 2) {
      if (onUpdateParentCondition !== undefined && parentCondition) {
        onUpdateParentCondition(index, parentCondition[index === 0 ? 1 : 0]);
      }
    }

    if (parentLength <= 1 && onRemoveParent !== undefined) {
      onRemoveParent(parentIndex);
    }
  };

  const handleUpdate = (idx: number, cond: Condition) => {
    if (onUpdateCondition !== undefined) {
      onUpdateCondition(index, cond);
    }
  };

  const getExistingValue = (name: (string | number)[], i: number, obj: any): any => {
    if (i >= name.length) {
      return obj;
    }

    const key = name[i];
    return getExistingValue(name, i + 1, obj[key]);
  };

  const handleChangeOp = () => {
    const newOperator = condition.operator === "OR" ? "AND" : "OR";
    const obj = getExistingValue([...prefix, index], 0, getValues());

    if (onUpdateCondition !== undefined) {
      onUpdateCondition(index, { ...obj, operator: newOperator });
    }
  };

  const edit = () => {
    setShowEditForm(false);
    setCurrentIndex(-1);
    setDefaultValue({ operator: "QUEST", question: "", condition: "", answer: "", group: [] });
  };

  useOnClickOutside(ref, () => setShowEditForm(false));

  return (
    <Grid>
      {condition?.operator === "QUEST" && (
        <Grid container>
          <Grid
            item
            xs={10}
            sx={{
              background: parentOp === "OR" ? "rgba(255, 182, 0, 0.05)" : "rgba(64, 158, 255, 0.05)",
              borderRadius: "4px",
              alignItems: "center",
              display: "flex",
              padding: "12px 16px",
            }}
          >
            <Typography>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.15px",
                  marginRight: "8px",
                }}
              >
                {condition.question}
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "20px",
                  letterSpacing: "0.15px",
                  marginRight: "8px",
                }}
              >
                {t(`interview.${condition.condition}`)}
              </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  lineHeight: "20px",
                  letterSpacing: "0.15px",
                  color: "#1989FA",
                }}
              >
                {condition.answer}
              </span>
            </Typography>
          </Grid>
          <Grid item xs={2} alignItems="center" justifyContent="flex-end" display="flex">
            <Tooltip title="test">
              <IconButton
                color="primary"
                size="small"
                onClick={() => {
                  if (onEditCondition !== undefined) onEditCondition(condition, index);
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="test">
              <IconButton color="primary" size="small" onClick={handleRemove}>
                <DeleteOutlineIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      )}
      {(condition?.operator === "AND" || condition?.operator === "OR") && (
        <Grid
          key={index}
          sx={{
            marginBottom: 1,
            marginTop: 1,
            border: parentOp === undefined ? "" : parentOp === "OR" ? "1px solid rgba(255, 182, 0, 0.3)" : "1px solid rgba(25, 137, 250, 0.3)",
            boxShadow: parentOp === undefined ? "" : parentOp === "OR" ? "0px 1px 5px rgba(0, 0, 0, 0.2)" : "0px 1px 5px rgba(0, 0, 0, 0.2)",
            borderRadius: "2px",
            padding: "16px",
          }}
        >
          <Grid container spacing={1}>
            <Grid item xs={1} display="flex" alignItems="center" justifyContent="center" onClick={handleChangeOp}>
              <div style={{ background: "white", zIndex: 1 }}>
                <div
                  style={{
                    ...inViewStyle,
                    background:
                      condition?.operator === "OR"
                        ? "linear-gradient(0deg, rgba(255, 182, 0, 0.15), rgba(255, 182, 0, 0.15)), #FFFFFF"
                        : "linear-gradient(0deg, rgba(25, 137, 250, 0.15), rgba(25, 137, 250, 0.15)), #FFFFFF",
                  }}
                >
                  <div style={{ color: condition?.operator === "OR" ? "#FFB600" : "#1989FA" }}>{condition.operator}</div>
                </div>
              </div>

              <div
                style={{
                  borderTop: `2px solid ${condition?.operator === "OR" ? "#FFB600" : "#1989FA"}`,
                  borderLeft: `2px solid ${condition?.operator === "OR" ? "#FFB600" : "#1989FA"}`,
                  borderBottom: `2px solid ${condition?.operator === "OR" ? "#FFB600" : "#1989FA"}`,
                  height: "100%",
                  minWidth: "8px",
                  position: "relative",
                  right: "26px",
                }}
              />
            </Grid>
            <Grid item xs={11}>
              {fields.map((field, i) => (
                <Grid key={field.id}>
                  <Grid sx={{ my: 0.5 }} container spacing={1}>
                    <Grid item xs={12}>
                      <ConditionGroup
                        condition={field}
                        parentCondition={fields}
                        index={i}
                        prefix={[...prefix, index, "group"]}
                        onRemove={removeCondition}
                        onRemoveParent={onRemove}
                        parentOp={condition.operator}
                        parentLength={fields.length}
                        parentIndex={index}
                        onEditCondition={handleEditCondition}
                        onUpdateCondition={updateCondition}
                        onUpdateParentCondition={handleUpdate}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
            {showCreateForm && (
              <Grid item xs={12}>
                <CreateConditionForm control={createControl} defaultValue={defaultValue} errors={createErrors} setDefaultValue={setDefaultValue} />
              </Grid>
            )}

            {showEditForm && (
              <Grid item xs={12} ref={ref}>
                <EditConditionForm {...{ control, currentIndex, defaultValue, setDefaultValue, groupArrayInputPath, updateCondition }} />
              </Grid>
            )}
            <Grid item xs={12}>
              <Button className="buttonGroupSent" variant="outlined" size="small" aria-haspopup="true" onClick={handleShowCreateForm}>
                <AddIcon />
                ADD CONDITION
              </Button>
              <Button className="buttonGroupSent" variant="outlined" size="small" aria-haspopup="true" onClick={handleAppendGroup}>
                <AddIcon />
                ADD {parentIndex !== -1 && "INNER"} GROUP
              </Button>
              <Button onClick={handleAppendCondition}>Add Condition</Button>
              <Button onClick={edit}>Edit</Button>
            </Grid>
          </Grid>
        </Grid>
      )}
      {condition?.operator === "ROOT" && (
        <>
          {fields.map((field) => (
            <ConditionGroup
              key={field.id}
              condition={field}
              parentCondition={fields}
              index={0}
              prefix={[...prefix, "group"]}
              onRemove={removeCondition}
              onRemoveParent={onRemove}
              parentOp={condition.operator}
              parentLength={fields.length}
              parentIndex={index}
              onEditCondition={handleEditCondition}
              onUpdateCondition={updateCondition}
              onUpdateParentCondition={handleUpdate}
            />
          ))}
          {(fields.length === 0 || (fields.length > 0 && fields[0].operator === "QUEST")) && (
            <Grid container sx={{ marginBottom: 1, marginTop: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
              <Grid item xs={12}>
                {showCreateForm && (
                  <Grid item xs={12}>
                    <CreateConditionForm control={createControl} defaultValue={defaultValue} errors={createErrors} setDefaultValue={setDefaultValue} />
                  </Grid>
                )}

                {showEditForm && (
                  <Grid item xs={12}>
                    <EditConditionForm {...{ control, currentIndex, defaultValue, setDefaultValue, groupArrayInputPath, updateCondition }} />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <Button className="buttonGroupSent" variant="outlined" size="small" aria-haspopup="true" onClick={handleShowCreateForm}>
                    <AddIcon />
                    ADD CONDITION
                  </Button>
                  <Button className="buttonGroupSent" variant="outlined" size="small" aria-haspopup="true" onClick={handleAppendGroup}>
                    <AddIcon />
                    ADD GROUP
                  </Button>
                  <Button onClick={handleAppendCondition}>Add Condition</Button>
                  <Button onClick={edit}>Edit</Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

ConditionGroup.defaultProps = defaultProps;

export default ConditionGroup;
