import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Autocomplete, Checkbox, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DesktopDatePicker, DesktopDateRangePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers-pro";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { AddInterviewTimeSlotForm, Interviewer } from "../../../interfaces/interview";

function AddTimeSlotForm() {
  const methods = useFormContext<AddInterviewTimeSlotForm>();

  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  const [interviewer, setInterviewer] = useState<Interviewer[]>([
    { name: "Yeri Suen", available: true },
    { name: "aaaaa", available: true },
    { name: "bbbbb", available: true },
    { name: "ccccc", available: false },
    { name: "ddddd", available: false },
  ]);

  return (
    <>
      <Grid item xs={12}>
        <Controller
          name={"dateRange" as const}
          control={methods.control}
          rules={{ required: "Interview Title (English) is required" }}
          defaultValue={[null, null]}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterMoment} localeText={{ start: "Interview Date", end: "Interview Date" }}>
              <DesktopDateRangePicker
                {...field}
                open={openDateRangePicker}
                onOpen={() => setOpenDateRangePicker(true)}
                onClose={() => setOpenDateRangePicker(false)}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                renderInput={({ inputProps, ...startProps }, endProps) => {
                  const startValue = inputProps?.value;
                  delete inputProps?.value;
                  return (
                    <TextField
                      {...startProps}
                      fullWidth
                      inputProps={inputProps}
                      // eslint-disable-next-line react/jsx-no-duplicate-props
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton onClick={() => setOpenDateRangePicker((state) => !state)}>
                              <DateRangeIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="mm/dd/yy to mm/dd/yy"
                      value={endProps?.inputProps?.value === "" ? `${startValue}` : `${startValue} to ${endProps?.inputProps?.value}`}
                    />
                  );
                }}
                // renderInput={(startProps, endProps) => (
                //     <>
                //         <TextField {...startProps} />
                //         <Box sx={{ mx: 2 }}> to </Box>
                //         <TextField {...endProps} />
                //     </>
                // )}
              />
              {/* {methods.getValues().dateRange && (
                                <DesktopDatePicker
                                    {...field}
                                    value={field.value[0]}
                                    onChange={(newValue) => {
                                        field.onChange([newValue, newValue]);
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            )} */}
            </LocalizationProvider>
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name={"time.start" as const}
              control={methods.control}
              rules={{ required: "Interview Title (Traditional Chinese) is required" }}
              defaultValue=""
              render={({ field }) => (
                // <TextField
                //     {...field}
                //     fullWidth
                //     label="Start Time"
                //     error={methods.formState.errors?.time?.start && true}
                //     helperText={
                //         methods.formState.errors?.time?.start &&
                //         `${methods.formState.errors?.time?.start?.message}`
                //     }
                // />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    // label="Basic example"
                    // value={value}
                    {...field}
                    label="Start Time"
                    // onChange={(newValue) => {
                    //     setValue(newValue);
                    // }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={methods.formState.errors?.time?.start && true}
                        helperText={methods.formState.errors?.time?.start && `${methods.formState.errors?.time?.start?.message}`}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={"time.end" as const}
              control={methods.control}
              rules={{ required: "End Time is required" }}
              defaultValue=""
              render={({ field }) => (
                // <TextField
                //     {...field}
                //     fullWidth
                //     label="End Time"
                //     error={methods.formState.errors?.time?.end && true}
                //     helperText={
                //         methods.formState.errors?.time?.end && `${methods.formState.errors?.time?.end?.message}`
                //     }
                // />
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <TimePicker
                    // label="Basic example"
                    // value={value}
                    {...field}
                    label="End Time"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={methods.formState.errors?.time?.end && true}
                        helperText={methods.formState.errors?.time?.end && `${methods.formState.errors?.time?.end?.message}`}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={"duration" as const}
          control={methods.control}
          rules={{ required: "Interview Duration is required" }}
          render={({ field }) => (
            <TextField
              {...field}
              fullWidth
              type="number"
              label="Interview Duration"
              error={methods.formState.errors?.duration && true}
              helperText={methods.formState.errors?.duration && `${methods.formState.errors?.duration?.message}`}
              onChange={(event) => {
                if (parseInt(event.target.value) >= 0 || event.target.value === "") {
                  field.onChange(event.target.value);
                }
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Controller
              name={"quota" as const}
              control={methods.control}
              rules={{ required: "Quota is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  label="Quota"
                  onChange={(event) => {
                    if (parseInt(event.target.value) >= 0 || event.target.value === "") {
                      field.onChange(event.target.value);
                    }
                  }}
                  error={methods.formState.errors?.quota && true}
                  helperText={methods.formState.errors?.quota && `${methods.formState.errors?.quota?.message}`}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <InputLabel id="select-centre-label">Course Centre</InputLabel>
              <Controller
                name={"centre" as const}
                control={methods.control}
                rules={{ required: "Course Centre is required" }}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="select-centre-label"
                    label="Course Centre"
                    fullWidth
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    <MenuItem value="blue">Blue</MenuItem>
                    <MenuItem value="red">Orange</MenuItem>
                    <MenuItem value="orange">Red</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={"interviewer" as const}
          control={methods.control}
          rules={{ required: "Interviewer is required" }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={interviewer}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              getOptionDisabled={(option) => !option.available}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={option.available ? <CheckBoxOutlineBlankIcon /> : <SquareRoundedIcon />}
                    checkedIcon={<CheckBoxIcon color="primary" />}
                    style={{ marginRight: 8 }}
                    checked={option.available && selected}
                    disabled={option.available}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Interviewer" />}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip sx={{ background: "#E6F1FC" }} color="primary" variant="outlined" label={option.name} {...getTagProps({ index })} />
                ))
              }
              onChange={(event, data) => field.onChange(data)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={"location" as const}
          control={methods.control}
          rules={{ required: "Location is required" }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              freeSolo
              options={[]}
              // disableCloseOnSelect
              // getOptionLabel={(option) => option}
              // renderOption={(props, option, { selected }) => (
              //     <li {...props}>
              //         <Checkbox
              //             icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              //             checkedIcon={<CheckBoxIcon fontSize="small" />}
              //             style={{ marginRight: 8 }}
              //             checked={selected}
              //         />
              //         {option.name}
              //     </li>
              // )}
              onChange={(event, data) => field.onChange(data)}
              renderInput={(params) => <TextField {...params} label="Location" />}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip sx={{ background: "#E6F1FC" }} color="primary" variant="outlined" label={option} {...getTagProps({ index })} />
                ))
              }
            />
          )}
        />
      </Grid>
    </>
  );
}

export default AddTimeSlotForm;
