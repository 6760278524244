import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Box, IconButton, Typography } from "@mui/material";
import { Moment } from "moment";
import React, { useState } from "react";

interface SliceCalendarProps {
  initaialDate: Moment;
  targetDateList: Moment[];
  onChange: (date: Moment) => void;
}

const generateDateList = (date: Moment) =>
  Array(7)
    .fill(date.clone().subtract(date.weekday() - 1, "days"))
    .map((elem, index) => elem.clone().add(index, "days"));

function SliceCalendar(props: SliceCalendarProps) {
  const { initaialDate, targetDateList, onChange } = props;

  const [dateList, setDateList] = useState<Moment[]>(generateDateList(initaialDate));
  // const targetDateList = [moment("2022-09-19"), moment("2022-09-20")];
  const [selectedDate, setSelectedDate] = useState<Moment>(initaialDate);

  const handlePrevious = () => {
    setDateList(dateList.map((date) => date.clone().subtract(7, "days")));
  };

  const handleAfter = () => {
    setDateList(dateList.map((date) => date.clone().add(7, "days")));
  };

  const handleSelect = (date: Moment) => {
    setSelectedDate(date);
    onChange(date);
  };

  const translateWeek = (index: number) => {
    switch (index) {
      case 0:
        return "MON";
      case 1:
        return "TUE";
      case 2:
        return "WED";
      case 3:
        return "THU";
      case 4:
        return "FRI";
      case 5:
        return "SAT";
      default:
        return "SUN";
    }
  };

  return (
    <Box sx={{ p: 1 }}>
      <Box sx={{ py: 1, fontWeight: 600, fontSize: "16px", lineHeight: "125%", letterSpacing: "0.15px" }}>{dateList[0].format("MMMM YYYY").toUpperCase()}</Box>
      <Box display="flex" justifyContent="space-evenly" sx={{ width: 1 }}>
        <IconButton onClick={handlePrevious}>
          <KeyboardArrowLeftIcon />
        </IconButton>
        {dateList.map((date, index) => (
          <Box key={date.format("YYYY-MM-DD")}>
            <Typography
              display="flex"
              justifyContent="center"
              color={!targetDateList.map((targetDate) => targetDate.format("YYYY-MM-DD")).includes(date.format("YYYY-MM-DD")) ? "#9A9DA4" : "#606266"}
              sx={{ fontSize: "12px" }}
            >
              {translateWeek(index)}
            </Typography>
            <IconButton
              size="small"
              onClick={() => handleSelect(date)}
              disabled={date.isSame(selectedDate) || !targetDateList.map((targetDate) => targetDate.format("YYYY-MM-DD")).includes(date.format("YYYY-MM-DD"))}
              style={selectedDate && selectedDate.isSame(date) ? { background: "#1989FA", color: "#FFFFFF" } : {}}
              sx={{ fontSize: "16px" }}
            >
              {date.format("DD")}
            </IconButton>
          </Box>
        ))}
        <IconButton onClick={handleAfter}>
          <KeyboardArrowRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
}

export default SliceCalendar;
