import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router";
import { push } from "redux-first-history";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Button, Grid, Link, Tab, Tabs, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridRenderCellParams } from "@mui/x-data-grid";
import { deleteMessageTemplate, getMessageTemplate } from "../../../api/messageTemplate";
import { getApplicationListById } from "../../../api/applicationList";
import { ApplicationList } from "../../../interfaces/applicationList";
import { useAppDispatch } from "../../../redux/hooks";

interface SelectedData {
  id: number;
  name: string;
  createDate: string;
  updatedDate: string;
}

interface MessageTemplateData {
  id: number;
  schoolCode: string;
  name: string;
  json: { body: string };
  fields: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

function MessageTemplateList() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const [value, setValue] = React.useState("one");
  const [pageSize, setPageSize] = useState<number>(10);
  const [rows, setRows] = useState<SelectedData[]>([]);
  const [data, setData] = useState<MessageTemplateData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const messageTemplate = t("sendMessage.messageTemplate");
  const templateTitle = t("sendMessage.templateTitle");
  const createDate = t("sendMessage.createDate");
  const updatedDate = t("sendMessage.updatedDate");
  const action = t("sendMessage.action");
  const edit = t("sendMessage.edit");
  const deleteButton = t("sendMessage.delete");
  const search = t("sendMessage.search");
  const createTemplate = t("sendMessage.createTemplate");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const [listData, setListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setListData(result.data);
    }
  }

  async function fetchGetMessageTemplate() {
    const result = await getMessageTemplate();
    if (result.success) {
      setData(result.data);
    }
  }

  async function fetchDeleteMessageTemplate(id: number) {
    const result = await deleteMessageTemplate(id);
    if (result.success) {
      await fetchGetMessageTemplate();
    }
  }

  useEffect(() => {
    const fetching = async () => {
      if (params && params.applicationListId) {
        const listId = parseInt(params.applicationListId);
        await fetchApplicationList(listId);
      }
      await fetchGetMessageTemplate();
      setIsLoading(false);
    };

    fetching();
  }, [params]);

  useEffect(() => {
    if (data.length !== 0) {
      const selectedData: SelectedData[] = [];
      for (const field of data) {
        selectedData.push({
          id: field.id,
          name: field.name,
          createDate: moment(field.createdAt).format("YYYY-MM-DD HH:mm:ss"),
          updatedDate: moment(field.updatedAt).format("YYYY-MM-DD HH:mm:ss"),
        });
      }
      setRows(selectedData);
    } else {
      setRows([]);
    }
  }, [data]);

  function getFullName(gridParams: GridRenderCellParams) {
    return (
      <div>
        <Button
          variant="contained"
          size="small"
          className="buttonAction buttonActionBlue"
          onClick={async () => {
            navigate(`${gridParams.row.id}/edit`);
          }}
        >
          {edit}
        </Button>
        <Button
          variant="contained"
          size="small"
          className="buttonAction buttonActionRed"
          onClick={async () => {
            await fetchDeleteMessageTemplate(gridParams.row.id);
          }}
        >
          {deleteButton}
        </Button>
      </div>
    );
  }

  const columns: GridColDef[] = [
    { field: "name", headerName: templateTitle, width: 300 },
    { field: "createDate", headerName: createDate, width: 200 },
    { field: "updatedDate", headerName: updatedDate, width: 200 },
    {
      field: "actions",
      headerName: action,
      minWidth: 200,
      renderCell: getFullName,
    },
  ];

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId) {
      const listId = parseInt(params.applicationListId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/summary`));
    }
  }

  function handleClick3(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}`));
    }
  }

  function handleClick4(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message`));
    }
  }

  // function handleClick5(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  //     event.preventDefault();
  //     if (params && params.applicationListId && params.formId) {
  //         const listId = parseInt(params.applicationListId);
  //         const formId = parseInt(params.formId);
  //         dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message/create`));
  //     }
  // }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHK" ? listData.titleZhHk : i18n.resolvedLanguage === "enUS" ? listData.titleEnUs : null}
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick3}>
      Application
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick4}>
      Send Message
    </Link>,
    // <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick5}>
    //     Create Message
    // </Link>,
    <Typography key="3" color="primary.main">
      Message Template
    </Typography>,
  ];

  return (
    <Grid sx={{ m: 2 }}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <Grid sx={{ my: 1, background: "white" }}>
            <Grid container sx={{ pt: 0.5, pb: 2, px: 2 }} className="outterContainer">
              <Grid item xs={12}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs value={value} onChange={handleChange} aria-label="wrapped label tabs example">
                    <Tab value="one" label={messageTemplate} wrapped />
                  </Tabs>
                </Box>
              </Grid>

              <Grid item xs={6} display="flex" alignItems="center">
                <div>
                  {search} <input type="text" className="searchInput"></input>
                </div>
              </Grid>

              <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center" className="buttonGroups">
                <Button
                  className="buttonGroupSent"
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    navigate("create");
                  }}
                >
                  {createTemplate}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <div style={{ width: "100%" }}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    pageSize={pageSize}
                    rowsPerPageOptions={[10, 20, 30]}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    disableSelectionOnClick
                    autoHeight
                    disableColumnMenu
                  />
                </div>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default MessageTemplateList;
