import produce from "immer";
import * as locales from "@mui/material/locale";
import { LocalizationActions } from "./actions";

type SupportedLocales = keyof typeof locales;

export interface LocalizationState {
  lng: SupportedLocales;
}

const initialState: LocalizationState = {
  lng: (localStorage.getItem("i18nextLng") as SupportedLocales) || "zhHK",
};

// eslint-disable-next-line default-param-last
export function localizationReducer(state: LocalizationState = initialState, action: LocalizationActions): LocalizationState {
  switch (action.type) {
    case "@@localization/CHANGE_LANGUAGE":
      return produce(state, (nextState) => {
        nextState.lng = action.lng;
      });

    default:
      return state;
  }
}
