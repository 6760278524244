import DateRangeIcon from "@mui/icons-material/DateRange";
import FilterListIcon from "@mui/icons-material/FilterList";
import { Box, Button, Collapse, IconButton, InputAdornment, MenuItem, Popover, Select, Slider, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { DateRange, LocalizationProvider, DesktopDateRangePicker, DesktopDateTimePicker } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";
import React, { MouseEvent, useState } from "react";
import { GridFilterModel } from "@mui/x-data-grid";
import { InterviewTimeSlot } from "../../../interfaces/interview";
import TimeSlotFilterCollapse from "./TimeSlotFilterCollapse";

interface TimeSlotFilterMenuProps {
  timeSlots: InterviewTimeSlot[];
  onShowResult: (model: GridFilterModel) => void;
}

function TimeSlotFilterMenu(props: TimeSlotFilterMenuProps) {
  const { timeSlots, onShowResult } = props;
  const filters = [{ type: "DATE_RANGE", key: "datetime", title: "Interview Date & Time" }];

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dateRange, setDateRange] = useState<DateRange<Moment>>([null, null]);
  const [quotaRange, setQuotaRange] = useState<number[]>([0, 0]);
  const [openDateRangePicker, setOpenDateRangePicker] = useState(false);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleDateRange = (newValue: DateRange<Moment>) => {
    setDateRange(newValue);
  };

  const handleChangeQuota = (event: Event, newValue: number | number[]) => {
    setQuotaRange(newValue as number[]);
  };

  const handleShowResult = () => {
    const model: GridFilterModel = { items: [] };
    if (dateRange[0] && dateRange[1]) {
      model.items.push({ id: 0, columnField: "date", operatorValue: "range", value: dateRange });
    }
    if (quotaRange[0] !== 0 || quotaRange[1] !== 0) {
      model.items.push({ id: 1, columnField: "quota", operatorValue: "range", value: quotaRange });
    }
    onShowResult(model);
  };

  const handleClear = () => {
    setDateRange([null, null]);
    setQuotaRange([0, 0]);
    onShowResult({ items: [] });
  };

  return (
    <>
      <Button className="buttonGroupSent" variant="outlined" size="small" onClick={handleOpen}>
        <FilterListIcon />
      </Button>
      <Popover
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Box sx={{ p: 2.5, width: 460, height: 309 }}>
          <Grid container>
            <Grid xs={12}>
              <Typography sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px" }}>Filter</Typography>
            </Grid>
            <Grid xs={12}>
              <Grid container spacing={1}>
                <Grid xs={5} sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px", color: "#606266" }} display="flex" alignItems="center">
                  Interview Date & Time
                </Grid>
                <Grid xs={6} display="flex" alignItems="center" sx={{ color: "#1989FA" }}>
                  {dateRange[0] && dateRange[1] && `${dateRange[0].format("YYYY-MM-DD")} - ${dateRange[1].format("YYYY-MM-DD")}`}
                </Grid>
                <TimeSlotFilterCollapse>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    // localeText={{ start: "Start Date", end: "End Date" }}
                  >
                    <Box display="inline-flex">
                      <DesktopDateTimePicker
                        renderInput={(renderProps) => <TextField {...renderProps} />}
                        label="Start Date & Time"
                        value={dateRange[0]}
                        onChange={(value) => setDateRange([value, dateRange[1]])}
                      />
                      <Box display="flex" alignItems="center" justifyContent="center" sx={{ mx: 2 }}>
                        -
                      </Box>
                      <DesktopDateTimePicker
                        renderInput={(renderProps) => <TextField {...renderProps} />}
                        label="End Date & Time"
                        value={dateRange[1]}
                        onChange={(value) => setDateRange([dateRange[0], value])}
                      />
                    </Box>
                  </LocalizationProvider>
                </TimeSlotFilterCollapse>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container spacing={1}>
                <Grid xs={5} sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px", color: "#606266" }} display="flex" alignItems="center">
                  Course Centre & Room
                </Grid>
                <Grid xs={6} display="flex" alignItems="center"></Grid>
                <TimeSlotFilterCollapse>
                  <Select fullWidth>
                    <MenuItem>test</MenuItem>
                  </Select>
                </TimeSlotFilterCollapse>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container spacing={1}>
                <Grid xs={5} sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px", color: "#606266" }} display="flex" alignItems="center">
                  Quota
                </Grid>
                <Grid xs={6} display="flex" alignItems="center" sx={{ color: "#1989FA" }}>
                  {quotaRange[0] !== 0 && quotaRange[1] !== 0 && `${quotaRange[0]} - ${quotaRange[1]}`}
                </Grid>
                <TimeSlotFilterCollapse>
                  <Slider
                    value={quotaRange}
                    onChange={handleChangeQuota}
                    valueLabelDisplay="auto"
                    step={1}
                    marks
                    min={Math.min(...timeSlots.map((timeSlot) => timeSlot.quota))}
                    max={Math.max(...timeSlots.map((timeSlot) => timeSlot.quota))}
                  />
                </TimeSlotFilterCollapse>
              </Grid>
            </Grid>
            <Grid xs={12}>
              <Grid container spacing={1}>
                <Grid xs={5} sx={{ fontWeight: 400, fontSize: "16px", lineHeight: "150%", letterSpacing: "0.15px", color: "#606266" }} display="flex" alignItems="center">
                  Interviewer
                </Grid>
                <Grid xs={6} display="flex" alignItems="center"></Grid>
                <TimeSlotFilterCollapse>
                  <Select fullWidth>
                    <MenuItem>test</MenuItem>
                  </Select>
                </TimeSlotFilterCollapse>
              </Grid>
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={handleClear}>CLEAR</Button>
            <Button onClick={handleShowResult}>SHOW RESULT</Button>
          </Box>
        </Box>
      </Popover>
    </>
  );
}

export default TimeSlotFilterMenu;
