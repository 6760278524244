import React, { useEffect } from "react";

function RedirectPageForVpp() {
  useEffect(() => {
    window.open("https://application-o.victoriaplaypark.com/applicant/apply/playpark/query.html");
  }, []);
  return <div>Redirecting...</div>;
}

export default RedirectPageForVpp;
