import "./ViewApplicationFormDashboard.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { push } from "redux-first-history";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Alert,
  AlertTitle,
  Box,
  Breadcrumbs,
  Button,
  Grid,
  Link,
  Menu,
  MenuItem,
  Modal,
  Snackbar,
  Theme,
  TextField,
  Typography,
  useMediaQuery,
  FormHelperText,
  FormControl,
  styled,
  alpha,
  MenuProps,
  TextFieldProps,
  Select,
} from "@mui/material";
import { DataGrid, GridColDef, GridFilterInputValueProps, GridFilterOperator, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { DateRange } from "@mui/x-date-pickers-pro/DateRangePicker";
import { MuiTextFieldProps } from "@mui/x-date-pickers/internals";
import { getApplicationListById } from "../../../api/applicationList";
import { findApplicationStageById, exportApplicationForm } from "../../../api/applicationStage";
import { findSubmissionsAndUserByApplicationStageId, generateSubmissionExcel, updateSubmissionStatusBySubmissionId, updateRemarksBySubmissionId } from "../../../api/submission";
import { ApplicationList } from "../../../interfaces/applicationList";
import { ApplicationStage } from "../../../interfaces/applicationStage";
import { SubmissionOuterJoinUser } from "../../../interfaces/submission";
import { ApplicantStatusEnum } from "../../../models/applicantStatusEnum";
import { StateForDataGrid } from "../../utils/CustomStyle";
import EditApplicantsButton from "./EditApplicantsButton";
import UpdateApplicantStatusButton from "./UpdateApplicantStatusButton";
import { ApplicationStageFormStatus } from "../../../models/applicationStageFormStatus";
import { LanguageEnum } from "../../../models/languageEnum";
import { useAppDispatch } from "../../../redux/hooks";
import EnrolToCmsButton from "./EnrolToCmsButton";
import { RenderCellExpand } from "./RenderCellExpand";

export interface SelectedData {
  id: number;
  submissionId: string;
  userId: number;
  studentName: string;
  email: string;
  formStatus: string;
  point: number;
  applicationStatus: string;
  lastUpdate: string;
  remarks: string;
  enrolledCms: boolean;
}

export interface ExportExcelFormInput {
  dateRange: DateRange<Date>;
}

export interface SelectedRowObj {
  submissionIds: number[];
  applicationStatus: ApplicantStatusEnum;
}

export interface UpdateRemarkObj {
  submissionId: number;
  remarks: string;
}

const modalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 624,
  bgcolor: "background.paper",
  p: 3,
  borderRadius: 0.5,
};

function StatusFilterInput(props: GridFilterInputValueProps) {
  const { item, applyValue } = props;
  const { t } = useTranslation();
  const handleFilterChange = (e: any) => {
    applyValue({ ...item, value: e.target.value });
  };
  return (
    <Select value={item?.value || ""} label="Status" onChange={handleFilterChange} style={{ marginTop: 16 }}>
      <MenuItem value={ApplicantStatusEnum.PENDING}>{t("view.pending")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.REJECTED_BY_HT}>{t("view.rejectedByHT")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.APPROVED_BY_HT}>{t("view.approvedByHT")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.OFFERED}>{t("view.offered")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.DECLINED}>{t("view.declined")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.ACCEPTED}>{t("view.accepted")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.PAID}>{t("view.paid")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.PAYMENT_OVERDUE}>{t("view.paymentOverdue")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.REMAIN_WAITING}>{t("view.remainWaiting")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.NO_REPLY}>{t("view.noReply")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.WITHDRAWN}>{t("view.withdrawn")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.OVERAGE}>{t("view.overage")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.DUPLICATED}>{t("view.duplicated")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.TEST}>{t("view.test")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.REGISTER}>{t("view.register")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.WAITING}>{t("view.waiting")}</MenuItem>
      <MenuItem value={ApplicantStatusEnum.REJECT}>{t("view.reject")}</MenuItem>
    </Select>
  );
}

function ViewApplicationFormDashboard() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const param = useParams();
  const matchesXs = useMediaQuery((theme: Theme) => theme.breakpoints.up("xs"));
  const matchesMd = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"));
  const lastSchool = sessionStorage.getItem("lastSchool");
  const applicantId = t("view.applicantId");
  const studentName = t("view.studentName");
  const contactEmail = t("view.contactEmail");
  const contactPhone = t("view.contactPhone");
  const formStatus = t("view.formStatus");
  const applicantStatus = t("view.applicantStatus");
  const enrolledCMS = t("view.enrollmentStatus");
  const submissionTime = t("view.submissionTime");
  const lastUpdate = t("view.lastUpdate");
  const remark = t("view.remarks");
  const action = t("view.action");
  // const point = t("view.point");
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [applicationListData, setApplicationListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setApplicationListData(result.data);
    }
  }

  const [applicationFormData, setApplicationFormData] = useState<ApplicationStage>({
    id: -1,
    applicationListId: -1,
    stage: "",
    titleZhHk: "",
    titleEnUs: "",
    isPublished: false,
    admissionLimit: -1,
    deadline: "",
    fieldContent: [],
    createdAt: "",
    updatedAt: "",
  });

  async function fetchApplicationStageById(listId: number, stageId: number) {
    const result = await findApplicationStageById(listId, stageId);
    if (result.success) {
      setApplicationFormData(result.data);
    }
  }

  const [applicantsData, setApplicantsData] = useState<SubmissionOuterJoinUser[]>([]);
  async function fetchSubmissionsAndUserByApplicationStageId(listId: number, stageId: number) {
    const result = await findSubmissionsAndUserByApplicationStageId(listId, stageId);
    setApplicantsData(result.data);
  }

  useEffect(() => {
    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const formId = parseInt(param.formId);
        const applicationListId = parseInt(param.applicationListId);
        await fetchApplicationList(applicationListId);
        await fetchApplicationStageById(applicationListId, formId);
        await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
      }
      setIsLoading(false);
    };
    fetching();
  }, [param]);

  async function handleExportApplicationFormPdf(id: number) {
    if (param?.applicationListId && param?.formId) {
      const listId = parseInt(param.applicationListId);
      const stageId = parseInt(param.formId);
      const selectedIds = [id];
      await exportApplicationForm(listId, stageId, { selectedIds }).then((res) => {
        let extension = "zip";
        if (selectedIds.length === 1) {
          extension = "pdf";
        }
        const { headers, data } = res;
        const fileType = headers["content-type"];
        if (!fileType?.includes("application/json")) {
          const fileName = headers["content-disposition"]?.split("filename=")[1];
          const blob = new Blob([data], { type: fileType });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName || `registration_reply_slip.${extension}`;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
    }
  }
  function onClickView(userId: string) {
    const lastSchoolCode = sessionStorage.getItem("lastSchool");
    const token = sessionStorage.getItem(`token:${lastSchoolCode}`);
    const timestamp = sessionStorage.getItem(`token:${lastSchoolCode}:timestamp`);
    const jwt = sessionStorage.getItem(`token:${lastSchoolCode}:jwt`);
    window.open(`${window.location.href}/applicant/${userId}?token=${token}&timestamp=${timestamp}&jwt=${jwt}`, "_blank");
  }

  function actionBtns(params: GridRenderCellParams) {
    return (
      <div>
        <EditApplicantsButton
          id={params.row.id}
          userId={params.row.userId}
          lastNameEnUs={params.row.lastNameEnUs}
          firstNameEnUs={params.row.firstNameEnUs}
          lastNameZhHk={params.row.lastNameZhHk}
          firstNameZhHk={params.row.firstNameZhHk}
          personalDocumentType={params.row.personalDocumentType}
          personalDocumentId={params.row.personalDocumentId}
          email={params.row.email}
          phoneNumber={params.row.phoneNumber}
          reload={async () => {
            if (param && param.formId && param.applicationListId) {
              const formId = parseInt(param.formId);
              const applicationListId = parseInt(param.applicationListId);
              await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
            }
          }}
        />
        <Button
          variant="contained"
          size="small"
          className="buttonAction buttonActionGreen"
          onClick={async () => {
            onClickView(params.row.userId);
            // navigate(`applicant/${params.row.userId}`);
          }}
        >
          {t("view.view")}
        </Button>
        {param.schoolCode === "vpuk" && (
          <Button variant="contained" size="small" className="buttonAction buttonActionDarkBlue" onClick={() => handleExportApplicationFormPdf(params.row.userId)}>
            PDF
          </Button>
        )}
      </div>
    );
  }

  const [rows, setRows] = useState<SelectedData[]>([]);
  function getStatus(params: GridRenderCellParams) {
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.ACCEPTED) {
      return <div className="statusTextWithBg statusTextWithBgBlue">{t("view.accepted")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.WITHDRAWN) {
      return <div className="statusTextWithBg statusTextWithBgGrey">{t("view.withdrawn")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.PENDING) {
      return <div className="statusTextWithBg statusTextWithBgGrey">{t("view.pending")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.WAITING) {
      return <div className="statusTextWithBg statusTextWithBgOrange">{t("view.waiting")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.REJECT) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.reject")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.DUPLICATED) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.duplicated")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.DECLINED) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.declined")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.APPROVED_BY_HT) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.approvedByHT")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.REJECTED_BY_HT) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.rejectedByHT")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.PAID) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.paid")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.TEST) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.test")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.REGISTER) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.register")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.OFFERED) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.offered")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.NO_REPLY) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.noReply")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.REMAIN_WAITING) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.remainWaiting")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.PAYMENT_OVERDUE) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.paymentOverdue")}</div>;
    }
    if (rows.filter((row) => row.id === params.row.id)[0].applicationStatus === ApplicantStatusEnum.OVERAGE) {
      return <div className="statusTextWithBg statusTextWithBgRed">{t("view.overage")}</div>;
    }
    return <div className="statusTextWithBg statusTextWithBgBlue">{t("view.accepted")}</div>;
  }

  function getEnrolledCmsStatus(params: GridRenderCellParams) {
    if (rows.filter((row) => row.id === params.row.id)[0].enrolledCms) {
      return <div className="statusTextWithBg statusTextWithBgGreen">Enrolled</div>;
    }
    return <div>-</div>;
  }

  const statusOnlyOperators: GridFilterOperator<any, string>[] = [
    {
      label: "Equal",
      value: "equal",
      getApplyFilterFn: (filterItem: any) => {
        if (!filterItem?.columnField || !filterItem.value || !filterItem?.operatorValue) {
          return null;
        }
        return (field) => {
          if ((!field?.value || field?.value === "ACCEPT") && filterItem.value === ApplicantStatusEnum.ACCEPTED) {
            return true;
          }
          return field.value === filterItem.value;
        };
      },
      InputComponent: StatusFilterInput,
    },
  ];

  const columns: GridColDef[] = [
    { field: "submissionId", headerName: applicantId, width: 150 },
    { field: "studentName", headerName: studentName, width: 200 },
    { field: "email", headerName: contactEmail, width: 200 },
    { field: "formStatus", headerName: formStatus, width: 100 },
    { field: "phoneNumber", headerName: contactPhone, width: 200 },
    {
      field: "applicationStatus",
      headerName: applicantStatus,
      width: 150,
      renderCell: getStatus,
      filterOperators: statusOnlyOperators,
    },
    { field: "enrolledCms", headerName: enrolledCMS, width: 150, renderCell: getEnrolledCmsStatus },
    { field: "submissionTime", headerName: submissionTime, width: 200 },
    { field: "lastUpdate", headerName: lastUpdate, width: 200 },
    ...(lastSchool === "vpuk" ? [{ field: "remarks", headerName: remark, editable: true, width: 300, renderCell: RenderCellExpand }] : []),
    {
      field: "actions",
      headerName: action,
      minWidth: 260,
      renderCell: actionBtns,
    },
  ];

  useEffect(() => {
    if (applicantsData.length !== 0) {
      const selectedData = [];
      for (const applicantData of applicantsData) {
        const { id, submissionId, userId, applicationStatus, user, submittedAt, remarks, updatedAt } = applicantData;
        const { email, lastNameEnUs, firstNameEnUs, lastNameZhHk, firstNameZhHk, personalDocumentId, personalDocumentType, phoneNumber, countryCode, enrolledCms } = user;
        selectedData.push({
          id,
          submissionId,
          userId,
          studentName:
            i18n.resolvedLanguage === LanguageEnum.ZH_HK
              ? `${lastNameZhHk || firstNameEnUs} ${firstNameZhHk || lastNameEnUs}`
              : i18n.resolvedLanguage === LanguageEnum.EN_US
              ? `${firstNameEnUs} ${lastNameEnUs}`
              : `${firstNameEnUs} ${lastNameEnUs}`,
          email,
          phoneNumber: `${countryCode} ${phoneNumber}`,
          formStatus: applicantData.formStatus === ApplicationStageFormStatus.INCOMPLETE ? t("view.incompleteApplication") : t("view.completed"),
          point: 0,
          applicationStatus,
          submissionTime: moment(submittedAt).format("YYYY-MM-DD HH:mm:ss"),
          lastUpdate: moment(updatedAt).format("YYYY-MM-DD HH:mm:ss"),
          lastNameEnUs,
          firstNameEnUs,
          lastNameZhHk,
          firstNameZhHk,
          personalDocumentId,
          personalDocumentType,
          enrolledCms,
          remarks,
        });
      }
      setRows(selectedData);
    } else {
      setRows([]);
    }
  }, [applicantsData, i18n.resolvedLanguage]);

  const [selectedRows, setSelectedRows] = useState<GridRowModel[]>([]);

  const [pageSize, setPageSize] = useState<number>(10);

  const [state, setState] = useState<StateForDataGrid>({
    openSnackbar: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openSnackbar } = state;

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ openSnackbar: false, vertical: "top", horizontal: "right" });
  };

  async function onBatchDownloadPdf() {
    if (param?.applicationListId && param?.formId) {
      const listId = parseInt(param.applicationListId);
      const stageId = parseInt(param.formId);
      const selectedIds = selectedRows.map((row) => row.userId);

      await exportApplicationForm(listId, stageId, { selectedIds }).then((res) => {
        let extension = "zip";
        if (selectedIds.length === 1) {
          extension = "pdf";
        }
        const { headers, data } = res;
        const fileType = headers["content-type"];
        if (!fileType?.includes("application/json")) {
          const fileName = headers["content-disposition"]?.split("filename=")[1];
          const blob = new Blob([data], { type: fileType });
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.href = url;
          a.download = fileName || `application_forms.${extension}`;
          a.click();
          window.URL.revokeObjectURL(url);
        }
      });
    }
  }

  const [anchorMenuEl, setAnchorMenuEl] = React.useState<null | HTMLElement>(null);
  const menuOpen = Boolean(anchorMenuEl);
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorMenuEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorMenuEl(null);
  };

  function onApprovedByHT() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.APPROVED_BY_HT,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onRejectedByHT() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.REJECTED_BY_HT,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onPaid() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.PAID,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onTest() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.TEST,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onDuplicated() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.DUPLICATED,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onDeclined() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.DECLINED,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onRegister() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.REGISTER,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onOffered() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.OFFERED,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onNoReply() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.NO_REPLY,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onRemainWaiting() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.REMAIN_WAITING,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onPaymentOverdue() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.PAYMENT_OVERDUE,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onOverage() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.OVERAGE,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onAccepted() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj: SelectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.ACCEPTED,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onWaiting() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.WAITING,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onReject() {
    const submissionIds: number[] = [];
    for (const selecedRow of selectedRows) {
      submissionIds.push(selecedRow.id);
    }

    const selectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.REJECT,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onPending() {
    const submissionIds: number[] = [];
    for (const selectedRow of selectedRows) {
      submissionIds.push(selectedRow.id);
    }

    const selectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.PENDING,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  function onWithdrawn() {
    const submissionIds: number[] = [];
    for (const selectedRow of selectedRows) {
      submissionIds.push(selectedRow.id);
    }

    const selectedRowObj = {
      submissionIds,
      applicationStatus: ApplicantStatusEnum.WITHDRAWN,
    };

    const fetching = async () => {
      if (param && param.formId && param.applicationListId) {
        const applicationListId = parseInt(param.applicationListId);
        const formId = parseInt(param.formId);
        const result = await updateSubmissionStatusBySubmissionId(applicationListId, formId, selectedRowObj);
        if (result.success) {
          setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
          await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
          await fetchApplicationStageById(applicationListId, formId);
        }
      }
    };
    fetching();
    setAnchorMenuEl(null);
  }

  async function onUpdateRemarks(updatedRow: any, originalRow: any) {
    if (updatedRow.remarks === originalRow.remarks) {
      return updatedRow;
    }

    const { id: submissionId, remarks } = updatedRow;
    const updateRemarksObj: UpdateRemarkObj = {
      submissionId,
      remarks,
    };

    if (param && param.formId && param.applicationListId) {
      const applicationListId = parseInt(param.applicationListId);
      const formId = parseInt(param.formId);
      const result = await updateRemarksBySubmissionId(applicationListId, formId, updateRemarksObj);

      if (result.success) {
        setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
        return updatedRow;
      }
    }
    return originalRow;
  }

  const handleRefresh = async () => {
    if (param && param.formId && param.applicationListId) {
      const applicationListId = parseInt(param.applicationListId);
      const formId = parseInt(param.formId);
      await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
      await fetchApplicationStageById(applicationListId, formId);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (param && param.applicationListId) {
      const listId = parseInt(param.applicationListId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/summary`));
    }
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHK" ? applicationListData.titleZhHk : i18n.resolvedLanguage === "enUS" ? applicationListData.titleEnUs : null}
    </Link>,
    <Typography key="3" color="primary.main">
      {t("view.application")}
    </Typography>,
  ];

  const [openModal, setOpenModal] = useState<boolean>(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = async () => {
    setOpenModal(false);
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ExportExcelFormInput>();
  const onSubmit: SubmitHandler<ExportExcelFormInput> = async (data) => {
    const { dateRange } = data;

    if (param && param.formId && param.applicationListId) {
      const formId = parseInt(param.formId);
      const applicationListId = parseInt(param.applicationListId);

      const unixFromDate = moment(dateRange[0]).toDate().getTime();
      const unixToDate = moment(dateRange[1]).add(1, "days").toDate().getTime();
      generateSubmissionExcel(applicationListId, formId, unixFromDate, unixToDate);
      handleCloseModal();
      handleClose();
    }
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color: theme.palette.mode === "light" ? "#0000000" : theme.palette.grey[300],
      boxShadow: "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        // padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        },
      },
    },
  }));

  return (
    <Grid sx={{ m: 2 }} height="100%">
      {isLoading && <div>Loading...</div>}
      {!isLoading && applicationFormData === null && <div>Appication Stage Not Found</div>}
      {!isLoading && applicationFormData !== null && (
        <>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <Grid sx={{ my: 1, background: "white" }}>
            <Grid container sx={{ p: 2 }} className="outterContainer">
              <Grid container spacing={2}>
                <Grid item xs={11}>
                  <div>
                    <div className="pageTitle">
                      {i18n.resolvedLanguage === LanguageEnum.ZH_HK
                        ? applicationListData.titleZhHk
                        : i18n.resolvedLanguage === LanguageEnum.EN_US
                        ? applicationListData.titleEnUs
                        : null}
                    </div>
                    <div className={`statusTextWithBg ${applicationFormData.isPublished ? "statusTextWithBgGreen" : "statusTextWithBgRed"}`}>
                      {applicationFormData.isPublished ? t("view.publishing") : t("view.unpublished")}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={1} display="flex" alignItems="center" justifyContent="flex-end">
                  <Button
                    className="buttonAction buttonActionBlue"
                    variant="contained"
                    size="small"
                    onClick={() => {
                      dispatch(push(`/cms/school/${lastSchool}/application_list/${applicationListData.id}/edit_application/${applicationFormData.id}`));
                    }}
                  >
                    {t("view.edit")}
                  </Button>
                </Grid>

                <Grid item xs={12} sm={4} lg={2}>
                  <div className="viewApplicationFormDataGrid_InfoBox">
                    {t("view.applicantLimit")} <div>{applicationFormData.admissionLimit}</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                  <div className="viewApplicationFormDataGrid_InfoBox">
                    {t("view.availableDate")}
                    <div>
                      {moment(applicationFormData.createdAt).format("YYYY/MM/DD")} - {moment(applicationFormData.deadline).format("YYYY/MM/DD")}
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                  <div className="viewApplicationFormDataGrid_InfoBox">
                    {t("view.acceptedApplicant")}
                    <div>{applicantsData.filter((applicant) => applicant.applicationStatus === ApplicantStatusEnum.ACCEPTED).length}</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                  <div className="viewApplicationFormDataGrid_InfoBox">
                    {t("view.waitingList")}
                    <div>{applicantsData.filter((applicant) => applicant.applicationStatus === ApplicantStatusEnum.WAITING).length}</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                  <div className="viewApplicationFormDataGrid_InfoBox">
                    {t("view.rejectApplicant")}
                    <div>{applicantsData.filter((applicant) => applicant.applicationStatus === ApplicantStatusEnum.REJECT).length}</div>
                  </div>
                </Grid>
                <Grid item xs={12} sm={4} lg={2}>
                  <div className="viewApplicationFormDataGrid_InfoBox">
                    {t("view.pendingApplicant")}
                    <div>{applicantsData.filter((applicant) => applicant.applicationStatus === ApplicantStatusEnum.PENDING).length}</div>
                  </div>
                </Grid>

                <Grid item xs={12} md={3} display="flex" justifyContent={matchesMd ? "flex-start" : matchesXs ? "center" : "flex-start"} alignItems="center">
                  {/* <div>
                                        {t("sendMessage.search")} <input type="text" className="searchInput"></input>
                                    </div> */}
                </Grid>

                {/* {selectedRows.length !== 0 && (
                  <Grid item xs={12} md={9} display="flex" justifyContent={matchesMd ? "flex-end" : matchesXs ? "center" : "flex-end"} alignItems="center" className="buttonGroups">
                    {param.schoolCode === "vpuk" && (
                      <Button className="buttonGroupBatchDownload" variant="contained" size="small" onClick={() => onBatchDownloadPdf()}>
                        {t("view.batchDownload")}
                      </Button>
                    )}
                    <Button className="buttonGroupAccept" variant="contained" size="small" onClick={onAccept}>
                      {t("view.accept")}
                    </Button>
                    <Button className="buttonGroupWaiting" variant="contained" size="small" onClick={onWaiting}>
                      {t("view.waiting")}
                    </Button>
                    <Button className="buttonGroupReject" variant="contained" size="small" onClick={onReject}>
                      {t("view.reject")}
                    </Button>
                    <Button className="buttonGroupPending" variant="contained" size="small" onClick={onPending}>
                      {t("view.pending")}
                    </Button>
                  </Grid>
                )} */}

                {selectedRows.length !== 0 && (
                  <Grid item xs={12} md={9} display="flex" justifyContent={matchesMd ? "flex-end" : matchesXs ? "center" : "flex-end"} alignItems="center">
                    {param.formId && applicationFormData.stage !== "REGISTRATION" && (
                      <EnrolToCmsButton
                        selectedData={selectedRows as SelectedData[]}
                        applicationStageId={+param.formId}
                        onSubmitSuccess={async () => {
                          if (param && param.formId && param.applicationListId) {
                            const formId = parseInt(param.formId);
                            const applicationListId = parseInt(param.applicationListId);
                            await fetchApplicationList(applicationListId);
                            await fetchApplicationStageById(applicationListId, formId);
                            await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
                          }
                        }}
                      />
                    )}
                    {param.schoolCode === "vpuk" && (
                      <Button className="buttonGroupBatchDownload" variant="contained" size="small" onClick={() => onBatchDownloadPdf()}>
                        {t("view.batchDownload")}
                      </Button>
                    )}
                    <Button
                      id="menu-button"
                      className="buttonGroupAccept"
                      variant="contained"
                      size="small"
                      aria-controls={menuOpen ? "status-menu" : undefined}
                      aria-haspopup="true"
                      aria-expanded={menuOpen ? "true" : undefined}
                      onClick={handleMenuClick}
                    >
                      {t("view.batchChangeStatus")}
                    </Button>
                    <StyledMenu
                      id="status-menu"
                      anchorEl={anchorMenuEl}
                      open={menuOpen}
                      onClose={handleMenuClose}
                      MenuListProps={{
                        "aria-labelledby": "menu-button",
                      }}
                    >
                      <MenuItem className="menuButton" onClick={onPending}>
                        {t("view.pending")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onRejectedByHT}>
                        {t("view.rejectedByHT")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onApprovedByHT}>
                        {t("view.approvedByHT")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onOffered}>
                        {t("view.offered")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onDeclined}>
                        {t("view.declined")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onAccepted}>
                        {t("view.accepted")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onPaid}>
                        {t("view.paid")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onPaymentOverdue}>
                        {t("view.paymentOverdue")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onRemainWaiting}>
                        {t("view.remainWaiting")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onNoReply}>
                        {t("view.noReply")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onWithdrawn}>
                        {t("view.withdrawn")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onOverage}>
                        {t("view.overage")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onDuplicated}>
                        {t("view.duplicated")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onTest}>
                        {t("view.test")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onRegister}>
                        {t("view.register")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onWaiting}>
                        {t("view.waiting")}
                      </MenuItem>
                      <MenuItem className="menuButton" onClick={onReject}>
                        {t("view.reject")}
                      </MenuItem>
                    </StyledMenu>
                  </Grid>
                )}

                {selectedRows.length === 0 && (
                  <Grid item xs={12} md={9} display="flex" justifyContent={matchesMd ? "flex-end" : matchesXs ? "center" : "flex-end"} alignItems="center" className="buttonGroups">
                    <Grid container spacing={2} display="flex" justifyContent="center">
                      {matchesMd && <Grid item xs={0} md></Grid>}
                      <Grid item xs={12} md="auto" display="flex" justifyContent={matchesMd ? "flex-end" : matchesXs ? "center" : "flex-end"}>
                        <Button
                          className="buttonGroupSent"
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            navigate("message");
                          }}
                        >
                          {t("view.sendMessage").toUpperCase()}
                        </Button>
                      </Grid>
                      <Grid item xs={12} md="auto" display="flex" justifyContent={matchesMd ? "flex-end" : matchesXs ? "center" : "flex-end"}>
                        <Button
                          className="buttonGroupSent"
                          variant="outlined"
                          size="small"
                          id="exportButton"
                          aria-controls={open ? "status-menu" : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? "true" : undefined}
                          onClick={handleClick}
                        >
                          {t("view.export")}
                        </Button>
                        <Menu
                          id="status-menu"
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            "aria-labelledby": "exportButton",
                          }}
                        >
                          {/* <MenuItem onClick={handleOpenModal}>{t("view.exportToMondayCom")}</MenuItem> */}
                          <MenuItem onClick={handleOpenModal}>{t("view.exportApplicantList")}</MenuItem>
                          {/* <MenuItem onClick={handleClose}>Export Applicant PDF</MenuItem> */}
                        </Menu>
                        <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                          <Box sx={modalStyle}>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <Grid container spacing={2}>
                                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                                  <div>{t("view.exportApplicantList")}</div>
                                  <Button variant="text" onClick={handleCloseModal} className="modalClearBtn">
                                    <ClearIcon />
                                  </Button>
                                </Grid>

                                <Grid item xs={12}>
                                  <FormControl error={errors.dateRange && true} component="fieldset">
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                      <Controller
                                        name={"dateRange" as const}
                                        control={control}
                                        defaultValue={[null, null]}
                                        rules={{
                                          required: t("forms.required"),
                                          validate: (value) => value !== null && moment(value[0]).isValid() && moment(value[1]).isValid(),
                                        }}
                                        render={({ field }) => (
                                          <div className="modalDatePickerContainer">
                                            <div className="datePickerRow">
                                              <DatePicker
                                                className="datePicker"
                                                label={t("view.startDate")}
                                                {...field}
                                                value={field.value[0] ?? null}
                                                onChange={(value) => {
                                                  const newValue = [value, field.value[1]];
                                                  field.onChange(newValue);
                                                }}
                                                renderInput={(props: MuiTextFieldProps) => <TextField {...props} />}
                                              />
                                              <DatePicker
                                                className="datePicker"
                                                label={t("view.endDate")}
                                                {...field}
                                                value={field.value[1] ?? null}
                                                onChange={(value) => {
                                                  const newValue = [field.value[0], value];
                                                  field.onChange(newValue);
                                                }}
                                                renderInput={(props: MuiTextFieldProps) => <TextField {...props} />}
                                              />
                                            </div>
                                            <StaticDateRangePicker
                                              {...field}
                                              displayStaticWrapperAs="desktop"
                                              renderInput={(startProps: MuiTextFieldProps, endProps: MuiTextFieldProps) => (
                                                <>
                                                  <TextField {...startProps} />
                                                  <Box sx={{ mx: 2 }}> to </Box>
                                                  <TextField {...endProps} error={errors.dateRange && true} helperText={errors.dateRange && t("forms.invalidDate")} />
                                                </>
                                              )}
                                            />
                                          </div>
                                        )}
                                      />
                                      <FormHelperText>{errors.dateRange && t("forms.invalidDate")}</FormHelperText>
                                    </LocalizationProvider>
                                  </FormControl>
                                </Grid>

                                <Grid item xs={12} display="flex" justifyContent="flex-end">
                                  <Button variant="text" onClick={handleCloseModal}>
                                    {t("view.cancel")}
                                  </Button>
                                  <Button variant="text" type="submit">
                                    {t("view.export")}
                                  </Button>
                                </Grid>
                              </Grid>
                            </form>
                          </Box>
                        </Modal>
                      </Grid>
                      <Grid item xs={12} md="auto" display="flex" justifyContent={matchesMd ? "flex-end" : matchesXs ? "center" : "flex-end"}>
                        <UpdateApplicantStatusButton onRefresh={handleRefresh} />
                      </Grid>
                    </Grid>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <div style={{ width: "100%" }}>
                    <DataGrid
                      rows={rows}
                      columns={columns}
                      pageSize={pageSize}
                      rowsPerPageOptions={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                      checkboxSelection
                      disableSelectionOnClick
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        const nextSelectedRows = rows.filter((row) => selectedIDs.has(row.id));
                        setSelectedRows(nextSelectedRows);
                      }}
                      processRowUpdate={onUpdateRemarks}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      onProcessRowUpdateError={() => {}}
                      experimentalFeatures={{ newEditingApi: true }}
                      autoHeight
                      // disableColumnMenu
                    />
                    {/* <pre style={{ fontSize: 10 }}>{JSON.stringify(selectedRows, null, 4)}</pre> */}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical, horizontal }}>
        <Alert severity="success">
          <AlertTitle>
            <strong>Success</strong>
          </AlertTitle>
          Applicant status has been updated.
        </Alert>
      </Snackbar>
    </Grid>
  );
}

export default ViewApplicationFormDashboard;
