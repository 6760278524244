import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ClearIcon from "@mui/icons-material/Clear";
import DateRangeIcon from "@mui/icons-material/DateRange";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Autocomplete, Box, Button, Checkbox, Chip, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Modal, Select, TextField } from "@mui/material";
import { DesktopDateRangePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import React, { useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { AddInterviewTimeSlotForm, Interviewer, Location } from "../../../interfaces/interview";
import modalStyle from "../../utils/modalStyle";
import AddTimeSlotForm from "./AddTimeSlotForm";

function AddTimeSlotModal() {
  const { t } = useTranslation();
  const methods = useForm<AddInterviewTimeSlotForm>();

  const [open, setOpen] = useState(false);
  // const [openDateRangePicker, setOpenDateRangePicker] = useState(false);
  // const [interviewer, setInterviewer] = useState<Interviewer[]>([
  //     { name: "Yeri Suen", available: true },
  //     { name: "aaaaa", available: true },
  //     { name: "bbbbb", available: true },
  //     { name: "ccccc", available: false },
  //     { name: "ddddd", available: false },
  // ]);
  // const [location, setLocation] = useState<Location[]>([
  //     { name: "Room 509", capacity: 15, available: "Available" },
  //     { name: "Room 510", capacity: 20, available: "Partially Available" },
  //     { name: "Room 601", capacity: 20, available: "Available" },
  //     { name: "Room 503", capacity: 10, available: "Available" },
  // ]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setOpen(false);
    console.log("time slot form =", methods.getValues());
  };

  return (
    <>
      <Button className="buttonGroupSent" variant="outlined" size="small" onClick={handleOpen}>
        ADD TIME SLOT
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={modalStyle}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                  <div>Add Time Slot</div>
                  <Button variant="text" onClick={handleClose} className="modalClearBtn">
                    <ClearIcon />
                  </Button>
                </Grid>
                <AddTimeSlotForm />
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button variant="text" onClick={handleClose}>
                    {t("view.cancel")}
                  </Button>
                  <Button variant="text" type="submit">
                    ADD
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default AddTimeSlotModal;
