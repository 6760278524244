import { useFieldArray, useFormContext } from "react-hook-form";
import { Condition } from "../../../interfaces/interview";

// interface ConditionFormFieldValues {
//     operator: "QUEST" | "AND" | "OR";
//     question: string;
//     condition: string;
//     answer: string;
//     group?: { operator: "QUEST" | "AND" | "OR"; question: string; condition: string; answer: string; group?: [] }[];
// }

function useConditionFormField(prefix: string) {
  const { control } = useFormContext<Condition>();
  const groupArrayInputPath = `${prefix}.group` as "group";
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: groupArrayInputPath,
  });

  const addCondition = (value: Condition) => {
    append(value);
  };

  const addGroup = () => {
    append({
      operator: "AND",
      question: "",
      condition: "",
      answer: "",
      group: [],
    });
  };

  const removeCondition = (index: number) => {
    remove(index);
  };

  const updateCondition = (index: number, condition: Condition) => {
    update(index, condition);
  };

  return {
    control,
    fields,
    addCondition,
    addGroup,
    removeCondition,
    updateCondition,
    groupArrayInputPath,
  };
}

export default useConditionFormField;
