import React, { SyntheticEvent, useEffect, useState } from "react";
import { DataGrid, GridColDef, GridRenderCellParams, GridRowModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { TabContext, TabList } from "@mui/lab";
import { SelectChangeEvent, Grid, InputLabel, Accordion, AccordionSummary, Typography, AccordionDetails, Box, Tab, Select, MenuItem, Button, Breadcrumbs } from "@mui/material";
import { useParams } from "react-router";
import Parse from "html-react-parser";
import moment from "moment";
import { Link } from "@material-ui/core";
import { push } from "redux-first-history";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { getApplicationListById } from "../../../api/applicationList";
import { ApplicationList } from "../../../interfaces/applicationList";
import { MessageWithMessageAttachmentAndMessageRecipient } from "../../../interfaces/message";
import { getMessageById } from "../../../api/message";
import { LanguageEnum } from "../../../models/languageEnum";
import { useAppDispatch } from "../../../redux/hooks";

interface SelectedData {
  id: number;
  applicantId: number;
  applicantName: string;
  contactEmail: string;
  status: string;
  deliveryDate: string;
}

const title = {
  fontSize: 20,
  color: "#303133",
  fontStyle: "Medium",
  marginBottom: 1,
};

const inViewStyle = {
  fontSize: 14,
  color: "#409EFF",
  bgcolor: "#ECF5FF",
  width: 50,
  paddingLeft: 2,
  paddingRight: 2,
  textAlign: "Center",
  borderRadius: 10,
};

const selectedFilterStyle = {
  width: 150,
  height: 30,
  marginLeft: 1,
};

const divider = {
  color: "E4E7ED",
  border: "1px solid #E4E7ED",
  marginBottom: 1,
};

const attachmentDetailStyle = {
  marginBottom: 1,
  marginTop: 1,
  color: "#646B70",
  fontSize: 16,
};

function MessageDashboard() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const { t, i18n } = useTranslation();
  const applicantId = t("sendMessage.applicantId");
  const applicantName = t("sendMessage.applicantName");
  const contactEmail = t("sendMessage.contactEmail");
  const status = t("sendMessage.status");
  const deliveryDate = t("sendMessage.deliveryDate");
  const announcementOfWaitingList = t("sendMessage.announcementOfWaitingList");
  const sentOn = t("sendMessage.sentOn");
  const at = t("sendMessage.at");
  const messageContent = t("sendMessage.messageContent");
  const attachment = t("sendMessage.attachment");
  const sent = t("sendMessage.sentWaitingList");
  const notSent = t("sendMessage.notSent");
  const allRecipient = t("sendMessage.allRecipient");
  const filterByTranslate = t("sendMessage.filterBy");
  const read = t("sendMessage.read");
  const notRead = t("sendMessage.notRead");
  const download = t("sendMessage.download");
  const inView = t("sendMessage.inView");

  const params = useParams();
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<MessageWithMessageAttachmentAndMessageRecipient>({
    id: -1,
    applicationStageId: -1,
    name: "",
    state: "",
    type: "",
    html: "",
    json: null,
    fields: [],
    successCount: 0,
    failCount: 0,
    scheduledTime: null,
    createdAt: "",
    updatedAt: "",
    messageAttachments: [],
    messageRecipients: [],
  });
  const [selectedRows, setSelectedRows] = useState<GridRowModel[]>([]);
  const [pageSize, setPageSize] = useState<number>(10);
  const [rows, setRows] = useState<SelectedData[]>([]);
  const [whichButtonBeClicked, setButtonStatus] = useState("ALL RECIPIENT");
  const columns: GridColDef[] = [
    { field: "applicantId", headerName: applicantId, width: 300 },
    { field: "applicantName", headerName: applicantName, width: 200 },
    { field: "contactEmail", headerName: contactEmail, width: 200 },
    { field: "status", headerName: status, width: 200, renderCell: whichStatus },
    { field: "deliveryDate", headerName: deliveryDate, width: 200 },
  ];
  const [listData, setListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [filterBy, setFilterBy] = useState("");

  const changeFilterBy = (event: SelectChangeEvent) => {
    setFilterBy(event.target.value);
  };

  useEffect(() => {
    const fetching = async () => {
      if (params && params.applicationListId && params.formId && params.messageId) {
        const applicationListId = parseInt(params.applicationListId);
        const formId = parseInt(params.formId);
        const messageId = parseInt(params.messageId);
        await fetchGetMessages(applicationListId, formId, messageId);
        await fetchApplicationList(applicationListId);
      }
      setIsLoading(false);
    };
    fetching();
  }, [params]);

  useEffect(() => {
    if (!isLoading) {
      setRows(
        message.messageRecipients
          .map((rcp, i) => ({
            id: i,
            applicantId: rcp.userId,
            applicantName: `${rcp.user.firstNameEnUs} ${rcp.user.lastNameEnUs}`,
            contactEmail: rcp.receiverEmail,
            status: rcp.state === "SENT" ? (rcp.readCheck ? "Read" : "Not read") : "-",
            deliveryDate: rcp.state === "SENT" ? moment(rcp.createdAt).format("YYYY/MM/DD HH:mm:ss") : "NOT SENT",
          }))
          .filter(
            (data) =>
              (whichButtonBeClicked === "ALL RECIPIENT" && (filterBy === "" || filterBy === "-")) ||
              (data.deliveryDate === "NOT SENT" && whichButtonBeClicked === "NOT SENT") ||
              (data.deliveryDate !== "NOT SENT" && whichButtonBeClicked !== "NOT SENT" && (data.status === filterBy || filterBy === "" || filterBy === "-"))
          )
      );
    }
  }, [filterBy, whichButtonBeClicked, isLoading]);

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId) {
      const listId = parseInt(params.applicationListId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/summary`));
    }
  }

  function handleClick3(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}`));
    }
  }

  function handleClick4(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId && params.messageId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message`));
    }
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === LanguageEnum.ZH_HK ? listData.titleZhHk : i18n.resolvedLanguage === LanguageEnum.EN_US ? listData.titleEnUs : null}
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick3}>
      {t("view.application")}
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick4}>
      {t("view.sendMessage")}
    </Link>,
    <Typography key="3" color="primary.main">
      {announcementOfWaitingList}
    </Typography>,
  ];
  async function fetchGetMessages(listId: number, stageId: number, messageId: number) {
    const result = await getMessageById(listId, stageId, messageId);
    if (result.success) {
      setMessage(result.data);
    }
  }

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setListData(result.data);
    }
  }

  function filterShowOrNot() {
    if (whichButtonBeClicked !== "NOT SENT") {
      return "";
    }
    return "noErrorMessage";
  }

  function statusTranslate(statusStr: string) {
    switch (statusStr) {
      case "Read":
        return read;
      case "Not read":
        return notRead;
      default:
        return "-";
    }
  }

  function whichStatus(gridRenderCellParams: GridRenderCellParams) {
    return <div>{statusTranslate(gridRenderCellParams.row.status)}</div>;
  }
  const [valueButton, setButtonValue] = useState("ALL RECIPIENT");

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setButtonValue(newValue);
  };

  return (
    <Grid sx={{ m: 2 }}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <Grid sx={{ my: 1, background: "white" }}>
            <Grid container sx={{ p: 2 }} className="outterContainer">
              <Grid item xs={12}>
                <InputLabel sx={title}>{announcementOfWaitingList}</InputLabel>
              </Grid>

              <Grid item xs={12}>
                <InputLabel sx={{ marginBottom: 1 }}>{moment(message.createdAt).format(`[${sentOn}] YYYY/MM/DD [${at}] HH:mm:ss`)}</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={inViewStyle}>{inView}</InputLabel>
              </Grid>
              <Grid item xs={12}>
                <Grid container sx={{ marginBottom: 1, marginTop: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <Grid container sx={{ marginBottom: 1, marginTop: 1 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography sx={{ fontSize: 14, color: "#606266" }}>{messageContent}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography sx={{ borderTop: "1px dashed #B7B7B7" }}>{Parse(message.html)}</Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                    <Grid container sx={{ marginBottom: 1, marginTop: 2 }} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                      <Grid item xs={12}>
                        <Accordion>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                            <Typography sx={{ fontSize: 14, color: "#606266" }}>{`${attachment} ${
                              message.messageAttachments.length > 0 ? `(${message.messageAttachments.length})` : ""
                            }`}</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography sx={{ borderTop: "1px dashed #B7B7B7" }}>
                              {message.messageAttachments &&
                                message.messageAttachments.map((atm, i) => (
                                  <InputLabel key={atm.id} sx={attachmentDetailStyle}>
                                    {`${i + 1}. ${atm.name} (size)`}
                                  </InputLabel>
                                ))}
                            </Typography>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid container sx={{ marginTop: 1 }}>
                  <Grid item xs={6} alignItems="left">
                    <Box sx={{ width: "100%", typography: "body1" }}>
                      <TabContext value={valueButton}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <TabList onChange={handleChange} aria-label="lab API tabs example">
                            <Tab label={`${allRecipient} (${rows.length})`} value="ALL RECIPIENT" onClick={() => setButtonStatus("ALL RECIPIENT")} />
                            <Tab
                              label={`${sent} (${rows.reduce((acc, curr) => (curr.deliveryDate !== "NOT SENT" ? acc + 1 : acc), 0)})`}
                              value="SENT"
                              onClick={() => setButtonStatus("SENT")}
                            />
                            <Tab
                              label={`${notSent} (${rows.reduce((acc, curr) => (curr.deliveryDate === "NOT SENT" ? acc + 1 : acc), 0)})`}
                              value="NOT SENT"
                              onClick={() => setButtonStatus("NOT SENT")}
                            />
                            <Tab
                              label={`SENT FAILED (${rows.reduce((acc, curr) => (curr.deliveryDate === "NOT SENT" ? acc + 1 : acc), 0)})`}
                              value="SENT FAILED"
                              onClick={() => setButtonStatus("NOT SENT")}
                            />
                          </TabList>
                        </Box>
                      </TabContext>
                    </Box>
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <InputLabel sx={divider}></InputLabel>
              </Grid>

              <Grid item xs={12}>
                <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={6} display="flex" alignItems="center">
                    <div className={filterShowOrNot()}>{filterByTranslate}</div>
                    <Select className={filterShowOrNot()} sx={selectedFilterStyle} value={filterBy} onChange={changeFilterBy}>
                      <MenuItem value="Read">{read}</MenuItem>
                      <MenuItem value="Not read">{notRead}</MenuItem>
                      <MenuItem value="-">-</MenuItem>
                    </Select>
                  </Grid>

                  <Grid item xs={6} display="flex" justifyContent="flex-end" alignItems="center" className="buttonGroups">
                    <Button className="buttonGroupSent" variant="outlined" size="small">
                      {download}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={12}>
                    <div style={{ width: "100%" }}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        pageSize={pageSize}
                        rowsPerPageOptions={[10, 20, 30]}
                        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                        disableSelectionOnClick
                        onSelectionModelChange={(ids) => {
                          const selectedIDs = new Set(ids);
                          const newSelectedRows = rows.filter((row) => selectedIDs.has(row.id));
                          setSelectedRows(newSelectedRows);
                        }}
                        autoHeight
                        disableColumnMenu
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default MessageDashboard;
