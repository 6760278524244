import i18next from "i18next";
import React, { ChangeEvent, useState } from "react";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { Control, Controller, FieldErrors, useFieldArray, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import AddLinkSharpIcon from "@mui/icons-material/AddLinkSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Button, Fab, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Switch, TextField, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FieldEnum, SelectEnum } from "../../../models/fieldsEnum";
import linkFunctionItems from "../../../models/linkFunctionItems";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";
import CustomDropdownSessionQuestions from "./CustomDropdownSessionQuestions";
import MultipleAnswerSession from "./MultipleAnswerSession";
import MultipleAnswerSetSession from "./MultipleAnswerSetSession";
import MultipleChoicesSessionQuestions from "./MultipleChoicesSessionQuestions";
import SingleChoiceSessionQuestions from "./SingleChoiceSessionQuestions";
import YesNoSessionQuestions from "./YesNoSessionQuestions";

interface Props {
  nestIndex: number;
  control: Control<CreateApplicationStageFormInput, object>;
  register: UseFormRegister<CreateApplicationStageFormInput>;
  setValue: UseFormSetValue<CreateApplicationStageFormInput>;
  getValues: UseFormGetValues<CreateApplicationStageFormInput>;
  watch: UseFormWatch<CreateApplicationStageFormInput>;
  errors: FieldErrors<CreateApplicationStageFormInput>;
}

interface FieldOption {
  elem: FieldEnum;
  nativeName: string;
}

interface DropdownOption {
  elem: SelectEnum;
  nativeName: string;
}

function SessionContent({ nestIndex, control, register, watch, setValue, getValues, errors }: Props) {
  const { t } = useTranslation();
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const more = t("createForm.formContent.more");
  const copy = t("createForm.formContent.copy");
  const titleDelete = t("createForm.formContent.delete");
  const dropdownQuestions: DropdownOption[] = [
    { elem: SelectEnum.NATIONALITY, nativeName: `${t("createForm.formContent.nationality")}` },
    { elem: SelectEnum.JOBINDUSTRY, nativeName: t("createForm.formContent.jobIndustry") },
    { elem: SelectEnum.POB, nativeName: t("createForm.formContent.placeOfBirth") },
    { elem: SelectEnum.CUSTOM, nativeName: t("createForm.formContent.custom") },
  ];
  const fieldOptions: FieldOption[] = [
    {
      elem: FieldEnum.YES_NO,
      nativeName: i18next.t("createForm.formContent.yesNoQuestions"),
    },
    {
      elem: FieldEnum.SHORT_ANSWER,
      nativeName: i18next.t("createForm.formContent.shortAnswer"),
    },
    {
      elem: FieldEnum.PARAGRAPH,
      nativeName: i18next.t("createForm.formContent.paragraph"),
    },
    {
      elem: FieldEnum.VALUE,
      nativeName: i18next.t("createForm.formContent.value"),
    },
    {
      elem: FieldEnum.IMAGE_UPLOAD,
      nativeName: i18next.t("createForm.formContent.uploadImage"),
    },
    {
      elem: FieldEnum.FILE_UPLOAD,
      nativeName: i18next.t("createForm.formContent.uploadFile"),
    },
    {
      elem: FieldEnum.SINGLE_CHOICE,
      nativeName: i18next.t("createForm.formContent.singleChoice"),
    },
    {
      elem: FieldEnum.MULTIPLE_CHOICES,
      nativeName: i18next.t("createForm.formContent.multipleChoices"),
    },
    {
      elem: FieldEnum.DROPDOWN,
      nativeName: i18next.t("createForm.formContent.dropdown"),
    },
    {
      elem: FieldEnum.DATEPICKER,
      nativeName: i18next.t("createForm.formContent.datepicker"),
    },
    {
      elem: FieldEnum.EMAIL,
      nativeName: i18next.t("createForm.formContent.eMail"),
    },
    {
      elem: FieldEnum.PHONE_NUMBER,
      nativeName: i18next.t("createForm.formContent.phoneNumber"),
    },
    {
      elem: FieldEnum.APPLICATION_NUMBER,
      nativeName: i18next.t("createForm.formContent.applicationNumber"),
    },
    {
      elem: FieldEnum.CHECKBOX,
      nativeName: i18next.t("createForm.formContent.checkbox"),
    },
    {
      elem: FieldEnum.MULTIPLE_ANSWER_SET,
      nativeName: i18next.t("createForm.formContent.multipleAnswerSet"),
    },
  ];

  const { fields, append, remove } = useFieldArray({
    control,
    name: `fieldContent.${nestIndex}.sectionQuestion`,
  });

  const onAppend = () => {
    append({
      fieldType: "",
      mandatory: true,
      fieldQuestion: {
        zhHK: "",
        enUS: "",
      },
      yesNoQuestions: {
        yes: {
          points: 0,
          linkedQuestion: [],
        },
        no: { points: 0, linkedQuestion: [] },
      },
      singleChoiceQuestions: [
        {
          zhHK: "",
          enUS: "",
          others: false,
          points: 0,
          linkedQuestion: [],
        },
      ],
      multipleChoiceQuestions: [
        {
          zhHK: "",
          enUS: "",
          others: false,
          points: 0,
        },
      ],
      dropdownQuestions: {
        type: "",
        custom: [
          {
            zhHK: "",
            enUS: "",
            points: 0,
            linkedQuestion: "",
          },
        ],
      },
      multipleAnswerSet: {
        answersNo: 1,
        answerSetsNo: 1,
        answers: [
          {
            zhHK: "",
            enUS: "",
            others: false,
            points: 0,
          },
        ],
        answerSets: [
          {
            placeholder: {
              zhHK: "",
              enUS: "",
            },
            sets: [
              {
                zhHK: "",
                enUS: "",
                others: false,
                points: 0,
              },
            ],
          },
        ],
      },
      relationship: "",
    });
  };

  const onCopy = (k: number) => {
    const originalContent = getValues().fieldContent[nestIndex].sectionQuestion[k];
    if (originalContent.id) {
      delete originalContent.id;
    }
    append(originalContent);
  };

  const onRemove = (k: number) => {
    remove(k);
  };

  const [selectedMultipleAnswerIndex, setSelectedMultipleAnswerIndex] = useState<number>(0);
  const [extraFunction, setExtraFunction] = useState<null | HTMLElement>(null);
  const openExtraFunction = Boolean(extraFunction);
  const handleClickExtraFunction = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setExtraFunction(event.currentTarget);
    setSelectedMultipleAnswerIndex(index);
  };
  const handleCloseExtraFunction = () => {
    setExtraFunction(null);
  };

  const [selectedIndexForLinkedQuestion, setSelectedIndexForLinkedQuestion] = useState<number>(0);
  const [linkFunction, setLinkFunction] = useState<null | HTMLElement>(null);
  const openLinkFunction = Boolean(linkFunction);
  const handleClickLinkFunction = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setLinkFunction(event.currentTarget);
    setSelectedIndexForLinkedQuestion(index);
  };
  const handleCloseLinkFunction = () => {
    setLinkFunction(null);
  };

  const onAllowMultipleAnswers = (index: number) => {
    setExtraFunction(null);
    setValue(`fieldContent.${nestIndex}.sectionQuestion.${index}.multipleAnswers`, true);
  };

  const onDisallowMultipleAnswers = (index: number) => {
    setExtraFunction(null);
    setValue(`fieldContent.${nestIndex}.sectionQuestion.${index}.multipleAnswers`, false);
  };

  return (
    <>
      <Droppable droppableId="fieldSection" type="INNER" isCombineEnabled>
        {(droppablepProvided) => (
          <div ref={droppablepProvided.innerRef} {...droppablepProvided.droppableProps}>
            {fields.map((sectionQuestion, k) => {
              return (
                <Draggable key={sectionQuestion.id} draggableId={sectionQuestion.id} index={k}>
                  {(draggableProvided, snapshot) => (
                    <Grid
                      ref={draggableProvided.innerRef}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}
                      key={sectionQuestion.id}
                      sx={{ m: 2, mb: 4 }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        className={snapshot.isDragging ? "activeFormContentSessionQuestionContainer" : !snapshot.isDragging ? "inactiveFormContentSessionQuestionContainer" : ""}
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={12} md={12}>
                            <div className="dragIndicatorContainer">
                              <DragIndicatorIcon className="dragIndicatorIcon" />
                            </div>
                          </Grid>

                          <Grid item xs={1} sm={0.6} md={0.5} lg={0.5} xl={0.5}>
                            <div className="circleNoContainer">{k + 1}</div>
                          </Grid>

                          <Grid item xs={11} sm={5.7} md={3.3} lg={4} xl={4.4}>
                            <FormControl fullWidth required>
                              <InputLabel id="questionTypeLabel">{t("createForm.formContent.questionType")}</InputLabel>
                              <Controller
                                name={`fieldContent.${nestIndex}.sectionQuestion.${k}.fieldType` as const}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <Select
                                    {...field}
                                    label="Question Type"
                                    id="questionTypeLabel"
                                    variant="outlined"
                                    onChange={(event: SelectChangeEvent<string>) => {
                                      watch([`fieldContent.${nestIndex}.sectionQuestion.${k}.fieldType`]);
                                      field.onChange(event.target.value);
                                      setValue(`fieldContent.${nestIndex}.sectionQuestion.${k}`, {
                                        fieldType: event.target.value,
                                        mandatory: true,
                                        fieldQuestion: {
                                          zhHK: "",
                                          enUS: "",
                                        },
                                        yesNoQuestions: {
                                          yes: {
                                            points: 0,
                                            linkedQuestion: [],
                                          },
                                          no: { points: 0, linkedQuestion: [] },
                                        },
                                        singleChoiceQuestions: [
                                          {
                                            zhHK: "",
                                            enUS: "",
                                            others: false,
                                            points: 0,
                                            linkedQuestion: [],
                                          },
                                        ],
                                        multipleChoiceQuestions: [
                                          {
                                            zhHK: "",
                                            enUS: "",
                                            others: false,
                                            points: 0,
                                          },
                                        ],
                                        dropdownQuestions: {
                                          type: "",
                                          custom: [
                                            {
                                              zhHK: "",
                                              enUS: "",
                                              points: 0,
                                              linkedQuestion: "",
                                            },
                                          ],
                                        },
                                        multipleAnswerSet: {
                                          answersNo: 1,
                                          answerSetsNo: 1,
                                          answers: [
                                            {
                                              zhHK: "",
                                              enUS: "",
                                              others: false,
                                              points: 0,
                                            },
                                          ],
                                          answerSets: [
                                            {
                                              placeholder: {
                                                zhHK: "",
                                                enUS: "",
                                              },
                                              sets: [
                                                {
                                                  zhHK: "",
                                                  enUS: "",
                                                  others: false,
                                                  points: 0,
                                                },
                                              ],
                                            },
                                          ],
                                        },
                                        relationship: "",
                                      });
                                    }}
                                  >
                                    {fieldOptions.map((fieldOption) => {
                                      return (
                                        <MenuItem key={fieldOption.elem} value={fieldOption.elem} style={{ zIndex: "99999 !important" }}>
                                          {fieldOption.nativeName}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                )}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={5.7} md={3.3} lg={4} xl={4.4} style={{ zIndex: "999999 !important" }}>
                            {getValues().fieldContent[nestIndex].sectionQuestion[k].fieldType === FieldEnum.DROPDOWN && (
                              <FormControl fullWidth required style={{ zIndex: "999999 !important" }}>
                                <InputLabel id="answerTypeLabel">{t("createForm.formContent.answerType")}</InputLabel>
                                <Controller
                                  name={`fieldContent.${nestIndex}.sectionQuestion.${k}.dropdownQuestions.type` as const}
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      style={{ zIndex: "999999 !important" }}
                                      label="Answer Type"
                                      id="answerTypeLabel"
                                      variant="outlined"
                                      onChange={(event: SelectChangeEvent<string>) => {
                                        watch([`fieldContent.${nestIndex}.sectionQuestion.${k}.dropdownQuestions.type`]);
                                        field.onChange(event.target.value);
                                      }}
                                    >
                                      {dropdownQuestions.map((dropDownOption) => {
                                        return (
                                          <MenuItem style={{ zIndex: "999999 !important" }} key={dropDownOption.elem} value={dropDownOption.elem}>
                                            {dropDownOption.nativeName}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  )}
                                />
                              </FormControl>
                            )}

                            {getValues().fieldContent[nestIndex].sectionQuestion[k].fieldType === FieldEnum.MULTIPLE_ANSWER_SET && (
                              <Grid container spacing={2}>
                                <Grid item xs={6} md={6}>
                                  <Controller
                                    name={`fieldContent.${nestIndex}.sectionQuestion.${k}.multipleAnswerSet.answersNo` as const}
                                    control={control}
                                    rules={{ required: "Number of answer is required" }}
                                    defaultValue={undefined}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        inputProps={{ type: "number" }}
                                        label={t("createForm.formContent.noOfAnswer")}
                                        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                          if (parseInt(e.target.value) >= 0) {
                                            field.onChange(parseInt(e.target.value));
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                                <Grid item xs={6} md={6}>
                                  <Controller
                                    name={`fieldContent.${nestIndex}.sectionQuestion.${k}.multipleAnswerSet.answerSetsNo` as const}
                                    control={control}
                                    rules={{
                                      required: "No. of Answer Set is required",
                                    }}
                                    defaultValue={undefined}
                                    render={({ field }) => (
                                      <TextField
                                        {...field}
                                        fullWidth
                                        inputProps={{ type: "number" }}
                                        label={t("createForm.formContent.noOfAnswerSet")}
                                        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                          if (parseInt(e.target.value) >= 0) {
                                            field.onChange(parseInt(e.target.value));
                                          }
                                        }}
                                      />
                                    )}
                                  />
                                </Grid>
                              </Grid>
                            )}
                          </Grid>

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].mandatory !== undefined && (
                            <Grid item xs={12} sm={6} md={2.6} lg={2} xl={1.8} display="flex" justifyContent={matches ? "flex-end" : "center"} alignItems="center">
                              {getValues().fieldContent[nestIndex].sectionQuestion[k].mandatory ? t("forms.required") : "Not Required Field"}
                              <Controller
                                name={`fieldContent.${nestIndex}.sectionQuestion.${k}.mandatory` as const}
                                control={control}
                                defaultValue
                                render={({ field }) => (
                                  <Switch
                                    {...field}
                                    checked={getValues().fieldContent[nestIndex].sectionQuestion[k].mandatory}
                                    onChange={() => {
                                      watch([`fieldContent.${nestIndex}.sectionQuestion.${k}.mandatory`]);
                                      setValue(`fieldContent.${nestIndex}.sectionQuestion.${k}.mandatory`, !getValues().fieldContent[nestIndex].sectionQuestion[k].mandatory);
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          )}

                          <Grid item xs={12} sm={6} md={2.3} lg={1.5} xl={0.9} display="flex" justifyContent={matches ? "flex-start" : "center"}>
                            <Tooltip title={more}>
                              <IconButton
                                color="primary"
                                size="small"
                                id="basic-button"
                                aria-controls={openExtraFunction ? "basic-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={openExtraFunction ? "true" : undefined}
                                onClick={(e) => {
                                  handleClickExtraFunction(e, k);
                                }}
                              >
                                <MoreVertRoundedIcon />
                              </IconButton>
                            </Tooltip>
                            <Menu
                              id="basic-menu"
                              anchorEl={extraFunction}
                              open={openExtraFunction}
                              onClose={handleCloseExtraFunction}
                              MenuListProps={{
                                "aria-labelledby": "basic-button",
                              }}
                            >
                              <MenuItem
                                onClick={() => {
                                  if (
                                    getValues().fieldContent !== undefined &&
                                    getValues().fieldContent[nestIndex] !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].description === undefined
                                  ) {
                                    watch([`fieldContent.${nestIndex}.sectionQuestion.${selectedMultipleAnswerIndex}.description`]);
                                    setValue(`fieldContent.${nestIndex}.sectionQuestion.${selectedMultipleAnswerIndex}.description`, { enUS: "", zhHK: "" });
                                    setExtraFunction(null);
                                  } else if (
                                    getValues().fieldContent !== undefined &&
                                    getValues().fieldContent[nestIndex] !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].description !== undefined
                                  ) {
                                    watch([`fieldContent.${nestIndex}.sectionQuestion.${selectedMultipleAnswerIndex}.description`]);
                                    setValue(`fieldContent.${nestIndex}.sectionQuestion.${selectedMultipleAnswerIndex}.description`, undefined);
                                    setExtraFunction(null);
                                  }
                                }}
                              >
                                {t("createForm.formContent.addDescription")}
                                {getValues().fieldContent !== undefined &&
                                getValues().fieldContent[nestIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].description !== undefined ? (
                                  <CheckCircleIcon className="tickIcon" fontSize="small" />
                                ) : null}
                              </MenuItem>
                              <MenuItem
                                onClick={() => {
                                  if (
                                    getValues().fieldContent !== undefined &&
                                    getValues().fieldContent[nestIndex] !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].hyperlink === undefined
                                  ) {
                                    watch([`fieldContent.${nestIndex}.sectionQuestion.${selectedMultipleAnswerIndex}.hyperlink`]);
                                    setValue(`fieldContent.${nestIndex}.sectionQuestion.${selectedMultipleAnswerIndex}.hyperlink`, "");
                                    setExtraFunction(null);
                                  } else if (
                                    getValues().fieldContent !== undefined &&
                                    getValues().fieldContent[nestIndex] !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                    getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].hyperlink !== undefined
                                  ) {
                                    watch([`fieldContent.${nestIndex}.sectionQuestion.${selectedMultipleAnswerIndex}.hyperlink`]);
                                    setValue(`fieldContent.${nestIndex}.sectionQuestion.${selectedMultipleAnswerIndex}.hyperlink`, undefined);
                                    setExtraFunction(null);
                                  }
                                }}
                              >
                                {t("createForm.formContent.addHyperlink")}
                                {getValues().fieldContent !== undefined &&
                                getValues().fieldContent[nestIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].hyperlink !== undefined ? (
                                  <CheckCircleIcon className="tickIcon" fontSize="small" />
                                ) : null}
                              </MenuItem>

                              {getValues().fieldContent !== undefined &&
                                getValues().fieldContent[nestIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.YES_NO &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.IMAGE_UPLOAD &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.FILE_UPLOAD &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.SINGLE_CHOICE &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.DROPDOWN &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.EMAIL &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.PHONE_NUMBER &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.APPLICATION_NUMBER &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.CHECKBOX &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.DATEPICKER &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.MULTIPLE_ANSWER_SET && (
                                  <MenuItem onClick={handleCloseExtraFunction} disabled>
                                    Custom Validation
                                  </MenuItem>
                                )}

                              {getValues().fieldContent !== undefined &&
                                getValues().fieldContent[nestIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.YES_NO &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.IMAGE_UPLOAD &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.FILE_UPLOAD &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.SINGLE_CHOICE &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.MULTIPLE_CHOICES &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.DROPDOWN &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.CHECKBOX &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.DATEPICKER &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType !== FieldEnum.MULTIPLE_ANSWER_SET && (
                                  <MenuItem
                                    onClick={() => {
                                      if (
                                        getValues().fieldContent !== undefined &&
                                        getValues().fieldContent[nestIndex] !== undefined &&
                                        getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                        getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                        getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].multipleAnswers === true
                                      ) {
                                        onDisallowMultipleAnswers(selectedMultipleAnswerIndex);
                                      } else if (
                                        getValues().fieldContent !== undefined &&
                                        getValues().fieldContent[nestIndex] !== undefined &&
                                        getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                        getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                        getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].multipleAnswers === false
                                      ) {
                                        onAllowMultipleAnswers(selectedMultipleAnswerIndex);
                                      } else {
                                        onAllowMultipleAnswers(selectedMultipleAnswerIndex);
                                      }
                                    }}
                                  >
                                    <div style={{ display: "flex" }}>
                                      <div>{t("createForm.formContent.allowMultipleAnswer")}</div>
                                      <div>
                                        {getValues().fieldContent !== undefined &&
                                        getValues().fieldContent[nestIndex] !== undefined &&
                                        getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                        getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                        getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].multipleAnswers === true ? (
                                          <CheckCircleIcon className="tickIcon" fontSize="small" />
                                        ) : null}
                                      </div>
                                    </div>
                                  </MenuItem>
                                )}

                              {getValues().fieldContent !== undefined &&
                                getValues().fieldContent[nestIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType === FieldEnum.DATEPICKER && (
                                  <MenuItem onClick={handleCloseExtraFunction}>Add Year</MenuItem>
                                )}
                              {getValues().fieldContent !== undefined &&
                                getValues().fieldContent[nestIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType === FieldEnum.DATEPICKER && (
                                  <MenuItem onClick={handleCloseExtraFunction}>Add Time</MenuItem>
                                )}
                              {getValues().fieldContent !== undefined &&
                                getValues().fieldContent[nestIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex] !== undefined &&
                                getValues().fieldContent[nestIndex].sectionQuestion[selectedMultipleAnswerIndex].fieldType === FieldEnum.MULTIPLE_ANSWER_SET && (
                                  <MenuItem onClick={handleCloseExtraFunction}>Required to Fill All Answers</MenuItem>
                                )}
                            </Menu>

                            <Tooltip title={copy}>
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  onCopy(k);
                                }}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </Tooltip>

                            <Tooltip title={titleDelete}>
                              <IconButton
                                color="primary"
                                size="small"
                                onClick={() => {
                                  onRemove(k);
                                }}
                              >
                                <DeleteOutlineIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>

                          <Grid item xs={12} sm={5.5} md={5.7}>
                            <Controller
                              name={`fieldContent.${nestIndex}.sectionQuestion.${k}.fieldQuestion.enUS` as const}
                              control={control}
                              rules={{ required: "Question (English) is required" }}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  label={t("createForm.formContent.questionEng")}
                                  error={errors.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.fieldQuestion?.enUS !== undefined && true}
                                  helperText={
                                    errors.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.fieldQuestion?.enUS !== undefined &&
                                    `${errors.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.fieldQuestion?.enUS?.message}`
                                  }
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={5.5} md={5.7}>
                            <Controller
                              name={`fieldContent.${nestIndex}.sectionQuestion.${k}.fieldQuestion.zhHK` as const}
                              control={control}
                              rules={{ required: "Question (Chinese) is required" }}
                              defaultValue=""
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  label={t("createForm.formContent.questionCh")}
                                  error={errors.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.fieldQuestion?.zhHK !== undefined && true}
                                  helperText={
                                    errors.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.fieldQuestion?.zhHK !== undefined &&
                                    `${errors.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.fieldQuestion?.zhHK?.message}`
                                  }
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} sm={1} md={0.6}>
                            <Fab
                              aria-label="link"
                              className={getValues().fieldContent[nestIndex].sectionQuestion[k].relationship ? "activeLinkBtnForQuestions" : "inactiveLinkBtnForQuestions"}
                              size="small"
                              onClick={(e) => {
                                handleClickLinkFunction(e, k);
                              }}
                            >
                              <AddLinkSharpIcon />
                            </Fab>
                            <Menu
                              id="basic-menu"
                              anchorEl={linkFunction}
                              open={openLinkFunction}
                              onClose={handleCloseLinkFunction}
                              MenuListProps={{
                                "aria-labelledby": "link-button",
                              }}
                            >
                              {linkFunctionItems.map((linkFunctionItem) => (
                                <MenuItem
                                  key={linkFunctionItem.elem}
                                  onClick={() => {
                                    if (getValues().fieldContent[nestIndex].sectionQuestion[selectedIndexForLinkedQuestion].relationship === "") {
                                      setValue(`fieldContent.${nestIndex}.sectionQuestion.${selectedIndexForLinkedQuestion}.relationship`, linkFunctionItem.elem);
                                    } else if (getValues().fieldContent[nestIndex].sectionQuestion[selectedIndexForLinkedQuestion].relationship === linkFunctionItem.elem) {
                                      setValue(`fieldContent.${nestIndex}.sectionQuestion.${selectedIndexForLinkedQuestion}.relationship`, "");
                                    } else {
                                      setValue(`fieldContent.${nestIndex}.sectionQuestion.${selectedIndexForLinkedQuestion}.relationship`, linkFunctionItem.elem);
                                    }
                                    handleCloseLinkFunction();
                                  }}
                                >
                                  {linkFunctionItem.nativeName}
                                  {getValues().fieldContent[nestIndex].sectionQuestion[selectedIndexForLinkedQuestion].relationship === linkFunctionItem.elem ? (
                                    <CheckCircleIcon className="tickIcon" fontSize="small" />
                                  ) : (
                                    ""
                                  )}
                                </MenuItem>
                              ))}
                            </Menu>
                          </Grid>

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].description?.enUS !== undefined && (
                            <Grid item xs={12}>
                              <Controller
                                name={`fieldContent.${nestIndex}.sectionQuestion.${k}.description.enUS` as const}
                                control={control}
                                rules={{
                                  required: "Description (English) is required",
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="filled"
                                    label="Description (English)"
                                    error={errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.description?.enUS !== undefined && true}
                                    helperText={
                                      errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.description?.enUS !== undefined &&
                                      `${errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.description?.enUS?.message}`
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          )}

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].description?.zhHK !== undefined && (
                            <Grid item xs={12}>
                              <Controller
                                name={`fieldContent.${nestIndex}.sectionQuestion.${k}.description.zhHK` as const}
                                control={control}
                                rules={{
                                  required: "Description (Chinese) is required",
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="filled"
                                    label="Description (Chinese)"
                                    error={errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.description?.zhHK !== undefined && true}
                                    helperText={
                                      errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.description?.zhHK !== undefined &&
                                      `${errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.description?.zhHK?.message}`
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          )}

                          {getValues().fieldContent[nestIndex].sectionQuestion[k]?.hyperlink !== undefined && (
                            <Grid item xs={12}>
                              <Controller
                                name={`fieldContent.${nestIndex}.sectionQuestion.${k}.hyperlink` as const}
                                control={control}
                                rules={{
                                  required: "Hyperlink is required",
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="filled"
                                    label="Hyperlink"
                                    error={errors.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.hyperlink !== undefined && true}
                                    helperText={
                                      errors.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.hyperlink &&
                                      `${errors.fieldContent?.[nestIndex]?.sectionQuestion?.[k]?.hyperlink?.message}`
                                    }
                                  />
                                )}
                              />
                            </Grid>
                          )}

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].fieldType === FieldEnum.YES_NO && (
                            <Grid item xs={12} md={12}>
                              <YesNoSessionQuestions
                                nestIndex={nestIndex}
                                secondNestIndex={k}
                                {...{
                                  control,
                                  register,
                                  watch,
                                  setValue,
                                  getValues,
                                  errors,
                                }}
                              />
                            </Grid>
                          )}

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].fieldType === FieldEnum.SINGLE_CHOICE && (
                            <Grid item xs={12} md={12}>
                              <SingleChoiceSessionQuestions
                                nestIndex={nestIndex}
                                secondNestIndex={k}
                                {...{
                                  control,
                                  register,
                                  watch,
                                  setValue,
                                  getValues,
                                  errors,
                                }}
                              />
                            </Grid>
                          )}

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].fieldType === FieldEnum.MULTIPLE_CHOICES && (
                            <Grid item xs={12} md={12}>
                              <MultipleChoicesSessionQuestions
                                nestIndex={nestIndex}
                                secondNestIndex={k}
                                {...{
                                  control,
                                  register,
                                  watch,
                                  setValue,
                                  getValues,
                                  errors,
                                }}
                              />
                            </Grid>
                          )}

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].dropdownQuestions.type === SelectEnum.CUSTOM && (
                            <Grid item xs={12} md={12}>
                              <CustomDropdownSessionQuestions
                                nestIndex={nestIndex}
                                secondNestIndex={k}
                                {...{
                                  control,
                                  register,
                                  watch,
                                  setValue,
                                  getValues,
                                  errors,
                                }}
                              />
                            </Grid>
                          )}

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].fieldType === FieldEnum.DATEPICKER && (
                            <Grid item xs={6} md={6}>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                  value={new Date()}
                                  onChange={() => {
                                    const newDate = new Date();
                                    return newDate;
                                  }}
                                  disabled
                                  inputFormat="DD/MM/yyyy"
                                  renderInput={(params) => <TextField {...params} disabled fullWidth variant="standard" />}
                                />
                              </LocalizationProvider>
                            </Grid>
                          )}

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].fieldType === FieldEnum.CHECKBOX && (
                            <>
                              <Grid item xs={0.5} md={0.5}>
                                <div className="checkBoxOutlineBlankIcon"></div>
                              </Grid>
                              <Grid item xs={11.5} md={11.5}>
                                <Controller
                                  name={`fieldContent.${nestIndex}.sectionQuestion.${k}.checkboxQuestions.enUS` as const}
                                  control={control}
                                  rules={{ required: "English description is required" }}
                                  defaultValue=""
                                  render={({ field }) => <TextField {...field} fullWidth variant="standard" label={t("createForm.formContent.textEng")} />}
                                />
                              </Grid>

                              <Grid item xs={0.5} md={0.5}></Grid>
                              <Grid item xs={11.5} md={11.5}>
                                <Controller
                                  name={`fieldContent.${nestIndex}.sectionQuestion.${k}.checkboxQuestions.zhHK` as const}
                                  control={control}
                                  rules={{ required: "Chinese description is required" }}
                                  defaultValue=""
                                  render={({ field }) => <TextField {...field} fullWidth variant="standard" label={t("createForm.formContent.textCh")} />}
                                />
                              </Grid>
                            </>
                          )}

                          {getValues().fieldContent[nestIndex].sectionQuestion[k].fieldType === FieldEnum.MULTIPLE_ANSWER_SET && (
                            <>
                              <Grid item xs={12} md={12}>
                                <MultipleAnswerSession
                                  nestIndex={nestIndex}
                                  secondNestIndex={k}
                                  {...{
                                    control,
                                    register,
                                    watch,
                                    setValue,
                                    getValues,
                                    errors,
                                  }}
                                />
                              </Grid>

                              <Grid item xs={12} md={12}>
                                <MultipleAnswerSetSession
                                  nestIndex={nestIndex}
                                  secondNestIndex={k}
                                  {...{
                                    control,
                                    register,
                                    watch,
                                    setValue,
                                    getValues,
                                    errors,
                                  }}
                                />
                              </Grid>
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                </Draggable>
              );
            })}
            {droppablepProvided.placeholder}
          </div>
        )}
      </Droppable>

      <Grid item xs={12}>
        <Button variant="outlined" onClick={onAppend}>
          <AddIcon />
          {t("createForm.formContent.addQuestion")}
        </Button>
      </Grid>
    </>
  );
}

export default SessionContent;
