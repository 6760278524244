import React, { ReactNode } from "react";
import { Control, Controller, FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormControl, FormHelperText, MenuItem } from "@mui/material";
import { DropdownQuestionCustom } from "../../interfaces/createApplicationForm";
import { SelectEnum } from "../../models/fieldsEnum";
import { RoundSelect } from "../utils/CustomStyle";
import { SingleQuestion } from "./SubmissionForm";
import { LanguageEnum } from "../../models/languageEnum";
import QuestionComponent from "./QuestionComponent";

interface Props {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
  question: SingleQuestion;
  dropdownQuestions: string;
  dropdownQuestionsCustom: DropdownQuestionCustom[];
}

function Dropdown({ errors, control, question, dropdownQuestions, dropdownQuestionsCustom }: Props) {
  // const { t, i18n } = useTranslation(["countries", "districts", "jobs", "translation"]);
  const { t, i18n } = useTranslation();

  const jobOptions = [
    { elem: t("accounting", { lng: "enUS", ns: "jobs" }), nativeName: t("accounting", { ns: "jobs" }) },
    { elem: t("adminAndClerical", { lng: "enUS", ns: "jobs" }), nativeName: t("adminAndClerical", { ns: "jobs" }) },
    { elem: t("architectureAndConstruction", { lng: "enUS", ns: "jobs" }), nativeName: t("architectureAndConstruction", { ns: "jobs" }) },
    {
      elem: t("artsAndEntertainmentAndPublishing", { lng: "enUS", ns: "jobs" }),
      nativeName: t("artsAndEntertainmentAndPublishing", { ns: "jobs" }),
    },
    { elem: t("banking", { lng: "enUS", ns: "jobs" }), nativeName: t("banking", { ns: "jobs" }) },
    { elem: t("beauty", { lng: "enUS", ns: "jobs" }), nativeName: t("beauty", { ns: "jobs" }) },
    { elem: t("businessConsultant", { lng: "enUS", ns: "jobs" }), nativeName: t("businessConsultant", { ns: "jobs" }) },
    { elem: t("customerService", { lng: "enUS", ns: "jobs" }), nativeName: t("customerService", { ns: "jobs" }) },
    { elem: t("designAndCreative", { lng: "enUS", ns: "jobs" }), nativeName: t("designAndCreative", { ns: "jobs" }) },
    { elem: t("education", { lng: "enUS", ns: "jobs" }), nativeName: t("education", { ns: "jobs" }) },
    { elem: t("engineering", { lng: "enUS", ns: "jobs" }), nativeName: t("engineering", { ns: "jobs" }) },
    { elem: t("finance", { lng: "enUS", ns: "jobs" }), nativeName: t("finance", { ns: "jobs" }) },
    { elem: t("fitness", { lng: "enUS", ns: "jobs" }), nativeName: t("fitness", { ns: "jobs" }) },
    { elem: t("insurance", { lng: "enUS", ns: "jobs" }), nativeName: t("insurance", { ns: "jobs" }) },
    { elem: t("internetOrOnlineBusiness", { lng: "enUS", ns: "jobs" }), nativeName: t("internetOrOnlineBusiness", { ns: "jobs" }) },
    { elem: t("it", { lng: "enUS", ns: "jobs" }), nativeName: t("it", { ns: "jobs" }) },
    { elem: t("legal", { lng: "enUS", ns: "jobs" }), nativeName: t("legal", { ns: "jobs" }) },
    { elem: t("logistics", { lng: "enUS", ns: "jobs" }), nativeName: t("logistics", { ns: "jobs" }) },
    { elem: t("management", { lng: "enUS", ns: "jobs" }), nativeName: t("management", { ns: "jobs" }) },
    { elem: t("marketingOrbusinessDevelopment", { lng: "enUS", ns: "jobs" }), nativeName: t("marketingOrbusinessDevelopment", { ns: "jobs" }) },
    { elem: t("medicalOrHealthcare", { lng: "enUS", ns: "jobs" }), nativeName: t("medicalOrHealthcare", { ns: "jobs" }) },
    { elem: t("nonProfitOrVolunteer", { lng: "enUS", ns: "jobs" }), nativeName: t("nonProfitOrVolunteer", { ns: "jobs" }) },
    { elem: t("operations", { lng: "enUS", ns: "jobs" }), nativeName: t("operations", { ns: "jobs" }) },
    { elem: t("pr", { lng: "enUS", ns: "jobs" }), nativeName: t("pr", { ns: "jobs" }) },
    { elem: t("professionalServices", { lng: "enUS", ns: "jobs" }), nativeName: t("professionalServices", { ns: "jobs" }) },
    { elem: t("realestate", { lng: "enUS", ns: "jobs" }), nativeName: t("realestate", { ns: "jobs" }) },
    { elem: t("recruitmentAndHr", { lng: "enUS", ns: "jobs" }), nativeName: t("recruitmentAndHr", { ns: "jobs" }) },
    { elem: t("restaurantsAndBars", { lng: "enUS", ns: "jobs" }), nativeName: t("restaurantsAndBars", { ns: "jobs" }) },
    { elem: t("retail", { lng: "enUS", ns: "jobs" }), nativeName: t("retail", { ns: "jobs" }) },
    { elem: t("sales", { lng: "enUS", ns: "jobs" }), nativeName: t("sales", { ns: "jobs" }) },
    { elem: t("telecommunicationJobs", { lng: "enUS", ns: "jobs" }), nativeName: t("telecommunicationJobs", { ns: "jobs" }) },
    { elem: t("tourism", { lng: "enUS", ns: "jobs" }), nativeName: t("tourism", { ns: "jobs" }) },
    { elem: t("tradingAndMerchandising", { lng: "enUS", ns: "jobs" }), nativeName: t("tradingAndMerchandising", { ns: "jobs" }) },
    { elem: t("translation", { lng: "enUS", ns: "jobs" }), nativeName: t("translation", { ns: "jobs" }) },
    { elem: t("others", { lng: "enUS", ns: "jobs" }), nativeName: t("others", { ns: "jobs" }) },
  ];

  const countryOptions = [
    { elem: t("AF", { lng: "enUS", ns: "countries" }), nativeName: t("AF", { ns: "countries" }) },
    { elem: t("AX", { lng: "enUS", ns: "countries" }), nativeName: t("AX", { ns: "countries" }) },
    { elem: t("AL", { lng: "enUS", ns: "countries" }), nativeName: t("AL", { ns: "countries" }) },
    { elem: t("DZ", { lng: "enUS", ns: "countries" }), nativeName: t("DZ", { ns: "countries" }) },
    { elem: t("AS", { lng: "enUS", ns: "countries" }), nativeName: t("AS", { ns: "countries" }) },
    { elem: t("AD", { lng: "enUS", ns: "countries" }), nativeName: t("AD", { ns: "countries" }) },
    { elem: t("AO", { lng: "enUS", ns: "countries" }), nativeName: t("AO", { ns: "countries" }) },
    { elem: t("AI", { lng: "enUS", ns: "countries" }), nativeName: t("AI", { ns: "countries" }) },
    { elem: t("AQ", { lng: "enUS", ns: "countries" }), nativeName: t("AQ", { ns: "countries" }) },
    { elem: t("AG", { lng: "enUS", ns: "countries" }), nativeName: t("AG", { ns: "countries" }) },
    { elem: t("AR", { lng: "enUS", ns: "countries" }), nativeName: t("AR", { ns: "countries" }) },
    { elem: t("AM", { lng: "enUS", ns: "countries" }), nativeName: t("AM", { ns: "countries" }) },
    { elem: t("AW", { lng: "enUS", ns: "countries" }), nativeName: t("AW", { ns: "countries" }) },
    { elem: t("AU", { lng: "enUS", ns: "countries" }), nativeName: t("AU", { ns: "countries" }) },
    { elem: t("AT", { lng: "enUS", ns: "countries" }), nativeName: t("AT", { ns: "countries" }) },
    { elem: t("AZ", { lng: "enUS", ns: "countries" }), nativeName: t("AZ", { ns: "countries" }) },
    { elem: t("BS", { lng: "enUS", ns: "countries" }), nativeName: t("BS", { ns: "countries" }) },
    { elem: t("BH", { lng: "enUS", ns: "countries" }), nativeName: t("BH", { ns: "countries" }) },
    { elem: t("BD", { lng: "enUS", ns: "countries" }), nativeName: t("BD", { ns: "countries" }) },
    { elem: t("BB", { lng: "enUS", ns: "countries" }), nativeName: t("BB", { ns: "countries" }) },
    { elem: t("BY", { lng: "enUS", ns: "countries" }), nativeName: t("BY", { ns: "countries" }) },
    { elem: t("BE", { lng: "enUS", ns: "countries" }), nativeName: t("BE", { ns: "countries" }) },
    { elem: t("BZ", { lng: "enUS", ns: "countries" }), nativeName: t("BZ", { ns: "countries" }) },
    { elem: t("BJ", { lng: "enUS", ns: "countries" }), nativeName: t("BJ", { ns: "countries" }) },
    { elem: t("BM", { lng: "enUS", ns: "countries" }), nativeName: t("BM", { ns: "countries" }) },
    { elem: t("BT", { lng: "enUS", ns: "countries" }), nativeName: t("BT", { ns: "countries" }) },
    { elem: t("BO", { lng: "enUS", ns: "countries" }), nativeName: t("BO", { ns: "countries" }) },
    { elem: t("BA", { lng: "enUS", ns: "countries" }), nativeName: t("BA", { ns: "countries" }) },
    { elem: t("BW", { lng: "enUS", ns: "countries" }), nativeName: t("BW", { ns: "countries" }) },
    { elem: t("BV", { lng: "enUS", ns: "countries" }), nativeName: t("BV", { ns: "countries" }) },
    { elem: t("BR", { lng: "enUS", ns: "countries" }), nativeName: t("BR", { ns: "countries" }) },
    { elem: t("IO", { lng: "enUS", ns: "countries" }), nativeName: t("IO", { ns: "countries" }) },
    { elem: t("BN", { lng: "enUS", ns: "countries" }), nativeName: t("BN", { ns: "countries" }) },
    { elem: t("BG", { lng: "enUS", ns: "countries" }), nativeName: t("BG", { ns: "countries" }) },
    { elem: t("BF", { lng: "enUS", ns: "countries" }), nativeName: t("BF", { ns: "countries" }) },
    { elem: t("BI", { lng: "enUS", ns: "countries" }), nativeName: t("BI", { ns: "countries" }) },
    { elem: t("KH", { lng: "enUS", ns: "countries" }), nativeName: t("KH", { ns: "countries" }) },
    { elem: t("CM", { lng: "enUS", ns: "countries" }), nativeName: t("CM", { ns: "countries" }) },
    { elem: t("CA", { lng: "enUS", ns: "countries" }), nativeName: t("CA", { ns: "countries" }) },
    { elem: t("CV", { lng: "enUS", ns: "countries" }), nativeName: t("CV", { ns: "countries" }) },
    { elem: t("KY", { lng: "enUS", ns: "countries" }), nativeName: t("KY", { ns: "countries" }) },
    { elem: t("CF", { lng: "enUS", ns: "countries" }), nativeName: t("CF", { ns: "countries" }) },
    { elem: t("TD", { lng: "enUS", ns: "countries" }), nativeName: t("TD", { ns: "countries" }) },
    { elem: t("CL", { lng: "enUS", ns: "countries" }), nativeName: t("CL", { ns: "countries" }) },
    { elem: t("CN", { lng: "enUS", ns: "countries" }), nativeName: t("CN", { ns: "countries" }) },
    { elem: t("CX", { lng: "enUS", ns: "countries" }), nativeName: t("CX", { ns: "countries" }) },
    { elem: t("CC", { lng: "enUS", ns: "countries" }), nativeName: t("CC", { ns: "countries" }) },
    { elem: t("CO", { lng: "enUS", ns: "countries" }), nativeName: t("CO", { ns: "countries" }) },
    { elem: t("KM", { lng: "enUS", ns: "countries" }), nativeName: t("KM", { ns: "countries" }) },
    { elem: t("CG", { lng: "enUS", ns: "countries" }), nativeName: t("CG", { ns: "countries" }) },
    { elem: t("CD", { lng: "enUS", ns: "countries" }), nativeName: t("CD", { ns: "countries" }) },
    { elem: t("CK", { lng: "enUS", ns: "countries" }), nativeName: t("CK", { ns: "countries" }) },
    { elem: t("CR", { lng: "enUS", ns: "countries" }), nativeName: t("CR", { ns: "countries" }) },
    { elem: t("CI", { lng: "enUS", ns: "countries" }), nativeName: t("CI", { ns: "countries" }) },
    { elem: t("HR", { lng: "enUS", ns: "countries" }), nativeName: t("HR", { ns: "countries" }) },
    { elem: t("CU", { lng: "enUS", ns: "countries" }), nativeName: t("CU", { ns: "countries" }) },
    { elem: t("CY", { lng: "enUS", ns: "countries" }), nativeName: t("CY", { ns: "countries" }) },
    { elem: t("CZ", { lng: "enUS", ns: "countries" }), nativeName: t("CZ", { ns: "countries" }) },
    { elem: t("DK", { lng: "enUS", ns: "countries" }), nativeName: t("DK", { ns: "countries" }) },
    { elem: t("DJ", { lng: "enUS", ns: "countries" }), nativeName: t("DJ", { ns: "countries" }) },
    { elem: t("DM", { lng: "enUS", ns: "countries" }), nativeName: t("DM", { ns: "countries" }) },
    { elem: t("DO", { lng: "enUS", ns: "countries" }), nativeName: t("DO", { ns: "countries" }) },
    { elem: t("EC", { lng: "enUS", ns: "countries" }), nativeName: t("EC", { ns: "countries" }) },
    { elem: t("EG", { lng: "enUS", ns: "countries" }), nativeName: t("EG", { ns: "countries" }) },
    { elem: t("SV", { lng: "enUS", ns: "countries" }), nativeName: t("SV", { ns: "countries" }) },
    { elem: t("GQ", { lng: "enUS", ns: "countries" }), nativeName: t("GQ", { ns: "countries" }) },
    { elem: t("ER", { lng: "enUS", ns: "countries" }), nativeName: t("ER", { ns: "countries" }) },
    { elem: t("EE", { lng: "enUS", ns: "countries" }), nativeName: t("EE", { ns: "countries" }) },
    { elem: t("ET", { lng: "enUS", ns: "countries" }), nativeName: t("ET", { ns: "countries" }) },
    { elem: t("FK", { lng: "enUS", ns: "countries" }), nativeName: t("FK", { ns: "countries" }) },
    { elem: t("FO", { lng: "enUS", ns: "countries" }), nativeName: t("FO", { ns: "countries" }) },
    { elem: t("FJ", { lng: "enUS", ns: "countries" }), nativeName: t("FJ", { ns: "countries" }) },
    { elem: t("FI", { lng: "enUS", ns: "countries" }), nativeName: t("FI", { ns: "countries" }) },
    { elem: t("FR", { lng: "enUS", ns: "countries" }), nativeName: t("FR", { ns: "countries" }) },
    { elem: t("GF", { lng: "enUS", ns: "countries" }), nativeName: t("GF", { ns: "countries" }) },
    { elem: t("PF", { lng: "enUS", ns: "countries" }), nativeName: t("PF", { ns: "countries" }) },
    { elem: t("TF", { lng: "enUS", ns: "countries" }), nativeName: t("TF", { ns: "countries" }) },
    { elem: t("GA", { lng: "enUS", ns: "countries" }), nativeName: t("GA", { ns: "countries" }) },
    { elem: t("GM", { lng: "enUS", ns: "countries" }), nativeName: t("GM", { ns: "countries" }) },
    { elem: t("GE", { lng: "enUS", ns: "countries" }), nativeName: t("GE", { ns: "countries" }) },
    { elem: t("DE", { lng: "enUS", ns: "countries" }), nativeName: t("DE", { ns: "countries" }) },
    { elem: t("GH", { lng: "enUS", ns: "countries" }), nativeName: t("GH", { ns: "countries" }) },
    { elem: t("GI", { lng: "enUS", ns: "countries" }), nativeName: t("GI", { ns: "countries" }) },
    { elem: t("GR", { lng: "enUS", ns: "countries" }), nativeName: t("GR", { ns: "countries" }) },
    { elem: t("GL", { lng: "enUS", ns: "countries" }), nativeName: t("GL", { ns: "countries" }) },
    { elem: t("GD", { lng: "enUS", ns: "countries" }), nativeName: t("GD", { ns: "countries" }) },
    { elem: t("GP", { lng: "enUS", ns: "countries" }), nativeName: t("GP", { ns: "countries" }) },
    { elem: t("GU", { lng: "enUS", ns: "countries" }), nativeName: t("GU", { ns: "countries" }) },
    { elem: t("GT", { lng: "enUS", ns: "countries" }), nativeName: t("GT", { ns: "countries" }) },
    { elem: t("GG", { lng: "enUS", ns: "countries" }), nativeName: t("GG", { ns: "countries" }) },
    { elem: t("GN", { lng: "enUS", ns: "countries" }), nativeName: t("GN", { ns: "countries" }) },
    { elem: t("GW", { lng: "enUS", ns: "countries" }), nativeName: t("GW", { ns: "countries" }) },
    { elem: t("GY", { lng: "enUS", ns: "countries" }), nativeName: t("GY", { ns: "countries" }) },
    { elem: t("HT", { lng: "enUS", ns: "countries" }), nativeName: t("HT", { ns: "countries" }) },
    { elem: t("HM", { lng: "enUS", ns: "countries" }), nativeName: t("HM", { ns: "countries" }) },
    { elem: t("VA", { lng: "enUS", ns: "countries" }), nativeName: t("VA", { ns: "countries" }) },
    { elem: t("HN", { lng: "enUS", ns: "countries" }), nativeName: t("HN", { ns: "countries" }) },
    { elem: t("HK", { lng: "enUS", ns: "countries" }), nativeName: t("HK", { ns: "countries" }) },
    { elem: t("HU", { lng: "enUS", ns: "countries" }), nativeName: t("HU", { ns: "countries" }) },
    { elem: t("IS", { lng: "enUS", ns: "countries" }), nativeName: t("IS", { ns: "countries" }) },
    { elem: t("IN", { lng: "enUS", ns: "countries" }), nativeName: t("IN", { ns: "countries" }) },
    { elem: t("ID", { lng: "enUS", ns: "countries" }), nativeName: t("ID", { ns: "countries" }) },
    { elem: t("IR", { lng: "enUS", ns: "countries" }), nativeName: t("IR", { ns: "countries" }) },
    { elem: t("IQ", { lng: "enUS", ns: "countries" }), nativeName: t("IQ", { ns: "countries" }) },
    { elem: t("IE", { lng: "enUS", ns: "countries" }), nativeName: t("IE", { ns: "countries" }) },
    { elem: t("IM", { lng: "enUS", ns: "countries" }), nativeName: t("IM", { ns: "countries" }) },
    { elem: t("IL", { lng: "enUS", ns: "countries" }), nativeName: t("IL", { ns: "countries" }) },
    { elem: t("IT", { lng: "enUS", ns: "countries" }), nativeName: t("IT", { ns: "countries" }) },
    { elem: t("JM", { lng: "enUS", ns: "countries" }), nativeName: t("JM", { ns: "countries" }) },
    { elem: t("JP", { lng: "enUS", ns: "countries" }), nativeName: t("JP", { ns: "countries" }) },
    { elem: t("JE", { lng: "enUS", ns: "countries" }), nativeName: t("JE", { ns: "countries" }) },
    { elem: t("JO", { lng: "enUS", ns: "countries" }), nativeName: t("JO", { ns: "countries" }) },
    { elem: t("KZ", { lng: "enUS", ns: "countries" }), nativeName: t("KZ", { ns: "countries" }) },
    { elem: t("KE", { lng: "enUS", ns: "countries" }), nativeName: t("KE", { ns: "countries" }) },
    { elem: t("KI", { lng: "enUS", ns: "countries" }), nativeName: t("KI", { ns: "countries" }) },
    { elem: t("KP", { lng: "enUS", ns: "countries" }), nativeName: t("KP", { ns: "countries" }) },
    { elem: t("KR", { lng: "enUS", ns: "countries" }), nativeName: t("KR", { ns: "countries" }) },
    { elem: t("KW", { lng: "enUS", ns: "countries" }), nativeName: t("KW", { ns: "countries" }) },
    { elem: t("KG", { lng: "enUS", ns: "countries" }), nativeName: t("KG", { ns: "countries" }) },
    { elem: t("LA", { lng: "enUS", ns: "countries" }), nativeName: t("LA", { ns: "countries" }) },
    { elem: t("LV", { lng: "enUS", ns: "countries" }), nativeName: t("LV", { ns: "countries" }) },
    { elem: t("LB", { lng: "enUS", ns: "countries" }), nativeName: t("LB", { ns: "countries" }) },
    { elem: t("LS", { lng: "enUS", ns: "countries" }), nativeName: t("LS", { ns: "countries" }) },
    { elem: t("LR", { lng: "enUS", ns: "countries" }), nativeName: t("LR", { ns: "countries" }) },
    { elem: t("LY", { lng: "enUS", ns: "countries" }), nativeName: t("LY", { ns: "countries" }) },
    { elem: t("LI", { lng: "enUS", ns: "countries" }), nativeName: t("LI", { ns: "countries" }) },
    { elem: t("LT", { lng: "enUS", ns: "countries" }), nativeName: t("LT", { ns: "countries" }) },
    { elem: t("LU", { lng: "enUS", ns: "countries" }), nativeName: t("LU", { ns: "countries" }) },
    { elem: t("MO", { lng: "enUS", ns: "countries" }), nativeName: t("MO", { ns: "countries" }) },
    { elem: t("MK", { lng: "enUS", ns: "countries" }), nativeName: t("MK", { ns: "countries" }) },
    { elem: t("MG", { lng: "enUS", ns: "countries" }), nativeName: t("MG", { ns: "countries" }) },
    { elem: t("MW", { lng: "enUS", ns: "countries" }), nativeName: t("MW", { ns: "countries" }) },
    { elem: t("MY", { lng: "enUS", ns: "countries" }), nativeName: t("MY", { ns: "countries" }) },
    { elem: t("MV", { lng: "enUS", ns: "countries" }), nativeName: t("MV", { ns: "countries" }) },
    { elem: t("ML", { lng: "enUS", ns: "countries" }), nativeName: t("ML", { ns: "countries" }) },
    { elem: t("MT", { lng: "enUS", ns: "countries" }), nativeName: t("MT", { ns: "countries" }) },
    { elem: t("MH", { lng: "enUS", ns: "countries" }), nativeName: t("MH", { ns: "countries" }) },
    { elem: t("MQ", { lng: "enUS", ns: "countries" }), nativeName: t("MQ", { ns: "countries" }) },
    { elem: t("MR", { lng: "enUS", ns: "countries" }), nativeName: t("MR", { ns: "countries" }) },
    { elem: t("MU", { lng: "enUS", ns: "countries" }), nativeName: t("MU", { ns: "countries" }) },
    { elem: t("YT", { lng: "enUS", ns: "countries" }), nativeName: t("YT", { ns: "countries" }) },
    { elem: t("MX", { lng: "enUS", ns: "countries" }), nativeName: t("MX", { ns: "countries" }) },
    { elem: t("FM", { lng: "enUS", ns: "countries" }), nativeName: t("FM", { ns: "countries" }) },
    { elem: t("MD", { lng: "enUS", ns: "countries" }), nativeName: t("MD", { ns: "countries" }) },
    { elem: t("MC", { lng: "enUS", ns: "countries" }), nativeName: t("MC", { ns: "countries" }) },
    { elem: t("MN", { lng: "enUS", ns: "countries" }), nativeName: t("MN", { ns: "countries" }) },
    { elem: t("MS", { lng: "enUS", ns: "countries" }), nativeName: t("MS", { ns: "countries" }) },
    { elem: t("MA", { lng: "enUS", ns: "countries" }), nativeName: t("MA", { ns: "countries" }) },
    { elem: t("MZ", { lng: "enUS", ns: "countries" }), nativeName: t("MZ", { ns: "countries" }) },
    { elem: t("MM", { lng: "enUS", ns: "countries" }), nativeName: t("MM", { ns: "countries" }) },
    { elem: t("NA", { lng: "enUS", ns: "countries" }), nativeName: t("NA", { ns: "countries" }) },
    { elem: t("NR", { lng: "enUS", ns: "countries" }), nativeName: t("NR", { ns: "countries" }) },
    { elem: t("NP", { lng: "enUS", ns: "countries" }), nativeName: t("NP", { ns: "countries" }) },
    { elem: t("NL", { lng: "enUS", ns: "countries" }), nativeName: t("NL", { ns: "countries" }) },
    { elem: t("AN", { lng: "enUS", ns: "countries" }), nativeName: t("AN", { ns: "countries" }) },
    { elem: t("NC", { lng: "enUS", ns: "countries" }), nativeName: t("NC", { ns: "countries" }) },
    { elem: t("NZ", { lng: "enUS", ns: "countries" }), nativeName: t("NZ", { ns: "countries" }) },
    { elem: t("NI", { lng: "enUS", ns: "countries" }), nativeName: t("NI", { ns: "countries" }) },
    { elem: t("NE", { lng: "enUS", ns: "countries" }), nativeName: t("NE", { ns: "countries" }) },
    { elem: t("NG", { lng: "enUS", ns: "countries" }), nativeName: t("NG", { ns: "countries" }) },
    { elem: t("NU", { lng: "enUS", ns: "countries" }), nativeName: t("NU", { ns: "countries" }) },
    { elem: t("NF", { lng: "enUS", ns: "countries" }), nativeName: t("NF", { ns: "countries" }) },
    { elem: t("MP", { lng: "enUS", ns: "countries" }), nativeName: t("MP", { ns: "countries" }) },
    { elem: t("NO", { lng: "enUS", ns: "countries" }), nativeName: t("NO", { ns: "countries" }) },
    { elem: t("OM", { lng: "enUS", ns: "countries" }), nativeName: t("OM", { ns: "countries" }) },
    { elem: t("PK", { lng: "enUS", ns: "countries" }), nativeName: t("PK", { ns: "countries" }) },
    { elem: t("PW", { lng: "enUS", ns: "countries" }), nativeName: t("PW", { ns: "countries" }) },
    { elem: t("PS", { lng: "enUS", ns: "countries" }), nativeName: t("PS", { ns: "countries" }) },
    { elem: t("PA", { lng: "enUS", ns: "countries" }), nativeName: t("PA", { ns: "countries" }) },
    { elem: t("PG", { lng: "enUS", ns: "countries" }), nativeName: t("PG", { ns: "countries" }) },
    { elem: t("PY", { lng: "enUS", ns: "countries" }), nativeName: t("PY", { ns: "countries" }) },
    { elem: t("PE", { lng: "enUS", ns: "countries" }), nativeName: t("PE", { ns: "countries" }) },
    { elem: t("PH", { lng: "enUS", ns: "countries" }), nativeName: t("PH", { ns: "countries" }) },
    { elem: t("PN", { lng: "enUS", ns: "countries" }), nativeName: t("PN", { ns: "countries" }) },
    { elem: t("PL", { lng: "enUS", ns: "countries" }), nativeName: t("PL", { ns: "countries" }) },
    { elem: t("PT", { lng: "enUS", ns: "countries" }), nativeName: t("PT", { ns: "countries" }) },
    { elem: t("PR", { lng: "enUS", ns: "countries" }), nativeName: t("PR", { ns: "countries" }) },
    { elem: t("QA", { lng: "enUS", ns: "countries" }), nativeName: t("QA", { ns: "countries" }) },
    { elem: t("RE", { lng: "enUS", ns: "countries" }), nativeName: t("RE", { ns: "countries" }) },
    { elem: t("RO", { lng: "enUS", ns: "countries" }), nativeName: t("RO", { ns: "countries" }) },
    { elem: t("RU", { lng: "enUS", ns: "countries" }), nativeName: t("RU", { ns: "countries" }) },
    { elem: t("RW", { lng: "enUS", ns: "countries" }), nativeName: t("RW", { ns: "countries" }) },
    { elem: t("SH", { lng: "enUS", ns: "countries" }), nativeName: t("SH", { ns: "countries" }) },
    { elem: t("KN", { lng: "enUS", ns: "countries" }), nativeName: t("KN", { ns: "countries" }) },
    { elem: t("LC", { lng: "enUS", ns: "countries" }), nativeName: t("LC", { ns: "countries" }) },
    { elem: t("PM", { lng: "enUS", ns: "countries" }), nativeName: t("PM", { ns: "countries" }) },
    { elem: t("VC", { lng: "enUS", ns: "countries" }), nativeName: t("VC", { ns: "countries" }) },
    { elem: t("WS", { lng: "enUS", ns: "countries" }), nativeName: t("WS", { ns: "countries" }) },
    { elem: t("SM", { lng: "enUS", ns: "countries" }), nativeName: t("SM", { ns: "countries" }) },
    { elem: t("ST", { lng: "enUS", ns: "countries" }), nativeName: t("ST", { ns: "countries" }) },
    { elem: t("SA", { lng: "enUS", ns: "countries" }), nativeName: t("SA", { ns: "countries" }) },
    { elem: t("SN", { lng: "enUS", ns: "countries" }), nativeName: t("SN", { ns: "countries" }) },
    { elem: t("CS", { lng: "enUS", ns: "countries" }), nativeName: t("CS", { ns: "countries" }) },
    { elem: t("SC", { lng: "enUS", ns: "countries" }), nativeName: t("SC", { ns: "countries" }) },
    { elem: t("SL", { lng: "enUS", ns: "countries" }), nativeName: t("SL", { ns: "countries" }) },
    { elem: t("SG", { lng: "enUS", ns: "countries" }), nativeName: t("SG", { ns: "countries" }) },
    { elem: t("SK", { lng: "enUS", ns: "countries" }), nativeName: t("SK", { ns: "countries" }) },
    { elem: t("SI", { lng: "enUS", ns: "countries" }), nativeName: t("SI", { ns: "countries" }) },
    { elem: t("SB", { lng: "enUS", ns: "countries" }), nativeName: t("SB", { ns: "countries" }) },
    { elem: t("SO", { lng: "enUS", ns: "countries" }), nativeName: t("SO", { ns: "countries" }) },
    { elem: t("ZA", { lng: "enUS", ns: "countries" }), nativeName: t("ZA", { ns: "countries" }) },
    { elem: t("GS", { lng: "enUS", ns: "countries" }), nativeName: t("GS", { ns: "countries" }) },
    { elem: t("ES", { lng: "enUS", ns: "countries" }), nativeName: t("ES", { ns: "countries" }) },
    { elem: t("LK", { lng: "enUS", ns: "countries" }), nativeName: t("LK", { ns: "countries" }) },
    { elem: t("SD", { lng: "enUS", ns: "countries" }), nativeName: t("SD", { ns: "countries" }) },
    { elem: t("SR", { lng: "enUS", ns: "countries" }), nativeName: t("SR", { ns: "countries" }) },
    { elem: t("SJ", { lng: "enUS", ns: "countries" }), nativeName: t("SJ", { ns: "countries" }) },
    { elem: t("SZ", { lng: "enUS", ns: "countries" }), nativeName: t("SZ", { ns: "countries" }) },
    { elem: t("SE", { lng: "enUS", ns: "countries" }), nativeName: t("SE", { ns: "countries" }) },
    { elem: t("CH", { lng: "enUS", ns: "countries" }), nativeName: t("CH", { ns: "countries" }) },
    { elem: t("SY", { lng: "enUS", ns: "countries" }), nativeName: t("SY", { ns: "countries" }) },
    { elem: t("TW", { lng: "enUS", ns: "countries" }), nativeName: t("TW", { ns: "countries" }) },
    { elem: t("TJ", { lng: "enUS", ns: "countries" }), nativeName: t("TJ", { ns: "countries" }) },
    { elem: t("JZ", { lng: "enUS", ns: "countries" }), nativeName: t("JZ", { ns: "countries" }) },
    { elem: t("TH", { lng: "enUS", ns: "countries" }), nativeName: t("TH", { ns: "countries" }) },
    { elem: t("TL", { lng: "enUS", ns: "countries" }), nativeName: t("TL", { ns: "countries" }) },
    { elem: t("TG", { lng: "enUS", ns: "countries" }), nativeName: t("TG", { ns: "countries" }) },
    { elem: t("TK", { lng: "enUS", ns: "countries" }), nativeName: t("TK", { ns: "countries" }) },
    { elem: t("TO", { lng: "enUS", ns: "countries" }), nativeName: t("TO", { ns: "countries" }) },
    { elem: t("TT", { lng: "enUS", ns: "countries" }), nativeName: t("TT", { ns: "countries" }) },
    { elem: t("TN", { lng: "enUS", ns: "countries" }), nativeName: t("TN", { ns: "countries" }) },
    { elem: t("TR", { lng: "enUS", ns: "countries" }), nativeName: t("TR", { ns: "countries" }) },
    { elem: t("TM", { lng: "enUS", ns: "countries" }), nativeName: t("TM", { ns: "countries" }) },
    { elem: t("TC", { lng: "enUS", ns: "countries" }), nativeName: t("TC", { ns: "countries" }) },
    { elem: t("TV", { lng: "enUS", ns: "countries" }), nativeName: t("TV", { ns: "countries" }) },
    { elem: t("UG", { lng: "enUS", ns: "countries" }), nativeName: t("UG", { ns: "countries" }) },
    { elem: t("UA", { lng: "enUS", ns: "countries" }), nativeName: t("UA", { ns: "countries" }) },
    { elem: t("AE", { lng: "enUS", ns: "countries" }), nativeName: t("AE", { ns: "countries" }) },
    { elem: t("GB", { lng: "enUS", ns: "countries" }), nativeName: t("GB", { ns: "countries" }) },
    { elem: t("US", { lng: "enUS", ns: "countries" }), nativeName: t("US", { ns: "countries" }) },
    { elem: t("UM", { lng: "enUS", ns: "countries" }), nativeName: t("UM", { ns: "countries" }) },
    { elem: t("UY", { lng: "enUS", ns: "countries" }), nativeName: t("UY", { ns: "countries" }) },
    { elem: t("UZ", { lng: "enUS", ns: "countries" }), nativeName: t("UZ", { ns: "countries" }) },
    { elem: t("VU", { lng: "enUS", ns: "countries" }), nativeName: t("VU", { ns: "countries" }) },
    { elem: t("VE", { lng: "enUS", ns: "countries" }), nativeName: t("VE", { ns: "countries" }) },
    { elem: t("VN", { lng: "enUS", ns: "countries" }), nativeName: t("VN", { ns: "countries" }) },
    { elem: t("VG", { lng: "enUS", ns: "countries" }), nativeName: t("VG", { ns: "countries" }) },
    { elem: t("VI", { lng: "enUS", ns: "countries" }), nativeName: t("VI", { ns: "countries" }) },
    { elem: t("WF", { lng: "enUS", ns: "countries" }), nativeName: t("WF", { ns: "countries" }) },
    { elem: t("EH", { lng: "enUS", ns: "countries" }), nativeName: t("EH", { ns: "countries" }) },
    { elem: t("YE", { lng: "enUS", ns: "countries" }), nativeName: t("YE", { ns: "countries" }) },
    { elem: t("ZM", { lng: "enUS", ns: "countries" }), nativeName: t("ZM", { ns: "countries" }) },
    { elem: t("ZW", { lng: "enUS", ns: "countries" }), nativeName: t("ZW", { ns: "countries" }) },
  ];

  const districts = [
    {
      elem: t("centralAndWestern", { lng: "enUS", ns: "districts" }),
      nativeName: t("centralAndWestern", { ns: "districts" }),
    },
    { elem: t("eastern", { lng: "enUS", ns: "districts" }), nativeName: t("eastern", { ns: "districts" }) },
    { elem: t("islands", { lng: "enUS", ns: "districts" }), nativeName: t("islands", { ns: "districts" }) },
    { elem: t("kowloonCity", { lng: "enUS", ns: "districts" }), nativeName: t("kowloonCity", { ns: "districts" }) },
    { elem: t("kwaiTsing", { lng: "enUS", ns: "districts" }), nativeName: t("kwaiTsing", { ns: "districts" }) },
    { elem: t("kwunTong", { lng: "enUS", ns: "districts" }), nativeName: t("kwunTong", { ns: "districts" }) },
    { elem: t("north", { lng: "enUS", ns: "districts" }), nativeName: t("north", { ns: "districts" }) },
    { elem: t("saiKung", { lng: "enUS", ns: "districts" }), nativeName: t("saiKung", { ns: "districts" }) },
    { elem: t("shaTin", { lng: "enUS", ns: "districts" }), nativeName: t("shaTin", { ns: "districts" }) },
    { elem: t("shamShuiPo", { lng: "enUS", ns: "districts" }), nativeName: t("shamShuiPo", { ns: "districts" }) },
    { elem: t("southern", { lng: "enUS", ns: "districts" }), nativeName: t("southern", { ns: "districts" }) },
    { elem: t("taiPo", { lng: "enUS", ns: "districts" }), nativeName: t("taiPo", { ns: "districts" }) },
    { elem: t("tsuenWan", { lng: "enUS", ns: "districts" }), nativeName: t("tsuenWan", { ns: "districts" }) },
    { elem: t("tuenMun", { lng: "enUS", ns: "districts" }), nativeName: t("tuenMun", { ns: "districts" }) },
    { elem: t("wanChai", { lng: "enUS", ns: "districts" }), nativeName: t("wanChai", { ns: "districts" }) },
    { elem: t("wongTaiSin", { lng: "enUS", ns: "districts" }), nativeName: t("wongTaiSin", { ns: "districts" }) },
    { elem: t("yauTsimMong", { lng: "enUS", ns: "districts" }), nativeName: t("yauTsimMong", { ns: "districts" }) },
    { elem: t("yuenLong", { lng: "enUS", ns: "districts" }), nativeName: t("yuenLong", { ns: "districts" }) },
    { elem: t("others", { lng: "enUS", ns: "districts" }), nativeName: t("others", { ns: "districts" }) },
  ];

  return (
    <>
      {question.mandatory === true ? (
        <FormControl fullWidth error={errors[question.id] && true}>
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            rules={{ required: t("forms.required") }}
            defaultValue=""
            render={({ field }) => (
              <RoundSelect {...field} fullWidth size="small" error={errors[question.id] && true}>
                {dropdownQuestions === SelectEnum.JOBINDUSTRY &&
                  jobOptions.map((jobOption) => {
                    return (
                      <MenuItem key={jobOption.elem} value={jobOption.elem}>
                        {jobOption.nativeName}
                      </MenuItem>
                    );
                  })}
                {dropdownQuestions === SelectEnum.NATIONALITY &&
                  countryOptions.map((countryOption) => {
                    return (
                      <MenuItem key={countryOption.elem} value={countryOption.elem}>
                        {countryOption.nativeName}
                      </MenuItem>
                    );
                  })}
                {dropdownQuestions === SelectEnum.POB &&
                  countryOptions.map((countryOption) => {
                    return (
                      <MenuItem key={countryOption.elem} value={countryOption.elem}>
                        {countryOption.nativeName}
                      </MenuItem>
                    );
                  })}
                {dropdownQuestions === SelectEnum.DISTRICT &&
                  districts.map((district) => {
                    return (
                      <MenuItem key={district.elem} value={district.elem}>
                        {district.nativeName}
                      </MenuItem>
                    );
                  })}
                {dropdownQuestions === SelectEnum.CUSTOM &&
                  dropdownQuestionsCustom.map((custom) => {
                    return (
                      <MenuItem key={custom.enUS} value={custom.enUS}>
                        {i18n.resolvedLanguage === "zhHK" ? custom.zhHK : i18n.resolvedLanguage === "enUS" ? custom.enUS : null}
                      </MenuItem>
                    );
                  })}
              </RoundSelect>
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      ) : (
        <FormControl fullWidth error={errors[question.id] && true}>
          <QuestionComponent
            fieldQuestionZhHk={question.fieldQuestion.zhHK}
            fieldQuestionEnUs={question.fieldQuestion.enUS}
            mandatory={question.mandatory}
            descriptionZhHk={question.description?.zhHK}
            descriptionEnUs={question.description?.enUS}
            hyperlink={question.hyperlink}
            error={errors[question.id] && true}
          />
          <Controller
            name={`${question.id}` as const}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <RoundSelect {...field} fullWidth size="small" error={errors[question.id] && true}>
                {dropdownQuestions === SelectEnum.JOBINDUSTRY &&
                  jobOptions.map((jobOption) => {
                    return (
                      <MenuItem key={jobOption.elem} value={jobOption.elem}>
                        {jobOption.nativeName}
                      </MenuItem>
                    );
                  })}
                {dropdownQuestions === SelectEnum.NATIONALITY &&
                  countryOptions.map((jobOption) => {
                    return (
                      <MenuItem key={jobOption.elem} value={jobOption.elem}>
                        {jobOption.nativeName}
                      </MenuItem>
                    );
                  })}
                {dropdownQuestions === SelectEnum.POB &&
                  countryOptions.map((jobOption) => {
                    return (
                      <MenuItem key={jobOption.elem} value={jobOption.elem}>
                        {jobOption.nativeName}
                      </MenuItem>
                    );
                  })}
                {dropdownQuestions === SelectEnum.DISTRICT &&
                  districts.map((district) => {
                    return (
                      <MenuItem key={district.elem} value={district.elem}>
                        {district.nativeName}
                      </MenuItem>
                    );
                  })}
                {dropdownQuestions === SelectEnum.CUSTOM &&
                  dropdownQuestionsCustom.map((custom) => {
                    return (
                      <MenuItem key={custom.enUS} value={custom.enUS}>
                        {i18n.resolvedLanguage === LanguageEnum.ZH_HK ? custom.zhHK : i18n.resolvedLanguage === LanguageEnum.EN_US ? custom.enUS : null}
                      </MenuItem>
                    );
                  })}
              </RoundSelect>
            )}
          />
          <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
        </FormControl>
      )}
    </>
  );
}

export default Dropdown;
