import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import applicationListStatusEnUs from "./enUS/applicationListStatus.json";
import applicationListStatusZhHK from "./zhHK/applicationListStatus.json";
import commonEnUs from "./enUS/common.json";
import commonZhHk from "./zhHK/common.json";
import countriesEnUs from "./enUS/countries.json";
import countriesZhHk from "./zhHK/countries.json";
import districtsEnUs from "./enUS/districts.json";
import districtsZhHk from "./zhHK/districts.json";
import jobsEnUs from "./enUS/jobs.json";
import jobsZhHk from "./zhHK/jobs.json";
import translationEnUs from "./enUS/translation.json";
import translationZhHk from "./zhHK/translation.json";

// the translations
const resources = {
  enUS: {
    applicationListStatus: applicationListStatusEnUs,
    common: commonEnUs,
    countries: countriesEnUs,
    districts: districtsEnUs,
    jobs: jobsEnUs,
    translation: translationEnUs,
  },
  zhHK: {
    applicationListStatus: applicationListStatusZhHK,
    common: commonZhHk,
    countries: countriesZhHk,
    districts: districtsZhHk,
    jobs: jobsZhHk,
    translation: translationZhHk,
  },
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    resources,
    fallbackLng: "enUS",
    // lng: "enUS",
    // debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
