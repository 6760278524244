export function adminVerifiedSuccess() {
  return {
    type: "@@auth/ADMIN_VERIFIED_SUCCESS" as const,
  };
}

export function studentLoginSuccess(user: number, token: string) {
  return {
    type: "@@auth/STUDENT_LOGIN_SUCCESS" as const,
    user,
    token,
  };
}

export function studentAutoLoginSuccess(userId: number) {
  return {
    type: "@@auth/STUDENT_AUTO_LOGIN_SUCCESS" as const,
    userId,
  };
}

export function studentAutoLoginFail() {
  return {
    type: "@@auth/STUDENT_AUTO_LOGIN_FAIL" as const,
  };
}

export function studentLogoutSuccess() {
  return {
    type: "@@auth/STUDENT_LOGOUT_SUCCESS" as const,
  };
}

export type AuthActions = ReturnType<
  typeof adminVerifiedSuccess | typeof studentAutoLoginSuccess | typeof studentAutoLoginFail | typeof studentLoginSuccess | typeof studentLogoutSuccess
>;
