import React, { Fragment, useEffect, useState } from "react";
import { Divider, TableContainer, Table, TableBody, TableRow, TableCell, Grid, Breadcrumbs, Link, Typography, Checkbox, Box, useMediaQuery, Theme } from "@mui/material";
import { withStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { push } from "redux-first-history";
import moment from "moment";
import { useParams } from "react-router";
import i18n from "../../../i18n/config";
import { getApplicationListById } from "../../../api/applicationList";
import { ApplicationList } from "../../../interfaces/applicationList";
import { findSubmissionDetailsByStageIdAndUserId, downloadSubmissionFiles } from "../../../api/submission";
import { SubmissionDetail, SubmissionQuestion } from "../../../interfaces/submission";
import { User } from "../../../interfaces/user";
import { FieldEnum, FormContentElemEnum } from "../../../models/fieldsEnum";
import { LanguageEnum } from "../../../models/languageEnum";
import { buildLink } from "../../../api/interceptors";
import formatBytes from "../../utils/formatFileSize";
import { useAppDispatch } from "../../../redux/hooks";

const tableStyle = {
  marginLeft: 2,
  width: 1000,
  effect: "Drop shadow",
  borderRadius: 2,
  marginTop: "10px",
  marginBottom: "20px",
};
const tableCellStyle1 = {
  border: "2px solid #E4E7ED",
  color: "#9A9DA4",
  width: "40% !important",
  minWidth: "40% !important",
  fontSize: 14,
  height: "auto !important",
  overflowX: "auto",
};
const tableCellStyle2 = {
  border: "2px solid #E4E7ED",
  color: "#606266",
  fontSize: 14,
  fontWeight: 600,
  height: "20px !important",
  maxWidth: "60% !important",
};
const title = {
  marginLeft: 2,
  fontSize: 20,
  color: "#303133",
  marginBottom: 2,
  fontWeight: 500,
};

const title2 = {
  marginLeft: "16px",
  marginTop: "20px",
  marginBottom: "4px",
  fontSize: 20,
  color: "#606266",
  fontWeight: 600,
};

const description1 = {
  marginBottom: "20px",
  marginLeft: "16px",
  color: "#606266",
  fontSize: 16,
  fontWeight: 500,
};

function ViewApplicantDetails() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const lastSchool = sessionStorage.getItem("lastSchool");
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  const applicationForm = t("view.applicationForm");
  const applicationFormDes = t("view.applicationFormDes");

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [applicationListData, setApplicationListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  const [submissionDetailList, setSubmissionDetailList] = useState<SubmissionDetail[]>([]);
  const [userDetails, setUserDetails] = useState<User>({
    createdAt: "",
    email: "",
    firstNameEnUs: "",
    firstNameZhHk: "",
    id: 0,
    lastNameEnUs: "",
    lastNameZhHk: "",
    phoneNumber: "",
    countryCode: "",
    password: "",
    personalDocumentId: "",
    personalDocumentType: "",
    enrolledCms: false,
    updatedAt: "",
  });
  const [submissionId, setSubmissionId] = useState<number>(-1);
  const [submissionFormId, setSubmissionFormId] = useState<string>("");

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setApplicationListData(result.data);
    }
  }

  async function fetchApplicantDetailList(listId: number, stageId: number, userId: number) {
    const result = await findSubmissionDetailsByStageIdAndUserId(listId, stageId, userId);
    if (result.success) {
      setSubmissionDetailList(result.data.submissionDetails);
      setUserDetails(result.data.user);
      setSubmissionId(result.data.id);
      setSubmissionFormId(result.data.submissionId);
    }
  }

  async function fetchFile(name: string) {
    if (params && params.applicationListId && params.formId) {
      const applicationListId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      const result = await downloadSubmissionFiles(applicationListId, formId, submissionId, name);
      return result;
    }
    return "";
  }

  useEffect(() => {
    const fetching = async () => {
      if (params && params.applicationListId && params.formId && params.applicantId) {
        const applicationListId = parseInt(params.applicationListId);
        const formId = parseInt(params.formId);
        const applicantId = parseInt(params.applicantId);
        await fetchApplicationList(applicationListId);
        await fetchApplicantDetailList(applicationListId, formId, applicantId);
      }
      setIsLoading(false);
    };
    fetching();
  }, [params]);

  const StyledTableCell = withStyles(() => ({
    root: {
      height: 30,
      padding: "0px 16px",
      paddingTop: "6px",
      paddingBottom: "6px",
    },
  }))(TableCell);

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId) {
      const listId = parseInt(params.applicationListId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/summary`));
    }
  }

  function handleClick3(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}`));
    }
  }

  function getI18nStr(en: string, zh: string) {
    return i18n.resolvedLanguage === LanguageEnum.ZH_HK && zh ? zh : en;
    // return i18n.resolvedLanguage === LanguageEnum.ZH_HK ? zh : i18n.resolvedLanguage === LanguageEnum.EN_US ? en : "";
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHK" ? applicationListData.titleZhHk : i18n.resolvedLanguage === "enUS" ? applicationListData.titleEnUs : null}
    </Link>,
    <Link underline="hover" key="3" color="inherit" href="/" onClick={handleClick3}>
      {t("view.application")}
    </Link>,
    <Typography key="3" color="primary.main">
      #{submissionFormId} {"  "}
      {/* #{`00000${userDetails.id}`.slice(-5)}{" "} */}
      {getI18nStr(
        `${getI18nStr(userDetails.firstNameEnUs, userDetails.firstNameZhHk)} ${getI18nStr(userDetails.lastNameEnUs, userDetails.lastNameZhHk)}`,
        `${getI18nStr(userDetails.lastNameEnUs, userDetails.lastNameZhHk)} ${getI18nStr(userDetails.firstNameEnUs, userDetails.firstNameZhHk)}`
      )}{" "}
      [{userDetails.personalDocumentId}]
    </Typography>,
  ];

  function displayMAS(question: SubmissionQuestion, index: number) {
    if (question.answer) {
      const ph = Object.keys(question.answer);
      // sort for form First Choice, Second Choice, Third Choice
      const clone: { [key: string]: any } = JSON.parse(JSON.stringify(Object.values(question.answer)[0]));
      const sortedKeys = Object.keys(clone)
        .sort()
        .reduce<{ [key: string]: any }>((obj, key) => {
          obj[key] = clone[key];
          return obj;
        }, {});
      const ans = Object.keys(sortedKeys).map((a) => {
        return [a, ...ph];
      });
      const option = Object.values(sortedKeys).map((_) => {
        return [_];
      });
      const noFirst = option.slice(1);

      return (
        <Fragment key={index}>
          <TableRow>
            <StyledTableCell className="tableCell" sx={tableCellStyle1} align="left" rowSpan={matches ? option.length * 2 : 1} colSpan={matches ? 1 : ans[0].length}>
              {getI18nStr(question.fieldQuestionEnUs, question.fieldQuestionZhHk) + (question.mandatory ? "*" : "")}
              {(question.descriptionEnUs || question.descriptionZhHk) && (
                <>
                  <br />
                  {getI18nStr(question.descriptionEnUs ? question.descriptionEnUs : "", question.descriptionZhHk ? question.descriptionZhHk : "")}
                </>
              )}
            </StyledTableCell>
            {matches && (
              <StyledTableCell sx={tableCellStyle2} colSpan={ans[0].length}>
                {ans[0].map(
                  (a, i) => `${i < ans[0].length - 2 ? (i === 0 ? a : `${a},`) : i < ans[0].length - 1 && i !== 0 ? `${a} and` : a} `
                  // (a, i) => (i < ans[0].length - 2 ? (i === 0 ? a : a + ",") : i < ans[0].length - 1 && i !== 0 ? a + " and" : a) + " "
                )}
              </StyledTableCell>
            )}
          </TableRow>
          {!matches && (
            <TableRow>
              <StyledTableCell sx={tableCellStyle2} colSpan={ans[0].length}>
                {ans[0].map((a, i) => `${i < ans[0].length - 2 ? (i === 0 ? a : `${a},`) : i < ans[0].length - 1 && i !== 0 ? `${a} and` : a} `)}
              </StyledTableCell>
            </TableRow>
          )}
          <TableRow>
            {option[0].map((c, index2) => (
              <StyledTableCell key={index2} sx={{ ...tableCellStyle2, fontWeight: 400 }}>
                {`${c}`}
              </StyledTableCell>
            ))}
          </TableRow>
          {noFirst.map((d, index3) => (
            <Fragment key={index3}>
              <TableRow>
                <StyledTableCell sx={tableCellStyle2} colSpan={2}>
                  {ans[index3 + 1].map((a, i) => `${i < ans[index3 + 1].length - 2 ? (i === 0 ? a : `${a},`) : i < ans[0].length - 1 && i !== 0 ? `${a} and` : a} `)}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                {d.map((e, index4) => (
                  <StyledTableCell
                    key={index4}
                    sx={{
                      ...tableCellStyle2,
                      fontWeight: 400,
                      width: `${(100.0 - 40) / d.length}% !important`,
                    }}
                    align="left"
                  >
                    {`${e}`}
                  </StyledTableCell>
                ))}
              </TableRow>
            </Fragment>
          ))}
        </Fragment>
      );
    }
    return <></>;
  }

  function displayRow(details: SubmissionDetail) {
    const questions = details.contentElem === FormContentElemEnum.SECTION ? details.sectionQuestions : [details.singleQuestion];

    return questions?.map(
      (row, index2) =>
        row &&
        ((row.fieldType === FieldEnum.MULTIPLE_ANSWER_SET && displayMAS(row, index2)) || (
          <Fragment key={index2}>
            <TableRow style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
              <StyledTableCell
                className="tableCell"
                sx={tableCellStyle1}
                align="left"
                rowSpan={
                  matches
                    ? row.answer
                      ? (row.fieldType === FieldEnum.IMAGE_UPLOAD || row.fieldType === FieldEnum.FILE_UPLOAD || row.fieldType === FieldEnum.APPLICATION_NUMBER) &&
                        Array.isArray(row.answer)
                        ? row.answer.length + 1
                        : row.fieldType === FieldEnum.MULTIPLE_ANSWER_SET
                        ? Object.keys(Object.values(row.answer)[0]).length * 2 + 1
                        : 2
                      : 2
                    : 1
                  // row.fieldType === FieldEnum.YES_NO ||
                  //   row.fieldType === FieldEnum.SHORT_ANSWER ||
                  //   row.fieldType === FieldEnum.PARAGRAPH ||
                  //   row.fieldType === FieldEnum.VALUE ||
                  //   row.fieldType === FieldEnum.SINGLE_CHOICE ||
                  //   row.fieldType === FieldEnum.MULTIPLE_CHOICES ||
                  //   row.fieldType === FieldEnum.DROPDOWN ||
                  //   row.fieldType === FieldEnum.DATEPICKER ||
                  //   row.fieldType === FieldEnum.DESCRIPTION ||
                  //   row.fieldType === FieldEnum.EMAIL ||
                  //   row.fieldType === FieldEnum.PHONE_NUMBER ||
                  //   row.fieldType === FieldEnum.CHECKBOX ||
                  //   row.fieldType === FieldEnum.STUDENT_ID ||
                  //   row.fieldType === FieldEnum.APPLY_COURSE

                  // (typeof row.answer === "object"
                  //       ? !Array.isArray(row.answer) && row.fieldType !== FieldEnum.MULTIPLE_CHOICES
                  //           ? Object.keys(Object.values(row.answer)[0]).length * 2
                  //           : row.fieldType !== FieldEnum.MULTIPLE_CHOICES
                  //           ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //             // @ts-ignore
                  //             row.answer.length === 0
                  //               ? 1
                  //               : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //                 // @ts-ignore
                  //                 row.answer.length
                  //           : 1
                  //       : 1) + 1
                  //   : 1
                }
                colSpan={matches ? 1 : 2}
              >
                {getI18nStr(row.fieldQuestionEnUs, row.fieldQuestionZhHk) + (row.mandatory ? "*" : "")}
                {(row.descriptionEnUs || row.descriptionZhHk) && (
                  <>
                    <br />
                    {getI18nStr(`${row.descriptionEnUs}`, `${row.descriptionZhHk}`)}
                  </>
                )}
              </StyledTableCell>
            </TableRow>
            {((row.fieldType === FieldEnum.FILE_UPLOAD || row.fieldType === FieldEnum.IMAGE_UPLOAD) &&
              Array.isArray(row.answer) &&
              (row.answer.length ? (
                row.answer.map((f, index3) => (
                  <TableRow key={index3} sx={{ height: 10 }} style={{ wordWrap: "break-word", whiteSpace: "normal" }}>
                    <StyledTableCell sx={tableCellStyle2} align="left">
                      {row.fieldType === FieldEnum.FILE_UPLOAD && (
                        // eslint-disable-next-line jsx-a11y/anchor-is-valid
                        <Link
                          component="button"
                          underline="always"
                          color="inherit"
                          style={{ cursor: "pointer" }}
                          onClick={async () => {
                            if (params.applicationListId && params.formId) {
                              await fetchFile(f.name);
                            }
                          }}
                        >
                          {f.name} ({formatBytes(parseInt(f.size))})
                        </Link>
                      )}
                      {row.fieldType === FieldEnum.IMAGE_UPLOAD && (
                        <Box
                          component="img"
                          sx={{
                            height: 100,
                            maxHeight: { xs: 100, md: 167 },
                            borderRadius: "10px",
                            marginTop: "5px",
                          }}
                          src={
                            params &&
                            params.applicationListId &&
                            params.formId &&
                            buildLink(
                              `/applicationList/${parseInt(params.applicationListId)}/applicationStage/${parseInt(params.formId)}/submission/${submissionId}/image/${f.name}`
                            )
                          }
                          alt="CANNOT SHOW IMAGE"
                        />
                      )}
                    </StyledTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow sx={{ height: 10 }}>
                  <StyledTableCell sx={tableCellStyle2} align="left"></StyledTableCell>
                </TableRow>
              ))) || (
              <TableRow>
                <StyledTableCell sx={tableCellStyle2} align="left" colSpan={2}>
                  {/* {row.fieldType === FieldEnum.MULTIPLE_CHOICES && Array.isArray(row.answer) && row.answer.join(", ")} */}
                  {row.fieldType === FieldEnum.MULTIPLE_CHOICES &&
                    row.answer &&
                    Object.keys(row.answer)
                      .filter((val) => {
                        if (
                          typeof row.answer === "object" &&
                          !Array.isArray(row.answer) &&
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          typeof row.answer[val] === "boolean"
                        ) {
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-ignore
                          return row.answer[val];
                        }
                        return false;
                      })
                      .join(", ")}
                  {(typeof row.answer === "boolean" && (
                    <div style={{ display: "inline-flex" }}>
                      <Checkbox sx={{ p: 0, pr: 1 }} checked={row.answer} disabled />
                      <div>{getI18nStr(row.checkboxQuestionEnUs ? row.checkboxQuestionEnUs : "", row.checkboxQuestionZhHk ? row.checkboxQuestionZhHk : "")}</div>
                    </div>
                  )) ||
                    (row.fieldType === FieldEnum.DATEPICKER && typeof row.answer === "string" && (row.answer ? moment(row.answer).format("LL") : " ")) ||
                    (row.fieldType !== FieldEnum.MULTIPLE_CHOICES && <div>{`${row.answer}`}</div>)}
                </StyledTableCell>
              </TableRow>
            )}
          </Fragment>
        ))
    );
  }

  return (
    <Grid sx={{ m: 2 }}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumarginBottom">
            {breadcrumbs}
          </Breadcrumbs>
          <Grid sx={{ my: 1, background: "white" }}>
            <Grid container sx={{ p: 2, borderRadius: "4px" }} style={{ overflowX: "auto" }} className="outterContainer">
              <Grid item xs={12} sx={title}>
                {applicationForm} #{submissionFormId}
                {/* {applicationForm} #{`00000${userDetails.id}`.slice(-5)} */}
              </Grid>
              <div style={{ width: "100%", marginBottom: "20px", marginLeft: "16px" }}>
                <Divider />
              </div>
              <div style={description1}>{applicationFormDes}</div>
              <Grid item xs={12}>
                {submissionDetailList.map((elem, index1) => (
                  <div key={index1}>
                    {(elem.sectionTitleEnUs || elem.sectionTitleZhHk) && (
                      <div style={title2}>{getI18nStr(elem.sectionTitleEnUs ? elem.sectionTitleEnUs : "", elem.sectionTitleZhHk ? elem.sectionTitleZhHk : "")}</div>
                    )}
                    {(elem.sectionDescriptionEnUs || elem.sectionDescriptionZhHk) && (
                      <div style={description1}>
                        {getI18nStr(elem.sectionDescriptionEnUs ? elem.sectionDescriptionEnUs : "", elem.sectionDescriptionZhHk ? elem.sectionDescriptionZhHk : "")}
                      </div>
                    )}
                    {elem.contentElem === FormContentElemEnum.DESCRIPTION && (
                      <div style={description1}>{getI18nStr(elem.descriptionEnUs ? elem.descriptionEnUs : "", elem.descriptionZhHk ? elem.descriptionZhHk : "")}</div>
                    )}
                    {(elem.singleQuestion || elem.sectionQuestions) && (
                      <TableContainer sx={tableStyle}>
                        <Table aria-label="caption table">
                          <TableBody>{displayRow(elem)}</TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  );
}

export default ViewApplicantDetails;
