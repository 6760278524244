import React from "react";
import { EvaluationForm, Interview, Interviewee, InterviewTimeSlot } from "../../../../interfaces/interview";
import EditTimeSlotModal from "../EditTimeSlotModal";
import TimeSlotDetailModal from "../TimeSlotDetailModal";

interface TimeSlotActionButtonsProps {
  interview: Interview;
  evaluationForm: EvaluationForm[];
  timeSlot: InterviewTimeSlot;
  title: string;
  applicationList: Interviewee[];
}

function TimeSlotActionButtons(props: TimeSlotActionButtonsProps) {
  const { timeSlot } = props;

  return (
    <>
      <TimeSlotDetailModal {...props} />
      <EditTimeSlotModal timeSlot={timeSlot} />
      {/* <Button
                variant="contained"
                size="small"
                className="buttonAction buttonActionRed"
                onClick={() => {
                    onClickDelete(1);
                }}
            >
                {t("sendMessage.delete")}
            </Button>
            <Button
                variant="contained"
                size="small"
                className="buttonAction buttonActionRed"
                onClick={() => {
                    onClickDelete(1);
                }}
            >
                {t("view.export")}
            </Button> */}
    </>
  );
}

export default TimeSlotActionButtons;
