import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Button, FormControl, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import React, { ChangeEvent, Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AnswerPointChoice, Choice, DatePoint, Interview, Value } from "../../../interfaces/interview";
import useAnswerPointFormField from "./useAnswerPointFormField";

interface AnswerPointProps {
  answerPoint: AnswerPointChoice;
  prefix: (string | number)[];
}

function AnswerPoint(props: AnswerPointProps) {
  const { answerPoint, prefix } = props;
  const { control, fields, register, append, trigger, setValue, watch, addCondition, removeCondition, pointsArrayInputPath } = useAnswerPointFormField(`${prefix.join(".")}`);

  const handleAddCondition = () => {
    if (answerPoint.type === "Date") {
      addCondition({
        type: "DATE",
        answer: { start: "", end: "" },
        points: 0,
      });
    }
    if (answerPoint.type === "Value") {
      addCondition({
        type: "VALUE",
        answer: { compare: "lt", value: { start: 0, end: 0 } },
        points: 0,
      });
    }
  };

  const handleRemoveCondition = (index: number) => () => removeCondition(index);

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          background: "rgba(64, 158, 255, 0.05)",
          borderRadius: "4px",
          marginTop: "16px",
          px: "16px",
          py: "12px",
          fontWeight: 500,
          lineHeight: "150%",
          letterSpacing: "0.15px",
          color: "#606266",
        }}
      >
        <Grid container rowSpacing={0.5} spacing={1}>
          <Grid item xs={6} sx={{ fontSize: "14px" }}>
            {answerPoint.question.position}
          </Grid>
          <Grid item xs={6} sx={{ fontSize: "14px", fontWeight: 300 }} alignItems="center" justifyContent="flex-end" display="flex">
            {answerPoint.type}
          </Grid>
          <Grid item xs={12} sx={{ fontSize: "18px" }}>
            {answerPoint.question.question}
          </Grid>
        </Grid>
      </Grid>

      {answerPoint.points.map((points, index) => (
        <Fragment key={`${answerPoint.question.position}-${answerPoint.question.question}-${points.answer}`}>
          {points.type === "CHOICE" && (
            <Grid
              item
              xs={9}
              style={{ paddingLeft: 16, paddingTop: 0, paddingBottom: 0, paddingRight: 0 }}
              sx={{
                boxSizing: "border-box",
                border: "1px solid #D8DCE6",
                borderRadius: "4px",
                marginTop: "8px",
                height: "56px",
              }}
              alignItems="center"
              display="flex"
            >
              <div style={{ paddingLeft: "8px" }}>
                <span style={{ fontWeight: 400 }}>Answer:</span>
                <span style={{ marginLeft: "8px", color: "#1989FA" }}>{points.answer}</span>
              </div>
            </Grid>
          )}
          {points.type === "DATE" && (
            <Grid item xs={9}>
              <Grid container>
                <Grid item xs={5.5}>
                  <Controller
                    name={`${pointsArrayInputPath}.${index}.answer.start` as const}
                    control={control}
                    defaultValue={points.answer.start}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="medium"
                        type="date"
                        sx={{ height: "100%" }}
                        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          field.onChange(e.target.value);
                        }}
                        // error={errors[questionField.singleQuestion.id] && true}
                        // helperText={errors[questionField.singleQuestion.id] ? required : ""}
                      />
                    )}
                  />

                  {/* {points.answer.start} */}
                </Grid>
                <Grid item xs={1} alignItems="center" justifyContent="center" display="flex">
                  <span>to</span>
                </Grid>
                <Grid item xs={5.5}>
                  <Controller
                    name={`${pointsArrayInputPath}.${index}.answer.end` as const}
                    control={control}
                    defaultValue={points.answer.end}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="medium"
                        type="date"
                        onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                          field.onChange(e.target.value);
                        }}
                        // error={errors[questionField.singleQuestion.id] && true}
                        // helperText={errors[questionField.singleQuestion.id] ? required : ""}
                      />
                    )}
                  />
                  {/* {points.answer.end} */}
                </Grid>
              </Grid>
            </Grid>
          )}
          {points.type === "VALUE" && (
            <>
              <Grid item xs={4.5}>
                <FormControl fullWidth required>
                  <InputLabel id="condition-label">Compare</InputLabel>
                  <Controller
                    name={`${pointsArrayInputPath}.${index}.answer.compare` as const}
                    control={control}
                    defaultValue="lt"
                    render={({ field }) => (
                      <Select
                        {...field}
                        sx={{ color: "#1989FA" }}
                        labelId="condition-label"
                        label="Condition"
                        fullWidth
                        // onChange={(event) => {
                        //     field.onChange(event.target.value);
                        //     // console.log("event.target.value", event.target.value);
                        //     // setDefaultValue({ ...defaultValue, condition: `${event.target.value}` });
                        // }}
                        // error={errors.condition && true}
                        // helperText={errors.condition && `${errors.condition}`}
                      >
                        <MenuItem value="lt">Less than</MenuItem>
                        <MenuItem value="le">Less than or equal to</MenuItem>
                        <MenuItem value="eq">Equals to</MenuItem>
                        <MenuItem value="ge">Greater than or equal to</MenuItem>
                        <MenuItem value="gt">Greater than</MenuItem>
                        <MenuItem value="btw">Between</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
                {/* {points.answer.compare} */}
              </Grid>

              {points.answer.compare === "btw" && (
                <Grid item xs={4.5}>
                  <Grid container>
                    <Grid item xs={5}>
                      <Controller
                        name={`${pointsArrayInputPath}.${index}.answer.value.start` as const}
                        control={control}
                        defaultValue={points.answer.value.start}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            size="medium"
                            type="number"
                            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                              if (parseInt(e.target.value) >= 0 || e.target.value === "") {
                                field.onChange(parseInt(e.target.value));
                              }
                            }}
                            // error={errors[questionField.singleQuestion.id] && true}
                            // helperText={errors[questionField.singleQuestion.id] ? required : ""}
                          />
                        )}
                      />
                      {/* {points.answer.value.start} */}
                    </Grid>
                    <Grid item xs={2} alignItems="center" justifyContent="center" display="flex">
                      <span>and</span>
                    </Grid>
                    <Grid item xs={5}>
                      {/* {points.answer.value.end} */}
                      <Controller
                        name={`${pointsArrayInputPath}.${index}.answer.value.end` as const}
                        control={control}
                        defaultValue={points.answer.value.end}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            size="medium"
                            type="number"
                            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                              if (parseInt(e.target.value) >= 0 || e.target.value === "") {
                                field.onChange(parseInt(e.target.value));
                              }
                            }}
                            // error={errors[questionField.singleQuestion.id] && true}
                            // helperText={errors[questionField.singleQuestion.id] ? required : ""}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {points.answer.compare !== "btw" && (
                <Grid item xs={4.5}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Controller
                      name={`${pointsArrayInputPath}.${index}.answer.value.start` as const}
                      control={control}
                      defaultValue={points.answer.value.start}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size="medium"
                          type="number"
                          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            if (parseInt(e.target.value) >= 0 || e.target.value === "") {
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-ignore
                              field.onChange(parseInt(e.target.value));
                            }
                          }}
                          // error={errors[questionField.singleQuestion.id] && true}
                          // helperText={errors[questionField.singleQuestion.id] ? required : ""}
                        />
                      )}
                    />
                  }
                  {/* {points.answer.value.start} */}
                </Grid>
              )}
            </>
          )}
          <Grid item xs={points.type === "DATE" || points.type === "VALUE" ? 2.25 : 3}>
            <Controller
              name={`${pointsArrayInputPath}.${index}.points` as const}
              control={control}
              defaultValue={points.points}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="medium"
                  type="number"
                  InputProps={{
                    endAdornment: <InputAdornment position="end">Points</InputAdornment>,
                  }}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                    if (parseInt(e.target.value) >= 0 || e.target.value === "") {
                      field.onChange(parseInt(e.target.value));
                    }
                  }}
                  // error={errors[questionField.singleQuestion.id] && true}
                  // helperText={errors[questionField.singleQuestion.id] ? required : ""}
                />
              )}
            />
            {/* <span>{points.points}</span> */}
            {/* <span>Points</span> */}
          </Grid>
          {points.type !== "CHOICE" && (
            <Grid item xs={0.75} alignItems="center" justifyContent="center" display="flex">
              <IconButton color="primary" size="small" onClick={handleRemoveCondition(index)}>
                <DeleteOutlineIcon />
              </IconButton>
            </Grid>
          )}
        </Fragment>
      ))}
      {(answerPoint.type === "Date" || answerPoint.type === "Value") && (
        <Grid item xs={12}>
          <Button sx={{ marginLeft: "0 !important" }} className="buttonGroupSent" variant="outlined" size="small" aria-haspopup="true" onClick={handleAddCondition}>
            <AddIcon />
            ANSWER CONDITION
          </Button>
        </Grid>
      )}
      {answerPoint.linkedQuestion.map((linkedQuestion, index) => (
        <Grid container key={`${linkedQuestion.question.position}-${linkedQuestion.question.question}`} sx={{ paddingLeft: "16px" }}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <AnswerPoint answerPoint={linkedQuestion} prefix={[...prefix, "linkedQuestion", index]} />
            </Grid>
          </Grid>
        </Grid>
      ))}
    </>
  );
}

export default AnswerPoint;
