import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Modal, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { createApplicationList } from "../../../api/applicationList";
import modalStyle from "../../utils/modalStyle";

interface Props {
  reloadData: () => Promise<void>;
}

export interface NewApplicationFormInput {
  titleEnUs: string;
  titleZhHk: string;
}

function NewApplicationButton({ reloadData }: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<NewApplicationFormInput>();
  const onSubmit: SubmitHandler<NewApplicationFormInput> = async (data) => {
    const result = await createApplicationList(data);
    if (result.success) {
      await reloadData();
      handleClose();
    }
  };

  return (
    <Box>
      <Button className="applicationFormListButton" variant="outlined" size="small" sx={{ ml: 1 }} onClick={handleOpen}>
        <span className="applicationFormListButtonText">{t("list.newApplication", { ns: "translation" })}</span>
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                <div>{t("list.newApplication", { ns: "translation" })}</div>
                <Button variant="text" onClick={handleClose} className="modalClearBtn">
                  <ClearIcon />
                </Button>
              </Grid>
              <Grid xs={12}>
                <Controller
                  name={"titleEnUs" as const}
                  control={control}
                  rules={{
                    required: `${t("list.duplicateButton.applicationTitleEng", { ns: "translation" })}${t("isRequired", {
                      ns: "common",
                    })}`,
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t("list.duplicateButton.applicationTitleEng", { ns: "translation" })}
                      error={errors.titleEnUs && true}
                      helperText={errors.titleEnUs && `${errors.titleEnUs.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12}>
                <Controller
                  name={"titleZhHk" as const}
                  control={control}
                  rules={{
                    required: `${t("list.duplicateButton.applicationTitleChi", { ns: "translation" })}${t("isRequired", {
                      ns: "common",
                    })}`,
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t("list.duplicateButton.applicationTitleChi", { ns: "translation" })}
                      error={errors.titleZhHk && true}
                      helperText={errors.titleZhHk && `${errors.titleZhHk.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid xs={12} display="flex" justifyContent="flex-end">
                <Button variant="text" className="modalSubmitBtn" onClick={handleClose}>
                  {t("cancel", { ns: "common" })}
                </Button>
                <Button variant="text" className="modalSubmitBtn" type="submit">
                  {t("create", { ns: "common" })}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </Box>
  );
}

export default NewApplicationButton;
