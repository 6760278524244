import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Collapse, IconButton } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import React, { ReactNode, useState } from "react";

interface TimeSlotFilterCollapseProps {
  children: ReactNode;
}

function TimeSlotFilterCollapse(props: TimeSlotFilterCollapseProps) {
  const { children } = props;
  const [open, setOpen] = useState(false);
  return (
    <>
      <Grid xs={1}>
        <IconButton onClick={() => setOpen(!open)}>{open ? <RemoveIcon /> : <AddIcon />}</IconButton>
      </Grid>
      <Grid xs={12}>
        <Collapse in={open} timeout="auto" unmountOnExit>
          {children}
        </Collapse>
      </Grid>
    </>
  );
}

export default TimeSlotFilterCollapse;
