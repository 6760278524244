import React, { ChangeEvent, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult, OnDragEndResponder } from "react-beautiful-dnd";
import { Control, Controller, FieldErrors, useFieldArray, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import AddLinkSharpIcon from "@mui/icons-material/AddLinkSharp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import { Button, Fab, FormControl, Grid, IconButton, InputLabel, Menu, MenuItem, Select, SelectChangeEvent, Switch, TextField, Theme, Tooltip, useMediaQuery } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { FieldEnum, FormContentElemEnum, SelectEnum } from "../../../models/fieldsEnum";
import linkFunctionItems from "../../../models/linkFunctionItems";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";
import CustomDropdownQuestions from "./CustomDropdownQuestions";
import MultipleAnswer from "./MultipleAnswer";
import MultipleAnswerSet from "./MultipleAnswerSet";
import MultipleChoicesQuestions from "./MultipleChoicesQuestions";
import SessionContent from "./SessionContent";
import SingleChoiceQuestions from "./SingleChoiceQuestions";
import YesNoQuestions from "./YesNoQuestions";
import "./FieldsRootComponent.css";

interface Props {
  control: Control<CreateApplicationStageFormInput, object>;
  register: UseFormRegister<CreateApplicationStageFormInput>;
  setValue: UseFormSetValue<CreateApplicationStageFormInput>;
  getValues: UseFormGetValues<CreateApplicationStageFormInput>;
  watch: UseFormWatch<CreateApplicationStageFormInput>;
  errors: FieldErrors<CreateApplicationStageFormInput>;
}

interface FieldOption {
  elem: FieldEnum;
  nativeName: string;
}

interface DropdownOption {
  elem: SelectEnum;
  nativeName: string;
}

function FieldsRootComponent({ control, register, watch, setValue, getValues, errors }: Props) {
  const { t } = useTranslation();
  const matchesSm = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));
  const dropdownQuestions: DropdownOption[] = [
    { elem: SelectEnum.NATIONALITY, nativeName: t("createForm.formContent.nationality") },
    { elem: SelectEnum.JOBINDUSTRY, nativeName: t("createForm.formContent.jobIndustry") },
    { elem: SelectEnum.POB, nativeName: t("createForm.formContent.placeOfBirth") },
    { elem: SelectEnum.DISTRICT, nativeName: t("createForm.formContent.district") },
    { elem: SelectEnum.CUSTOM, nativeName: t("createForm.formContent.custom") },
  ];
  const fieldOptions: FieldOption[] = [
    {
      elem: FieldEnum.YES_NO,
      nativeName: t("createForm.formContent.yesNoQuestions"),
    },
    {
      elem: FieldEnum.SHORT_ANSWER,
      nativeName: t("createForm.formContent.shortAnswer"),
    },
    {
      elem: FieldEnum.PARAGRAPH,
      nativeName: t("createForm.formContent.paragraph"),
    },
    {
      elem: FieldEnum.VALUE,
      nativeName: t("createForm.formContent.value"),
    },
    {
      elem: FieldEnum.IMAGE_UPLOAD,
      nativeName: t("createForm.formContent.uploadImage"),
    },
    {
      elem: FieldEnum.FILE_UPLOAD,
      nativeName: t("createForm.formContent.uploadFile"),
    },
    {
      elem: FieldEnum.SINGLE_CHOICE,
      nativeName: t("createForm.formContent.singleChoice"),
    },
    {
      elem: FieldEnum.MULTIPLE_CHOICES,
      nativeName: t("createForm.formContent.multipleChoices"),
    },
    {
      elem: FieldEnum.DROPDOWN,
      nativeName: t("createForm.formContent.dropdown"),
    },
    {
      elem: FieldEnum.DATEPICKER,
      nativeName: t("createForm.formContent.datepicker"),
    },
    {
      elem: FieldEnum.EMAIL,
      nativeName: t("createForm.formContent.eMail"),
    },
    {
      elem: FieldEnum.PHONE_NUMBER,
      nativeName: t("createForm.formContent.phoneNumber"),
    },
    {
      elem: FieldEnum.APPLICATION_NUMBER,
      nativeName: t("createForm.formContent.applicationNumber"),
    },
    {
      elem: FieldEnum.CHECKBOX,
      nativeName: t("createForm.formContent.checkbox"),
    },
    {
      elem: FieldEnum.MULTIPLE_ANSWER_SET,
      nativeName: t("createForm.formContent.multipleAnswerSet"),
    },
    {
      elem: FieldEnum.STUDENT_ID,
      nativeName: t("createForm.formContent.studentId"),
    },
    {
      elem: FieldEnum.APPLY_COURSE,
      nativeName: t("createForm.formContent.applyCourse"),
    },
  ];
  const more = t("createForm.formContent.more");
  const copy = t("createForm.formContent.copy");
  const titleDelete = t("createForm.formContent.delete");

  const { fields, append, remove, move } = useFieldArray({
    control,
    name: "fieldContent",
  });

  // Form Content Menu Popover
  const [formContentMenuAnchorEl, setFormContentMenuAnchorEl] = useState<null | HTMLElement>(null);
  const openFormContentMenu = Boolean(formContentMenuAnchorEl);
  const handleClickFormContentMenu = (event: React.MouseEvent<HTMLElement>) => {
    setFormContentMenuAnchorEl(event.currentTarget);
  };
  const handleCloseFormContentMenu = () => {
    setFormContentMenuAnchorEl(null);
  };

  // Tooltip for question extra function
  const [selectedMultipleAnswerIndex, setSelectedMultipleAnswerIndex] = useState<number>(0);
  const [extraFunction, setExtraFunction] = useState<null | HTMLElement>(null);
  const openExtraFunction = Boolean(extraFunction);
  const handleClickExtraFunction = (event: React.MouseEvent<HTMLElement>) => {
    setExtraFunction(event.currentTarget);
  };
  const handleCloseExtraFunction = () => {
    setExtraFunction(null);
  };

  // Tooltip for question linkage function
  const [selectedIndexForLinkedQuestion, setSelectedIndexForLinkedQuestion] = useState<number>(0);
  const [linkFunction, setLinkFunction] = useState<null | HTMLElement>(null);
  const openLinkFunction = Boolean(linkFunction);
  const handleClickLinkFunction = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setLinkFunction(event.currentTarget);
    setSelectedIndexForLinkedQuestion(index);
  };
  const handleCloseLinkFunction = () => {
    setLinkFunction(null);
  };

  // Tooltip for section extra function
  const [selectedSectionAnswerIndex, setSelectedSectionAnswerIndex] = useState<number>(0);
  const [sectionFunction, setSectionFunction] = useState<null | HTMLElement>(null);
  const openSectionFunction = Boolean(sectionFunction);
  const handleClickSectionFunction = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setSectionFunction(event.currentTarget);
    setSelectedSectionAnswerIndex(index);
  };
  const handleCloseSectionFunction = () => {
    setSectionFunction(null);
  };

  const appendQuestion = () => {
    setFormContentMenuAnchorEl(null);
    append([
      {
        contentElem: FormContentElemEnum.QUESTION,
        description: { zhHK: "", enUS: "", hyperlink: "" },
        section: {
          sectionTitle: {
            zhHK: "",
            enUS: "",
          },
        },
        singleQuestion: {
          fieldType: "",
          mandatory: true,
          fieldQuestion: {
            zhHK: "",
            enUS: "",
          },
          yesNoQuestions: {
            yes: {
              points: 0,
              linkedQuestion: [],
            },
            no: { points: 0, linkedQuestion: [] },
          },
          singleChoiceQuestions: [
            {
              zhHK: "",
              enUS: "",
              others: false,
              points: 0,
              linkedQuestion: [],
            },
          ],
          multipleChoiceQuestions: [
            {
              zhHK: "",
              enUS: "",
              others: false,
              points: 0,
            },
          ],
          dropdownQuestions: {
            type: "",
            custom: [
              {
                zhHK: "",
                enUS: "",
                points: 0,
                linkedQuestion: "",
              },
            ],
          },
          multipleAnswerSet: {
            answersNo: 1,
            answerSetsNo: 1,
            answers: [
              {
                zhHK: "",
                enUS: "",
                others: false,
                points: 0,
              },
            ],
            answerSets: [
              {
                placeholder: {
                  zhHK: "",
                  enUS: "",
                },
                sets: [
                  {
                    zhHK: "",
                    enUS: "",
                    others: false,
                    points: 0,
                  },
                ],
              },
            ],
          },
          relationship: "",
        },
        sectionQuestion: [],
      },
    ]);
  };

  const appendSection = () => {
    setFormContentMenuAnchorEl(null);
    append([
      {
        contentElem: FormContentElemEnum.SECTION,
        description: { zhHK: "", enUS: "", hyperlink: "" },
        section: {
          sectionTitle: {
            zhHK: "",
            enUS: "",
          },
        },
        singleQuestion: {
          fieldType: "",
          mandatory: true,
          fieldQuestion: {
            zhHK: "",
            enUS: "",
          },
          yesNoQuestions: {
            yes: {
              points: 0,
              linkedQuestion: [],
            },
            no: { points: 0, linkedQuestion: [] },
          },
          singleChoiceQuestions: [
            {
              zhHK: "",
              enUS: "",
              others: false,
              points: 0,
              linkedQuestion: [],
            },
          ],
          multipleChoiceQuestions: [
            {
              zhHK: "",
              enUS: "",
              others: false,
              points: 0,
            },
          ],
          dropdownQuestions: {
            type: "",
            custom: [
              {
                zhHK: "",
                enUS: "",
                points: 0,
                linkedQuestion: "",
              },
            ],
          },
          multipleAnswerSet: {
            answersNo: 1,
            answerSetsNo: 1,
            answers: [
              {
                zhHK: "",
                enUS: "",
                others: false,
                points: 0,
              },
            ],
            answerSets: [
              {
                placeholder: {
                  zhHK: "",
                  enUS: "",
                },
                sets: [
                  {
                    zhHK: "",
                    enUS: "",
                    others: false,
                    points: 0,
                  },
                ],
              },
            ],
          },
          relationship: "",
        },
        sectionQuestion: [],
      },
    ]);
  };

  const appendNewPage = () => {
    setFormContentMenuAnchorEl(null);
    append([
      {
        contentElem: FormContentElemEnum.NEW_PAGE,
        description: { zhHK: "", enUS: "", hyperlink: "" },
        section: {
          sectionTitle: {
            zhHK: "",
            enUS: "",
          },
        },
        singleQuestion: {
          fieldType: "",
          mandatory: true,
          fieldQuestion: {
            zhHK: "",
            enUS: "",
          },
          yesNoQuestions: {
            yes: {
              points: 0,
              linkedQuestion: [],
            },
            no: { points: 0, linkedQuestion: [] },
          },
          singleChoiceQuestions: [
            {
              zhHK: "",
              enUS: "",
              others: false,
              points: 0,
              linkedQuestion: [],
            },
          ],
          multipleChoiceQuestions: [
            {
              zhHK: "",
              enUS: "",
              others: false,
              points: 0,
            },
          ],
          dropdownQuestions: {
            type: "",
            custom: [
              {
                zhHK: "",
                enUS: "",
                points: 0,
                linkedQuestion: "",
              },
            ],
          },
          multipleAnswerSet: {
            answersNo: 1,
            answerSetsNo: 1,
            answers: [
              {
                zhHK: "",
                enUS: "",
                others: false,
                points: 0,
              },
            ],
            answerSets: [
              {
                placeholder: {
                  zhHK: "",
                  enUS: "",
                },
                sets: [
                  {
                    zhHK: "",
                    enUS: "",
                    others: false,
                    points: 0,
                  },
                ],
              },
            ],
          },
          relationship: "",
        },
        sectionQuestion: [],
      },
    ]);
  };

  const appendDescription = () => {
    setFormContentMenuAnchorEl(null);
    append([
      {
        contentElem: FormContentElemEnum.DESCRIPTION,
        description: { zhHK: "", enUS: "", hyperlink: "" },
        section: {
          sectionTitle: {
            zhHK: "",
            enUS: "",
          },
        },
        singleQuestion: {
          fieldType: "",
          mandatory: true,
          fieldQuestion: {
            zhHK: "",
            enUS: "",
          },
          yesNoQuestions: {
            yes: {
              points: 0,
              linkedQuestion: [],
            },
            no: { points: 0, linkedQuestion: [] },
          },
          singleChoiceQuestions: [
            {
              zhHK: "",
              enUS: "",
              others: false,
              points: 0,
              linkedQuestion: [],
            },
          ],
          multipleChoiceQuestions: [
            {
              zhHK: "",
              enUS: "",
              others: false,
              points: 0,
            },
          ],
          dropdownQuestions: {
            type: "",
            custom: [
              {
                zhHK: "",
                enUS: "",
                points: 0,
                linkedQuestion: "",
              },
            ],
          },
          multipleAnswerSet: {
            answersNo: 1,
            answerSetsNo: 1,
            answers: [
              {
                zhHK: "",
                enUS: "",
                others: false,
                points: 0,
              },
            ],
            answerSets: [
              {
                placeholder: {
                  zhHK: "",
                  enUS: "",
                },

                sets: [
                  {
                    zhHK: "",
                    enUS: "",
                    others: false,
                    points: 0,
                  },
                ],
              },
            ],
          },
          relationship: "",
        },
        sectionQuestion: [],
      },
    ]);
  };

  const onCopy = (index: number) => {
    const originalContent = getValues().fieldContent[index];
    if (originalContent.singleQuestion.id) {
      delete originalContent.singleQuestion.id;
    }
    append(originalContent);
  };

  const onAllowMultipleAnswers = (index: number) => {
    setExtraFunction(null);
    setValue(`fieldContent.${index}.singleQuestion.multipleAnswers`, true);
  };

  const onDisallowMultipleAnswers = (index: number) => {
    setExtraFunction(null);
    setValue(`fieldContent.${index}.singleQuestion.multipleAnswers`, false);
  };

  const onAddSessionDescription = (index: number) => {
    setSectionFunction(null);
    watch([`fieldContent.${index}.section.sectionDescription`]);
    setValue(`fieldContent.${index}.section.sectionDescription`, { enUS: "", zhHK: "" });
  };

  const onRemoveSessionDescription = (index: number) => {
    setSectionFunction(null);
    watch([`fieldContent.${index}.section.sectionDescription`]);
    setValue(`fieldContent.${index}.section.sectionDescription`, undefined);
  };

  const onAddHyperlink = (index: number) => {
    setSectionFunction(null);
    watch([`fieldContent.${index}.section.hyperlink`]);
    setValue(`fieldContent.${index}.section.hyperlink`, "");
  };

  const onRemoveHyperlink = (index: number) => {
    setSectionFunction(null);
    watch([`fieldContent.${index}.section.hyperlink`]);
    setValue(`fieldContent.${index}.section.hyperlink`, undefined);
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  const onDragEnd: OnDragEndResponder = (result: DropResult) => {
    // console.log(result);

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // did not move anywhere - can bail early
    // if (source.droppableId === destination.droppableId && source.index === destination.index) {
    //     return;
    // }

    if (result.type === "OUTER") {
      move(source.index, destination.index);
    }

    // if (result.type === "INNER") {
    //     move(source.index, destination.index);
    // }
  };

  // console.log("errors =", errors);

  return (
    <div style={{ paddingBottom: 200 }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="fieldContent" type="OUTER">
          {(droppableProvided) => (
            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              {fields.map((form, index) => {
                return (
                  <Draggable key={form.id} draggableId={form.id} index={index}>
                    {(provided, snapshot) => (
                      <Grid
                        container
                        alignItems="center"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        className={
                          getValues().fieldContent[index].contentElem === FormContentElemEnum.NEW_PAGE && snapshot.isDragging
                            ? "newPageContainer"
                            : getValues().fieldContent[index].contentElem === FormContentElemEnum.SECTION && snapshot.isDragging
                            ? "activeFormContentSectionContainer"
                            : getValues().fieldContent[index].contentElem === FormContentElemEnum.DESCRIPTION && snapshot.isDragging
                            ? "activeFormContentDescriptionContainer"
                            : getValues().fieldContent[index].contentElem === FormContentElemEnum.QUESTION && snapshot.isDragging
                            ? "activeFormContentQuestionContainer"
                            : getValues().fieldContent[index].contentElem === FormContentElemEnum.NEW_PAGE && !snapshot.isDragging
                            ? "newPageContainer"
                            : getValues().fieldContent[index].contentElem === FormContentElemEnum.SECTION && !snapshot.isDragging
                            ? "inactiveFormContentSessionContainer"
                            : getValues().fieldContent[index].contentElem === FormContentElemEnum.DESCRIPTION && !snapshot.isDragging
                            ? "inactiveFormContentDescriptionContainer"
                            : getValues().fieldContent[index].contentElem === FormContentElemEnum.QUESTION && !snapshot.isDragging
                            ? "inactiveFormContentQuestionContainer"
                            : ""
                        }
                      >
                        {getValues().fieldContent[index].contentElem === FormContentElemEnum.NEW_PAGE && (
                          <Grid container alignItems="center">
                            <Grid item display="flex" justifyContent="center" alignItems="center" xs={11} md={11.5}>
                              {t("createForm.formContent.newPage")}
                            </Grid>
                            <Grid
                              item
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              xs={1}
                              md={0.5}
                              onClick={() => {
                                onRemove(index);
                              }}
                              className="newPageClearBtn"
                            >
                              <ClearSharpIcon />
                            </Grid>
                          </Grid>
                        )}

                        {getValues().fieldContent[index].contentElem === FormContentElemEnum.DESCRIPTION && (
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={2.5} sm={1} md={0.7} lg={0.5} display="flex" justifyContent="center" alignItems="center">
                              <div className="descriptionCircleNoContainer">{index + 1}</div>
                            </Grid>
                            <Grid item xs={6.5} sm={9} md={9.8} lg={10.5}>
                              <Controller
                                name={`fieldContent.${index}.description.enUS` as const}
                                control={control}
                                rules={{
                                  required: t("forms.required"),
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    label={t("createForm.formContent.descriptionEng")}
                                    error={errors.fieldContent?.[index]?.description?.enUS !== undefined && true}
                                    helperText={errors.fieldContent?.[index]?.description?.enUS !== undefined && `${errors.fieldContent?.[index]?.description?.enUS?.message}`}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={3} sm={2} md={1.5} lg={1}>
                              <Tooltip title="Copy">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    onCopy(index);
                                  }}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title="Delete">
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    onRemove(index);
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={2.5} sm={1} md={0.7} lg={0.5}></Grid>
                            <Grid item xs={6.5} sm={9} md={9.8} lg={10.5}>
                              <Controller
                                name={`fieldContent.${index}.description.zhHK` as const}
                                control={control}
                                rules={{
                                  required: t("forms.required"),
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    label={t("createForm.formContent.descriptionCh")}
                                    error={errors.fieldContent?.[index]?.description?.zhHK !== undefined && true}
                                    helperText={errors.fieldContent?.[index]?.description?.zhHK !== undefined && `${errors.fieldContent?.[index]?.description?.zhHK?.message}`}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={3} sm={2} md={1.5} lg={1}></Grid>
                            <Grid item xs={2.5} sm={1} md={0.7} lg={0.5}></Grid>
                            <Grid item xs={6.5} sm={9} md={9.8} lg={10.5}>
                              <Controller
                                name={`fieldContent.${index}.description.hyperlink` as const}
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    label={t("createForm.formContent.hyperlink")}
                                    error={errors.fieldContent?.[index]?.description?.hyperlink !== undefined && true}
                                    helperText={
                                      errors.fieldContent?.[index]?.description?.hyperlink !== undefined && `${errors.fieldContent?.[index]?.description?.hyperlink?.message}`
                                    }
                                  />
                                )}
                              />
                            </Grid>
                            <Grid item xs={3} sm={2} md={1.5} lg={1}></Grid>
                          </Grid>
                        )}

                        {getValues().fieldContent[index].contentElem === FormContentElemEnum.SECTION && (
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12}>
                              <div className="dragIndicatorContainer">
                                <DragIndicatorIcon className="dragIndicatorIcon" />
                              </div>
                            </Grid>
                            <Grid item xs={2.5} sm={1} md={0.8} lg={0.5} xl={0.5} display="flex" justifyContent="center" alignItems="center">
                              <div className="sessionCircleNoContainer">{index + 1}</div>
                            </Grid>

                            <Grid item xs={9.5} sm={5.5} md={4.7} lg={5} xl={5.2}>
                              <Controller
                                name={`fieldContent.${index}.section.sectionTitle.enUS` as const}
                                control={control}
                                rules={{
                                  required: t("forms.required"),
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    label={t("createForm.formContent.sectionTitleEng")}
                                    variant="filled"
                                    error={errors.fieldContent?.[index]?.section?.sectionTitle?.enUS !== undefined && true}
                                    helperText={
                                      errors.fieldContent?.[index]?.section?.sectionTitle?.enUS !== undefined &&
                                      `${errors.fieldContent?.[index]?.section?.sectionTitle?.enUS?.message}`
                                    }
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} sm={5.5} md={4.7} lg={5} xl={5.2}>
                              <Controller
                                name={`fieldContent.${index}.section.sectionTitle.zhHK` as const}
                                control={control}
                                rules={{
                                  required: t("forms.required"),
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    label={t("createForm.formContent.sectionTitleChinese")}
                                    variant="filled"
                                    error={errors.fieldContent?.[index]?.section?.sectionTitle?.zhHK !== undefined && true}
                                    helperText={
                                      errors.fieldContent?.[index]?.section?.sectionTitle?.zhHK !== undefined &&
                                      `${errors.fieldContent?.[index]?.section?.sectionTitle?.zhHK?.message}`
                                    }
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={1.8} lg={1.5} xl={1.1} display="flex" justifyContent="center">
                              <Tooltip title={more}>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  id="sectionMoreButton"
                                  aria-controls={openSectionFunction ? "sectionMoreMenu" : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openSectionFunction ? "true" : undefined}
                                  onClick={(e) => {
                                    handleClickSectionFunction(e, index);
                                  }}
                                >
                                  <MoreVertRoundedIcon />
                                </IconButton>
                              </Tooltip>
                              <Menu
                                id="sectionMoreMenu"
                                anchorEl={sectionFunction}
                                open={openSectionFunction}
                                onClose={handleCloseSectionFunction}
                                MenuListProps={{
                                  "aria-labelledby": "sectionMoreButton",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    if (
                                      getValues().fieldContent !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex] !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex].section !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex].section.sectionDescription === undefined
                                    ) {
                                      onAddSessionDescription(selectedSectionAnswerIndex);
                                    } else if (
                                      getValues().fieldContent !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex] !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex].section !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex].section.sectionDescription !== undefined
                                    ) {
                                      onRemoveSessionDescription(selectedSectionAnswerIndex);
                                    }
                                  }}
                                >
                                  {t("createForm.formContent.addDescription")}
                                  {getValues().fieldContent !== undefined &&
                                  getValues().fieldContent[selectedSectionAnswerIndex] !== undefined &&
                                  getValues().fieldContent[selectedSectionAnswerIndex].section !== undefined &&
                                  getValues().fieldContent[selectedSectionAnswerIndex].section.sectionDescription ? (
                                    <CheckCircleIcon className="tickIcon" fontSize="small" />
                                  ) : null}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    if (
                                      getValues().fieldContent !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex] !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex].section !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex].section.hyperlink === undefined
                                    ) {
                                      onAddHyperlink(selectedSectionAnswerIndex);
                                    } else if (
                                      getValues().fieldContent !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex] !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex].section !== undefined &&
                                      getValues().fieldContent[selectedSectionAnswerIndex].section.hyperlink !== undefined
                                    ) {
                                      onRemoveHyperlink(selectedSectionAnswerIndex);
                                    }
                                  }}
                                >
                                  {t("createForm.formContent.addHyperlink")}
                                  {getValues().fieldContent !== undefined &&
                                  getValues().fieldContent[selectedSectionAnswerIndex] !== undefined &&
                                  getValues().fieldContent[selectedSectionAnswerIndex].section !== undefined &&
                                  getValues().fieldContent[selectedSectionAnswerIndex].section.hyperlink !== undefined ? (
                                    <CheckCircleIcon className="tickIcon" fontSize="small" />
                                  ) : null}
                                </MenuItem>
                              </Menu>
                              <Tooltip title={copy}>
                                <IconButton color="primary" size="small">
                                  <ContentCopyIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={titleDelete}>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    onRemove(index);
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>

                            {getValues().fieldContent[index].section.sectionDescription?.enUS !== undefined && (
                              <Grid item xs={12} md={12}>
                                <Controller
                                  name={`fieldContent.${index}.section.sectionDescription.enUS` as const}
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => <TextField {...field} fullWidth label={t("createForm.formContent.descriptionTitleEng")} variant="filled" />}
                                />
                              </Grid>
                            )}

                            {getValues().fieldContent[index].section.sectionDescription?.zhHK !== undefined && (
                              <Grid item xs={12} md={12}>
                                <Controller
                                  name={`fieldContent.${index}.section.sectionDescription.zhHK` as const}
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => <TextField {...field} fullWidth label={t("createForm.formContent.descriptionTitleCh")} variant="filled" />}
                                />
                              </Grid>
                            )}

                            {getValues().fieldContent[index].section?.hyperlink !== undefined && (
                              <Grid item xs={12} md={12}>
                                <Controller
                                  name={`fieldContent.${index}.section.hyperlink` as const}
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => <TextField {...field} fullWidth label="Hyperlink" variant="filled" />}
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} md={12}>
                              <SessionContent
                                key={form.id}
                                nestIndex={index}
                                {...{
                                  control,
                                  register,
                                  watch,
                                  setValue,
                                  getValues,
                                  errors,
                                }}
                              />
                            </Grid>
                          </Grid>
                        )}

                        {getValues().fieldContent[index].contentElem === FormContentElemEnum.QUESTION && (
                          <Grid container alignItems="center" spacing={2}>
                            <Grid item xs={12} md={12}>
                              <div className="dragIndicatorContainer">
                                <DragIndicatorIcon className="dragIndicatorIcon" />
                              </div>
                            </Grid>

                            <Grid item xs={2.5} sm={1} md={0.7} lg={0.5} xl={0.5} display="flex" justifyContent="center" alignItems="center">
                              <div className="circleNoContainer">{index + 1}</div>
                            </Grid>

                            <Grid item xs={9.5} sm={5.5} md={2.9} lg={3.8} xl={4.4}>
                              <FormControl fullWidth required>
                                <InputLabel id="questionTypeLabel">{t("createForm.formContent.questionType")}</InputLabel>
                                <Controller
                                  name={`fieldContent.${index}.singleQuestion.fieldType` as const}
                                  control={control}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <Select
                                      {...field}
                                      label="Question Type"
                                      id="questionTypeLabel"
                                      variant="outlined"
                                      onChange={(event: SelectChangeEvent<string>) => {
                                        watch([`fieldContent.${index}.singleQuestion.fieldType`]);
                                        field.onChange(event.target.value);
                                        setValue(`fieldContent.${index}.singleQuestion`, {
                                          fieldType: event.target.value,
                                          mandatory: true,
                                          fieldQuestion: {
                                            zhHK: "",
                                            enUS: "",
                                          },
                                          yesNoQuestions: {
                                            yes: {
                                              points: 0,
                                              linkedQuestion: [],
                                            },
                                            no: { points: 0, linkedQuestion: [] },
                                          },
                                          singleChoiceQuestions: [
                                            {
                                              zhHK: "",
                                              enUS: "",
                                              others: false,
                                              points: 0,
                                              linkedQuestion: [],
                                            },
                                          ],
                                          multipleChoiceQuestions: [
                                            {
                                              zhHK: "",
                                              enUS: "",
                                              others: false,
                                              points: 0,
                                            },
                                          ],
                                          dropdownQuestions: {
                                            type: "",
                                            custom: [
                                              {
                                                zhHK: "",
                                                enUS: "",
                                                points: 0,
                                                linkedQuestion: "",
                                              },
                                            ],
                                          },
                                          multipleAnswerSet: {
                                            answersNo: 1,
                                            answerSetsNo: 1,
                                            answers: [
                                              {
                                                zhHK: "",
                                                enUS: "",
                                                others: false,
                                                points: 0,
                                              },
                                            ],
                                            answerSets: [
                                              {
                                                placeholder: {
                                                  zhHK: "",
                                                  enUS: "",
                                                },
                                                sets: [
                                                  {
                                                    zhHK: "",
                                                    enUS: "",
                                                    others: false,
                                                    points: 0,
                                                  },
                                                ],
                                              },
                                            ],
                                          },
                                          relationship: "",
                                        });
                                      }}
                                    >
                                      {fieldOptions.map((fieldOption) => {
                                        return (
                                          <MenuItem key={fieldOption.elem} value={fieldOption.elem} style={{ zIndex: 99999 }}>
                                            {fieldOption.nativeName}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  )}
                                />
                              </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={5.5} md={2.9} lg={3.8} xl={4.4}>
                              {getValues().fieldContent[index].singleQuestion.fieldType === FieldEnum.DROPDOWN && (
                                <FormControl fullWidth required>
                                  <InputLabel id="answerTypeLabel">{t("createForm.formContent.answerType")}</InputLabel>
                                  <Controller
                                    name={`fieldContent.${index}.singleQuestion.dropdownQuestions.type` as const}
                                    control={control}
                                    defaultValue=""
                                    render={({ field }) => (
                                      <Select
                                        {...field}
                                        label="Answer Type"
                                        id="answerTypeLabel"
                                        variant="outlined"
                                        onChange={(event: SelectChangeEvent<string>) => {
                                          watch([`fieldContent.${index}.singleQuestion.dropdownQuestions.type`]);
                                          field.onChange(event.target.value);
                                        }}
                                      >
                                        {dropdownQuestions.map((dropDownOption) => {
                                          return (
                                            <MenuItem key={dropDownOption.elem} value={dropDownOption.elem}>
                                              {dropDownOption.nativeName}
                                            </MenuItem>
                                          );
                                        })}
                                      </Select>
                                    )}
                                  />
                                </FormControl>
                              )}

                              {getValues().fieldContent[index].singleQuestion.fieldType === FieldEnum.MULTIPLE_ANSWER_SET && (
                                <Grid container spacing={2}>
                                  <Grid item xs={6} md={6}>
                                    <Controller
                                      name={`fieldContent.${index}.singleQuestion.multipleAnswerSet.answersNo` as const}
                                      control={control}
                                      rules={{
                                        required: "Number of answer is required",
                                      }}
                                      defaultValue={undefined}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          fullWidth
                                          inputProps={{
                                            type: "number",
                                          }}
                                          label={t("createForm.formContent.noOfAnswer")}
                                          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                            if (parseInt(e.target.value) >= 0) {
                                              field.onChange(parseInt(e.target.value));
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                  <Grid item xs={6} md={6}>
                                    <Controller
                                      name={`fieldContent.${index}.singleQuestion.multipleAnswerSet.answerSetsNo` as const}
                                      control={control}
                                      rules={{
                                        required: "No. of Answer Set is required",
                                      }}
                                      defaultValue={undefined}
                                      render={({ field }) => (
                                        <TextField
                                          {...field}
                                          fullWidth
                                          inputProps={{
                                            type: "number",
                                          }}
                                          label={t("createForm.formContent.noOfAnswerSet")}
                                          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                                            if (parseInt(e.target.value) >= 0) {
                                              field.onChange(parseInt(e.target.value));
                                            }
                                          }}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              )}
                            </Grid>

                            {getValues().fieldContent[index].singleQuestion.mandatory !== undefined && (
                              <Grid item xs={12} sm={6} md={3.2} lg={2.4} xl={1.8} display="flex" justifyContent={matchesSm ? "flex-end" : "center"} alignItems="center">
                                {getValues().fieldContent !== undefined &&
                                getValues().fieldContent[index] !== undefined &&
                                getValues().fieldContent[index].singleQuestion !== undefined &&
                                getValues().fieldContent[index].singleQuestion.mandatory
                                  ? t("forms.required")
                                  : "Not Required Field"}
                                <Controller
                                  name={`fieldContent.${index}.singleQuestion.mandatory` as const}
                                  control={control}
                                  defaultValue={getValues().fieldContent !== undefined && true}
                                  render={({ field }) => (
                                    <Switch
                                      {...field}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      checked={getValues().fieldContent[index].singleQuestion.mandatory}
                                      onChange={() => {
                                        watch([`fieldContent.${index}.singleQuestion.mandatory`]);
                                        setValue(`fieldContent.${index}.singleQuestion.mandatory`, !getValues().fieldContent[index].singleQuestion.mandatory);
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                            )}

                            <Grid item xs={12} sm={6} md={2.3} lg={1.5} xl={0.9} display="flex" justifyContent={matchesSm ? "flex-start" : "center"}>
                              <Tooltip title={more}>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  id="questionMoreButton"
                                  aria-controls={openExtraFunction ? "questionMoreMenu" : undefined}
                                  aria-haspopup="true"
                                  aria-expanded={openExtraFunction ? "true" : undefined}
                                  onClick={(e) => {
                                    handleClickExtraFunction(e);
                                    setSelectedMultipleAnswerIndex(index);
                                  }}
                                >
                                  <MoreVertRoundedIcon />
                                </IconButton>
                              </Tooltip>

                              <Menu
                                id="questionMoreMenu"
                                anchorEl={extraFunction}
                                open={openExtraFunction}
                                onClose={handleCloseExtraFunction}
                                MenuListProps={{
                                  "aria-labelledby": "questionMoreButton",
                                }}
                              >
                                <MenuItem
                                  onClick={() => {
                                    if (
                                      getValues().fieldContent !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.description === undefined
                                    ) {
                                      watch([`fieldContent.${selectedMultipleAnswerIndex}.singleQuestion.description`]);
                                      setValue(`fieldContent.${selectedMultipleAnswerIndex}.singleQuestion.description`, { enUS: "", zhHK: "" });
                                      setExtraFunction(null);
                                    } else if (
                                      getValues().fieldContent !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.description !== undefined
                                    ) {
                                      watch([`fieldContent.${selectedMultipleAnswerIndex}.singleQuestion.description`]);
                                      setValue(`fieldContent.${selectedMultipleAnswerIndex}.singleQuestion.description`, undefined);
                                      setExtraFunction(null);
                                    }
                                  }}
                                >
                                  {t("createForm.formContent.addDescription")}
                                  {getValues().fieldContent !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.description !== undefined ? (
                                    <CheckCircleIcon className="tickIcon" fontSize="small" />
                                  ) : null}
                                </MenuItem>
                                <MenuItem
                                  onClick={() => {
                                    if (
                                      getValues().fieldContent !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.hyperlink === undefined
                                    ) {
                                      watch([`fieldContent.${selectedMultipleAnswerIndex}.singleQuestion.hyperlink`]);
                                      setValue(`fieldContent.${selectedMultipleAnswerIndex}.singleQuestion.hyperlink`, "");
                                      setExtraFunction(null);
                                    } else if (
                                      getValues().fieldContent !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                      getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.hyperlink !== undefined
                                    ) {
                                      watch([`fieldContent.${selectedMultipleAnswerIndex}.singleQuestion.hyperlink`]);
                                      setValue(`fieldContent.${selectedMultipleAnswerIndex}.singleQuestion.hyperlink`, undefined);
                                      setExtraFunction(null);
                                    }
                                  }}
                                >
                                  {t("createForm.formContent.addHyperlink")}
                                  {getValues().fieldContent !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.hyperlink !== undefined ? (
                                    <CheckCircleIcon className="tickIcon" fontSize="small" />
                                  ) : null}
                                </MenuItem>
                                {getValues().fieldContent !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.YES_NO &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.IMAGE_UPLOAD &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.FILE_UPLOAD &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.SINGLE_CHOICE &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.DROPDOWN &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.EMAIL &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.PHONE_NUMBER &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.APPLICATION_NUMBER &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.CHECKBOX &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.DATEPICKER &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.MULTIPLE_ANSWER_SET && (
                                    <MenuItem onClick={handleCloseExtraFunction} disabled>
                                      Custom Validation
                                    </MenuItem>
                                  )}
                                {getValues().fieldContent !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.YES_NO &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.IMAGE_UPLOAD &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.FILE_UPLOAD &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.SINGLE_CHOICE &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.MULTIPLE_CHOICES &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.DROPDOWN &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.CHECKBOX &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.DATEPICKER &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType !== FieldEnum.MULTIPLE_ANSWER_SET && (
                                    <MenuItem
                                      onClick={() => {
                                        if (
                                          getValues().fieldContent !== undefined &&
                                          getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                          getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                          getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.multipleAnswers === true
                                        ) {
                                          onDisallowMultipleAnswers(selectedMultipleAnswerIndex);
                                        } else if (
                                          getValues().fieldContent !== undefined &&
                                          getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                          getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                          getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.multipleAnswers === false
                                        ) {
                                          onAllowMultipleAnswers(selectedMultipleAnswerIndex);
                                        } else {
                                          onAllowMultipleAnswers(selectedMultipleAnswerIndex);
                                        }
                                      }}
                                    >
                                      <div style={{ display: "flex" }}>
                                        <div>{t("createForm.formContent.allowMultipleAnswer")}</div>
                                        <div>
                                          {getValues().fieldContent !== undefined &&
                                          getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                          getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                          getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.multipleAnswers ? (
                                            <CheckCircleIcon className="tickIcon" fontSize="small" />
                                          ) : null}
                                        </div>
                                      </div>
                                    </MenuItem>
                                  )}
                                {getValues().fieldContent !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType === FieldEnum.DATEPICKER && (
                                    <MenuItem onClick={handleCloseExtraFunction}>Add Year</MenuItem>
                                  )}
                                {getValues().fieldContent !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType === FieldEnum.DATEPICKER && (
                                    <MenuItem onClick={handleCloseExtraFunction}>Add Time</MenuItem>
                                  )}
                                {getValues().fieldContent !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex] !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion !== undefined &&
                                  getValues().fieldContent[selectedMultipleAnswerIndex].singleQuestion.fieldType === FieldEnum.MULTIPLE_ANSWER_SET && (
                                    <MenuItem onClick={handleCloseExtraFunction}>Required to Fill All Answers</MenuItem>
                                  )}
                              </Menu>
                              <Tooltip title={copy}>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    onCopy(index);
                                  }}
                                >
                                  <ContentCopyIcon />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={titleDelete}>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => {
                                    onRemove(index);
                                  }}
                                >
                                  <DeleteOutlineIcon />
                                </IconButton>
                              </Tooltip>
                            </Grid>

                            <Grid item xs={12} sm={5.5} md={5.7}>
                              <Controller
                                name={`fieldContent.${index}.singleQuestion.fieldQuestion.enUS` as const}
                                control={control}
                                rules={{
                                  required: "Question (English) is required",
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="filled"
                                    label={t("createForm.formContent.questionEng")}
                                    error={errors.fieldContent?.[index]?.singleQuestion?.fieldQuestion?.enUS !== undefined && true}
                                    helperText={
                                      errors.fieldContent?.[index]?.singleQuestion?.fieldQuestion?.enUS !== undefined &&
                                      `${errors.fieldContent?.[index]?.singleQuestion?.fieldQuestion?.enUS?.message}`
                                    }
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} sm={5.5} md={5.7}>
                              <Controller
                                name={`fieldContent.${index}.singleQuestion.fieldQuestion.zhHK` as const}
                                control={control}
                                rules={{
                                  required: "Question (Chinese) is required",
                                }}
                                defaultValue=""
                                render={({ field }) => (
                                  <TextField
                                    {...field}
                                    fullWidth
                                    variant="filled"
                                    label={t("createForm.formContent.questionCh")}
                                    error={errors.fieldContent?.[index]?.singleQuestion?.fieldQuestion?.zhHK !== undefined && true}
                                    helperText={
                                      errors.fieldContent?.[index]?.singleQuestion?.fieldQuestion?.zhHK !== undefined &&
                                      `${errors.fieldContent?.[index]?.singleQuestion?.fieldQuestion?.zhHK?.message}`
                                    }
                                  />
                                )}
                              />
                            </Grid>

                            <Grid item xs={12} sm={1} md={0.6}>
                              <Fab
                                aria-label="link"
                                className={
                                  getValues().fieldContent[selectedIndexForLinkedQuestion] !== undefined &&
                                  getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion !== undefined &&
                                  getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion.relationship !== undefined &&
                                  getValues().fieldContent[index].singleQuestion.relationship
                                    ? "activeLinkBtnForQuestions"
                                    : "inactiveLinkBtnForQuestions"
                                }
                                size="small"
                                onClick={(e) => {
                                  handleClickLinkFunction(e, index);
                                }}
                              >
                                <AddLinkSharpIcon />
                              </Fab>
                              <Menu
                                id="basic-menu"
                                anchorEl={linkFunction}
                                open={openLinkFunction}
                                onClose={handleCloseLinkFunction}
                                MenuListProps={{
                                  "aria-labelledby": "link-button",
                                }}
                              >
                                {linkFunctionItems.map((linkFunctionItem) => (
                                  <MenuItem
                                    key={linkFunctionItem.elem}
                                    onClick={() => {
                                      if (
                                        getValues().fieldContent[selectedIndexForLinkedQuestion] !== undefined &&
                                        getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion !== undefined &&
                                        getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion.relationship !== undefined &&
                                        getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion.relationship === ""
                                      ) {
                                        setValue(`fieldContent.${selectedIndexForLinkedQuestion}.singleQuestion.relationship`, linkFunctionItem.elem);
                                      } else if (
                                        getValues().fieldContent[selectedIndexForLinkedQuestion] !== undefined &&
                                        getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion !== undefined &&
                                        getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion.relationship !== undefined &&
                                        getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion.relationship === linkFunctionItem.elem
                                      ) {
                                        setValue(`fieldContent.${selectedIndexForLinkedQuestion}.singleQuestion.relationship`, "");
                                      } else {
                                        setValue(`fieldContent.${selectedIndexForLinkedQuestion}.singleQuestion.relationship`, linkFunctionItem.elem);
                                      }
                                      handleCloseLinkFunction();
                                    }}
                                  >
                                    {linkFunctionItem.nativeName}
                                    {getValues().fieldContent[selectedIndexForLinkedQuestion] !== undefined &&
                                    getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion !== undefined &&
                                    getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion.relationship !== undefined &&
                                    getValues().fieldContent[selectedIndexForLinkedQuestion].singleQuestion.relationship === linkFunctionItem.elem ? (
                                      <CheckCircleIcon className="tickIcon" fontSize="small" />
                                    ) : (
                                      ""
                                    )}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </Grid>

                            {getValues().fieldContent[index].singleQuestion.description?.enUS !== undefined && (
                              <Grid item xs={12}>
                                <Controller
                                  name={`fieldContent.${index}.singleQuestion.description.enUS` as const}
                                  control={control}
                                  rules={{
                                    required: "Description (English) is required",
                                  }}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      fullWidth
                                      variant="filled"
                                      label="Description (English)"
                                      error={errors.fieldContent?.[index]?.singleQuestion?.description?.enUS !== undefined && true}
                                      helperText={
                                        errors.fieldContent?.[index]?.singleQuestion?.description?.enUS !== undefined &&
                                        `${errors.fieldContent?.[index]?.singleQuestion?.description?.enUS?.message}`
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            )}

                            {getValues().fieldContent?.[index]?.singleQuestion?.description?.zhHK !== undefined && (
                              <Grid item xs={12}>
                                <Controller
                                  name={`fieldContent.${index}.singleQuestion.description.zhHK` as const}
                                  control={control}
                                  rules={{
                                    required: "Description (Traditional Chinese) is required",
                                  }}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      fullWidth
                                      variant="filled"
                                      label="Description (Traditional Chinese)"
                                      error={errors.fieldContent?.[index]?.singleQuestion?.description?.zhHK !== undefined && true}
                                      helperText={
                                        errors.fieldContent?.[index]?.singleQuestion?.description?.zhHK !== undefined &&
                                        `${errors.fieldContent?.[index]?.singleQuestion?.description?.zhHK?.message}`
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            )}

                            {getValues().fieldContent[index].singleQuestion.hyperlink !== undefined && (
                              <Grid item xs={12}>
                                <Controller
                                  name={`fieldContent.${index}.singleQuestion.hyperlink` as const}
                                  control={control}
                                  rules={{
                                    required: "Hyperlink is required",
                                  }}
                                  defaultValue=""
                                  render={({ field }) => (
                                    <TextField
                                      {...field}
                                      fullWidth
                                      variant="filled"
                                      label="Hyperlink"
                                      error={errors.fieldContent?.[index]?.singleQuestion?.hyperlink !== undefined && true}
                                      helperText={
                                        errors.fieldContent?.[index]?.singleQuestion?.hyperlink !== undefined &&
                                        `${errors.fieldContent?.[index]?.singleQuestion?.hyperlink?.message}`
                                      }
                                    />
                                  )}
                                />
                              </Grid>
                            )}

                            {getValues().fieldContent[index].singleQuestion.fieldType === FieldEnum.YES_NO && (
                              <Grid item xs={12} md={12}>
                                <YesNoQuestions
                                  nestIndex={index}
                                  {...{
                                    control,
                                    register,
                                    watch,
                                    setValue,
                                    getValues,
                                    errors,
                                  }}
                                />
                              </Grid>
                            )}

                            {getValues().fieldContent[index].singleQuestion.fieldType === FieldEnum.SINGLE_CHOICE && (
                              <Grid item xs={12} md={12}>
                                <SingleChoiceQuestions
                                  nestIndex={index}
                                  {...{
                                    control,
                                    register,
                                    watch,
                                    setValue,
                                    getValues,
                                    errors,
                                  }}
                                />
                              </Grid>
                            )}

                            {getValues().fieldContent[index].singleQuestion.fieldType === FieldEnum.MULTIPLE_CHOICES && (
                              <Grid item xs={12} md={12}>
                                <MultipleChoicesQuestions
                                  nestIndex={index}
                                  {...{
                                    control,
                                    register,
                                    watch,
                                    setValue,
                                    getValues,
                                    errors,
                                  }}
                                />
                              </Grid>
                            )}

                            {getValues().fieldContent[index].singleQuestion.dropdownQuestions.type === SelectEnum.CUSTOM && (
                              <Grid item xs={12} md={12}>
                                <CustomDropdownQuestions
                                  nestIndex={index}
                                  {...{
                                    control,
                                    register,
                                    watch,
                                    setValue,
                                    getValues,
                                    errors,
                                  }}
                                />
                              </Grid>
                            )}

                            {getValues().fieldContent[index].singleQuestion.fieldType === FieldEnum.DATEPICKER && (
                              <Grid item xs={6} md={6}>
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                  <DesktopDatePicker
                                    disabled
                                    inputFormat="DD/MM/yyyy"
                                    value={new Date()}
                                    onChange={() => {
                                      const newDate = new Date();
                                      return newDate;
                                    }}
                                    renderInput={(params) => <TextField {...params} disabled fullWidth variant="standard" />}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            )}

                            {getValues().fieldContent[index].singleQuestion.fieldType === FieldEnum.CHECKBOX && (
                              <>
                                <Grid item xs={0.5} md={0.5}>
                                  <div className="checkBoxOutlineBlankIcon"></div>
                                </Grid>
                                <Grid item xs={11.5} md={11.5}>
                                  <Controller
                                    name={`fieldContent.${index}.singleQuestion.checkboxQuestions.enUS` as const}
                                    control={control}
                                    rules={{
                                      required: "English description is required",
                                    }}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} fullWidth variant="standard" label={t("createForm.formContent.textEng")} />}
                                  />
                                </Grid>

                                <Grid item xs={0.5} md={0.5} display="flex" alignItems="center"></Grid>
                                <Grid item xs={11.5} md={11.5} display="flex" alignItems="center">
                                  <Controller
                                    name={`fieldContent.${index}.singleQuestion.checkboxQuestions.zhHK` as const}
                                    control={control}
                                    rules={{
                                      required: "Chinese description is required",
                                    }}
                                    defaultValue=""
                                    render={({ field }) => <TextField {...field} fullWidth variant="standard" label={t("createForm.formContent.textCh")} />}
                                  />
                                </Grid>
                              </>
                            )}

                            {getValues().fieldContent[index].singleQuestion.fieldType === FieldEnum.MULTIPLE_ANSWER_SET && (
                              <>
                                <Grid item xs={12} md={12}>
                                  <MultipleAnswer
                                    nestIndex={index}
                                    {...{
                                      control,
                                      register,
                                      watch,
                                      setValue,
                                      getValues,
                                      errors,
                                    }}
                                  />
                                </Grid>

                                <Grid item xs={12} md={12}>
                                  <MultipleAnswerSet
                                    nestIndex={index}
                                    {...{
                                      control,
                                      register,
                                      watch,
                                      setValue,
                                      getValues,
                                      errors,
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        )}
                      </Grid>
                    )}
                  </Draggable>
                );
              })}
              {droppableProvided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Grid container alignItems="center">
        <Grid item xs={12} sx={{ py: 2 }}>
          <Button
            variant="outlined"
            color="primary"
            onClick={handleClickFormContentMenu}
            fullWidth
            id="formContentButton"
            aria-controls={openFormContentMenu ? "formContentMenu" : undefined}
            aria-haspopup="true"
            className={`addBtn ${openFormContentMenu ? "addBtnActive" : null}`}
            aria-expanded={openFormContentMenu ? "true" : undefined}
          >
            <AddIcon />
          </Button>

          <Menu
            id="formContentMenu"
            aria-labelledby="formContentButton"
            anchorEl={formContentMenuAnchorEl}
            open={openFormContentMenu}
            onClose={handleCloseFormContentMenu}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            style={{ zIndex: 99999 }}
          >
            <MenuItem dense sx={{ width: 220 }} onClick={appendSection}>
              {t("createForm.formContent.section")}
            </MenuItem>
            <MenuItem dense onClick={appendQuestion}>
              {t("createForm.formContent.question")}
            </MenuItem>
            <MenuItem dense onClick={appendDescription}>
              {t("createForm.formContent.description")}
            </MenuItem>
            <MenuItem dense onClick={appendNewPage}>
              {t("createForm.formContent.newPage")}
            </MenuItem>
          </Menu>
        </Grid>
      </Grid>
    </div>
  );
}

export default FieldsRootComponent;
