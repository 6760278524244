interface LinkFunctionItem {
  elem: string;
  nativeName: string;
}

enum FormTypeEnum {
  APPLICANT_FIRST_NAME_EN = "applicantFirstNameEn",
  APPLICANT_LAST_NAME_EN = "applicantLastNameEn",
  APPLICANT_FIRST_NAME_CH = "applicantFirstNameCh",
  APPLICANT_LAST_NAME_CH = "applicantLastNameCh",
  APPLICANT_DOCUMENT_TYPE = "applicantDocumentType",
  APPLICANT_DOCUMENT_NUMBER = "applicantDocumentNumber",
  APPLICANT_GENDER = "applicantGender",
  APPLICANT_DATE_OF_BIRTH = "applicantDateOfBirth",
  APPLICANT_PLACE_OF_BIRTH = "applicantPlaceOfBirth",
  APPLICANT_CONTACT_NUMBER = "applicantContactNumber",
  APPLICANT_ADDRESS = "applicantAddress",
  APPLICANT_NATIONALITY = "applicantNationality",
  APPLICANT_PRIMARY_CONTACT_PERSON = "applicantPrimaryContactPerson",

  // Applicant Guardian Information (Father)
  FATHER_FIRST_NAME_EN = "fatherFirstNameEn",
  FATHER_LAST_NAME_EN = "fatherLastNameEn",
  FATHER_FIRST_NAME_CH = "fatherFirstNameCh",
  FATHER_LAST_NAME_CH = "fatherLastNameCh",
  FATHER_CONTACT_NUMBER = "fatherContactNumber",
  FATHER_DAYTIME_CONTACT_NUMBER = "fatherDaytimeContactNumber",
  FATHER_EMAIL = "fatherDocumentType",

  // Applicant Guardian Information (Mother)
  MOTHER_FIRST_NAME_EN = "motherFirstNameEn",
  MOTHER_LAST_NAME_EN = "motherLastNameEn",
  MOTHER_FIRST_NAME_CH = "motherFirstNameCh",
  MOTHER_LAST_NAME_CH = "motherLastNameCh",
  MOTHER_CONTACT_NUMBER = "motherContactNumber",
  MOTHER_DAYTIME_CONTACT_NUMBER = "motherDaytimeContactNumber",
  MOTHER_EMAIL = "motherDocumentType",
}

const linkFunctionItems: LinkFunctionItem[] = [
  {
    elem: FormTypeEnum.APPLICANT_FIRST_NAME_EN,
    nativeName: "Applicant First Name in English",
  },
  {
    elem: FormTypeEnum.APPLICANT_LAST_NAME_EN,
    nativeName: "Applicant Last Name in English",
  },
  {
    elem: FormTypeEnum.APPLICANT_FIRST_NAME_CH,
    nativeName: "Applicant First Name in Chinese",
  },
  {
    elem: FormTypeEnum.APPLICANT_LAST_NAME_CH,
    nativeName: "Applicant Last Name in Chinese",
  },
  {
    elem: FormTypeEnum.APPLICANT_DOCUMENT_TYPE,
    nativeName: "Applicant Document Type",
  },
  {
    elem: FormTypeEnum.APPLICANT_DOCUMENT_NUMBER,
    nativeName: "Applicant Document Number",
  },
  {
    elem: FormTypeEnum.APPLICANT_GENDER,
    nativeName: "Applicant Gender",
  },
  {
    elem: FormTypeEnum.APPLICANT_DATE_OF_BIRTH,
    nativeName: "Applicant Date Of Birth",
  },
  {
    elem: FormTypeEnum.APPLICANT_PLACE_OF_BIRTH,
    nativeName: "Applicant Place Of Birth",
  },
  {
    elem: FormTypeEnum.APPLICANT_CONTACT_NUMBER,
    nativeName: "Applicant Contact Number",
  },
  {
    elem: FormTypeEnum.APPLICANT_ADDRESS,
    nativeName: "Applicant Address",
  },
  {
    elem: FormTypeEnum.APPLICANT_NATIONALITY,
    nativeName: "Applicant Nationality",
  },
  {
    elem: FormTypeEnum.APPLICANT_PRIMARY_CONTACT_PERSON,
    nativeName: "Applicant Primary Contact Person",
  },
  // Applicant Guardian (Father)
  {
    elem: FormTypeEnum.FATHER_FIRST_NAME_EN,
    nativeName: "Father First Name in English",
  },
  {
    elem: FormTypeEnum.FATHER_LAST_NAME_EN,
    nativeName: "Father Last Name in English",
  },
  {
    elem: FormTypeEnum.FATHER_FIRST_NAME_CH,
    nativeName: "Father First Name in Chinese",
  },
  {
    elem: FormTypeEnum.FATHER_LAST_NAME_CH,
    nativeName: "Father Last Name in Chinese",
  },
  {
    elem: FormTypeEnum.FATHER_CONTACT_NUMBER,
    nativeName: "Father Contact Number",
  },
  {
    elem: FormTypeEnum.FATHER_DAYTIME_CONTACT_NUMBER,
    nativeName: "Father Daytime Contact Number",
  },
  {
    elem: FormTypeEnum.FATHER_EMAIL,
    nativeName: "Father Email",
  },
  // Applicant Guardian (Father)
  {
    elem: FormTypeEnum.MOTHER_FIRST_NAME_EN,
    nativeName: "Mother First Name in English",
  },
  {
    elem: FormTypeEnum.MOTHER_LAST_NAME_EN,
    nativeName: "Mother Last Name in English",
  },
  {
    elem: FormTypeEnum.MOTHER_FIRST_NAME_CH,
    nativeName: "Mother First Name in Chinese",
  },
  {
    elem: FormTypeEnum.MOTHER_LAST_NAME_CH,
    nativeName: "Mother Last Name in Chinese",
  },
  {
    elem: FormTypeEnum.MOTHER_CONTACT_NUMBER,
    nativeName: "Mother Contact Number",
  },
  {
    elem: FormTypeEnum.MOTHER_DAYTIME_CONTACT_NUMBER,
    nativeName: "Mother Daytime Contact Number",
  },
  {
    elem: FormTypeEnum.MOTHER_EMAIL,
    nativeName: "Mother Email",
  },
];

export default linkFunctionItems;
