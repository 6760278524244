import { Box, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-pro";
import moment from "moment";
import React from "react";
import { Interview, Interviewee } from "../../../interfaces/interview";

interface DetailPanelContentProps {
  application: Interviewee;
}

function DetailPanelContent(props: DetailPanelContentProps) {
  const { application } = props;
  // const apiRef = useGridApiContext();

  return (
    <Box sx={{ background: "rgba(64, 158, 255, 0.05)" }}>
      <TableContainer>
        <TableHead>
          <TableRow>
            <TableCell colSpan={4}>Interview</TableCell>
            <TableCell>Interview Time</TableCell>
            <TableCell>Interview Location</TableCell>
            <TableCell>Interview Status</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {application.interviews.map((interview) => (
            <TableRow key={`${application.info[0]}-${interview.title}`} style={{ backgroundColor: "white" }}>
              <TableCell colSpan={4}>{interview.title}</TableCell>
              <TableCell>{moment(interview.time).format("YYYY/MM/DD (ddd), HH:mm")}</TableCell>
              <TableCell>{interview.location}</TableCell>
              <TableCell>{interview.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </TableContainer>
    </Box>
  );
}

export default DetailPanelContent;
