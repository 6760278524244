/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { ReactNode, useState } from "react";
import {
  Control,
  Controller,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, FormControl, FormHelperText, Grid, IconButton, styled } from "@mui/material";
import { SingleQuestion } from "./SubmissionForm";
import { SubmissionAttachment } from "../../interfaces/submissionAttachment";
import QuestionComponent from "./QuestionComponent";

interface Props {
  question: SingleQuestion;
  submissionAttachments: SubmissionAttachment[];
  deselectFiles: (id: number) => void;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
  trigger: UseFormTrigger<FieldValues>;
}

const Input = styled("input")({
  display: "none",
});

function UploadImage({ question, submissionAttachments, deselectFiles, register, errors, watch, clearErrors, setValue, getValues, control, trigger }: Props) {
  const { t } = useTranslation();

  const [images, setImages] = useState<FileList | null>(null);
  const [imageError, setImageError] = useState(false);
  const [originalFiles, setOriginalFiles] = useState<SubmissionAttachment[]>(submissionAttachments);

  // This function will be triggered when the "Remove This Image" button is clicked
  const removeSelectedImage = (index: number) => {
    if (images !== null) {
      const imagesArray: File[] = Array.from(images);
      imagesArray.splice(index, 1);
      const dt = new DataTransfer();
      for (const image of imagesArray) {
        dt.items.add(image);
      }
      const newFileList = dt.files;
      if (newFileList.length <= 0) {
        setImages(newFileList);
        setValue(question.id, undefined);
      } else {
        setImages(newFileList);
        setValue(question.id, newFileList);
      }
      trigger(question.id);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const fileSize = event.target.files[0].size;
      const maxSize = 2 * 1024 * 1024; // 2MB

      if (fileSize > maxSize) {
        setImageError(true);
        return;
      }
      setImageError(false);
      setImages(event.target.files);
      setValue(question.id, event.target.files);
      trigger(question.id);
    }
  };

  return (
    <div>
      <Box>
        {originalFiles.length !== 0 &&
          originalFiles.map((originalFile: SubmissionAttachment) => {
            return (
              <Grid container className="imgContainer" key={originalFile.id}>
                <Grid item xs={10}>
                  {originalFile.name}
                </Grid>
                <Grid item xs={2} display="flex" justifyContent="flex-end">
                  <IconButton
                    onClick={() => {
                      const modifiedFiles = originalFiles.filter((file) => file.id !== originalFile.id);
                      setOriginalFiles(modifiedFiles);
                      deselectFiles(originalFile.id);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            );
          })}
      </Box>
      {images !== null &&
        Array.from(images) &&
        Array.from(images).length > 0 &&
        Array.from(images).map((image: File, index: number) => {
          return (
            <Grid container className="imgContainer" key={image.lastModified}>
              <Grid item xs={10}>
                {image.name}
              </Grid>
              <Grid item xs={2} display="flex" justifyContent="flex-end">
                <IconButton
                  onClick={() => {
                    removeSelectedImage(index);
                  }}
                >
                  <ClearIcon />
                </IconButton>
              </Grid>
            </Grid>
          );
        })}

      <div className="upload-btn-wrapper">
        {question.mandatory === true ? (
          <FormControl error={errors[question.id] && true} fullWidth>
            <QuestionComponent
              fieldQuestionZhHk={question.fieldQuestion.zhHK}
              fieldQuestionEnUs={question.fieldQuestion.enUS}
              mandatory={question.mandatory}
              descriptionZhHk={question.description?.zhHK}
              descriptionEnUs={question.description?.enUS}
              hyperlink={question.hyperlink}
              error={errors[question.id] && true}
            />
            <label htmlFor={question.id}>
              <Controller
                control={control}
                name={`${question.id}` as const}
                rules={{
                  required: t("forms.required"),
                  validate: {
                    validateImageSize: (files) => {
                      if (files && files[0]) {
                        const fileSize = files[0].size;
                        const maxSize = 2 * 1024 * 1024; // 2MB

                        if (fileSize > maxSize) {
                          setImageError(true);
                          return false;
                        }
                      }
                      setImageError(false);
                      return true;
                    },
                  },
                }}
                render={({ field: { ref, onChange } }) => (
                  <div>
                    <Input
                      accept="image/*"
                      id={question.id}
                      name={question.id}
                      multiple
                      type="file"
                      onChange={(e) => {
                        trigger("validateImageSize"); // Trigger image size validation
                        handleChange(e);
                      }}
                      ref={ref}
                    />
                    {imageError && <span className="error">{t("forms.imageSizeOverLimit")}</span>}
                  </div>
                )}
              />

              <Button variant="outlined" className={errors[question.id] ? "submissionFormUploadBtnWithError" : "submissionFormUploadBtn"} component="span">
                {t("forms.uploadImage")}
              </Button>
            </label>
            <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
          </FormControl>
        ) : (
          <FormControl error={errors[question.id] && true} fullWidth>
            <QuestionComponent
              fieldQuestionZhHk={question.fieldQuestion.zhHK}
              fieldQuestionEnUs={question.fieldQuestion.enUS}
              mandatory={question.mandatory}
              descriptionZhHk={question.description?.zhHK}
              descriptionEnUs={question.description?.enUS}
              hyperlink={question.hyperlink}
              error={errors[question.id] && true}
            />
            <label htmlFor={question.id}>
              <Controller
                control={control}
                name={`${question.id}` as const}
                rules={{
                  validate: {
                    validateImageSize: (files) => {
                      if (files && files[0]) {
                        const fileSize = files[0].size;
                        const maxSize = 2 * 1024 * 1024; // 2MB

                        if (fileSize > maxSize) {
                          setImageError(t("forms.imageSizeOverLimit"));
                          return false;
                        }
                      }
                      setImageError(t(""));
                      return true;
                    },
                  },
                }}
                render={({ field: { ref } }) => (
                  <div>
                    <Input
                      accept="image/*"
                      id={question.id}
                      name={question.id}
                      multiple
                      type="file"
                      onChange={(e) => {
                        handleChange(e);
                        trigger("validateImageSize"); // Trigger image size validation
                      }}
                      ref={ref}
                    />
                    {imageError && <span className="error">{imageError}</span>}
                  </div>
                )}
              />

              <Button variant="outlined" className="submissionFormUploadBtn" component="span">
                {t("forms.uploadImage")}
              </Button>
            </label>
            <FormHelperText>{errors[question.id] && (errors[question.id]?.message as ReactNode)}</FormHelperText>
          </FormControl>
        )}
      </div>
    </div>
  );
}

export default UploadImage;
