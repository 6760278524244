import { Button } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { push } from "redux-first-history";
import { Interview } from "../../../../interfaces/interview";
import { useAppDispatch } from "../../../../redux/hooks";
import InterviewSettingModal from "../InterviewSettingModal";

interface ApplicationActionButtonsProps {
  interview: Interview;
}

function ApplicationActionButtons(props: ApplicationActionButtonsProps) {
  const { interview } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const onClickViewDetails = (id: number) => {
    console.log("onClickViewDetails");
    dispatch(push("interview/edit"));
  };

  const onClickDelete = (id: number) => {
    console.log("onClickDelete");
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        className="buttonAction buttonActionGreen"
        onClick={() => {
          onClickViewDetails(1);
        }}
      >
        {t("view.view")}
      </Button>
      <InterviewSettingModal
        interview={interview}
        button={
          <Button variant="contained" size="small" className="buttonAction buttonActionDarkBlue">
            {t("view.edit")}
          </Button>
        }
      />
      {/* <Button
                variant="contained"
                size="small"
                className="buttonAction buttonActionRed"
                onClick={() => {
                    onClickDelete(1);
                }}
            >
                {t("sendMessage.delete")}
            </Button> */}
    </>
  );
}

export default ApplicationActionButtons;
