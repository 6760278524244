/* eslint-disable react/no-array-index-key */
import React, { ChangeEvent, useEffect, useState } from "react";
import { Control, UseFormRegister, UseFormSetValue, UseFormGetValues, UseFormWatch, FieldErrors, Controller } from "react-hook-form";
import { Box, Chip, Button, FormControl, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Popover, Select, SelectChangeEvent, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";
import LinkIcon from "@mui/icons-material/Link";
import { useTranslation } from "react-i18next";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";
import { FormContentElemEnum } from "../../../models/fieldsEnum";

interface Props {
  nestIndex: number;
  control: Control<CreateApplicationStageFormInput, object>;
  register: UseFormRegister<CreateApplicationStageFormInput>;
  setValue: UseFormSetValue<CreateApplicationStageFormInput>;
  getValues: UseFormGetValues<CreateApplicationStageFormInput>;
  watch: UseFormWatch<CreateApplicationStageFormInput>;
  errors: FieldErrors<CreateApplicationStageFormInput>;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

function YesNoQuestions({ nestIndex, control, watch, setValue, getValues }: Props) {
  const { t } = useTranslation();
  // Functions for point calculation system of Yes
  const [pointForYes, setPointForYes] = useState<null | HTMLElement>(null);

  const handleClickForYes = (event: React.MouseEvent<HTMLElement>) => {
    setPointForYes(event.currentTarget);
  };
  const handleCloseForYes = () => {
    setPointForYes(null);
  };
  const openForYes = Boolean(pointForYes);
  const yesId = openForYes ? "simple-popover" : undefined;

  // Functions for point calculation system of No
  const [pointForNo, setPointForNo] = useState<null | HTMLElement>(null);
  const handleClickForNo = (event: React.MouseEvent<HTMLElement>) => {
    setPointForNo(event.currentTarget);
  };
  const handleCloseForNo = () => {
    setPointForNo(null);
  };
  const openForNo = Boolean(pointForNo);
  const noId = openForNo ? "simple-popover" : undefined;

  const [selected, setSelected] = useState<number[]>([]);
  function setSelectedLinkedQuestion() {
    const linkedQuestions: number[] = [];
    const fieldContents = getValues().fieldContent;
    if (fieldContents.length !== 0) {
      for (const fieldContent of fieldContents) {
        const yesLinkedQuestions = fieldContent.singleQuestion.yesNoQuestions.yes.linkedQuestion;
        const noLinkedQuestions = fieldContent.singleQuestion.yesNoQuestions.no.linkedQuestion;
        for (const yesLinkedQuestion of yesLinkedQuestions) {
          linkedQuestions.push(parseInt(yesLinkedQuestion));
        }
        for (const noLinkedQuestion of noLinkedQuestions) {
          linkedQuestions.push(parseInt(noLinkedQuestion));
        }

        const { singleChoiceQuestions } = fieldContent.singleQuestion;
        for (const singleChoiceQuestion of singleChoiceQuestions) {
          const singleChoiceLinkedQuestions = singleChoiceQuestion.linkedQuestion;
          for (const singleChoiceLinkedQuestion of singleChoiceLinkedQuestions) {
            linkedQuestions.push(parseInt(singleChoiceLinkedQuestion));
          }
        }
      }
    }
    setSelected(linkedQuestions);
  }

  // Functions for linkage of yes
  const [openYesLinked, setOpenYesLinked] = useState(false);
  const handleOpenYesLinked = () => {
    setSelectedLinkedQuestion();
    setOpenYesLinked(true);
  };
  const handleCloseYesLinked = () => {
    setSelectedLinkedQuestion();
    setOpenYesLinked(false);
  };
  const yesLinked = getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.yes.linkedQuestion;
  const parsedYes = yesLinked.map((value) => {
    return value + 1;
  });
  const [yesLinkedQuestion, setYesLinkedQuestion] = useState<string[]>([]);
  const handleChangeForYes = (event: SelectChangeEvent<typeof yesLinkedQuestion>) => {
    const {
      target: { value },
    } = event;
    setYesLinkedQuestion(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
    setValue(`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.yes.linkedQuestion`, typeof value === "string" ? value.split(",") : value);
    setSelectedLinkedQuestion();
  };

  // Functions for linkage of no
  const [openNoLinked, setOpenNoLinked] = useState(false);
  const handleOpenNoLinked = () => {
    setSelectedLinkedQuestion();
    setOpenNoLinked(true);
  };
  const handleCloseNoLinked = () => {
    setSelectedLinkedQuestion();
    setOpenNoLinked(false);
  };
  const noLinked = getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.no.linkedQuestion;
  const parsedNo = noLinked.map((value) => {
    return value + 1;
  });
  const [noLinkedQuestion, setNoLinkedQuestion] = useState<string[]>([]);

  const handleChangeForNo = (event: SelectChangeEvent<typeof yesLinkedQuestion>) => {
    const {
      target: { value },
    } = event;
    setNoLinkedQuestion(typeof value === "string" ? value.split(",") : value);
    setValue(`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.no.linkedQuestion`, typeof value === "string" ? value.split(",") : value);
    setSelectedLinkedQuestion();
  };

  useEffect(() => {
    setYesLinkedQuestion(getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.yes.linkedQuestion);
    setNoLinkedQuestion(getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.no.linkedQuestion);
  }, [getValues, nestIndex]);

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={3} sm={1.5} md={1} lg={1} xl={0.7} display="flex" alignItems="center">
        <div className="checkBoxOutlineBlankIcon"></div>
        <div className="yesNoCaption">{t("createForm.formContent.yes")}</div>
      </Grid>
      <Grid item xs={9} sm={3} md={2} lg={2} xl={1} display="flex" alignItems="center" justifyContent="flex-start">
        <Button className="yesNoAddPointBtnContainer" variant="outlined" size="small">
          <Grid container className="yesNoAddPointBtnElem">
            {getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.yes?.points > 0 ? null : (
              <Grid item xs={3} onClick={handleClickForYes}>
                <div className="yesNoAddPointBtn">
                  <AddIcon fontSize="small" />
                </div>
              </Grid>
            )}
            <Grid item xs={9} onClick={handleClickForYes}>
              {getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.yes?.points > 0
                ? `${getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.yes?.points} point`
                : t("createForm.formContent.addPoint")}
            </Grid>
            {getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.yes?.points > 0 ? (
              <Grid
                item
                xs={3}
                className="yesNoRemovePointBtn"
                onClick={(e) => {
                  e.stopPropagation();
                  watch([`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.yes.points`]);
                  setValue(`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.yes.points`, 0);
                }}
              >
                <ClearIcon fontSize="small" />
              </Grid>
            ) : null}
          </Grid>
        </Button>
        <Popover
          id={yesId}
          open={openForYes}
          anchorEl={pointForYes}
          onClose={handleCloseForYes}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="yesNoQuestionsContainer">
            <div>
              <Controller
                name={`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.yes.points` as const}
                control={control}
                rules={{
                  required: "Point is required",
                }}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    variant="standard"
                    label={t("createForm.formContent.answerPoint")}
                    inputProps={{ type: "number" }}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      if (parseInt(e.target.value) >= 0) {
                        field.onChange(parseInt(e.target.value));
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className="yesNoQuestionsElem">
              <Button variant="text" onClick={handleCloseForYes}>
                {t("createForm.formContent.confirm")}
              </Button>
            </div>
          </div>
        </Popover>
      </Grid>

      <Grid item xs={3} sm={1} md={0.5} lg={0.3} xl={0.3} display="flex" alignItems="center" justifyContent="flex-start">
        {getValues().fieldContent.filter((content) => content.contentElem === FormContentElemEnum.QUESTION).length > 1 && (
          <LinkIcon onClick={handleOpenYesLinked} className="linkIcon" color="primary" />
        )}
        <Modal open={openYesLinked} onClose={handleCloseYesLinked} aria-labelledby="modalYesTitle" aria-describedby="modalYesDescription">
          <Box sx={style}>
            <div className="yesNoModalTitle">{t("createForm.formContent.createLinkage")}</div>
            <div className="yesNoModal">{t("createForm.formContent.linkageDes")}</div>
            <div className="yesNoSelectedContainer">
              <div className="yesNoSelectedTitle">{t("createForm.formContent.theSelectedAnswer")}</div>
              <div className="yesNoSelectedAns">{t("createForm.formContent.yes")}</div>
            </div>
            <FormControl fullWidth required>
              <InputLabel id="demo-multiple-chip-label">{t("createForm.formContent.relatedSectionQuestion")}</InputLabel>
              <Controller
                name={`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.yes.linkedQuestion` as const}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    variant="outlined"
                    input={<OutlinedInput id="select-multiple-chip" label={t("createForm.formContent.relatedSectionQuestion")} />}
                    multiple
                    value={yesLinkedQuestion}
                    onChange={handleChangeForYes}
                    renderValue={(selectedValue) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selectedValue.map((value) => (
                          <Chip key={value} label={`Question ${value + 1}`} />
                        ))}
                      </Box>
                    )}
                  >
                    {getValues().fieldContent.map((fieldOption, i) => (
                      <MenuItem disabled={i <= nestIndex ? true : !!selected.includes(i)} key={i} value={i}>
                        <div>
                          <span className="yesNoModalMenuItem">
                            {t("createForm.formContent.question")} {i + 1}
                          </span>
                          {fieldOption !== undefined &&
                            fieldOption.singleQuestion !== undefined &&
                            fieldOption.singleQuestion.fieldQuestion !== undefined &&
                            fieldOption.singleQuestion.fieldQuestion.zhHK}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={9} sm={6.5} md={8.5} lg={8.7} xl={10} display="flex" alignItems="center">
        {yesLinked.length !== 0 && (
          <div className="linkedDesc linkedQuestionContainer">
            {t("createForm.formContent.linkedWithQuestions")} {parsedYes.sort().toString()}
            <div className="linkedQuestionContainer linkClearIcon">
              <ClearIcon
                onClick={() => {
                  setYesLinkedQuestion([]);
                  watch([`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.yes.linkedQuestion`]);
                  setValue(`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.yes.linkedQuestion`, []);
                  setSelectedLinkedQuestion();
                }}
                fontSize="small"
              />
            </div>
          </div>
        )}
      </Grid>

      <Grid item xs={3} sm={1.5} md={1} lg={1} xl={0.7} display="flex" alignItems="center">
        <div className="checkBoxOutlineBlankIcon"></div>
        <div className="yesNoCaption">{t("createForm.formContent.no")}</div>
      </Grid>
      <Grid item xs={9} sm={3} md={2} lg={2} xl={1} display="flex" alignItems="center" justifyContent="flex-start">
        <Button className="yesNoAddPointBtnContainer" variant="outlined" size="small" onClick={handleClickForNo}>
          <Grid container className="yesNoAddPointBtnElem">
            {getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.no?.points > 0 ? null : (
              <Grid item xs={3} onClick={handleClickForNo}>
                <div className="yesNoAddPointBtn">
                  <AddIcon fontSize="small" />
                </div>
              </Grid>
            )}
            <Grid item xs={9} onClick={handleClickForNo}>
              {getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.no?.points > 0
                ? `${getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.no?.points} point`
                : t("createForm.formContent.addPoint")}
            </Grid>
            {getValues().fieldContent[nestIndex].singleQuestion.yesNoQuestions.no?.points > 0 ? (
              <Grid
                item
                xs={3}
                className="yesNoRemovePointBtn"
                onClick={(e) => {
                  e.stopPropagation();
                  watch([`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.no.points`]);
                  setValue(`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.no.points`, 0);
                }}
              >
                <ClearIcon fontSize="small" />
              </Grid>
            ) : null}
          </Grid>
        </Button>
        <Popover
          id={noId}
          open={openForNo}
          anchorEl={pointForNo}
          onClose={handleCloseForNo}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <div className="yesNoQuestionsContainer">
            <div>
              <Controller
                name={`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.no.points` as const}
                control={control}
                defaultValue={0}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    size="small"
                    variant="standard"
                    label={t("createForm.formContent.answerPoint")}
                    inputProps={{ type: "number" }}
                    onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                      if (parseInt(e.target.value) >= 0) {
                        field.onChange(parseInt(e.target.value));
                      }
                    }}
                  />
                )}
              />
            </div>
            <div className="yesNoQuestionsElem">
              <Button variant="text" onClick={handleCloseForNo}>
                {t("createForm.formContent.confirm")}
              </Button>
            </div>
          </div>
        </Popover>
      </Grid>

      <Grid item xs={3} sm={1} md={0.5} lg={0.3} xl={0.3} display="flex" alignItems="center" justifyContent="flex-start">
        {getValues().fieldContent.filter((content) => content.contentElem === FormContentElemEnum.QUESTION).length > 1 && (
          <LinkIcon onClick={handleOpenNoLinked} className="linkIcon" color="primary" />
        )}
        <Modal open={openNoLinked} onClose={handleCloseNoLinked} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box sx={style}>
            <div className="yesNoModalTitle">{t("createForm.formContent.createLinkage")}</div>
            <div className="yesNoModal">{t("createForm.formContent.linkageDes")}</div>
            <div className="yesNoSelectedContainer">
              <div className="yesNoSelectedTitle">{t("createForm.formContent.theSelectedAnswer")}</div>
              <div className="yesNoSelectedAns">{t("createForm.formContent.no")}</div>
            </div>
            <FormControl fullWidth required>
              <InputLabel id="relatedQuestionLabel">{t("createForm.formContent.relatedSectionQuestion")}</InputLabel>
              <Controller
                name={`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.no.linkedQuestion` as const}
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-chip-label"
                    id="demo-multiple-chip"
                    variant="outlined"
                    input={<OutlinedInput id="select-multiple-chip" label={t("createForm.formContent.relatedSectionQuestion")} />}
                    multiple
                    value={noLinkedQuestion}
                    onChange={handleChangeForNo}
                    renderValue={(selectedValue) => (
                      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {selectedValue.map((value) => (
                          <Chip key={value} label={`Question ${value + 1}`} />
                        ))}
                      </Box>
                    )}
                  >
                    {getValues().fieldContent.map((fieldOption, i) => (
                      <MenuItem disabled={!!(i <= nestIndex || selected.includes(i))} key={i} value={i}>
                        <div>
                          <span className="yesNoModalMenuItem">
                            {t("createForm.formContent.question")} {i + 1}
                          </span>
                          {fieldOption !== undefined &&
                            fieldOption.singleQuestion !== undefined &&
                            fieldOption.singleQuestion.fieldQuestion !== undefined &&
                            fieldOption.singleQuestion.fieldQuestion.zhHK}
                        </div>
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Box>
        </Modal>
      </Grid>
      <Grid item xs={9} sm={6.5} md={8.5} lg={8.7} xl={10}>
        {noLinked.length !== 0 && (
          <div className="linkedDesc linkedQuestionContainer">
            <div>
              {t("createForm.formContent.linkedWithQuestions")} {parsedNo.sort().toString()}
            </div>
            <div className="linkedQuestionContainer linkClearIcon">
              <ClearIcon
                onClick={() => {
                  setNoLinkedQuestion([]);
                  watch([`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.no.linkedQuestion`]);
                  setValue(`fieldContent.${nestIndex}.singleQuestion.yesNoQuestions.no.linkedQuestion`, []);
                  setSelectedLinkedQuestion();
                }}
                fontSize="small"
              />
            </div>
          </div>
        )}
      </Grid>
    </Grid>
  );
}

export default YesNoQuestions;
