import React, { useEffect } from "react";
import { Grid, TextField } from "@mui/material";
import { Control, UseFormRegister, UseFormSetValue, UseFormGetValues, UseFormWatch, FieldErrors, Controller, useFieldArray } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";

interface Props {
  nestIndex: number;
  secondNestIndex: number;
  control: Control<CreateApplicationStageFormInput, object>;
  register: UseFormRegister<CreateApplicationStageFormInput>;
  setValue: UseFormSetValue<CreateApplicationStageFormInput>;
  getValues: UseFormGetValues<CreateApplicationStageFormInput>;
  watch: UseFormWatch<CreateApplicationStageFormInput>;
  errors: FieldErrors<CreateApplicationStageFormInput>;
}

function MultipleAnswerSession({ nestIndex, secondNestIndex, control, register, watch, setValue, getValues, errors }: Props) {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answers`,
  });
  const originAnswersNo = getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].multipleAnswerSet.answers.length;
  // console.log("originAnswersNo =", originAnswersNo);

  const newAnswersNo = watch(`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answersNo`);
  // console.log("newAnswersNo =", newAnswersNo);

  const changes = newAnswersNo - originAnswersNo;
  // console.log("changes =", changes);

  useEffect(() => {
    if (changes > 0) {
      for (let i = 0; i < changes; i++) {
        append({
          zhHK: "",
          enUS: "",
          others: false,
          points: 0,
        });
      }
    } else if (changes < 0) {
      const negativeChanges = -changes;
      const index = originAnswersNo - 1;
      for (let i = 0; i < negativeChanges; i++) {
        remove(index);
      }
    }
  }, [append, changes, originAnswersNo, remove]);

  return (
    <Grid item xs={12} md={12}>
      {fields.map((multipleAnswerSetAnswer, k) => {
        return (
          <Grid container alignItems="center" spacing={2} key={multipleAnswerSetAnswer.id}>
            <Grid item xs={0.4} md={0.4} display="flex" justifyContent="center" alignItems="center">
              <div className="multipleAnswerSetCircleNoContainer">{k + 1}</div>
            </Grid>

            <Grid item xs={5.8} md={5.8}>
              <Controller
                name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answers.${k}.enUS` as const}
                control={control}
                rules={{ required: "English answer is required" }}
                defaultValue=""
                render={({ field }) => (
                  <TextField {...field} fullWidth variant="standard" label={`${t("createForm.formContent.answer")} ${k + 1} ${t("createForm.formContent.english")}`} />
                )}
              />
            </Grid>

            <Grid item xs={5.8} md={5.8}>
              <Controller
                name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answers.${k}.zhHK` as const}
                control={control}
                rules={{ required: "Chinese answer is required" }}
                defaultValue=""
                render={({ field }) => (
                  <TextField {...field} fullWidth variant="standard" label={`${t("createForm.formContent.answer")} ${k + 1} ${t("createForm.formContent.traditionalChinese")}`} />
                )}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
}

export default MultipleAnswerSession;
