import React, { ReactNode, useCallback, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { Alert, AlertTitle, Box, Button, FormControl, FormHelperText, FormLabel, Grid, InputLabel, Menu, MenuItem, Modal, Select, Snackbar, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { updateUserById } from "../../../api/user";
import { DocumentTypeEnum } from "../../../models/documentTypeEnum";
import modalStyle from "../../utils/modalStyle";
import localization from "../../utils/countryCode.json";

interface Props {
  id: number;
  userId: number;
  lastNameEnUs: string;
  firstNameEnUs: string;
  lastNameZhHk: string;
  firstNameZhHk: string;
  personalDocumentType: string;
  personalDocumentId: string;
  email: string;
  phoneNumber: string;
  reload: () => void;
}

export interface UserFormInput {
  lastNameEnUs: string;
  firstNameEnUs: string;
  lastNameZhHk: string | null;
  firstNameZhHk: string | null;
  personalDocumentType: string;
  personalDocumentId: string;
  email: string;
  countryCode: string;
  phoneNumber: string;
}

function EditButton(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id, userId, lastNameEnUs, firstNameEnUs, lastNameZhHk, firstNameZhHk, personalDocumentType, personalDocumentId, email, reload, phoneNumber } = props;
  const personalDocumentTypes = [
    {
      value: DocumentTypeEnum.BIRTH_CERTIFICATE,
      label: t("register.hkBirthCertificate"),
    },
    {
      value: DocumentTypeEnum.IDENTITY_CARD,
      label: t("register.hkIdentityCard"),
    },
    {
      value: DocumentTypeEnum.PASSPORT,
      label: t("register.passport"),
    },
    {
      value: DocumentTypeEnum.RE_ENTRY_PERMIT,
      label: t("register.reEntryPermit"),
    },
    {
      value: DocumentTypeEnum.CERTIFICATE_OF_IDENTITY,
      label: t("register.certificateOfIdentity"),
    },
    {
      value: DocumentTypeEnum.DOCUMENT_OF_IDENTITY,
      label: t("register.documentOfIdentity"),
    },
    {
      value: DocumentTypeEnum.ENTRY_PERMIT,
      label: t("register.entryPermit"),
    },
  ];

  const defaultValues: UserFormInput = {
    lastNameEnUs,
    firstNameEnUs,
    lastNameZhHk: lastNameZhHk === null ? "" : lastNameZhHk,
    firstNameZhHk: firstNameZhHk === null ? "" : firstNameZhHk,
    personalDocumentType,
    personalDocumentId,
    countryCode: phoneNumber,
    phoneNumber,
    email,
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<UserFormInput>({ defaultValues });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => {
    handleClose();
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const handleOpenSnackbar = useCallback(() => {
    setOpenSnackbar(true);
  }, []);
  const handleCloseSnackbar = useCallback(() => {
    setOpenSnackbar(false);
  }, []);
  const onSubmit: SubmitHandler<UserFormInput> = async (data) => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { lastNameZhHk, firstNameZhHk, countryCode, phoneNumber } = data;
    // let tempPhoneNumber = phoneNumber;
    // if (!phoneNumber.includes("+")) {
    //   tempPhoneNumber = `${countryCode} ${phoneNumber}`;
    // }
    const modifiedData = {
      ...data,
      lastNameZhHk: lastNameZhHk === "" ? null : lastNameZhHk,
      firstNameZhHk: firstNameZhHk === "" ? null : firstNameZhHk,
      phoneNumber,
      countryCode,
    };
    const result = await updateUserById(userId, modifiedData);
    if (result.success) {
      handleOpenSnackbar();
      handleCloseModal();
      reload();
    }
  };

  function navigateToSubmissionForm(submissionId: number) {
    navigate(`submission/${submissionId}/edit`);
  }

  return (
    <>
      <Button variant="contained" size="small" className="buttonAction buttonActionBlue" onClick={handleClick}>
        {t("view.edit")}
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleOpenModal}>{t("view.accountInformation")}</MenuItem>
        <MenuItem
          onClick={() => {
            navigateToSubmissionForm(id);
          }}
        >
          {t("view.applicationForm")}
        </MenuItem>
      </Menu>

      <Modal open={openModal} onClose={handleCloseModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
              <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                <div>{t("view.editAccountInformation")}</div>
                <Button variant="text" onClick={handleCloseModal} className="modalClearBtn">
                  <ClearIcon />
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={"lastNameEnUs" as const}
                  control={control}
                  rules={{ required: "Applicant Last Name (EN) is required" }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t("view.applicantLastNameEn")}
                      error={errors.lastNameEnUs && true}
                      helperText={errors.lastNameEnUs && `${errors.lastNameEnUs.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={"firstNameEnUs" as const}
                  control={control}
                  rules={{ required: "Applicant First Name (EN) is required" }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t("view.applicantFirstNameEn")}
                      error={errors.firstNameEnUs && true}
                      helperText={errors.firstNameEnUs && `${errors.firstNameEnUs.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={"lastNameZhHk" as const}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t("view.applicantLastNameCh")}
                      error={errors.lastNameZhHk && true}
                      helperText={errors.lastNameZhHk && `${errors.lastNameZhHk.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={"firstNameZhHk" as const}
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t("view.applicantFirstNameCh")}
                      error={errors.firstNameZhHk && true}
                      helperText={errors.firstNameZhHk && `${errors.firstNameZhHk.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth required>
                  <InputLabel id="answerTypeLabel">{t("view.documentType")}</InputLabel>
                  <Controller
                    name={"personalDocumentType" as const}
                    control={control}
                    rules={{ required: "Document Type is required" }}
                    defaultValue=""
                    render={({ field }) => (
                      <Select {...field} label="Document Type" id="answerTypeLabel" variant="outlined">
                        {personalDocumentTypes.map((documentType) => {
                          return (
                            <MenuItem key={documentType.value} value={documentType.value}>
                              {documentType.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name={"personalDocumentId" as const}
                  control={control}
                  rules={{ required: "Document No. is required" }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label={t("view.documentNum")}
                      error={errors.personalDocumentId && true}
                      helperText={errors.personalDocumentId && `${errors.personalDocumentId.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={"email" as const}
                  control={control}
                  rules={{
                    required: "Contact email is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "invalid email address",
                    },
                  }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="email"
                      fullWidth
                      label={t("view.contactEmail")}
                      error={errors.email && true}
                      helperText={errors.email && `${errors.email.message}`}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name={"countryCode" as const}
                  control={control}
                  rules={{}}
                  defaultValue=""
                  render={({ field: countryCodeField }) => (
                    <Controller
                      name={"phoneNumber" as const}
                      control={control}
                      rules={{}}
                      defaultValue=""
                      render={({ field }) => (
                        <>
                          <FormLabel className="questionTitle">{t("view.contactPhone")}</FormLabel>
                          <PhoneInput
                            enableSearch
                            inputProps={{
                              name: "phoneNumber",
                              autoFocus: true,
                            }}
                            value={countryCodeField.value.replace("+", "") + field.value}
                            placeholder={t("register.phoneNumber")}
                            inputStyle={{ width: "100%" }}
                            isValid={!errors.phoneNumber && !errors.countryCode}
                            localization={localization}
                            onChange={(value, country, e, formattedValue) => {
                              countryCodeField.onChange((country as CountryData).dialCode ? `+${(country as CountryData).dialCode}` : "");
                              field.onChange(value.slice((country as CountryData).dialCode ? (country as CountryData).dialCode.length : 0));
                            }}
                          />
                          <FormHelperText className="textRed">{errors.countryCode && (t("register.countryCodeIsRequired") as ReactNode)}</FormHelperText>
                          <FormHelperText className="textRed">{errors.phoneNumber && (t("register.phoneNumberIsRequired") as ReactNode)}</FormHelperText>
                        </>
                      )}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button className="modalSubmitBtn" onClick={handleCloseModal}>
                  {t("view.cancel")}
                </Button>
                <Button className="modalSubmitBtn" type="submit" onClick={handleSubmit(onSubmit)}>
                  {t("view.save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical: "top", horizontal: "right" }}>
        <Alert severity="success">
          <AlertTitle>
            <strong>Success</strong>
          </AlertTitle>
          Applicant has been updated.
        </Alert>
      </Snackbar>
    </>
  );
}

export default EditButton;
