import * as locales from "@mui/material/locale";

type SupportedLocales = keyof typeof locales;

export function changeLanguage(lng: SupportedLocales) {
  return {
    type: "@@localization/CHANGE_LANGUAGE" as const,
    lng,
  };
}

export type LocalizationActions = ReturnType<typeof changeLanguage>;
