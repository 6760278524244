import { DataGridPro, GridColDef, GridFilterModel } from "@mui/x-data-grid-pro";
import React, { useCallback, useState } from "react";
import { findAllInterviewsByStageIdAndSchoolCodeAndTitle } from "../../../api/interview";
import { Interview } from "../../../interfaces/interview";
import InterviewToolbar from "./toolbar/InterviewToolbar";

interface InterviewDataGridProps {
  rows: Interview[];
  columns: GridColDef<Interview>[];
}

function InterviewDataGrid(props: InterviewDataGridProps) {
  const { rows, columns } = props;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [interviewRows, setInterviewRows] = useState<Interview[]>(rows);
  // const [rowCountState, setRowCountState] = useState(pageInfo?.totalRowCount || 0);

  const onFilterChange = useCallback(async (filterModel: GridFilterModel) => {
    if (filterModel.quickFilterValues) {
      const { _, data }: { _: boolean; data: Interview[] } = await findAllInterviewsByStageIdAndSchoolCodeAndTitle(2, 2, filterModel.quickFilterValues.join(" "));
      setInterviewRows(data.map((value) => ({ ...value, noOfCandidates: -1, arrangedCandidates: -1, unarrangedCandidates: -1 })));
      console.log(filterModel, data, interviewRows);
    }
  }, []);

  // useEffect(() => {
  //     setRowCountState((prevRowCountState) => (pageInfo?.totalRowCount !== undefined ? pageInfo?.totalRowCount : prevRowCountState));
  // }, [pageInfo?.totalRowCount, setRowCountState]);

  return (
    <DataGridPro
      sx={{ border: 0 }}
      rows={interviewRows}
      // rowCount={rowCountState}
      // loading={isLoading}
      columns={columns}
      getRowId={(row) => row.id}
      pagination
      page={page}
      pageSize={pageSize}
      // paginationMode="server"
      onPageChange={(newPage) => setPage(newPage)}
      onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
      rowsPerPageOptions={[5, 10, 20]}
      components={{ Toolbar: InterviewToolbar }}
      componentsProps={{
        toolbar: {
          interviews: columns,
        },
      }}
      filterMode="server"
      onFilterModelChange={onFilterChange}
      autoHeight
      checkboxSelection
      disableSelectionOnClick
      disableColumnResize
      disableColumnMenu
    />
  );
}

export default InterviewDataGrid;
