import React, { useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { Alert, AlertTitle, Box, Button, Modal, Snackbar, TextField } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import modalStyle from "../../utils/modalStyle";
import type { SelectedData } from "./ViewApplicationFormDashboard";
import { enrolUserToCms } from "../../../api/user";
import { StateForDataGrid } from "../../utils/CustomStyle";

interface Props {
  selectedData: SelectedData[];
  applicationStageId: number;
  // eslint-disable-next-line react/require-default-props,
  onSubmitSuccess?: () => void;
}

export interface NewApplicationFormInput {
  titleEnUs: string;
  titleZhHk: string;
}

function EnrolToCmsButton({ selectedData, applicationStageId, onSubmitSuccess }: Props) {
  const { t } = useTranslation(["common", "translation"]);
  const [open, setOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const haveInCompleted = selectedData.some((row) => row.formStatus !== t("view.completed", { ns: "translation" }));

  const [state, setState] = useState<StateForDataGrid>({
    openSnackbar: false,
    vertical: "top",
    horizontal: "right",
  });

  const [errorState, setErrorState] = useState<StateForDataGrid>({
    openSnackbar: false,
    vertical: "top",
    horizontal: "right",
  });

  const { vertical, horizontal, openSnackbar } = state;
  const { vertical: errorVertical, horizontal: errorHorizontal, openSnackbar: openErrorSnackbar } = errorState;

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setState({ openSnackbar: false, vertical: "top", horizontal: "right" });
  };

  const handleCloseErrorSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setErrorState({ openSnackbar: false, vertical: "top", horizontal: "right" });
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<NewApplicationFormInput>();
  const onSubmit: SubmitHandler<any> = async () => {
    setLoading(true);
    const data = {
      userIds: selectedData.map((item) => item.userId),
      applicationStageId,
    };
    try {
      const result = await enrolUserToCms(data);
      if (result.success) {
        // console.log("sucess: ", result);
        setLoading(false);
        setState({ openSnackbar: true, vertical: "top", horizontal: "right" });
        if (onSubmitSuccess) {
          onSubmitSuccess();
        }
        handleClose();
      } else {
        setErrorState({ openSnackbar: true, vertical: "top", horizontal: "right" });
        console.log("result error: ", result.error);
      }
    } catch (e) {
      setErrorState({ openSnackbar: true, vertical: "top", horizontal: "right" });
      console.log("error: ", e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      {haveInCompleted ? (
        <Button className="buttonAction buttonActionGrey" variant="contained" size="small" sx={{ ml: 1 }} disabled={haveInCompleted}>
          <span className="applicationFormListButtonText">{t("view.haveIncompleted", { ns: "translation" })}</span>
        </Button>
      ) : (
        <Button className="buttonAction buttonActionBlue" variant="contained" size="small" sx={{ ml: 1 }} onClick={handleOpen} disabled={haveInCompleted}>
          <span className="applicationFormListButtonText">{t("view.enrolToCms", { ns: "translation" })}</span>
          {/* <span className="applicationFormListButtonText">{t("list.newApplication", { ns: "translation" })}</span> */}
        </Button>
      )}
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle} style={{ height: "1px", minHeight: "30%", maxHeight: "60%" }}>
          <Grid container spacing={2} style={{ height: "100%", display: "flex", flexDirection: "column" }}>
            <Grid xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
              <div>{t("view.enrolStudentToCms", { ns: "translation" })}</div>
              <Button variant="text" onClick={handleClose} className="modalClearBtn">
                <ClearIcon />
              </Button>
            </Grid>
            <Grid xs={12}>
              <div>{t("view.enrolNumberOfStudents", { number: selectedData.length, ns: "translation" })}</div>
              {/* <div>The following {selectedData.length} student(s) will be enrolled into CMS. Note: enrolled student(s) will be ignored</div> */}
            </Grid>
            <Box style={{ flex: "1", overflowY: "auto", paddingLeft: "12px" }}>
              {selectedData &&
                selectedData.length &&
                selectedData.map((row, index) => (
                  <Grid key={row.id} xs={12}>
                    <span>{`${index + 1}.[${row.submissionId}] ${row.studentName}${row.enrolledCms ? `(${t("view.enrolled", { ns: "translation" })})` : ""}`}</span>
                  </Grid>
                ))}
            </Box>
            <Grid xs={12} display="flex" justifyContent="flex-end" alignContent="center">
              <Button variant="text" className="modalSubmitBtn" onClick={handleClose}>
                {t("cancel", { ns: "common" })}
              </Button>
              {loading ? (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>Loading...</div>
              ) : (
                <Button variant="text" className="modalSubmitBtn" type="submit" onClick={onSubmit}>
                  {t("view.enrol", { ns: "translation" })}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar open={openSnackbar} autoHideDuration={3000} onClose={handleCloseSnackbar} anchorOrigin={{ vertical, horizontal }}>
        <Alert severity="success">
          <AlertTitle>
            <strong>{t("view.success", { ns: "translation" })}</strong>
          </AlertTitle>
          {t("view.applicationsHaveEnrolled", { ns: "translation" })}
        </Alert>
      </Snackbar>
      <Snackbar open={openErrorSnackbar} autoHideDuration={3000} onClose={handleCloseErrorSnackbar} anchorOrigin={{ vertical, horizontal }}>
        <Alert severity="error">
          <AlertTitle>
            <strong>{t("view.error", { ns: "translation" })}</strong>
          </AlertTitle>
          {t("view.errorMessage", { ns: "translation" })}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default EnrolToCmsButton;
