export enum FieldEnum {
  YES_NO = "YES_NO",
  SHORT_ANSWER = "SHORT_ANSWER",
  PARAGRAPH = "PARAGRAPH",
  VALUE = "VALUE",
  IMAGE_UPLOAD = "IMAGE_UPLOAD",
  FILE_UPLOAD = "FILE_UPLOAD",
  SINGLE_CHOICE = "SINGLE_CHOICE",
  MULTIPLE_CHOICES = "MULTIPLE_CHOICES",
  DROPDOWN = "DROPDOWN",
  DATEPICKER = "DATEPICKER",
  DESCRIPTION = "DESCRIPTION",
  EMAIL = "EMAIL",
  PHONE_NUMBER = "PHONE_NUMBER",
  APPLICATION_NUMBER = "APPLICATION_NUMBER",
  CHECKBOX = "CHECKBOX",
  MULTIPLE_ANSWER_SET = "MULTIPLE_ANSWER_SET",
  STUDENT_ID = "STUDENT_ID",
  APPLY_COURSE = "APPLY_COURSE",
}

export enum SelectEnum {
  CUSTOM = "CUSTOM",
  POB = "POB",
  NATIONALITY = "NATIONALITY",
  JOBINDUSTRY = "JOBINDUSTRY",
  DISTRICT = "DISTRICT",
}

export enum FormContentElemEnum {
  SECTION = "SECTION",
  QUESTION = "QUESTION",
  DESCRIPTION = "DESCRIPTION",
  NEW_PAGE = "NEW_PAGE",
  SECTION_TITLE = "SECTION_TITLE",
}
