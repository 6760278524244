/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
export enum ApplicantStatusEnum {
  ACCEPTED = "ACCEPTED",
  WAITING = "WAITING",
  REJECT = "REJECT",
  PENDING = "PENDING",
  DUPLICATED = "DUPLICATED",
  DECLINED = "DECLINED",
  REGISTER = "REGISTER",
  OFFERED = "OFFERED",
  NO_REPLY = "NO_REPLY",
  REMAIN_WAITING = "REMAIN_WAITING",
  PAYMENT_OVERDUE = "PAYMENT_OVERDUE",
  APPROVED_BY_HT = "APPROVED_BY_HT",
  REJECTED_BY_HT = "REJECTED_BY_HT",
  PAID = "PAID",
  TEST = "TEST",
  OVERAGE = "OVERAGE",
  WITHDRAWN = "WITHDRAWN",
}
