import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import React, { SetStateAction } from "react";
import { Control, Controller } from "react-hook-form";
import { Condition } from "../../../interfaces/interview";

interface EditConditionFormProps {
  groupArrayInputPath: string;
  currentIndex: number;
  control: Control<Condition>;
  defaultValue: Condition;
  setDefaultValue: (value: SetStateAction<Condition>) => void;
  updateCondition: (index: number, condition: Condition) => void;
}

function EditConditionForm(props: EditConditionFormProps) {
  const { control, currentIndex, defaultValue, setDefaultValue, groupArrayInputPath, updateCondition } = props;

  return (
    <Grid container xs={12} sx={{ my: 1 }} spacing={2}>
      <Grid item xs={12}>
        <FormControl fullWidth required>
          <InputLabel id="select-question-label">Select Question</InputLabel>
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Controller
              name={`${groupArrayInputPath}.${currentIndex}.question` as "question"}
              control={control}
              defaultValue={defaultValue.question || ""}
              rules={{ required: "Interview Title (Traditional Chinese) is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="select-question-label"
                  label="Select Question"
                  fullWidth
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    updateCondition(currentIndex, { ...defaultValue, question: event.target.value });
                    setDefaultValue({ ...defaultValue, question: event.target.value });
                  }}
                >
                  <MenuItem value="Section 1 - Question 1">Section 1 - Question 1</MenuItem>
                  <MenuItem value="Section 1 - Question 1 (Yes - Linked Question)">Section 1 - Question 1 (Yes - Linked Question)</MenuItem>
                  <MenuItem value="Section 2 - Question 3">Section 2 - Question 3</MenuItem>
                  <MenuItem value="Section 1 - Question 4">Section 1 - Question 4</MenuItem>
                  <MenuItem value="Section 2 - Question 3">Section 2 - Question 3</MenuItem>
                  <MenuItem value="test1">test1</MenuItem>
                  <MenuItem value="test2">test2</MenuItem>
                  <MenuItem value="test3">test3</MenuItem>
                </Select>
              )}
            />
          }
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth required>
          <InputLabel id="condition-label">Condition</InputLabel>
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Controller
              name={`${groupArrayInputPath}.${currentIndex}.condition` as "condition"}
              control={control}
              defaultValue={defaultValue.condition || ""}
              rules={{ required: "Interview Title (Traditional Chinese) is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="condition-label"
                  label="Condition"
                  fullWidth
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    updateCondition(currentIndex, { ...defaultValue, condition: event.target.value });
                    setDefaultValue({ ...defaultValue, condition: event.target.value });
                  }}
                >
                  <MenuItem value="lt">Less than</MenuItem>
                  <MenuItem value="le">Less than or equal to</MenuItem>
                  <MenuItem value="eq">Equals to</MenuItem>
                  <MenuItem value="ge">Greater than or equal to</MenuItem>
                  <MenuItem value="gt">Greater than</MenuItem>
                </Select>
              )}
            />
          }
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl fullWidth required>
          <InputLabel id="select-answer-label">Select Answer</InputLabel>
          {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <Controller
              name={`${groupArrayInputPath}.${currentIndex}.answer` as "answer"}
              control={control}
              defaultValue={defaultValue?.answer || ""}
              rules={{ required: "Interview Title (Traditional Chinese) is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  labelId="select-answer-label"
                  label="Select Answer"
                  fullWidth
                  onChange={(event) => {
                    field.onChange(event.target.value);
                    updateCondition(currentIndex, { ...defaultValue, answer: event.target.value });
                    setDefaultValue({ ...defaultValue, answer: event.target.value });
                  }}
                >
                  <MenuItem value="Yes">Yes</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value="Banking">Banking</MenuItem>
                  <MenuItem value="Architecture and Construction">Architecture and Construction</MenuItem>
                  <MenuItem value="Girl">Girl</MenuItem>
                  <MenuItem value="Arts, Entertainment and Publishing">Arts, Entertainment and Publishing</MenuItem>
                  <MenuItem value="test1">test1</MenuItem>
                  <MenuItem value="test2">test2</MenuItem>
                  <MenuItem value="test3">test3</MenuItem>
                </Select>
              )}
            />
          }
        </FormControl>
      </Grid>
    </Grid>
  );
}

export default EditConditionForm;
