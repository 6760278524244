import AddCircleIcon from "@mui/icons-material/AddCircle";
import { Button, Menu, MenuItem } from "@mui/material";
import { GridColDef, GridColumnVisibilityModel } from "@mui/x-data-grid-pro";
import React, { useState, MouseEvent, SetStateAction } from "react";
import { DropResult } from "react-beautiful-dnd";
import DraggableList from "./DraggableList";

interface CustomizeViewMenuProps {
  columns: GridColDef[];
  visibilityModel: GridColumnVisibilityModel;
  onSelect: (key: string, checked: boolean) => void;
  onDragEnd: (result: GridColDef[]) => void;
}

function CustomizeViewMenu(props: CustomizeViewMenuProps) {
  const { columns, visibilityModel, onSelect, onDragEnd } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    const result = [...columns];
    const [removed] = result.splice(source.index, 1);
    result.splice(destination.index, 0, removed);
    onDragEnd(result);
    // const newRows = columns.map((row) => ({
    //     info: Object.assign({}, ...result.map((item) => ({ [item.id]: row.info[item.id] }))),
    //     interviews: row.interviews,
    // }));
    // setApplicationHeadCells(result);
    // setApplicationRows(newRows);
  };

  return (
    <>
      <Button
        className="buttonGroupSent"
        variant="outlined"
        size="small"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleOpen}
      >
        Customize View
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <DraggableList
          defs={columns}
          visibilityModel={visibilityModel}
          onDragEnd={handleDragEnd}
          onChange={(key, checked) => {
            // const newColumns = Array.from(columns);
            // newColumns[index].isDisplay = c;
            // setApplicationHeadCells(newHeadCells);
            onSelect(key, checked);
            handleClose();
          }}
        />
        <MenuItem onClick={handleClose}>
          <AddCircleIcon style={{ color: "#409EFF" }} />
          Custom Column
        </MenuItem>
      </Menu>
    </>
  );
}

export default CustomizeViewMenu;
