import { Select } from "@material-ui/core";
import ClearIcon from "@mui/icons-material/Clear";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Grid, IconButton, InputLabel, MenuItem, Modal, TextField, Typography } from "@mui/material";
import React, { Fragment, SyntheticEvent, useEffect, useState } from "react";
import { Controller, FieldValues, useForm, useFormContext, UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Condition, ConditionFormInput, Criteria, Interview } from "../../../interfaces/interview";
import ConnectForm from "./ConnectForm";
import useConditionFormField from "./useConditionFormField";
import ConditionGroup from "./ConditionGroup";
import AnswerPointSetting from "./AnswerPointSetting";
import CreateConditionForm from "./CreateConditionForm";
import AnswerPoint from "./AnswerPoint";
import useWindowDimensions from "./useWindowDimensions";

const style = {
  position: "absolute",
  maxHeight: "80%",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  bgcolor: "background.paper",
  p: 3,
  borderRadius: 0.5,
};

interface CriteriaSettingModalProps {
  criteria?: Criteria;
}

const defaultProps = {
  criteria: undefined,
};

function CriteriaSettingModal(props: CriteriaSettingModalProps) {
  const { criteria } = props;
  const { t } = useTranslation();
  const prefix = ["criteria", "condition"];

  const [open, setOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | false>(false);

  const methods = useFormContext<Interview>();
  const { height } = useWindowDimensions();

  if (!criteria) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    methods.setValue("criteria.condition", { operator: "ROOT", question: "", condition: "", answer: "", group: [] });
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // methods.reset();
    setOpen(false);
  };

  const handleAccordionChange = (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Button variant="text" onClick={handleOpen}>
        ADD INTERVIEW CRITERIA
      </Button>
      <Modal hideBackdrop open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <ConnectForm>
          {({ control, formState: { errors } }: UseFormReturn<Interview>) => (
            <Box style={{ maxHeight: "80%" }} sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                  <div>Interview Criteria Setting</div>
                  <Button variant="text" onClick={handleClose} className="modalClearBtn">
                    <ClearIcon />
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Controller
                      name={"criteria.title.zhHk" as const}
                      control={control}
                      rules={{ required: "Criteria Title (Traditional Chinese) is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          margin="dense"
                          variant="standard"
                          fullWidth
                          label="Criteria Title (Traditional Chinese)"
                          error={errors?.criteria?.title?.zhHk && true}
                          helperText={errors?.criteria?.title?.zhHk && `${errors?.criteria?.title?.zhHk?.message}`}
                        />
                      )}
                    />
                  }
                </Grid>
                <Grid item xs={6}>
                  {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    <Controller
                      name={"criteria.title.enUs" as const}
                      control={control}
                      rules={{ required: "Criteria Title (English) is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          margin="dense"
                          variant="standard"
                          fullWidth
                          label="Criteria Title (English)"
                          error={errors?.criteria?.title?.enUs && true}
                          helperText={errors?.criteria?.title?.enUs && `${errors?.criteria?.title?.enUs?.message}`}
                        />
                      )}
                    />
                  }
                </Grid>
                <Grid item xs={12}>
                  <Accordion expanded={expanded === "panel1"} onChange={handleAccordionChange("panel1")} sx={{ boxShadow: 0 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1" id="panel1">
                      <Typography sx={{ fontSize: 16, color: "#303133", fontWeight: 500, lineHeight: "150%" }}>Answer Point Setting</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ maxHeight: `${height - 600}px`, overflow: "scroll" }}>
                      {criteria && criteria.answerPoints && (
                        <Grid container spacing={1}>
                          {criteria.answerPoints.map((answerPointObject, index) => (
                            <AnswerPoint
                              key={`${answerPointObject.question.position}-${answerPointObject.question.question}`}
                              answerPoint={answerPointObject}
                              prefix={["criteria", "answerPoints", index]}
                            />
                          ))}
                        </Grid>
                      )}
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === "panel2"} onChange={handleAccordionChange("panel2")} sx={{ boxShadow: 0 }}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2" id="panel2">
                      <Typography sx={{ fontSize: 16, color: "#303133", fontWeight: 500, lineHeight: "150%" }}>Condition Setting</Typography>
                    </AccordionSummary>
                    <AccordionDetails style={{ maxHeight: `${height - 600}px`, overflow: "scroll" }}>
                      {criteria && criteria.condition && (
                        <ConditionGroup
                          condition={criteria.condition}
                          index={0}
                          parentIndex={-1}
                          parentLength={-1}
                          // onRemove={removeCondition}
                          // onUpdateCondition={handleUpdateRoot}
                          prefix={prefix}
                        />
                      )}
                    </AccordionDetails>
                  </Accordion>
                </Grid>
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="flex-end">
                <Button variant="text" onClick={handleClose}>
                  {t("view.cancel")}
                </Button>
                <Button variant="text" onClick={handleClose}>
                  ADD CRITERIA
                </Button>
              </Grid>
            </Box>
          )}
        </ConnectForm>
      </Modal>
    </>
  );
}

CriteriaSettingModal.defaultProps = defaultProps;

export default CriteriaSettingModal;
