import React, { useEffect } from "react";
import { Control, Controller, FieldErrors, useFieldArray, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Divider, Grid, TextField } from "@mui/material";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";
import InnerMultipleAnswerSetSession from "./InnerMultipleAnswerSetSession";

interface Props {
  nestIndex: number;
  secondNestIndex: number;
  control: Control<CreateApplicationStageFormInput, object>;
  register: UseFormRegister<CreateApplicationStageFormInput>;
  setValue: UseFormSetValue<CreateApplicationStageFormInput>;
  getValues: UseFormGetValues<CreateApplicationStageFormInput>;
  watch: UseFormWatch<CreateApplicationStageFormInput>;
  errors: FieldErrors<CreateApplicationStageFormInput>;
}

function MultipleAnswerSetSession({ nestIndex, secondNestIndex, control, register, watch, setValue, getValues, errors }: Props) {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answerSets`,
  });

  const originalValue = getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].multipleAnswerSet.answerSets.length;
  // console.log("originalValue =", originalValue);

  const newValue = watch(`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answerSetsNo`);
  // console.log("newValue =", newValue);

  const changes = newValue - originalValue;
  // console.log("changes =", changes);

  useEffect(() => {
    if (changes > 0) {
      for (let i = 0; i < changes; i++) {
        append({
          placeholder: {
            zhHK: "",
            enUS: "",
          },
          sets: [
            {
              zhHK: "",
              enUS: "",
              others: false,
              points: 0,
            },
          ],
        });
      }
    } else if (changes < 0) {
      const negativeChanges = -changes;
      const index = originalValue - 1;
      for (let i = 0; i < negativeChanges; i++) {
        remove(index);
      }
    }
  }, [append, changes, originalValue, remove]);

  return (
    <>
      {fields.map((multipleAnswerSetAnswerSet, k) => {
        return (
          <Grid container key={multipleAnswerSetAnswerSet.id} spacing={2}>
            <Grid item xs={12} md={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} md={12}>
              {t("createForm.formContent.answerSet")} {k + 1}
            </Grid>

            <Grid item xs={6}>
              <Controller
                name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answerSets.${k}.placeholder.enUS` as const}
                control={control}
                rules={{
                  required: "Placeholder (English) is required",
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    label="Placeholder (English)"
                    error={errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.multipleAnswerSet?.answerSets?.[k]?.placeholder?.enUS !== undefined && true}
                    helperText={
                      errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.multipleAnswerSet?.answerSets?.[k]?.placeholder?.enUS !== undefined &&
                      `${errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.multipleAnswerSet?.answerSets?.[k]?.placeholder?.enUS?.message}`
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.multipleAnswerSet.answerSets.${k}.placeholder.zhHK` as const}
                control={control}
                rules={{
                  required: "Placeholder (Traditional Chinese) is required",
                }}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    variant="filled"
                    label="Placeholder (Traditional Chinese)"
                    error={errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.multipleAnswerSet?.answerSets?.[k]?.placeholder?.zhHK !== undefined && true}
                    helperText={
                      errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.multipleAnswerSet?.answerSets?.[k]?.placeholder?.zhHK !== undefined &&
                      `${errors?.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.multipleAnswerSet?.answerSets?.[k]?.placeholder?.zhHK?.message}`
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <InnerMultipleAnswerSetSession
                nestIndex={nestIndex}
                secondNestIndex={secondNestIndex}
                k={k}
                {...{
                  control,
                  register,
                  watch,
                  setValue,
                  getValues,
                  errors,
                }}
              />
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}

export default MultipleAnswerSetSession;
