import { Button, Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridFilterModel, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import React, { ReactNode } from "react";
import { CandidateInfo, EvaluationForm, Interviewee, InterviewTimeSlot } from "../../../../interfaces/interview";
import AddCandidatePopover from "../AddCandidatePopover";
import AddTimeSlotModal from "../AddTimeSlotModal";
import GroupInterviewPerformanceRatingModal from "../GroupInterviewPerformanceRatingModal";
import TimeSlotFilterMenu from "../TimeSlotFilterMenu";

interface TimeSlotDetailToolbarProps {
  CustomizeViewMenu: ReactNode;
  applicationList: Interviewee[];
  candidates: CandidateInfo[];
  evaluationForm: EvaluationForm[];
  timeSlot: InterviewTimeSlot;
}

function TimeSlotDetailToolbar(props: TimeSlotDetailToolbarProps) {
  const { CustomizeViewMenu, applicationList, candidates, evaluationForm, timeSlot } = props;

  return (
    <GridToolbarContainer sx={{ display: "block" }}>
      <Grid container>
        <Grid xs={6}>
          <GridToolbarQuickFilter />
          {CustomizeViewMenu}
        </Grid>
        <Grid xs={6} display="flex" justifyContent="flex-end" alignItems="center" className="buttonGroups">
          <Button
            className="buttonGroupSent"
            variant="outlined"
            size="small"
            // aria-controls={openCustomizeView ? "basic-menu" : undefined}
            // aria-haspopup="true"
            // aria-expanded={openCustomizeView ? "true" : undefined}
            // onClick={handleClick}
          >
            EXPORT
          </Button>
          <GroupInterviewPerformanceRatingModal candidates={candidates} evaluationForm={evaluationForm} />
          <AddCandidatePopover applicationList={applicationList} timeSlot={timeSlot} />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default TimeSlotDetailToolbar;
