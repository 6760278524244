import produce from "immer";
import { ApplicationListActions } from "./actions";

export interface ApplicationListState {
  id: number;
  schoolCode: string;
  titleZhHk: string;
  titleEnUs: string;
  status: string;
  createdAt: string;
  updatedAt: string;
}

const initialState: ApplicationListState | null = null;

export function applicationListReducer(
  // eslint-disable-next-line default-param-last
  state: ApplicationListState | null = initialState,
  action: ApplicationListActions
): ApplicationListState | null {
  const { id, schoolCode, titleZhHk, titleEnUs, status, createdAt, updatedAt } = action;
  switch (action.type) {
    case "@@applicaitonList/FETCH_APPLICATION_LIST_SUCCESS":
      if (state !== null) {
        return produce(state, (nextState) => {
          nextState.id = action.id;
        });
      }
      return {
        id,
        schoolCode,
        titleZhHk,
        titleEnUs,
        status,
        createdAt,
        updatedAt,
      };

    default:
      return state;
  }
}
