import React from "react";
import { useTranslation } from "react-i18next";
import LanguageIcon from "@mui/icons-material/Language";
import { IconButton, Menu, MenuItem } from "@mui/material";
import { changeLanguage } from "../../redux/localization/actions";
import { useAppDispatch } from "../../redux/hooks";

function SwitchLanguageButton() {
  const { t, i18n } = useTranslation(["common", "translation"]);
  const dispatch = useAppDispatch();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchToZhHk = () => {
    i18n.changeLanguage("zhHK");
    dispatch(changeLanguage("zhHK"));
    handleClose();
  };

  const switchToEnUs = () => {
    i18n.changeLanguage("enUS");
    dispatch(changeLanguage("enUS"));
    handleClose();
  };
  return (
    <>
      <IconButton id="basic-button" aria-controls={open ? "basic-menu" : undefined} aria-haspopup="true" aria-expanded={open ? "true" : undefined} onClick={handleClick}>
        <LanguageIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={switchToZhHk}>{t("zhHK")}</MenuItem>
        <MenuItem onClick={switchToEnUs}>{t("enUS")}</MenuItem>
      </Menu>
    </>
  );
}

export default SwitchLanguageButton;
