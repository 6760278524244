// import ClearIcon from "@mui/icons-material/Clear";
// import { Box, Button, Grid, Modal } from "@mui/material";
// import React, { ReactNode, useEffect, useState } from "react";
// import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
// import { useTranslation } from "react-i18next";
// import { AddInterviewTimeSlotForm, InterviewTimeSlot } from "../../../interfaces/interview";
// import modalStyle from "../../utils/modalStyle";
// import TimeSlotForm from "./TimeSlotForm";

// interface EditTimeSlotModalProps {
//     timeSlot: InterviewTimeSlot;
//     button: ReactNode;
// }

// function EditTimeSlotModal(props: EditTimeSlotModalProps) {
//     const { button } = props;
//     const { t } = useTranslation();
//     const methods = useForm<AddInterviewTimeSlotForm>();
//     const [open, setOpen] = useState(false);

//     const handleOpen = () => {
//         setOpen(true);
//     };

//     const handleClose = () => {
//         methods.reset();
//         setOpen(false);
//     };

//     const onSubmit: SubmitHandler<AddInterviewTimeSlotForm> = async (data) => {
//         console.log("data =", data);
//     };

//     useEffect(() => {
//         // methods.
//     }, []);

//     return (
//         <>
//             <Grid style={{ display: "inline" }} onClick={handleOpen}>
//                 {button}
//             </Grid>
//             <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
//                 <Box sx={modalStyle}>
//                     <FormProvider {...methods}>
//                         <form onSubmit={methods.handleSubmit(onSubmit)}>
//                             <Grid container spacing={2}>
//                                 <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
//                                     <span style={{ fontWeight: 500, fontSize: "18px", lineHeight: "21px", letterSpacing: "0.15px" }}>
//                                         Interview Performance Rating
//                                     </span>
//                                     <Button variant="text" onClick={handleClose} className="modalClearBtn">
//                                         <ClearIcon />
//                                     </Button>
//                                 </Grid>
//                                 <TimeSlotForm />
//                                 <Grid item xs={12} display="flex" justifyContent="flex-end">
//                                     <Button variant="text" onClick={handleClose}>
//                                         {t("view.cancel")}
//                                     </Button>
//                                     <Button variant="text" type="submit">
//                                         Edit
//                                     </Button>
//                                 </Grid>
//                             </Grid>
//                         </form>
//                     </FormProvider>
//                 </Box>
//             </Modal>
//         </>
//     );
// }

import ClearIcon from "@mui/icons-material/Clear";
import { Box, Button, Grid, Modal } from "@mui/material";
import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { InterviewTimeSlot } from "../../../interfaces/interview";
import modalStyle from "../../utils/modalStyle";
import EditTimeSlotForm from "./EditTimeSlotForm";

interface EditTimeSlotModalProps {
  timeSlot: InterviewTimeSlot;
}

function EditTimeSlotModal(props: EditTimeSlotModalProps) {
  const { timeSlot } = props;
  const { t } = useTranslation();
  const methods = useForm<InterviewTimeSlot>({
    defaultValues: timeSlot,
  });
  // const methods = useForm<AddInterviewTimeSlotForm>({
  //     defaultValues: {
  //         dateRange: [new Date(timeSlot.date), new Date(timeSlot.date)],
  //         time: timeSlot.time,
  //         duration: 20,
  //         quota: timeSlot.quota,
  //         centre: timeSlot.location.courseCentre,
  //         interviewer: { name: "test", available: true },
  //         location: [timeSlot.location.room],
  //     },
  // });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmit = () => {
    setOpen(false);
    console.log("time slot form =", methods.getValues());
  };

  return (
    <>
      <Button variant="contained" size="small" className="buttonAction buttonActionDarkBlue" onClick={handleOpen}>
        {t("view.edit")}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={modalStyle}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                  <div>Edit Time Slot</div>
                  <Button variant="text" onClick={handleClose} className="modalClearBtn">
                    <ClearIcon />
                  </Button>
                </Grid>
                <EditTimeSlotForm />
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button variant="text" onClick={handleClose}>
                    {t("view.cancel")}
                  </Button>
                  <Button variant="text" type="submit">
                    {t("view.edit")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default EditTimeSlotModal;
