import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { Autocomplete, Checkbox, Chip, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { DesktopDatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers-pro";
import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";
import { Interviewer, InterviewTimeSlot } from "../../../interfaces/interview";

function EditTimeSlotForm() {
  const methods = useFormContext<InterviewTimeSlot>();

  const [interviewer, setInterviewer] = useState<Interviewer[]>([
    { name: "Yeri Suen", available: true },
    { name: "aaaaa", available: true },
    { name: "bbbbb", available: true },
    { name: "ccccc", available: false },
    { name: "ddddd", available: false },
  ]);

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <Grid item xs={12}>
        {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Controller
            name={"date" as const}
            control={methods.control}
            rules={{ required: "Interview Title (English) is required" }}
            render={({ field }) => (
              <DesktopDatePicker
                {...field}
                onChange={(newValue) => {
                  field.onChange(newValue);
                }}
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            )}
          />
        }
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name={"startTime" as const}
              control={methods.control}
              rules={{ required: "Interview Title (Traditional Chinese) is required" }}
              render={({ field }) => (
                // <TextField
                //     {...field}
                //     fullWidth
                //     label="Start Time"
                //     error={methods.formState.errors?.time?.start && true}
                //     helperText={
                //         methods.formState.errors?.time?.start &&
                //         `${methods.formState.errors?.time?.start?.message}`
                //     }
                // />
                <TimePicker
                  // label="Basic example"
                  // value={value}
                  {...field}
                  label="Start Time"
                  // onChange={(newValue) => {
                  //     setValue(newValue);
                  // }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={methods.formState.errors?.startTime && true}
                      helperText={methods.formState.errors?.startTime && `${methods.formState.errors?.startTime?.message}`}
                    />
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name={"endTime" as const}
              control={methods.control}
              rules={{ required: "End Time is required" }}
              render={({ field }) => (
                <TimePicker
                  {...field}
                  label="End Time"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={methods.formState.errors?.endTime && true}
                      helperText={methods.formState.errors?.endTime && `${methods.formState.errors?.endTime?.message}`}
                    />
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={1}>
          <Grid item xs={5}>
            <Controller
              name={"quota" as const}
              control={methods.control}
              rules={{ required: "Quota is required" }}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  type="number"
                  label="Quota"
                  onChange={(event) => {
                    if (parseInt(event.target.value) >= 0 || event.target.value === "") {
                      field.onChange(event.target.value);
                    }
                  }}
                  error={methods.formState.errors?.quota && true}
                  helperText={methods.formState.errors?.quota && `${methods.formState.errors?.quota?.message}`}
                />
              )}
            />
          </Grid>
          <Grid item xs={7}>
            <FormControl fullWidth>
              <InputLabel id="select-centre-label">Course Centre</InputLabel>
              <Controller
                name={"centre" as const}
                control={methods.control}
                rules={{ required: "Course Centre is required" }}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="select-centre-label"
                    label="Course Centre"
                    fullWidth
                    onChange={(event) => {
                      field.onChange(event.target.value);
                    }}
                  >
                    <MenuItem value="blue">Blue</MenuItem>
                    <MenuItem value="red">Orange</MenuItem>
                    <MenuItem value="orange">Red</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={"interviewer" as const}
          control={methods.control}
          rules={{ required: "Interviewer is required" }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={interviewer}
              disableCloseOnSelect
              getOptionLabel={(option) => option.name}
              getOptionDisabled={(option) => !option.available}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={option.available ? <CheckBoxOutlineBlankIcon /> : <SquareRoundedIcon />}
                    checkedIcon={<CheckBoxIcon color="primary" />}
                    style={{ marginRight: 8 }}
                    checked={option.available && selected}
                    disabled={option.available}
                  />
                  {option.name}
                </li>
              )}
              renderInput={(params) => <TextField {...params} label="Interviewer" />}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  // eslint-disable-next-line react/jsx-key
                  <Chip sx={{ background: "#E6F1FC" }} color="primary" variant="outlined" label={option.name} {...getTagProps({ index })} />
                ))
              }
              onChange={(event, data) => field.onChange(data)}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={"room" as const}
          control={methods.control}
          rules={{ required: "Location is required" }}
          render={({ field }) => (
            // <Autocomplete
            //     {...field}
            //     multiple
            //     freeSolo
            //     options={[]}
            //     onChange={(event, data) => field.onChange(data)}
            //     renderInput={(params) => <TextField {...params} label="Location" />}
            //     renderTags={(value, getTagProps) =>
            //         value.map((option, index) => (
            //             // eslint-disable-next-line react/jsx-key
            //             <Chip
            //                 sx={{ background: "#E6F1FC" }}
            //                 color="primary"
            //                 variant="outlined"
            //                 label={option}
            //                 {...getTagProps({ index })}
            //             />
            //         ))
            //     }
            // />
            <TextField {...field} fullWidth label="Room" onChange={(event) => field.onChange(event.target.value)} />
          )}
        />
      </Grid>
    </LocalizationProvider>
  );
}

export default EditTimeSlotForm;
