import { CreateInterviewCriteriaFormInput, CreateInterviewEvaluationFormInput, CreateInterviewFormInput } from "../interfaces/interview";
import client from "./interceptors";

export async function findAllInterviewsByStageIdAndSchoolCode(listId: number, stageId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/interview`);
  const result = res.data;
  return result;
}

export async function findAllInterviewsByStageIdAndSchoolCodeAndTitle(listId: number, stageId: number, title: string) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/interview/title/${title}`);
  const result = res.data;
  return result;
}

export async function createInterview(listId: number, stageId: number, data: CreateInterviewFormInput) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/interview`, data);
  const result = res.data;
  return result;
}

export async function createInterviewCriteriaById(listId: number, stageId: number, interviewId: number, data: CreateInterviewCriteriaFormInput) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/interview/${interviewId}/criteria`, data);
  const result = res.data;
  return result;
}

export async function createInterviewEvaluationFormById(listId: number, stageId: number, interviewId: number, data: CreateInterviewEvaluationFormInput) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/interview/${interviewId}/evaluationForm`, data);
  const result = res.data;
  return result;
}

export async function editInterviewById(listId: number, stageId: number, interviewId: number, data: CreateInterviewFormInput) {
  const res = await client.put(`applicationList/${listId}/applicationStage/${stageId}/interview/${interviewId}`, data);
  const result = res.data;
  return result;
}

export async function deleteInterviewById(listId: number, stageId: number, interviewId: number) {
  const res = await client.delete(`applicationList/${listId}/applicationStage/${stageId}/interview/${interviewId}`);
  const result = res.data;
  return result;
}
