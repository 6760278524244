import "./UpdateApplicantStatusButton.css";
import React, { useState } from "react";
import { FileUploader } from "react-drag-drop-files";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import ClearIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { Box, Button, Grid, Modal } from "@mui/material";
import { generateCurrentExcelForSubmissionStatus, generateTemplateExcelForSubmissionStatus, importApplicationStatusExcel } from "../../../api/submission";
import modalStyle from "../../utils/modalStyle";
import formatBytes from "../../utils/formatFileSize";

const fileTypes = ["xlsx", "XLSX"];

export interface UpdateApplicantStatusFormInput {
  file: File;
}

interface UpdateApplicantStatusButtonProps {
  onRefresh: () => Promise<void>;
}

function UpdateApplicantStatusButton(props: UpdateApplicantStatusButtonProps) {
  const { onRefresh } = props;
  const { t } = useTranslation();
  const params = useParams();
  const [open, setOpen] = useState<boolean>(false);

  const [file, setFile] = useState<File | null>(null);
  const handleFileChange = (selectedFile: File) => {
    setFile(selectedFile);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const convertBase64 = (excelFile: File) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(excelFile);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const { handleSubmit } = useForm<UpdateApplicantStatusFormInput>();
  const onSubmit: SubmitHandler<UpdateApplicantStatusFormInput> = async () => {
    if (params && params.applicationListId && params.formId && file) {
      const applicationListId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      const formData = new FormData();
      formData.append("file", file);
      const result = await importApplicationStatusExcel(applicationListId, formId, formData);
      if (result?.success) {
        await onRefresh();
        setOpen(false);
      }
    }
  };

  return (
    <div>
      <Button className="buttonGroupSent" variant="outlined" size="small" onClick={handleOpen}>
        {t("view.updateApplicantStatus")}
      </Button>
      <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={modalStyle}>
          <Grid container spacing={2}>
            <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
              <div>{t("view.updateApplicantStatusLowerCase")}</div>
              <Button variant="text" onClick={handleClose} className="modalClearBtn">
                <ClearIcon />
              </Button>
            </Grid>

            <Grid item xs={12}>
              <Button
                onClick={() => {
                  if (params && params.applicationListId && params.formId) {
                    const applicationListId = parseInt(params.applicationListId);
                    const applicationStageId = parseInt(params.formId);
                    generateTemplateExcelForSubmissionStatus(applicationListId, applicationStageId);
                  }
                }}
              >
                <div className="udateApplicantStatusButton_DownloadBtnContainer">
                  <DownloadIcon className="udateApplicantStatusButton_DownloadBtn" />
                  {t("view.updateApplicantStatusButton.downloadExcelTemplate")}
                </div>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                onClick={() => {
                  if (params && params.applicationListId && params.formId) {
                    const applicationListId = parseInt(params.applicationListId);
                    const applicationStageId = parseInt(params.formId);
                    generateCurrentExcelForSubmissionStatus(applicationListId, applicationStageId);
                  }
                }}
              >
                <div className="udateApplicantStatusButton_DownloadBtnContainer">
                  <DownloadIcon className="udateApplicantStatusButton_DownloadBtn" />
                  {t("view.updateApplicantStatusButton.downloadCurrentExcelFile")}
                </div>
              </Button>
            </Grid>
            <Grid item xs={12}>
              <FileUploader
                handleChange={handleFileChange}
                name="file"
                types={fileTypes}
                hoverTitle=""
                // eslint-disable-next-line react/no-children-prop
                children={
                  <div>
                    <div className="fileUploaderContainer">
                      <div className="fileUploaderDragAndDropElem">
                        <CloudUploadIcon fontSize="large" />
                      </div>
                      <div className="fileUploaderDragAndDropElem">
                        {t("view.updateApplicantStatusButton.dragAndDropOr")}
                        <span className="fileUploaderDragAndDropBrowse">{t("view.updateApplicantStatusButton.browse")}</span>
                      </div>
                    </div>
                  </div>
                }
              />
            </Grid>
            {file !== null && (
              <Grid item xs={12}>
                <div className="fileUploaderAttachmentContainer">
                  <div className="fileUploaderAttachmentLeftElem">
                    <InsertDriveFileIcon className="fileUploaderAttachmentBlue fileUploaderAttachmentIcon" />
                    <div className="fileUploaderAttachmentBlue">{file.name}</div>
                    <div className="fileUploaderAttachmentFileSize">({formatBytes(file.size)})</div>
                  </div>
                  <div>
                    <ClearIcon
                      className="fileUploaderAttachmentRightElem"
                      onClick={() => {
                        setFile(null);
                      }}
                    />
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <Button variant="text" className="modalSubmitBtn" onClick={handleClose}>
                {t("view.cancel")}
              </Button>
              <Button variant="text" className={`${file !== null && "modalSubmitBtn"}`} type="submit" disabled={file === null && true} onClick={handleSubmit(onSubmit)}>
                {t("view.updateApplicantStatusButton.update")}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}

export default UpdateApplicantStatusButton;
