import React, { ChangeEvent, useState } from "react";
import { Button, Grid, Popover, TextField } from "@mui/material";
import { Control, UseFormRegister, UseFormSetValue, UseFormGetValues, UseFormWatch, FieldErrors, useFieldArray, Controller } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";

interface Props {
  nestIndex: number;
  control: Control<CreateApplicationStageFormInput, object>;
  register: UseFormRegister<CreateApplicationStageFormInput>;
  setValue: UseFormSetValue<CreateApplicationStageFormInput>;
  getValues: UseFormGetValues<CreateApplicationStageFormInput>;
  watch: UseFormWatch<CreateApplicationStageFormInput>;
  errors: FieldErrors<CreateApplicationStageFormInput>;
}

function MultipleChoicesQuestions({ nestIndex, control, watch, setValue, getValues, errors }: Props) {
  const { t } = useTranslation();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `fieldContent.${nestIndex}.singleQuestion.multipleChoiceQuestions`,
  });

  const onAppend = () => {
    append({
      enUS: "",
      zhHK: "",
      others: false,
      points: 0,
    });
  };

  const onAppendOthers = () => {
    append({
      enUS: "",
      zhHK: "",
      others: true,
      points: 0,
    });
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  // Functions for point calculation
  const [optionPoint, setOptionPoint] = useState<null | HTMLElement>(null);
  const [selectedIndexForPoint, setSelectedIndexForPoint] = useState(-1);

  const handleClickForOptionPoint = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setOptionPoint(event.currentTarget);
    setSelectedIndexForPoint(index);
  };

  const handleCloseForOptionPoint = () => {
    setOptionPoint(null);
  };

  const openForOptionPoint = Boolean(optionPoint);
  const optionPointId = openForOptionPoint ? "simple-popover" : undefined;

  return (
    <>
      {fields.map((multipleChoiceQuestion, k) => {
        return (
          <Grid container spacing={2} alignItems="center" key={multipleChoiceQuestion.id}>
            <Grid item xs={1} sm={0.6} md={0.6} className="singleChoiceRadioButtonUncheckedIcon">
              <CheckBoxOutlineBlankIcon />
            </Grid>

            {getValues().fieldContent[nestIndex].singleQuestion.multipleChoiceQuestions[k].others === false && (
              <Grid item xs={5} sm={3.5} md={4}>
                <Controller
                  name={`fieldContent.${nestIndex}.singleQuestion.multipleChoiceQuestions.${k}.enUS` as const}
                  control={control}
                  rules={{ required: `Option ${k + 1} (English) is required` }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      label={`${t("createForm.formContent.option")} ${k + 1} ${t("createForm.formContent.english")}`}
                      error={errors.fieldContent?.[nestIndex]?.singleQuestion?.multipleChoiceQuestions?.[k]?.enUS && true}
                      helperText={errors.fieldContent?.[nestIndex]?.singleQuestion?.multipleChoiceQuestions?.[k]?.enUS?.message}
                    />
                  )}
                />
              </Grid>
            )}

            {getValues().fieldContent[nestIndex].singleQuestion.multipleChoiceQuestions[k].others === true && (
              <Grid item xs={10} sm={7} md={8} sx={{ minHeight: 64 }} alignItems="center" display="flex">
                {t("createForm.formContent.others")}
              </Grid>
            )}

            {getValues().fieldContent[nestIndex].singleQuestion.multipleChoiceQuestions[k].others === false && (
              <Grid item xs={5} sm={3.5} md={4}>
                <Controller
                  name={`fieldContent.${nestIndex}.singleQuestion.multipleChoiceQuestions.${k}.zhHK` as const}
                  control={control}
                  rules={{ required: `Option ${k + 1} (Chinese) is required` }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      label={`${t("createForm.formContent.option")} ${k + 1} ${t("createForm.formContent.chinese")}`}
                      error={errors.fieldContent?.[nestIndex]?.singleQuestion?.multipleChoiceQuestions?.[k]?.zhHK && true}
                      helperText={errors.fieldContent?.[nestIndex]?.singleQuestion?.multipleChoiceQuestions?.[k]?.zhHK?.message}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={10} sm={3.4} md={2.4}>
              <Button className="yesNoAddPointBtnContainer" variant="outlined" size="small">
                <Grid container className="yesNoAddPointBtnElem">
                  {getValues().fieldContent[nestIndex].singleQuestion.multipleChoiceQuestions[k].points > 0 ? null : (
                    <Grid
                      item
                      xs={3}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        handleClickForOptionPoint(event, k);
                      }}
                    >
                      <div className="yesNoAddPointBtn">
                        <AddIcon fontSize="small" />
                      </div>
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={9}
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      handleClickForOptionPoint(event, k);
                    }}
                  >
                    {getValues().fieldContent[nestIndex].singleQuestion.multipleChoiceQuestions[k].points > 0
                      ? `${getValues().fieldContent[nestIndex].singleQuestion.multipleChoiceQuestions[k].points} point`
                      : t("createForm.formContent.addPoint")}
                  </Grid>
                  {getValues().fieldContent[nestIndex].singleQuestion.multipleChoiceQuestions[k].points > 0 ? (
                    <Grid
                      item
                      xs={3}
                      className="yesNoRemovePointBtn"
                      onClick={(e: React.MouseEvent<HTMLElement>) => {
                        e.stopPropagation();
                        watch([`fieldContent.${nestIndex}.singleQuestion.multipleChoiceQuestions.${k}.points`]);
                        setValue(`fieldContent.${nestIndex}.singleQuestion.multipleChoiceQuestions.${k}.points`, 0);
                      }}
                    >
                      <ClearIcon fontSize="small" />
                    </Grid>
                  ) : null}
                </Grid>
              </Button>
              <Popover
                id={optionPointId}
                open={openForOptionPoint}
                anchorEl={optionPoint}
                onClose={handleCloseForOptionPoint}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <div className="yesNoQuestionsContainer">
                  <div>
                    <Controller
                      name={`fieldContent.${nestIndex}.singleQuestion.multipleChoiceQuestions.${selectedIndexForPoint}.points` as const}
                      control={control}
                      rules={{
                        required: "Point is required",
                      }}
                      defaultValue={0}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size="small"
                          variant="standard"
                          label={t("createForm.formContent.answerPoint")}
                          inputProps={{ type: "number" }}
                          onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                            if (parseInt(e.target.value) >= 0) {
                              field.onChange(parseInt(e.target.value));
                            }
                          }}
                        />
                      )}
                    />
                  </div>
                  <div className="yesNoQuestionsElem">
                    <Button variant="text" onClick={handleCloseForOptionPoint}>
                      {t("createForm.formContent.confirm")}
                    </Button>
                  </div>
                </div>
              </Popover>
            </Grid>
            <Grid item xs={1} sm={1} md={1} alignItems="center" display="flex" className="singleChoiceLinkRemoveIcon">
              <ClearIcon
                onClick={() => {
                  onRemove(k);
                }}
              />
            </Grid>
          </Grid>
        );
      })}

      <Grid container alignItems="center" className="singleChoiceAddOthers">
        <Grid item xs={1} sm={0.6} md={0.6} className="singleChoiceRadioButtonUncheckedIcon">
          <CheckBoxOutlineBlankIcon />
        </Grid>
        <Grid item xs={11} sm={11.4} md={11.4}>
          <div className="singleChoiceAddMessageContainer">
            <Button onClick={onAppend} className="singleChoiceAddMessage">
              {t("createForm.formContent.addNewOption")}
            </Button>
            <span> {t("createForm.formContent.or")} </span>
            <Button className="singleChoiceAddOthersMessage" onClick={onAppendOthers}>
              {t("createForm.formContent.addOther")}
            </Button>
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default MultipleChoicesQuestions;
