import "./ApplicationList.css";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Box, Button, Grid, IconButton } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { findApplicationStageAndSubmissionByUserId } from "../../api/user";
import ctfLogo from "../../images/CTF-logo.png";
import vpukLogo from "../../images/vpuk.png";
import document from "../../images/document.png";
import { ApplicationStageByFormIdAndUserId } from "../../interfaces/applicationStage";
import { ApplicationStageFormStatus } from "../../models/applicationStageFormStatus";
import { studentLogoutSuccess } from "../../redux/auth/actions";
import { RootState } from "../../redux/store";
import SwitchLanguageButton from "./SwitchLanguageButton";

function ApplicationList() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [data, setData] = useState<ApplicationStageByFormIdAndUserId>({
    applicationForms: [],
    registerForms: [],
  });
  const userId = useAppSelector((state: RootState) => state.auth.user);

  function onClickSubmissionForm(listId: number, stageId: number) {
    navigate(`${listId}/stage/${stageId}`);
  }

  useEffect(() => {
    const fetchData = async () => {
      if (userId !== undefined) {
        const result = await findApplicationStageAndSubmissionByUserId(userId);
        if (result.success) {
          setData({ applicationForms: result.applicationForms, registerForms: result.registrationForms });
        }
        setIsLoading(false);
      }
    };

    if (userId !== undefined) {
      fetchData();
    }
  }, [userId]);

  return (
    <Grid sx={{ m: 3 }}>
      <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center" sx={{ m: 1 }}>
        <Button
          variant="outlined"
          className="applicationListLeaveBtn"
          onClick={() => {
            dispatch(studentLogoutSuccess());
          }}
        >
          {t("submission.leaveApplication")}
        </Button>
        <SwitchLanguageButton />
      </Grid>
      {lastSchool === "vpp" && (
        <Grid item xs={12} display="flex" justifyContent="center" sx={{ m: 5 }}>
          <img src={ctfLogo} alt="School Logo" width={300} height={86} />
        </Grid>
      )}
      {lastSchool === "vpuk" && (
        <Grid item xs={12} display="flex" justifyContent="center" sx={{ m: 5 }}>
          <img src={vpukLogo} alt="School Logo" width={300} height={86} />
        </Grid>
      )}

      <Grid container spacing={2}>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <div className="applicationListTitle"> {t("submission.registerItem")}</div>
          {isLoading && <div>Loading...</div>}
          {!isLoading && data.registerForms !== undefined && data.registerForms.length === 0 && <div>{t("view.recordNotExist")}</div>}
          {!isLoading &&
            data.registerForms.length !== 0 &&
            data.registerForms.map((form) => (
              <Box
                className="applicationListContainer"
                key={form.id}
                onClick={() => {
                  onClickSubmissionForm(form.applicationListId, form.id);
                }}
              >
                <div className="applicationListContainerInside">
                  <img src={document} width="50" height="50" alt="img"></img>
                  <div className="applicationListDetailsContainer">
                    <div className="applicationListDetailsTitle">
                      <div>{i18n.resolvedLanguage === "zhHK" ? form.titleZhHk : form.titleEnUs}</div>
                      <div
                        className={
                          form.submissions.length !== 0 && form.submissions[0].formStatus === ApplicationStageFormStatus.COMPLETED
                            ? "applicationListCompletedStatus"
                            : "applicationListIncompleteStatus"
                        }
                      >
                        {form.submissions.length !== 0 && form.submissions[0].formStatus === ApplicationStageFormStatus.COMPLETED
                          ? t("submission.submitted")
                          : t("submission.applicationNotCompleted")}
                      </div>
                    </div>
                    <div className="applicationListDetailsPeriod">
                      {t("submission.applicationPeriod")}: {moment(new Date()).format("YYYY-MM-DD HH:mm")} {t("submission.to")} {moment(form.deadline).format("YYYY-MM-DD HH:mm")}
                    </div>
                  </div>
                </div>
                {(form.submissions.length === 0 || (form.submissions.length !== 0 && form.submissions[0].formStatus) === ApplicationStageFormStatus.INCOMPLETE) && (
                  <IconButton
                    aria-label="arrowForward"
                    onClick={() => {
                      onClickSubmissionForm(form.applicationListId, form.id);
                    }}
                  >
                    <ArrowForwardIosIcon className="applicationListDetailsBtn" />
                  </IconButton>
                )}
              </Box>
            ))}
        </Grid>
        <Grid item xs={3}></Grid>

        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <div className="applicationListTitle">{t("submission.applicationItem")}</div>
          <div className="applicationListDetailsTitle">{t("submission.applicationItemDesc")}</div>
          {isLoading && <div>Loading...</div>}
          {!isLoading && data.applicationForms.length === 0 && <div>{t("view.recordNotExist")}</div>}
          {data.applicationForms.length !== 0 &&
            data.applicationForms.map((form) => (
              <Box
                className="applicationListContainer"
                key={form.id}
                onClick={() => {
                  onClickSubmissionForm(form.applicationListId, form.id);
                }}
              >
                <div className="applicationListContainerInside">
                  <img src={document} width="50" height="50" alt="img"></img>
                  <div className="applicationListDetailsContainer">
                    <div className="applicationListDetailsTitle">
                      <div>{i18n.resolvedLanguage === "zhHK" ? form.titleZhHk : form.titleEnUs}</div>
                      <div
                        className={
                          form.submissions.length !== 0 && form.submissions[0].formStatus === ApplicationStageFormStatus.COMPLETED
                            ? "applicationListCompletedStatus"
                            : "applicationListIncompleteStatus"
                        }
                      >
                        {form.submissions.length !== 0 && form.submissions[0].formStatus === ApplicationStageFormStatus.COMPLETED
                          ? t("submission.submitted")
                          : t("submission.applicationNotCompleted")}
                      </div>
                    </div>
                    <div className="applicationListDetailsPeriod">
                      {t("submission.applicationPeriod")}：{moment(new Date()).format("YYYY-MM-DD HH:mm")} {t("submission.to")} {moment(form.deadline).format("YYYY-MM-DD HH:mm")}
                    </div>
                  </div>
                </div>
                {(form.submissions.length === 0 || (form.submissions.length !== 0 && form.submissions[0].formStatus) === ApplicationStageFormStatus.INCOMPLETE) && (
                  <IconButton
                    aria-label="arrowForward"
                    onClick={() => {
                      onClickSubmissionForm(form.applicationListId, form.id);
                    }}
                  >
                    <ArrowForwardIosIcon className="applicationListDetailsBtn" />
                  </IconButton>
                )}
              </Box>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ApplicationList;
