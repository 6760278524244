import "../create/CreateApplicationForm.css";
import React, { useEffect, useState, SyntheticEvent, MouseEvent } from "react";
import { Controller, SubmitHandler, useForm, useFieldArray } from "react-hook-form";
import { DragDropContext, Draggable, Droppable, DropResult, OnDragEndResponder } from "react-beautiful-dnd";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  Menu,
  MenuItem,
  Select,
  Tab,
  TextField,
  Tooltip,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import FilterListIcon from "@mui/icons-material/FilterList";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import AddIcon from "@mui/icons-material/Add";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { DataGridPro, GridColDef, GridColumnVisibilityModel, GridFilterModel } from "@mui/x-data-grid-pro";
import day from "dayjs";
import moment from "moment";
import {
  HeadCell,
  InterviewTimeSlot,
  MultipleLangAns,
  EvaluationForm,
  LinearScale,
  MultipleChoiceAnswer,
  MultipleChoiceOption,
  Interview,
  Interviewee,
  Info,
  Interviewer,
} from "../../../interfaces/interview";
import { ApplicationList } from "../../../interfaces/applicationList";
import { getApplicationListById } from "../../../api/applicationList";
import InterviewSettingModal from "./InterviewSettingModal";
import AddTimeSlotModal from "./AddTimeSlotModal";
import RowAction from "./RowAction";
import TimeSlotActionButtons from "./buttonGroup/TimeSlotActionButtons";
import TimeSlotFilterMenu from "./TimeSlotFilterMenu";
import TimeSlotToolbar from "./toolbar/TimeSlotToolbar";
import { useAppDispatch } from "../../../redux/hooks";
import { createInterviewEvaluationFormById, findAllInterviewsByStageIdAndSchoolCode } from "../../../api/interview";
import AllCandidatesActionButtons from "./buttonGroup/AllCandidatesActionButtons";
import AllCandidatesToolbar from "./toolbar/AllCandidatesToolbar";
import CustomizeViewMenu from "./DraggableMenu/CustomizeViewMenu";

export interface EvaluationFormInput {
  formTitle: MultipleLangAns;
  ratingType: string;
  itemTitle: MultipleLangAns;
  fullMarks?: number;
  description?: MultipleLangAns;
  minLinearScale: string;
  maxLinearScale: string;
  minLinearScaleDescription: MultipleLangAns;
  maxLinearScaleDescription: MultipleLangAns;
  multipleChoiceOption: MultipleChoiceOption[];
  evaluationFormItem: EvaluationForm[];
}

const titleStyle = {
  fontSize: 20,
  color: "#303133",
  fontStyle: "Medium",
  marginBottom: 1,
};

const useStyles = makeStyles({
  hideRightSeparator: {
    "& > .MuiDataGrid-columnSeparator": {
      visibility: "hidden",
    },
  },
});

type Order = "asc" | "desc";

function EditInterview() {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { t, i18n } = useTranslation();
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const urlParams = useParams();
  const lastSchool = sessionStorage.getItem("lastSchool");

  const [buttonValue, setValueButton] = useState<string>("1");
  const [buttonStatus, setButtonStatus] = useState<string>("TIME SLOT");
  const [openCustomizeView, setOpenCustomizeView] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [interviewTimeSlotOrder, setInterviewTimeSlotOrder] = useState<Order>("asc");
  const [interviewTimeSlotOrderBy, setInterviewTimeSlotOrderBy] = useState<string | number>("interviewDate");
  const [openAddEvalutionForm, setOpenAddEvalutionForm] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [addDescription, setAddDescription] = useState(false);
  const [addMarks, setAddMarks] = useState(false);
  const [fullMarksPrevValue, setFullMarksPrevValue] = useState(0);
  const [totalFullMarks, setTotalFullMarks] = useState(0);
  const [hidden, setHidden] = useState(false);
  const [interview, setInterview] = useState<Interview>({
    id: 1,
    colour: "#33CCCC",
    titleEnUs: "Ballet",
    titleZhHk: "芭蕾舞",
    criteria: {
      title: {
        enUs: "[7 - 8 yrs] Ballet for Girl",
        zhHk: "[7 - 8 歲] 女子芭蕾舞",
      },
      answerPoints: [
        {
          type: "Yes No Question",
          question: {
            position: "Section 1 - Question 1",
            question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
          },
          points: [
            { type: "CHOICE", answer: "Yes", points: 0 },
            { type: "CHOICE", answer: "No", points: 0 },
          ],
          linkedQuestion: [
            {
              type: "Yes No Question",
              question: {
                position: "Section 1 - Question 2",
                question: "Is the applicant’s brothers or sisters studying in HKCCHOIR?",
              },
              points: [
                { type: "CHOICE", answer: "Yes", points: 0 },
                { type: "CHOICE", answer: "No", points: 0 },
              ],
              linkedQuestion: [
                {
                  type: "Value",
                  question: {
                    position: "Section 1 - Question 9",
                    question: "How many brothers or sisters studying in HKCCHOIR?",
                  },
                  points: [
                    { type: "VALUE", answer: { compare: "le", value: { start: 1, end: 0 } }, points: 0 },
                    { type: "VALUE", answer: { compare: "btw", value: { start: 2, end: 4 } }, points: 10 },
                    { type: "VALUE", answer: { compare: "ge", value: { start: 5, end: 0 } }, points: 20 },
                  ],
                  linkedQuestion: [],
                },
              ],
            },
          ],
        },
        {
          type: "Date",
          question: { position: "Section 1 - Question 1", question: "Date of Birth" },
          points: [
            {
              type: "DATE",
              answer: { start: "2016-07-01", end: "2017-07-31" },
              points: 0,
            },
          ],
          linkedQuestion: [],
        },
        {
          type: "Single Choice",
          question: { position: "Section 1 - Question 1", question: "Gender" },
          points: [
            { type: "CHOICE", answer: "Boy", points: 0 },
            { type: "CHOICE", answer: "Girl", points: 5 },
          ],
          linkedQuestion: [],
        },
        {
          type: "Dropdown",
          question: { position: "Section 1 - Question 1", question: "Parent Job Industry" },
          points: [
            { type: "CHOICE", answer: "Accounting", points: 10 },
            { type: "CHOICE", answer: "Admin & Clerical", points: 0 },
            { type: "CHOICE", answer: "Architecture and Construction", points: 20 },
            { type: "CHOICE", answer: "Arts, Entertainment and Publishing", points: 0 },
            { type: "CHOICE", answer: "Banking", points: 30 },
          ],
          linkedQuestion: [],
        },
        {
          type: "Multiple Choice",
          question: {
            position: "Section 1 - Question 1",
            question: "How do you know about HKCCHOIR? (Can select one or more options)",
          },
          points: [
            { type: "CHOICE", answer: "Newspaper / Magazine", points: 0 },
            { type: "CHOICE", answer: "Social Media", points: 0 },
            { type: "CHOICE", answer: "Search Engine", points: 0 },
            { type: "CHOICE", answer: "Advertisement", points: 0 },
          ],
          linkedQuestion: [],
        },
        {
          type: "Apply Course",
          question: { position: "Section 1 - Question 1", question: "Programme(s) Applied" },
          points: [
            { type: "CHOICE", answer: "Chinese Dance", points: 5 },
            { type: "CHOICE", answer: "Ballet", points: 5 },
            { type: "CHOICE", answer: "Tap Dance", points: 5 },
            { type: "CHOICE", answer: "Jazz Dance", points: 5 },
            { type: "CHOICE", answer: "Hip Hop", points: 5 },
            { type: "CHOICE", answer: "Handbell", points: 5 },
            { type: "CHOICE", answer: "Putonghua", points: 5 },
          ],
          linkedQuestion: [],
        },
      ],
      condition: {
        operator: "ROOT",
        question: "",
        condition: "",
        answer: "",
        group: [
          {
            operator: "AND",
            question: "",
            condition: "",
            answer: "",
            group: [
              { operator: "QUEST", question: "Section 1 - Question 1", condition: "eq", answer: "Yes", group: [] },
              {
                operator: "QUEST",
                question: "Section 1 - Question 1 (Yes - Linked Question)",
                condition: "le",
                answer: 1,
                group: [],
              },
              {
                operator: "OR",
                question: "",
                condition: "",
                answer: "",
                group: [
                  { operator: "QUEST", question: "Section 2 - Question 3", condition: "eq", answer: "Banking", group: [] },
                  {
                    operator: "AND",
                    question: "",
                    condition: "",
                    answer: "",
                    group: [
                      { operator: "QUEST", question: "Section 1 - Question 4", condition: "eq", answer: "Girl", group: [] },
                      {
                        operator: "QUEST",
                        question: "Section 2 - Question 3",
                        condition: "eq",
                        answer: "Arts, Entertainment and Publishing",
                        group: [],
                      },
                    ],
                  },
                  {
                    operator: "QUEST",
                    question: "Section 2 - Question 3",
                    condition: "eq",
                    answer: "Architecture and Construction",
                    group: [],
                  },
                ],
              },
            ],
          },
        ],
      },
    },
    noOfCandidates: -1,
    arrangedCandidates: -1,
    unarrangedCandidates: -1,
  });

  const more = t("createForm.formContent.more");
  const copy = t("createForm.formContent.copy");
  const titleDelete = t("createForm.formContent.delete");

  const [applicationListData, setApplicationListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });

  const [interviewAllCandidatesHeadCells, setInterviewAllCandidatesHeadCells] = useState<HeadCell[]>([
    {
      id: "applicantId",
      numeric: true,
      disablePadding: true,
      label: "Applicant ID",
      isDisplay: true,
    },
    {
      id: "contactEmail",
      numeric: true,
      disablePadding: true,
      label: "Contact Email",
      isDisplay: true,
    },
    {
      id: "relatedApplicant",
      numeric: true,
      disablePadding: true,
      label: "Related Applicant ID",
      isDisplay: true,
    },
    {
      id: "interviewTime",
      numeric: true,
      disablePadding: true,
      label: "Interview Time",
      isDisplay: true,
    },
    {
      id: "interviewLocation",
      numeric: true,
      disablePadding: true,
      label: "Interview Location",
      isDisplay: true,
    },
    {
      id: "interviewStatus",
      numeric: true,
      disablePadding: true,
      label: "Interview Status",
      isDisplay: true,
    },
    {
      id: "action",
      numeric: true,
      disablePadding: true,
      label: "Action",
      isDisplay: false,
    },
  ]);

  const [allCandidatesColumnVisability, setAllCandidatesColumnVisability] = useState<GridColumnVisibilityModel>({
    a: false,
    b: false,
    c: false,
    d: false,
    e: false,
  });

  const [interviewTimeSlotRows, setInterviewTimeSlotRows] = useState<InterviewTimeSlot[]>([
    {
      id: 0,
      date: new Date("2022-09-15"),
      startTime: new Date("2022-09-15T03:24:00"),
      endTime: new Date("2022-09-15T04:00:00"),
      centre: "HK",
      room: "10",
      quota: 1,
      interviewer: [{ name: "Eunice", available: true }],
      candidate: [
        {
          applicantId: 1,
          contactEmail: "xyz@efhdbjn.com",
          relatedApplicant: ["kjqebfk", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 2,
          contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
          relatedApplicant: ["ewjkfbd", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Accept",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 3,
          contactEmail: "kduhflukqwbd@efhdbjn.com",
          relatedApplicant: ["wejfbn", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 4,
          contactEmail: "wqoeurpioqwejfljkq@efhdbjn.com",
          relatedApplicant: ["wejkbf", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 5,
          contactEmail: "sdkjagci@efhdbjn.com",
          relatedApplicant: ["cxlihusdcg", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
      ],
    },
    {
      id: 1,
      date: new Date("2022-09-15"),
      startTime: new Date("2022-09-15T03:24:00"),
      endTime: new Date("2022-09-15T04:00:00"),
      centre: "HK",
      room: "10",
      quota: 2,
      interviewer: [{ name: "Wing", available: true }],
      candidate: [
        {
          applicantId: 1,
          contactEmail: "xyz@efhdbjn.com",
          relatedApplicant: ["kjqebfk", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 2,
          contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
          relatedApplicant: ["ewjkfbd", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Accept",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 3,
          contactEmail: "kduhflukqwbd@efhdbjn.com",
          relatedApplicant: ["wejfbn", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 4,
          contactEmail: "wqoeurpioqwejfljkq@efhdbjn.com",
          relatedApplicant: ["wejkbf", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 5,
          contactEmail: "sdkjagci@efhdbjn.com",
          relatedApplicant: ["cxlihusdcg", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
      ],
    },
    {
      id: 2,
      date: new Date("2022-09-15"),
      startTime: new Date("2022-09-15T03:24:00"),
      endTime: new Date("2022-09-15T04:00:00"),
      centre: "HK",
      room: "10",
      quota: 3,
      interviewer: [{ name: "Yan Yan", available: true }],
      candidate: [
        {
          applicantId: 1,
          contactEmail: "xyz@efhdbjn.com",
          relatedApplicant: ["kjqebfk", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 2,
          contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
          relatedApplicant: ["ewjkfbd", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Accept",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 3,
          contactEmail: "kduhflukqwbd@efhdbjn.com",
          relatedApplicant: ["wejfbn", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 4,
          contactEmail: "wqoeurpioqwejfljkq@efhdbjn.com",
          relatedApplicant: ["wejkbf", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 5,
          contactEmail: "sdkjagci@efhdbjn.com",
          relatedApplicant: ["cxlihusdcg", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
      ],
    },
    {
      id: 3,
      date: new Date("2022-09-15"),
      startTime: new Date("2022-09-15T03:24:00"),
      endTime: new Date("2022-09-15T04:00:00"),
      centre: "HK",
      room: "10",
      quota: 4,
      interviewer: [{ name: "Siu", available: true }],
      candidate: [
        {
          applicantId: 1,
          contactEmail: "xyz@efhdbjn.com",
          relatedApplicant: ["kjqebfk", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 2,
          contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
          relatedApplicant: ["ewjkfbd", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Accept",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 3,
          contactEmail: "kduhflukqwbd@efhdbjn.com",
          relatedApplicant: ["wejfbn", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 4,
          contactEmail: "wqoeurpioqwejfljkq@efhdbjn.com",
          relatedApplicant: ["wejkbf", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 5,
          contactEmail: "sdkjagci@efhdbjn.com",
          relatedApplicant: ["cxlihusdcg", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
      ],
    },
    {
      id: 4,
      date: new Date("2022-09-15"),
      startTime: new Date("2022-09-15T03:24:00"),
      endTime: new Date("2022-09-15T04:00:00"),
      centre: "HK",
      room: "10",
      quota: 20,
      interviewer: [{ name: "Gi Gi", available: true }],
      candidate: [
        {
          applicantId: 1,
          contactEmail: "xyz@efhdbjn.com",
          relatedApplicant: ["kjqebfk", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 2,
          contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
          relatedApplicant: ["ewjkfbd", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Accept",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 3,
          contactEmail: "kduhflukqwbd@efhdbjn.com",
          relatedApplicant: ["wejfbn", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 4,
          contactEmail: "wqoeurpioqwejfljkq@efhdbjn.com",
          relatedApplicant: ["wejkbf", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 5,
          contactEmail: "sdkjagci@efhdbjn.com",
          relatedApplicant: ["cxlihusdcg", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
      ],
    },
    {
      id: 5,
      date: new Date("2022-09-16"),
      startTime: new Date("2022-09-16T03:24:00"),
      endTime: new Date("2022-09-16T04:00:00"),
      centre: "HK",
      room: "10",
      quota: 20,
      interviewer: [{ name: "Gi Gi", available: true }],
      candidate: [
        {
          applicantId: 1,
          contactEmail: "xyz@efhdbjn.com",
          relatedApplicant: ["kjqebfk", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 2,
          contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
          relatedApplicant: ["ewjkfbd", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "Present",
          rating: [],
          status: "Accept",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 3,
          contactEmail: "kduhflukqwbd@efhdbjn.com",
          relatedApplicant: ["wejfbn", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 4,
          contactEmail: "wqoeurpioqwejfljkq@efhdbjn.com",
          relatedApplicant: ["wejkbf", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
        {
          applicantId: 5,
          contactEmail: "sdkjagci@efhdbjn.com",
          relatedApplicant: ["cxlihusdcg", "jdsn"],
          a: "a",
          b: "b",
          c: "c",
          d: "d",
          e: "e",
          attendance: "-",
          rating: [],
          status: "Pending",
          answerPoints: { points: 60, total: 130 },
        },
      ],
    },
  ]);

  const [applicationList, setApplicationList] = useState<Interviewee[]>([
    {
      info: {
        applicantId: 1,
        contactEmail: "xyz@efhdbjn.com",
        relatedApplicant: [
          {
            info: {
              applicantId: 2,
              contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
              a: "a",
              b: "b",
              c: "c",
              d: "d",
              e: "e",
            },
            interviews: [
              {
                id: 1,
                title: "Ballet",
                time: "2022-09-15T03:24:00",
                location: "West Kowloon School (Room 509)",
                status: "PENDING",
              },
            ],
          },
          {
            info: {
              applicantId: 3,
              contactEmail: "kduhflukqwbd@efhdbjn.com",
              a: "a",
              b: "b",
              c: "c",
              d: "d",
              e: "e",
            },
            interviews: [
              {
                id: 1,
                title: "Ballet",
                time: "2022-09-15T03:24:00",
                location: "West Kowloon School (Room 509)",
                status: "PENDING",
              },
            ],
          },
        ],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "2022-09-15T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 2,
        contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
        relatedApplicant: [
          {
            info: {
              applicantId: 1,
              contactEmail: "xyz@efhdbjn.com",
              a: "a",
              b: "b",
              c: "c",
              d: "d",
              e: "e",
            },
            interviews: [
              {
                id: 1,
                title: "Ballet",
                time: "2022-09-15T03:24:00",
                location: "West Kowloon School (Room 509)",
                status: "PENDING",
              },
            ],
          },
          {
            info: {
              applicantId: 3,
              contactEmail: "kduhflukqwbd@efhdbjn.com",
              a: "a",
              b: "b",
              c: "c",
              d: "d",
              e: "e",
            },
            interviews: [
              {
                id: 1,
                title: "Ballet",
                time: "2022-09-15T03:24:00",
                location: "West Kowloon School (Room 509)",
                status: "PENDING",
              },
            ],
          },
        ],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "2022-09-15T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 3,
        contactEmail: "kduhflukqwbd@efhdbjn.com",
        relatedApplicant: [
          {
            info: {
              applicantId: 1,
              contactEmail: "xyz@efhdbjn.com",
              a: "a",
              b: "b",
              c: "c",
              d: "d",
              e: "e",
            },
            interviews: [
              {
                id: 1,
                title: "Ballet",
                time: "2022-09-15T03:24:00",
                location: "West Kowloon School (Room 509)",
                status: "PENDING",
              },
            ],
          },
          {
            info: {
              applicantId: 2,
              contactEmail: "hlfdubjhfugewhb@efhdbjn.com",
              a: "a",
              b: "b",
              c: "c",
              d: "d",
              e: "e",
            },
            interviews: [
              {
                id: 1,
                title: "Ballet",
                time: "2022-09-15T03:24:00",
                location: "West Kowloon School (Room 509)",
                status: "PENDING",
              },
            ],
          },
        ],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "2022-09-15T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 4,
        contactEmail: "wqoeurpioqwejfljkq@efhdbjn.com",
        relatedApplicant: [],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "2022-09-15T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 5,
        contactEmail: "sdkjagci@efhdbjn.com",
        relatedApplicant: [],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "2022-09-15T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 6,
        contactEmail: "euoyhi@efhdbjn.com",
        relatedApplicant: [],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "2022-09-15T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 7,
        contactEmail: "akdhfk@efhdbjn.com",
        relatedApplicant: [],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [
        {
          id: 1,
          title: "Ballet",
          time: "2022-09-15T03:24:00",
          location: "West Kowloon School (Room 509)",
          status: "PENDING",
        },
      ],
    },
    {
      info: {
        applicantId: 8,
        contactEmail: "akdhfk@efhdbjn.com",
        relatedApplicant: [],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
    {
      info: {
        applicantId: 9,
        contactEmail: "akdhfk@efhdbjn.com",
        relatedApplicant: [
          {
            info: {
              applicantId: 10,
              contactEmail: "akdhfk@efhdbjn.com",
              a: "a",
              b: "b",
              c: "c",
              d: "d",
              e: "e",
            },
            interviews: [
              {
                id: 1,
                title: "Ballet",
                time: "2022-09-15T03:24:00",
                location: "West Kowloon School (Room 509)",
                status: "PENDING",
              },
            ],
          },
        ],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
    {
      info: {
        applicantId: 10,
        contactEmail: "akdhfk@efhdbjn.com",
        relatedApplicant: [
          {
            info: {
              applicantId: 9,
              contactEmail: "akdhfk@efhdbjn.com",
              a: "a",
              b: "b",
              c: "c",
              d: "d",
              e: "e",
            },
            interviews: [
              {
                id: 1,
                title: "Ballet",
                time: "2022-09-15T03:24:00",
                location: "West Kowloon School (Room 509)",
                status: "PENDING",
              },
            ],
          },
        ],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
    {
      info: {
        applicantId: 11,
        contactEmail: "akdhfk@efhdbjn.com",
        relatedApplicant: [],
        a: "a",
        b: "b",
        c: "c",
        d: "d",
        e: "e",
      },
      interviews: [],
    },
  ]);

  const [title, setTitle] = useState("Ballet");

  // const [timeSlotFilterModel, setTimeSlotFilterModel] = useState<GridFilterModel>({
  //     items: [
  //         {
  //             id: 0,
  //             columnField: "date",
  //             value: "2022-09-15",
  //             operatorValue: "range",
  //         },
  //         {
  //             id: 1,
  //             columnField: "quota",
  //             value: 30,
  //             operatorValue: ">",
  //         },
  //     ],
  // });
  const [timeSlotFilterModel, setTimeSlotFilterModel] = useState<GridFilterModel>({
    items: [],
  });

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setApplicationListData(result.data);
    }
  }

  const {
    control,
    register,
    trigger,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    watch,
  } = useForm<EvaluationFormInput>({
    defaultValues: {
      formTitle: {
        enUs: "",
        zhHk: "",
      },
      ratingType: "",
      itemTitle: {
        enUs: "",
        zhHk: "",
      },
      // fullMarks: 0,
      // description: {
      //     enUs: "",
      //     zhHk: "",
      // },
      minLinearScale: "1",
      maxLinearScale: "5",
      minLinearScaleDescription: {
        enUs: "",
        zhHk: "",
      },
      maxLinearScaleDescription: {
        enUs: "",
        zhHk: "",
      },
      multipleChoiceOption: [],
      evaluationFormItem: [],
    },
  });

  const multipleChoiceOption = useFieldArray({
    control,
    name: "multipleChoiceOption",
  });

  const onAppendMCOption = () => {
    multipleChoiceOption.append({
      enUs: "",
      zhHk: "",
      others: false,
    });
  };

  const onAppendMCOthersOption = () => {
    multipleChoiceOption.append({
      enUs: "",
      zhHk: "",
      others: true,
    });
  };

  const evaluationFormItem = useFieldArray({
    control,
    name: "evaluationFormItem",
  });

  const onSubmit: SubmitHandler<EvaluationFormInput> = async (data) => {
    console.log("onSubmit", getValues());
    if (urlParams && urlParams.applicationListId && urlParams.formId) {
      const applicationListId = parseInt(urlParams.applicationListId);
      const formId = parseInt(urlParams.formId);
      await createInterviewEvaluationFormById(applicationListId, formId, interview.id, { evaluationForm: data.evaluationFormItem });
    }
  };

  const onCopy = (index: number) => {
    const originalContent = getValues().evaluationFormItem[index];
    const { fullMarks } = originalContent;
    if (fullMarks !== undefined) {
      setTotalFullMarks(Number(totalFullMarks) + Number(fullMarks));
      setFullMarksPrevValue(0);
    }
    // if (originalContent.singleQuestion.id) {
    //   delete originalContent.singleQuestion.id;
    // }
    evaluationFormItem.append(originalContent);
  };

  const onRemove = (index: number) => {
    const originalContent = getValues().evaluationFormItem[index];
    const { fullMarks } = originalContent;
    if (fullMarks !== undefined) {
      setTotalFullMarks(Number(totalFullMarks) - Number(fullMarks));
    }
    evaluationFormItem.remove(index);
  };

  const updateTotalFullMarks = (event: any) => {
    setTotalFullMarks(Number(totalFullMarks) + Number(event.target.value) - Number(fullMarksPrevValue));
    setFullMarksPrevValue(Number(event.target.value));
  };

  const linearScaleDescription = (ls: LinearScale) => {
    if (i18n.resolvedLanguage === "zhHk") {
      return `${ls.minLinearScale} (${ls.minLinearScaleDescription.zhHk}) to ${ls.maxLinearScale} (${ls.maxLinearScaleDescription.zhHk})`;
    }
    if (i18n.resolvedLanguage === "enUs") {
      return `${ls.minLinearScale} (${ls.minLinearScaleDescription.enUs}) to ${ls.maxLinearScale} (${ls.maxLinearScaleDescription.enUs})`;
    }
    return "";
  };

  const multipleChoiceOptionDescription = (mcAnswer: MultipleChoiceAnswer) => {
    const returnResult = mcAnswer.option.map((mcOption, index) => {
      if (mcOption.others === true) {
        return (
          <>
            {`${index + 1}. Others / 其他`}
            <br />
          </>
        );
      }
      return (
        <>
          {`${index + 1}. ${mcOption.enUs} / ${mcOption.zhHk}`}
          <br />
        </>
      );
    });
    return returnResult;
  };

  const onDragEnd: OnDragEndResponder = (result: DropResult) => {
    // console.log(result);

    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const { source } = result;
    const { destination } = result;

    // did not move anywhere - can bail early
    // if (source.droppableId === destination.droppableId && source.index === destination.index) {
    //     return;
    // }

    if (result.type === "OUTER") {
      evaluationFormItem.move(source.index, destination.index);
    }

    // if (result.type === "INNER") {
    //     move(source.index, destination.index);
    // }
  };

  const getEvaluationFormItemStyle = (isDragging: any, draggableStyle: any) => ({
    backgroundColor: "rgba(64, 158, 255, 0.05)",
    marginBottom: "8px",
    justifyContent: "center",

    ...draggableStyle,
  });

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValueButton(newValue);
  };

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenCustomizeView(true);
  };

  const handleClick1 = () => {
    console.log("handleClick1");
  };

  const handleClick2 = () => {
    console.log("handleClick2");
  };

  const handleRequestSort = (
    event: MouseEvent<unknown>,
    property: string | number,
    order: string,
    orderBy: string | number,
    setOrder: (order: Order) => void,
    setOrderBy: (orderBy: string | number) => void
  ) => {
    const isAsc = orderBy === property && order === "asc";
    console.log("property", property, order, orderBy);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleMoreVertClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };

  const handleMenuClose = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHk" ? applicationListData.titleZhHk : i18n.resolvedLanguage === "enUs" ? applicationListData.titleEnUs : null}
    </Link>,
    <Typography key="3" color="primary.main">
      {t("view.application")}
    </Typography>,
  ];

  const timeSlotColumns: GridColDef<InterviewTimeSlot>[] = [
    {
      field: "date",
      headerName: "Interview Date",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      valueGetter: (params) => moment(params.row.date).format("YYYY-MM-DD"),
      filterOperators: [
        {
          label: "Range",
          value: "range",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
              return null;
            }
            console.log(filterItem);

            return (params) => {
              // console.log(
              //     "date",
              //     params,
              //     filterItem,
              //     moment(filterItem.value[0]),
              //     moment(params.value).format("YYYY-MM-DD"),
              //     moment(params.value).isBetween(filterItem.value[0], filterItem.value[1])
              // );

              // return moment(params.value).isBetween(filterItem.value[0], moment(filterItem.value[1]));
              return moment(params.row.startTime).isBetween(filterItem.value[0], filterItem.value[1]);
            };
          },
          InputComponent: TextField,
          InputComponentProps: { type: "string" },
        },
      ],
    },
    {
      field: "time",
      headerName: "Interview Time",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      valueGetter: (params) => `${moment(params.row.startTime).format("HH:mm")} - ${moment(params.row.endTime).format("HH:mm")}`,
    },
    {
      field: "centre",
      headerName: "Course Centre",
      headerClassName: classes.hideRightSeparator,
      // valueGetter: (params) => params.row.centre,
      flex: 1,
    },
    {
      field: "room",
      headerName: "Room",
      headerClassName: classes.hideRightSeparator,
      // valueGetter: (params) => params.row.location.room,
      flex: 1,
    },
    {
      field: "quota",
      headerName: "Quota",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      type: "number",
      filterOperators: [
        {
          label: "Range",
          value: "range",
          getApplyFilterFn: (filterItem) => {
            if (!filterItem.columnField || !filterItem.value || !filterItem.operatorValue) {
              return null;
            }
            console.log("quota", filterItem);

            return (params) => {
              console.log(params, filterItem);
              return params.value >= filterItem.value[0] && params.value <= filterItem.value[1];
            };
          },
          InputComponent: TextField,
          InputComponentProps: { type: "string" },
        },
      ],
    },
    {
      field: "interviewer",
      headerName: "Interviewer",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      valueGetter: (params) => params.value.map((interviewer: Interviewer) => interviewer.name).join(","),
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: classes.hideRightSeparator,
      flex: 1,
      renderCell: (params) => (
        <RowAction>
          <TimeSlotActionButtons interview={interview} evaluationForm={getValues().evaluationFormItem} timeSlot={params.row} title={title} applicationList={applicationList} />
        </RowAction>
      ),
    },
  ];

  const [allCandidatesColumns, setAllCandidatesColumns] = useState<GridColDef<Interviewee>[]>([
    {
      field: "applicantId",
      headerName: "Applicant ID",
      flex: 1,
      valueGetter: (params) => params.row.info.applicantId,
    },
    {
      field: "contactEmail",
      headerName: "Contact Email",
      flex: 1,
      valueGetter: (params) => params.row.info.contactEmail,
    },
    {
      field: "relatedApplicant",
      headerName: "Related Applicant ID",
      flex: 1,
      valueGetter: (params) => params.row.info.relatedApplicant,
      renderCell: (params) =>
        params.row.info.relatedApplicant.length > 0 ? (
          <>
            {params.row.info.relatedApplicant.map((applicant: Interviewee) => (
              <Typography
                key={applicant.info.applicantId}
                color="primary"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  background: "#E6F1FC",
                  borderRadius: "4px",
                  mr: 0.5,
                  minWidth: 59,
                  height: 30,
                  fontWeight: 500,
                  fontSize: "13px",
                  lineHeight: "169%",
                  letterSpacing: "0.46px",
                  textTransform: "uppercase",
                }}
              >
                {`00000${applicant.info.applicantId}`.slice(-5)}
              </Typography>
            ))}
          </>
        ) : (
          "-"
        ),
    },
    {
      field: "interviewTime",
      headerName: "Interview Time",
      flex: 1,
      valueGetter: (params) => params.row.interviews.map((value) => value.time),
    },
    {
      field: "interviewLocation",
      headerName: "Interview Location",
      flex: 1,
      valueGetter: (params) => params.row.interviews.map((value) => value.location),
    },
    {
      field: "interviewStatus",
      headerName: "Interview Status",
      flex: 1,
      valueGetter: (params) => params.row.info.interviewStatus,
    },
    {
      field: "a",
      headerName: "a",
      flex: 1,
      valueGetter: (params) => params.row.info.a,
    },
    {
      field: "b",
      headerName: "b",
      flex: 1,
      valueGetter: (params) => params.row.info.b,
    },
    {
      field: "c",
      headerName: "c",
      flex: 1,
      valueGetter: (params) => params.row.info.c,
    },
    {
      field: "d",
      headerName: "d",
      flex: 1,
      valueGetter: (params) => params.row.info.d,
    },
    {
      field: "e",
      headerName: "e",
      flex: 1,
      valueGetter: (params) => params.row.info.e,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <RowAction>
          <AllCandidatesActionButtons timeSlots={interviewTimeSlotRows} interviewee={params.row} />
        </RowAction>
      ),
    },
  ]);

  const [allCandidatesColumnVisibilityModel, setAllCandidatesColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    ...allCandidatesColumns.reduce((prev, curr) => ({ ...prev, [curr.field]: false }), {}),
    applicantId: true,
    contactEmail: true,
    relatedApplicant: true,
    action: true,
  });

  useEffect(() => {
    const fetching = async () => {
      if (urlParams && urlParams.formId && urlParams.applicationListId) {
        const formId = parseInt(urlParams.formId);
        const applicationListId = parseInt(urlParams.applicationListId);
        await fetchApplicationList(applicationListId);
        // await fetchApplicationStageById(applicationListId, formId);
        // await fetchSubmissionsAndUserByApplicationStageId(applicationListId, formId);
        const { data } = await findAllInterviewsByStageIdAndSchoolCode(applicationListId, formId);
        console.log(data);
        setValue("evaluationFormItem", data.filter((value: Interview) => value.id === interview.id)[0].evaluationForm);
        setInterview(data.filter((value: Interview) => value.id === interview.id)[0]);
      }
      setIsLoading(false);
    };
    fetching();
    console.log("interview", interview);
    // setValue("evaluationFormItem", [
    //     {
    //         itemType: "text",
    //         itemTitle: { enUs: "adczxvfgre", zhHk: "efgrwe" },
    //         linearScale: {
    //             minLinearScale: "",
    //             minLinearScaleDescription: { enUs: "", zhHk: "" },
    //             maxLinearScale: "",
    //             maxLinearScaleDescription: { enUs: "", zhHk: "" },
    //         },
    //         multipleChoice: { option: [] },
    //     },
    //     {
    //         itemType: "text",
    //         itemTitle: { enUs: "frgerweqrfgrf", zhHk: "ewfgbfferfg" },
    //         fullMarks: 10,
    //         description: { enUs: "erwgfberweqrf", zhHk: "frgbrwerfg" },
    //         linearScale: {
    //             minLinearScale: "",
    //             minLinearScaleDescription: { enUs: "", zhHk: "" },
    //             maxLinearScale: "",
    //             maxLinearScaleDescription: { enUs: "", zhHk: "" },
    //         },
    //         multipleChoice: { option: [] },
    //     },
    //     {
    //         itemType: "linearScale",
    //         itemTitle: { enUs: "efgbrweqrfwg", zhHk: "egbgdrweqf" },
    //         description: { enUs: "", zhHk: "" },
    //         linearScale: {
    //             minLinearScale: "0",
    //             minLinearScaleDescription: { enUs: "erfgdrewf", zhHk: "fgbdfewfg" },
    //             maxLinearScale: "5",
    //             maxLinearScaleDescription: { enUs: "fgbgdfeqwfvd", zhHk: "efgbdvdfgerwqfsv" },
    //         },
    //         multipleChoice: { option: [] },
    //     },
    //     {
    //         itemType: "linearScale",
    //         itemTitle: { enUs: "fdsdbvfgsef", zhHk: "fsdbv dfgsesvb" },
    //         fullMarks: 5,
    //         description: { enUs: "ewrfgdbge", zhHk: "fdvbfdefv" },
    //         linearScale: {
    //             minLinearScale: "1",
    //             minLinearScaleDescription: { enUs: "fbgfefv", zhHk: "fdbvfddv" },
    //             maxLinearScale: "5",
    //             maxLinearScaleDescription: { enUs: "fvbfserfsdvb", zhHk: "fsddvsfasdv" },
    //         },
    //         multipleChoice: { option: [] },
    //     },
    //     {
    //         itemType: "multipleChoice",
    //         itemTitle: { enUs: "fvdbvsfgasev", zhHk: "bvdsfadbvsfa" },
    //         description: { enUs: "avsd sbafgrwfsv", zhHk: "asfvbfgrwfsd" },
    //         linearScale: {
    //             minLinearScale: "",
    //             minLinearScaleDescription: { enUs: "", zhHk: "" },
    //             maxLinearScale: "",
    //             maxLinearScaleDescription: { enUs: "", zhHk: "" },
    //         },
    //         multipleChoice: {
    //             option: [
    //                 { enUs: "abfgsdsvc", zhHk: "asfvasfvasfg", others: false },
    //                 { enUs: "asfvasfvsavafwg", zhHk: "asfvasfvsfvasfg", others: false },
    //                 { enUs: "", zhHk: "", others: true },
    //             ],
    //         },
    //     },
    // ]);
  }, [urlParams]);

  return (
    <Grid sx={{ m: 2 }}>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <>
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
          <Grid sx={{ my: 1, background: "white" }}>
            <Grid container sx={{ p: 2 }} className="outterContainer">
              <Grid item xs={6}>
                <InputLabel sx={titleStyle}>{title}</InputLabel>
              </Grid>

              <Grid item xs={6}>
                <Button variant="contained">AUTO ARRANGE</Button>
                <Button variant="contained">EDIT</Button>
              </Grid>

              <Grid item xs={12}>
                <InputLabel sx={{ marginBottom: 1 }}>{t("view.noOfApplicant")}: 89</InputLabel>
              </Grid>

              <Grid item xs={6}>
                <div className="viewApplicationFormDataGrid_InfoBox">
                  Interview Criteria
                  <InterviewSettingModal
                    interview={interview}
                    button={
                      <IconButton>
                        <MoreHorizIcon />
                      </IconButton>
                    }
                  />
                  <div>[7 - 8 yrs] Ballet for Girls</div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className="viewApplicationFormDataGrid_InfoBox">
                  Evalution Form
                  <Button variant="text" onClick={() => setOpenAddEvalutionForm(!openAddEvalutionForm)}>
                    ADD
                  </Button>
                  <div>-</div>
                </div>
              </Grid>

              <Grid item xs={12}>
                <TabContext value={buttonValue}>
                  <Grid item xs={12}>
                    <Grid container sx={{ marginTop: 1 }}>
                      <Grid item xs={12} alignItems="left">
                        <Box sx={{ width: "100%", typography: "body1" }}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList onChange={handleChange} aria-label="lab API tabs example">
                              <Tab label="TIME SLOT" value="1" />
                              <Tab label="ALL CANDIDATES" value="2" />
                            </TabList>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  <TabPanel value="1" sx={{ p: 0, pt: 2 }}>
                    {/* <Grid item xs={12}>
                                            <Grid container columnSpacing={{ xs: 1, sm: 2, ms: 3 }}>
                                                <Grid item xs={6} display="flex" alignItems="center">
                                                    <div>
                                                        Search: <input type="text" className="searchInput"></input>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    className="buttonGroups"
                                                >
                                                    <AddTimeSlotModal />
                                                    <TimeSlotFilterMenu timeSlots={interviewTimeSlotRows} />
                                                </Grid>
                                            </Grid>
                                        </Grid> */}

                    <Grid item xs={12}>
                      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} display="flex" alignItems="center">
                          <DataGridPro
                            sx={{ border: 0 }}
                            rows={interviewTimeSlotRows}
                            columns={timeSlotColumns}
                            getRowId={(row) => row.id}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            components={{ Toolbar: TimeSlotToolbar }}
                            componentsProps={{
                              toolbar: {
                                timeSlots: interviewTimeSlotRows,
                                onShowResult: (model: GridFilterModel) => {
                                  setTimeSlotFilterModel(model);
                                },
                              },
                            }}
                            filterModel={timeSlotFilterModel}
                            onFilterModelChange={(model) => setTimeSlotFilterModel(model)}
                            disableColumnResize
                            autoHeight
                            disableSelectionOnClick
                            disableColumnMenu
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                  <TabPanel value="2" sx={{ p: 0, pt: 2 }}>
                    {/* <Grid item xs={12}>
                                            <Grid container columnSpacing={{ xs: 1, sm: 2, ms: 3 }}>
                                                <Grid item xs={6} display="flex" alignItems="center">
                                                    <div>
                                                        Search: <input type="text" className="searchInput"></input>
                                                    </div>
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs={6}
                                                    display="flex"
                                                    justifyContent="flex-end"
                                                    alignItems="center"
                                                    className="buttonGroups"
                                                >
                                                    <Button
                                                        className="buttonGroupSent"
                                                        variant="outlined"
                                                        size="small"
                                                        // aria-controls={openCustomizeView ? "basic-menu" : undefined}
                                                        // aria-haspopup="true"
                                                        // aria-expanded={openCustomizeView ? "true" : undefined}
                                                        // onClick={handleClick}
                                                    >
                                                        EXPORT
                                                    </Button>
                                                    <Button
                                                        className="buttonGroupSent"
                                                        variant="outlined"
                                                        size="small"
                                                        // aria-controls={openCustomizeView ? "basic-menu" : undefined}
                                                        // aria-haspopup="true"
                                                        // aria-expanded={openCustomizeView ? "true" : undefined}
                                                        // onClick={handleClick}
                                                    >
                                                        UPDATE CANDIDATE STATUS
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid> */}

                    <Grid item xs={12}>
                      <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                        <Grid item xs={12} display="flex" alignItems="center">
                          {/* <TableContainer>
                                                        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium">
                                                            <EnhancedTableHead
                                                                headCells={interviewAllCandidatesHeadCells}
                                                                tab="INTERVIEW"
                                                                numSelected={interviewTimeSlotRows.length}
                                                                order={interviewTimeSlotOrder}
                                                                orderBy={interviewTimeSlotOrderBy}
                                                                rowCount={interviewTimeSlotRows.length}
                                                                onRequestSort={(e, p) =>
                                                                    handleRequestSort(
                                                                        e,
                                                                        p,
                                                                        interviewTimeSlotOrder,
                                                                        interviewTimeSlotOrderBy,
                                                                        setInterviewTimeSlotOrder,
                                                                        setInterviewTimeSlotOrderBy
                                                                    )
                                                                }
                                                            />
                                                            <TableBody>
                                                                {interviewTimeSlotRows.map((row, i) => (
                                                                    <InterviewTimeSlotRow labelId={`interview-timeslot-${i}`} row={row} key={i} />
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer> */}
                          <DataGridPro
                            sx={{ border: 0 }}
                            rows={applicationList}
                            columns={allCandidatesColumns}
                            columnVisibilityModel={allCandidatesColumnVisibilityModel}
                            getRowId={(row) => row.info.applicantId}
                            components={{ Toolbar: AllCandidatesToolbar }}
                            componentsProps={{
                              toolbar: {
                                CustomizeViewMenu: (
                                  <CustomizeViewMenu
                                    columns={allCandidatesColumns}
                                    visibilityModel={allCandidatesColumnVisibilityModel}
                                    onSelect={(key, checked) =>
                                      setAllCandidatesColumnVisibilityModel({
                                        ...allCandidatesColumnVisibilityModel,
                                        [key]: checked,
                                      })
                                    }
                                    onDragEnd={(result) => setAllCandidatesColumns(result)}
                                  />
                                ),
                              },
                            }}
                            pageSize={5}
                            rowsPerPageOptions={[5]}
                            disableColumnResize
                            autoHeight
                            checkboxSelection
                            disableSelectionOnClick
                            disableColumnMenu
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </TabPanel>
                </TabContext>
              </Grid>
            </Grid>
          </Grid>
          <Dialog open={openAddEvalutionForm} onClose={() => setOpenAddEvalutionForm(!openAddEvalutionForm)} fullWidth maxWidth="md">
            <form onSubmit={handleSubmit(onSubmit)}>
              <DialogTitle>Add Evaluation Form</DialogTitle>
              <DialogContent style={{ paddingTop: "10px" }}>
                <Grid container spacing={1}>
                  <Grid item xs={6} sm={6} md={6}>
                    <Controller
                      name={"formTitle.enUs" as const}
                      control={control}
                      rules={{ required: "Form Title (English) is required." }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Form Title (English)"
                          error={errors.formTitle?.enUs && true}
                          helperText={errors.formTitle?.enUs && `${errors.formTitle.enUs.message}`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Controller
                      name={"formTitle.zhHk" as const}
                      control={control}
                      rules={{ required: "Form Title (Traditional Chinese) is required." }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          label="Form Title (Traditional Chinese)"
                          error={errors.formTitle?.zhHk && true}
                          helperText={errors.formTitle?.zhHk && `${errors.formTitle.zhHk.message}`}
                          variant="outlined"
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </DialogContent>

              <DialogTitle>Evaluation Form Content</DialogTitle>

              {getValues().evaluationFormItem.length > 0 && (
                <DialogContent>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="evaluationFormItem" type="OUTER">
                      {(droppableProvided) => (
                        <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
                          {evaluationFormItem.fields.map((field, index) => (
                            <>
                              {field.itemType === "text" && (
                                <Draggable key={field.id} draggableId={field.id} index={index}>
                                  {(provided, snapshot) => (
                                    <Grid
                                      container
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getEvaluationFormItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <DragIndicatorIcon
                                        style={{
                                          color: "#d8dce6",
                                          transform: "rotate(90deg)",
                                          position: "absolute",
                                        }}
                                      />
                                      <Grid
                                        item
                                        style={{
                                          paddingTop: "12px",
                                          paddingLeft: "16px",
                                          paddingBottom: "12px",
                                        }}
                                        xs={10}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#606266",
                                          }}
                                        >
                                          Item {index + 1} (Text)
                                        </div>
                                        <div
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            color: "#606266",
                                          }}
                                        >
                                          {field.itemTitle.enUs} / {field.itemTitle.zhHk}
                                          {!!(field.fullMarks && field.fullMarks > 0) && ` (${field.fullMarks} Marks)`}
                                        </div>
                                        {field.description && field.description.enUs !== "" && (
                                          <div
                                            style={{
                                              fontWeight: "300",
                                              fontSize: "12px",
                                              lineHeight: "18px",
                                              color: "#606266",
                                            }}
                                          >
                                            {field.description.enUs} / {field.description.zhHk}
                                          </div>
                                        )}
                                      </Grid>
                                      <Grid item xs={2} container alignItems="center" justifyContent="flex-end">
                                        <Tooltip title={copy}>
                                          <IconButton
                                            color="primary"
                                            size="small"
                                            style={{
                                              transform: "scaleY(-1)",
                                            }}
                                            onClick={() => {
                                              onCopy(index);
                                            }}
                                          >
                                            <ContentCopyIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title={titleDelete}>
                                          <IconButton
                                            color="primary"
                                            size="small"
                                            style={{
                                              marginRight: "11.5px",
                                              marginLeft: "25.5px",
                                            }}
                                            onClick={() => {
                                              onRemove(index);
                                            }}
                                          >
                                            <DeleteOutlineIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Draggable>
                              )}
                              {field.itemType === "linearScale" && (
                                <Draggable key={field.id} draggableId={field.id} index={index}>
                                  {(provided, snapshot) => (
                                    <Grid
                                      container
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getEvaluationFormItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <DragIndicatorIcon
                                        style={{
                                          color: "#d8dce6",
                                          transform: "rotate(90deg)",
                                          position: "absolute",
                                        }}
                                      />
                                      <Grid
                                        item
                                        style={{
                                          paddingTop: "12px",
                                          paddingLeft: "16px",
                                          paddingBottom: "12px",
                                        }}
                                        xs={10}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#606266",
                                          }}
                                        >
                                          Item {index + 1} (Linear Scale)
                                        </div>
                                        <div
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            color: "#606266",
                                          }}
                                        >
                                          {field.itemTitle.enUs} / {field.itemTitle.zhHk}
                                          {!!(field.fullMarks && field.fullMarks > 0) && ` (${field.fullMarks} Marks)`}
                                        </div>
                                        {field.description && field.description.enUs !== "" && (
                                          <div
                                            style={{
                                              fontWeight: "300",
                                              fontSize: "12px",
                                              lineHeight: "18px",
                                              color: "#606266",
                                            }}
                                          >
                                            {field.description.enUs} / {field.description.zhHk}
                                          </div>
                                        )}
                                        <div
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#1989FA",
                                          }}
                                        >
                                          {linearScaleDescription(field.linearScale)}
                                        </div>
                                      </Grid>
                                      <Grid item xs={2} container alignItems="center" justifyContent="flex-end">
                                        <Tooltip title={copy}>
                                          <IconButton
                                            color="primary"
                                            size="small"
                                            style={{
                                              transform: "scaleY(-1)",
                                            }}
                                            onClick={() => {
                                              onCopy(index);
                                            }}
                                          >
                                            <ContentCopyIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title={titleDelete}>
                                          <IconButton
                                            color="primary"
                                            size="small"
                                            style={{
                                              marginRight: "11.5px",
                                              marginLeft: "25.5px",
                                            }}
                                            onClick={() => {
                                              onRemove(index);
                                            }}
                                          >
                                            <DeleteOutlineIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Draggable>
                              )}
                              {field.itemType === "multipleChoice" && (
                                <Draggable key={field.id} draggableId={field.id} index={index}>
                                  {(provided, snapshot) => (
                                    <Grid
                                      container
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={getEvaluationFormItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                      <DragIndicatorIcon
                                        style={{
                                          color: "#d8dce6",
                                          transform: "rotate(90deg)",
                                          position: "absolute",
                                        }}
                                      />
                                      <Grid
                                        item
                                        style={{
                                          paddingTop: "12px",
                                          paddingLeft: "16px",
                                          paddingBottom: "12px",
                                        }}
                                        xs={10}
                                      >
                                        <div
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#606266",
                                          }}
                                        >
                                          Item {index + 1} (Multiple Choice)
                                        </div>
                                        <div
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "14px",
                                            lineHeight: "21px",
                                            color: "#606266",
                                          }}
                                        >
                                          {field.itemTitle.enUs} / {field.itemTitle.zhHk}
                                          {!!(field.fullMarks && field.fullMarks > 0) && ` (${field.fullMarks} Marks)`}
                                        </div>
                                        {field.description && field.description.enUs !== "" && (
                                          <div
                                            style={{
                                              fontWeight: "300",
                                              fontSize: "12px",
                                              lineHeight: "18px",
                                              color: "#606266",
                                            }}
                                          >
                                            {field.description.enUs} / {field.description.zhHk}
                                          </div>
                                        )}
                                        <div
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "12px",
                                            lineHeight: "18px",
                                            color: "#1989FA",
                                          }}
                                        >
                                          {multipleChoiceOptionDescription(field.multipleChoice)}
                                        </div>
                                      </Grid>
                                      <Grid item xs={2} container alignItems="center" justifyContent="flex-end">
                                        <Tooltip title={copy}>
                                          <IconButton
                                            color="primary"
                                            size="small"
                                            style={{
                                              transform: "scaleY(-1)",
                                            }}
                                            onClick={() => {
                                              onCopy(index);
                                            }}
                                          >
                                            <ContentCopyIcon />
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title={titleDelete}>
                                          <IconButton
                                            color="primary"
                                            size="small"
                                            style={{
                                              marginRight: "11.5px",
                                              marginLeft: "25.5px",
                                            }}
                                            onClick={() => {
                                              onRemove(index);
                                            }}
                                          >
                                            <DeleteOutlineIcon />
                                          </IconButton>
                                        </Tooltip>
                                      </Grid>
                                    </Grid>
                                  )}
                                </Draggable>
                              )}
                            </>
                          ))}
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </DialogContent>
              )}
              {hidden !== true && (
                <DialogContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} style={{ marginTop: "8px" }}>
                      <Box display="flex" alignItems="center">
                        <FormControl fullWidth>
                          <InputLabel id="ratingTypeSelectLabel">Rating Type</InputLabel>
                          <Controller
                            name={"ratingType" as const}
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <Select
                                {...field}
                                label="Rating Type"
                                id="ratingTypeSelectLabel"
                                onChange={(event: SelectChangeEvent<string>) => {
                                  watch(["ratingType"]);
                                  setValue("ratingType", event.target.value);
                                  setValue("minLinearScale", "1");
                                  setValue("maxLinearScale", "5");
                                  setValue("minLinearScaleDescription.enUs", "");
                                  setValue("minLinearScaleDescription.zhHk", "");
                                  setValue("maxLinearScaleDescription.enUs", "");
                                  setValue("maxLinearScaleDescription.zhHk", "");
                                  setValue("multipleChoiceOption", []);
                                  if (event.target.value === "multipleChoice") {
                                    setAddMarks(false);
                                    setTotalFullMarks(Number(totalFullMarks) - Number(fullMarksPrevValue));
                                    setFullMarksPrevValue(0);
                                  }
                                }}
                              >
                                <MenuItem value="text">Text</MenuItem>
                                <MenuItem value="linearScale">Linear Scale</MenuItem>
                                <MenuItem value="multipleChoice">Mulitple Choice</MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                        <Tooltip title={more}>
                          <IconButton color="primary" size="small" onClick={handleMoreVertClick}>
                            <MoreVertRoundedIcon />
                          </IconButton>
                        </Tooltip>
                        {getValues().ratingType === "multipleChoice" ? (
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleMenuClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setAddDescription(!addDescription);
                                setAnchorEl(null);
                                setOpenMenu(false);
                                setValue("description.enUs", "");
                                setValue("description.zhHk", "");
                              }}
                            >
                              Add Description
                              {addDescription === true ? <CheckCircleIcon className="tickIcon" fontSize="small" /> : null}
                            </MenuItem>
                          </Menu>
                        ) : (
                          <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={openMenu}
                            onClose={handleMenuClose}
                            MenuListProps={{
                              "aria-labelledby": "basic-button",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                setAddDescription(!addDescription);
                                setAnchorEl(null);
                                setOpenMenu(false);
                                setValue("description.enUs", "");
                                setValue("description.zhHk", "");
                              }}
                            >
                              Add Description
                              {addDescription === true ? <CheckCircleIcon className="tickIcon" fontSize="small" /> : null}
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                setAddMarks(!addMarks);
                                setValue("fullMarks", 0);
                                setAnchorEl(null);
                                setOpenMenu(false);
                                setTotalFullMarks(Number(totalFullMarks) - Number(fullMarksPrevValue));
                                setFullMarksPrevValue(0);
                              }}
                            >
                              Add Marks
                              {addMarks === true ? <CheckCircleIcon className="tickIcon" fontSize="small" /> : null}
                            </MenuItem>
                          </Menu>
                        )}

                        <Tooltip title={copy}>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              trigger().then(function (result) {
                                if (result) {
                                  if (getValues().ratingType === "text") {
                                    evaluationFormItem.append({
                                      itemType: getValues().ratingType,
                                      itemTitle: getValues().itemTitle,
                                      fullMarks: getValues().fullMarks,
                                      description: getValues().description,
                                      linearScale: {
                                        minLinearScale: "",
                                        minLinearScaleDescription: { enUs: "", zhHk: "" },
                                        maxLinearScale: "",
                                        maxLinearScaleDescription: { enUs: "", zhHk: "" },
                                      },
                                      multipleChoice: { option: [] },
                                    });
                                  }

                                  if (getValues().ratingType === "linearScale") {
                                    evaluationFormItem.append({
                                      itemType: getValues().ratingType,
                                      itemTitle: getValues().itemTitle,
                                      fullMarks: getValues().fullMarks,
                                      description: getValues().description,
                                      linearScale: {
                                        minLinearScale: getValues().minLinearScale,
                                        minLinearScaleDescription: getValues().minLinearScaleDescription,
                                        maxLinearScale: getValues().maxLinearScale,
                                        maxLinearScaleDescription: getValues().maxLinearScaleDescription,
                                      },
                                      multipleChoice: { option: [] },
                                    });
                                  }

                                  if (getValues().ratingType === "multipleChoice") {
                                    evaluationFormItem.append({
                                      itemType: getValues().ratingType,
                                      itemTitle: getValues().itemTitle,
                                      description: getValues().description,
                                      linearScale: {
                                        minLinearScale: "",
                                        minLinearScaleDescription: { enUs: "", zhHk: "" },
                                        maxLinearScale: "",
                                        maxLinearScaleDescription: { enUs: "", zhHk: "" },
                                      },
                                      multipleChoice: {
                                        option: getValues().multipleChoiceOption,
                                      },
                                    });
                                  }
                                  const { fullMarks } = getValues();
                                  if (fullMarks !== undefined) {
                                    setTotalFullMarks(Number(totalFullMarks) + Number(fullMarks) - Number(fullMarksPrevValue));
                                  }
                                  setFullMarksPrevValue(0);
                                }
                              });
                            }}
                          >
                            <ContentCopyIcon />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={titleDelete}>
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={() => {
                              setValue("ratingType", "");
                              setValue("itemTitle.enUs", "");
                              setValue("itemTitle.zhHk", "");
                              setValue("fullMarks", 0);
                              setValue("description.enUs", "");
                              setValue("description.zhHk", "");
                              setValue("minLinearScale", "1");
                              setValue("maxLinearScale", "5");
                              setValue("minLinearScaleDescription.enUs", "");
                              setValue("minLinearScaleDescription.zhHk", "");
                              setValue("maxLinearScaleDescription.enUs", "");
                              setValue("maxLinearScaleDescription.zhHk", "");
                              setValue("multipleChoiceOption", []);
                              watch();
                            }}
                          >
                            <DeleteOutlineIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Grid>
                    {addMarks === true ? (
                      <>
                        <Grid item xs={5}>
                          <Controller
                            name={"itemTitle.enUs" as const}
                            control={control}
                            rules={{ required: "Title (English) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Title (English)"
                                error={errors.itemTitle?.enUs && true}
                                helperText={errors.itemTitle?.enUs && `${errors.itemTitle.enUs.message}`}
                                variant="filled"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={5}>
                          <Controller
                            name={"itemTitle.zhHk" as const}
                            control={control}
                            rules={{ required: "Title (Traditional Chinese) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Title (Traditional Chinese)"
                                error={errors.itemTitle?.zhHk && true}
                                helperText={errors.itemTitle?.zhHk && `${errors.itemTitle.zhHk.message}`}
                                variant="filled"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <Controller
                            name={"fullMarks" as const}
                            control={control}
                            rules={{ required: "Full Marks field is required." }}
                            defaultValue={0}
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Full Marks"
                                error={errors.fullMarks && true}
                                helperText={errors.fullMarks && `${errors.fullMarks.message}`}
                                variant="filled"
                                InputProps={{
                                  type: "number",
                                  endAdornment: <InputAdornment position="end">Mark(s)</InputAdornment>,
                                }}
                                onChange={(event) => {
                                  field.onChange(event);
                                  updateTotalFullMarks(event);
                                }}
                              />
                            )}
                          />
                        </Grid>
                      </>
                    ) : (
                      <>
                        <Grid item xs={6}>
                          <Controller
                            name={"itemTitle.enUs" as const}
                            control={control}
                            rules={{ required: "Title (English) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Title (English)"
                                error={errors.itemTitle?.enUs && true}
                                helperText={errors.itemTitle?.enUs && `${errors.itemTitle.enUs.message}`}
                                variant="filled"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <Controller
                            name={"itemTitle.zhHk" as const}
                            control={control}
                            rules={{ required: "Title (Traditional Chinese) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Title (Traditional Chinese)"
                                error={errors.itemTitle?.zhHk && true}
                                helperText={errors.itemTitle?.zhHk && `${errors.itemTitle.zhHk.message}`}
                                variant="filled"
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {addDescription === true && (
                      <>
                        <Grid item xs={12}>
                          <Controller
                            name={"description.enUs" as const}
                            control={control}
                            rules={{ required: "Description (English) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Description (English)"
                                error={errors.description?.enUs && true}
                                helperText={errors.description?.enUs && `${errors.description.enUs.message}`}
                                variant="filled"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Controller
                            name={"description.zhHk" as const}
                            control={control}
                            rules={{ required: "Description (Traditional Chinese) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="Description (Traditional Chinese)"
                                error={errors.description?.zhHk && true}
                                helperText={errors.description?.zhHk && `${errors.description.zhHk.message}`}
                                variant="filled"
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}
                    {getValues().ratingType === "linearScale" && (
                      <>
                        <Grid item xs={12}>
                          <Controller
                            name={"minLinearScale" as const}
                            control={control}
                            defaultValue="1"
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="minLinearScale"
                                onChange={(event: SelectChangeEvent<string>) => {
                                  watch(["minLinearScale"]);
                                  setValue("minLinearScale", event.target.value);
                                }}
                              >
                                <MenuItem value="0">0</MenuItem>
                                <MenuItem value="1">1</MenuItem>
                              </Select>
                            )}
                          />
                          to
                          <Controller
                            name={"maxLinearScale" as const}
                            control={control}
                            defaultValue="5"
                            render={({ field }) => (
                              <Select
                                {...field}
                                id="maxLinearScale"
                                onChange={(event: SelectChangeEvent<string>) => {
                                  watch(["maxLinearScale"]);
                                  setValue("maxLinearScale", event.target.value);
                                }}
                              >
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                                <MenuItem value="7">7</MenuItem>
                                <MenuItem value="8">8</MenuItem>
                                <MenuItem value="9">9</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                              </Select>
                            )}
                          />
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                          <div className="circleNoContainer" style={{ color: "#606266", border: "1px solid #D8DCE6" }}>
                            {getValues().minLinearScale}
                          </div>
                        </Grid>
                        <Grid item xs={5.5}>
                          <Controller
                            name={"minLinearScaleDescription.enUs" as const}
                            control={control}
                            rules={{ required: "Linear Scale Description (English) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="MIN Linear Scale Description (English)"
                                error={errors.minLinearScaleDescription?.enUs && true}
                                helperText={errors.minLinearScaleDescription?.enUs && `${errors.minLinearScaleDescription.enUs.message}`}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <Controller
                            name={"minLinearScaleDescription.zhHk" as const}
                            control={control}
                            rules={{ required: "MIN Linear Scale Description (Traditional Chinese) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="MIN Linear Scale Description (Traditional Chinese)"
                                error={errors.minLinearScaleDescription?.zhHk && true}
                                helperText={errors.minLinearScaleDescription?.zhHk && `${errors.minLinearScaleDescription.zhHk.message}`}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1} display="flex" justifyContent="center" alignItems="center">
                          <div className="circleNoContainer" style={{ color: "#606266", border: "1px solid #D8DCE6" }}>
                            {getValues().maxLinearScale}
                          </div>
                        </Grid>
                        <Grid item xs={5.5}>
                          <Controller
                            name={"maxLinearScaleDescription.enUs" as const}
                            control={control}
                            rules={{ required: "Linear Scale Description (English) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="MAX Linear Scale Description (English)"
                                error={errors.maxLinearScaleDescription?.enUs && true}
                                helperText={errors.maxLinearScaleDescription?.enUs && `${errors.maxLinearScaleDescription.enUs.message}`}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={5.5}>
                          <Controller
                            name={"maxLinearScaleDescription.zhHk" as const}
                            control={control}
                            rules={{ required: "MAX Linear Scale Description (Traditional Chinese) is required." }}
                            defaultValue=""
                            render={({ field }) => (
                              <TextField
                                {...field}
                                fullWidth
                                label="MAX Linear Scale Description (Traditional Chinese)"
                                error={errors.maxLinearScaleDescription?.zhHk && true}
                                helperText={errors.maxLinearScaleDescription?.zhHk && `${errors.maxLinearScaleDescription.zhHk.message}`}
                                variant="standard"
                              />
                            )}
                          />
                        </Grid>
                      </>
                    )}

                    {getValues().ratingType === "multipleChoice" && (
                      <Grid item xs={12} md={12}>
                        {multipleChoiceOption.fields.map((field, index) => {
                          return (
                            <Grid container alignItems="center" spacing={2} key={field.id}>
                              <Grid item xs={1} sm={0.6} md={0.6} className="singleChoiceRadioButtonUncheckedIcon">
                                <CheckBoxOutlineBlankIcon />
                              </Grid>

                              {getValues().multipleChoiceOption[index].others === true && (
                                <Grid item xs={10} sm={7} md={8} sx={{ minHeight: 64 }} alignItems="center" display="flex">
                                  {t("createForm.formContent.others")}
                                </Grid>
                              )}

                              {getValues().multipleChoiceOption[index].others === false && (
                                <>
                                  <Grid item xs={5.5} sm={3.5} md={4}>
                                    <Controller
                                      name={`multipleChoiceOption.${index}.enUs` as const}
                                      control={control}
                                      rules={{ required: "English answer is required" }}
                                      defaultValue=""
                                      render={({ field: renderField }) => (
                                        <TextField
                                          {...renderField}
                                          label={`${t("createForm.formContent.option")} ${index + 1} ${t("createForm.formContent.english")}`}
                                          fullWidth
                                          error={errors.multipleChoiceOption?.[index]?.enUs !== undefined && true}
                                          helperText={errors.multipleChoiceOption?.[index]?.enUs !== undefined && `${errors.multipleChoiceOption?.[index]?.enUs?.message}`}
                                          variant="standard"
                                        />
                                      )}
                                    />
                                  </Grid>

                                  <Grid item xs={5.5} sm={3.5} md={4}>
                                    <Controller
                                      name={`multipleChoiceOption.${index}.zhHk` as const}
                                      control={control}
                                      rules={{ required: "Chinese answer is required" }}
                                      defaultValue=""
                                      render={({ field: renderField }) => (
                                        <TextField
                                          {...renderField}
                                          fullWidth
                                          variant="standard"
                                          label={`${t("createForm.formContent.option")} ${index + 1} ${t("createForm.formContent.traditionalChinese")}`}
                                          error={errors.multipleChoiceOption?.[index]?.zhHk !== undefined && true}
                                          helperText={errors.multipleChoiceOption?.[index]?.zhHk !== undefined && `${errors.multipleChoiceOption?.[index]?.zhHk?.message}`}
                                        />
                                      )}
                                    />
                                  </Grid>
                                </>
                              )}
                            </Grid>
                          );
                        })}
                        <Grid container alignItems="center" className="singleChoiceAddOthers">
                          <Grid item xs={1} sm={0.6} md={0.6} className="singleChoiceRadioButtonUncheckedIcon">
                            <CheckBoxOutlineBlankIcon />
                          </Grid>
                          <Grid item xs={11} sm={11.4} md={11.4}>
                            <div className="singleChoiceAddMessageContainer">
                              <span onClick={onAppendMCOption} className="singleChoiceAddMessage">
                                {t("createForm.formContent.addNewOption")}
                              </span>
                              <span> {t("createForm.formContent.or")} </span>
                              <span className="singleChoiceAddOthersMessage" onClick={onAppendMCOthersOption}>
                                {t("createForm.formContent.addOther")}
                              </span>
                            </div>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </DialogContent>
              )}

              <DialogContent>
                <Button
                  variant="outlined"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    if (hidden === true) {
                      setHidden(false);
                    } else {
                      trigger().then((result) => {
                        if (result) {
                          if (getValues().ratingType === "text") {
                            evaluationFormItem.append({
                              itemType: getValues().ratingType,
                              itemTitle: getValues().itemTitle,
                              fullMarks: getValues().fullMarks,
                              description: getValues().description,
                              linearScale: {
                                minLinearScale: "",
                                minLinearScaleDescription: { enUs: "", zhHk: "" },
                                maxLinearScale: "",
                                maxLinearScaleDescription: { enUs: "", zhHk: "" },
                              },
                              multipleChoice: { option: [] },
                            });
                          }

                          if (getValues().ratingType === "linearScale") {
                            evaluationFormItem.append({
                              itemType: getValues().ratingType,
                              itemTitle: getValues().itemTitle,
                              fullMarks: getValues().fullMarks,
                              description: getValues().description,
                              linearScale: {
                                minLinearScale: getValues().minLinearScale,
                                minLinearScaleDescription: getValues().minLinearScaleDescription,
                                maxLinearScale: getValues().maxLinearScale,
                                maxLinearScaleDescription: getValues().maxLinearScaleDescription,
                              },
                              multipleChoice: { option: [] },
                            });
                          }

                          if (getValues().ratingType === "multipleChoice") {
                            evaluationFormItem.append({
                              itemType: getValues().ratingType,
                              itemTitle: getValues().itemTitle,
                              description: getValues().description,
                              linearScale: {
                                minLinearScale: "",
                                minLinearScaleDescription: { enUs: "", zhHk: "" },
                                maxLinearScale: "",
                                maxLinearScaleDescription: { enUs: "", zhHk: "" },
                              },
                              multipleChoice: {
                                option: getValues().multipleChoiceOption,
                              },
                            });
                          }
                          const { fullMarks } = getValues();
                          if (fullMarks !== undefined) {
                            setTotalFullMarks(Number(totalFullMarks) + Number(fullMarks) - Number(fullMarksPrevValue));
                          }
                          setValue("ratingType", "");
                          setValue("itemTitle.enUs", "");
                          setValue("itemTitle.zhHk", "");
                          setValue("fullMarks", 0);
                          setValue("description.enUs", "");
                          setValue("description.zhHk", "");
                          setValue("minLinearScale", "1");
                          setValue("maxLinearScale", "5");
                          setValue("minLinearScaleDescription.enUs", "");
                          setValue("minLinearScaleDescription.zhHk", "");
                          setValue("maxLinearScaleDescription.enUs", "");
                          setValue("maxLinearScaleDescription.zhHk", "");
                          setValue("multipleChoiceOption", []);
                          setFullMarksPrevValue(0);
                          setHidden(true);
                          setAddMarks(false);
                          setAddDescription(false);
                        }
                      });
                    }
                  }}
                >
                  <AddIcon />
                </Button>
              </DialogContent>
              <DialogActions>
                <Grid container>
                  <Grid item container xs={6} alignItems="center">
                    <div style={{ paddingLeft: "16px", fontWeight: "500", fontSize: "16px", lineHeight: "24px", color: "#1989FA" }}>
                      {!!(totalFullMarks && totalFullMarks > 0) && `Total Full Marks of this evaluation form: ${totalFullMarks.toString()} Marks`}
                    </div>
                  </Grid>
                  <Grid item container xs={6} alignItems="center" justifyContent="flex-end">
                    <Button style={{ color: "#606266" }} onClick={() => setOpenAddEvalutionForm(!openAddEvalutionForm)}>
                      CANCEL
                    </Button>
                    <Button type="submit" onClick={() => setOpenAddEvalutionForm(!openAddEvalutionForm)}>
                      ADD EVALUATION FORM
                    </Button>
                  </Grid>
                </Grid>
              </DialogActions>
            </form>
          </Dialog>
        </>
      )}
    </Grid>
  );
}

export default EditInterview;
