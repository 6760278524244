import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import React, { useState, MouseEvent, Fragment } from "react";
import { KeyForMailMerge } from "../../../interfaces/submission";

interface VariableFormAnswerMenuProps {
  keys: KeyForMailMerge[];
}

function VariableFormAnswerMenu(props: VariableFormAnswerMenuProps) {
  const { keys } = props;

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };

  const handleCopy = (id: string) => {
    navigator.clipboard.writeText(`{{${id}}}`);
    handleClose();
  };

  return (
    <>
      <Button variant="outlined" size="small" onClick={handleOpen}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <AddCircleOutlineIcon />
          <div>VARIABLE FORM ANSWER</div>
        </div>
      </Button>
      <Button variant="outlined" size="small" onClick={() => handleCopy("submissionId")}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <AddCircleOutlineIcon />
          <div>Submission ID</div>
        </div>
      </Button>
      <Menu
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "50%",
            overflow: "scroll",
            width: 500,
          },
        }}
      >
        {keys.map((key) => (
          <MenuItem key={key.id} onClick={() => handleCopy(key.id)}>
            <Box>{`${key.fieldQuestion} {{${key.id}}}`}</Box>
            <Box>{key.fieldContent}</Box>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default VariableFormAnswerMenu;
