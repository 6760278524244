import "./ApplicationRouter.css";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import { studentAuthenticate } from "../../redux/auth/thunk";
import ApplicationList from "./ApplicationList";
import ForgotPasswordPage from "./ForgotPasswordPage";
import LoginPage from "./LoginPage";
import PrivateRoute from "./PrivateRoute";
import RegisterPage from "./RegisterPage";
import SubmissionForm from "../submission/SubmissionForm";
import { useAppDispatch } from "../../redux/hooks";
import NotFound from "../utils/NotFound";
import RedirectPageForVpp from "../utils/RedirectPageForVpp";

function ApplicationRouter() {
  const dispatch = useAppDispatch();
  const params = useParams();
  const lastSchool = sessionStorage.getItem("lastSchool");

  useEffect(() => {
    const { schoolCode } = params;
    dispatch(studentAuthenticate(schoolCode));
  }, [dispatch]);

  return (
    <div className="applicationRouterBackgroundImg">
      <div className="applicationRouterBackground">
        <Routes>
          <Route path="login" element={<LoginPage />} />
          <Route path="register" element={<RegisterPage />} />
          <Route path="forget_password" element={<ForgotPasswordPage />} />
          <Route path="apply/playpark/query.html" element={<RedirectPageForVpp />} />

          <Route
            path="list"
            element={
              <PrivateRoute path="list">
                <ApplicationList />
              </PrivateRoute>
            }
          />
          <Route
            path="list/:listId/stage/:stageId"
            element={
              <PrivateRoute path="list/:listId/stage/:stageId">
                <SubmissionForm />
              </PrivateRoute>
            }
          />

          {lastSchool ? <Route path="*" element={<Navigate to={`/cms/school/${lastSchool}/application/register`} />} /> : <Route path="*" element={<NotFound />} />}
        </Routes>
      </div>
    </div>
  );
}

export default ApplicationRouter;
