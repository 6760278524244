import "../Common.css";
import React, { useEffect } from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import * as locales from "@mui/material/locale";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import { RootState } from "../../redux/store";
import ApplicationStageRouter from "../applicationForm/ApplicationStageRouter";
import ApplicationFormList from "../applicationForm/list/ApplicationFormList";
import NotFound from "../utils/NotFound";
import { schoolAuthenticate } from "../../redux/auth/thunk";
import LanguageSwitcher from "../utils/LanguageSwitcher";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

type SupportedLocales = keyof typeof locales;

function SchoolRouter() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const dispatch = useAppDispatch();
  const locale = useAppSelector((state: RootState) => state.localization.lng);
  const isAdminAuthenticated = useAppSelector((state: RootState) => state.auth.isAdminAuthenticated);
  const theme = createTheme(locales[locale as SupportedLocales]);
  const params = useParams();

  useEffect(() => {
    const { schoolCode } = params;
    dispatch(schoolAuthenticate(schoolCode));
  }, [dispatch]);

  useEffect(() => {
    console.log("isAdminAuthenticated", isAdminAuthenticated);
  }, [isAdminAuthenticated, lastSchool]);

  return (
    <ThemeProvider theme={theme}>
      {isAdminAuthenticated && (
        <Box mx={3}>
          <LanguageSwitcher />
          <Routes>
            <Route path="/application_list" element={<ApplicationFormList />} />
            <Route path="/application_list/:applicationListId/*" element={<ApplicationStageRouter />} />
            {/* <Route path="*" element={<Navigate to={`/cms/school/${lastSchool}/application_list/`} />} /> */}
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default SchoolRouter;
