import React, { ReactNode, useEffect } from "react";
import { Control, Controller, FieldErrors, FieldValues, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput, { CountryData } from "react-phone-input-2";
import { FormControl, FormHelperText, Grid, MenuItem } from "@mui/material";
import localization from "../utils/countryCode.json";
import { RoundSelect, RoundTextField } from "../utils/CustomStyle";
import { SingleQuestion } from "./SubmissionForm";
import QuestionComponent from "./QuestionComponent";

interface Props {
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<FieldValues, any>;
  question: SingleQuestion;
}

function PhoneNumberV2({ question, errors, control }: Props) {
  const { t } = useTranslation();
  const required = t("view.pleaseAnswerTheQuestion");

  return (
    <Grid container>
      <Grid item xs={12}>
        <QuestionComponent
          fieldQuestionZhHk={question.fieldQuestion.zhHK}
          fieldQuestionEnUs={question.fieldQuestion.enUS}
          mandatory={question.mandatory}
          descriptionZhHk={question.description?.zhHK}
          descriptionEnUs={question.description?.enUS}
          hyperlink={question.hyperlink}
          error={errors[`${question.id}-code`] && true}
        />
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth size="small" error={errors[`${question.id}-code`] && true}>
          <FormControl fullWidth size="small" error={errors[`${question.id}-number`] && true}>
            <Controller
              name={`${question.id}-code` as const}
              control={control}
              rules={question.mandatory ? { required: t("forms.required") } : {}}
              defaultValue=""
              render={({ field: countryCodeField }) => (
                <Controller
                  name={`${question.id}-number` as const}
                  control={control}
                  rules={question.mandatory ? { required: t("forms.required") } : {}}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <PhoneInput
                        enableSearch
                        inputProps={{
                          name: "phoneNumber",
                          required: question.mandatory,
                          autoFocus: true,
                        }}
                        value={countryCodeField.value.replace("+", "") + field.value}
                        placeholder={t("register.phoneNumber")}
                        inputStyle={{ width: "100%" }}
                        isValid={!errors.phoneNumber && !errors.countryCode}
                        // isValid={fieldState.error.phoneNumber}
                        localization={localization}
                        onChange={(value, country, e, formattedValue) => {
                          countryCodeField.onChange((country as CountryData).dialCode ? `+${(country as CountryData).dialCode}` : "");
                          field.onChange(value.slice((country as CountryData).dialCode ? (country as CountryData).dialCode.length : 0));
                        }}
                      />
                      <FormHelperText className="textRed">{errors.countryCode && (t("register.countryCodeIsRequired") as ReactNode)}</FormHelperText>
                      <FormHelperText className="textRed">{errors.phoneNumber && (t("register.phoneNumberIsRequired") as ReactNode)}</FormHelperText>
                    </>
                  )}
                />
              )}
            />
          </FormControl>
        </FormControl>
      </Grid>
    </Grid>
  );
  return (
    <Grid container>
      <Grid item xs={12}>
        <QuestionComponent
          fieldQuestionZhHk={question.fieldQuestion.zhHK}
          fieldQuestionEnUs={question.fieldQuestion.enUS}
          mandatory={question.mandatory}
          descriptionZhHk={question.description?.zhHK}
          descriptionEnUs={question.description?.enUS}
          hyperlink={question.hyperlink}
          error={errors[`${question.id}-code`] && true}
        />
      </Grid>
      <Grid item xs={2}>
        {question.mandatory === true ? (
          <FormControl fullWidth size="small" error={errors[`${question.id}-code`] && true}>
            <Controller
              name={`${question.id}-code` as const}
              control={control}
              rules={{ required: t("forms.required") }}
              defaultValue=""
              render={({ field }) => (
                <RoundSelect {...field}>
                  <MenuItem value="+852">+852</MenuItem>
                  <MenuItem value="+86">+86</MenuItem>
                </RoundSelect>
              )}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth size="small" error={errors[`${question.id}-code`] && true}>
            <Controller
              name={`${question.id}-code` as const}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <RoundSelect {...field} size="small">
                  <MenuItem value="+852">+852</MenuItem>
                  <MenuItem value="+86">+86</MenuItem>
                </RoundSelect>
              )}
            />
          </FormControl>
        )}
      </Grid>

      <Grid item xs={0.5}></Grid>

      <Grid item xs={9.5} alignSelf="self-end">
        {question.mandatory === true ? (
          <FormControl fullWidth size="small" error={errors[`${question.id}-number`] && true}>
            <Controller
              name={`${question.id}-number` as const}
              control={control}
              rules={{ required: t("forms.required") }}
              defaultValue=""
              render={({ field }) => (
                <RoundTextField
                  {...field}
                  fullWidth
                  size="small"
                  type="text"
                  error={errors[`${question.id}-number`] && true}
                  helperText={errors[`${question.id}-number`] ? required : ""}
                />
              )}
            />
          </FormControl>
        ) : (
          <FormControl fullWidth size="small" error={errors[`${question.id}-number`] && true}>
            <Controller
              name={`${question.id}-number` as const}
              control={control}
              defaultValue=""
              render={({ field }) => (
                <RoundTextField
                  {...field}
                  fullWidth
                  size="small"
                  type="text"
                  error={errors[`${question.id}-number`] && true}
                  helperText={errors[`${question.id}-number`] ? required : ""}
                />
              )}
            />
          </FormControl>
        )}
      </Grid>
    </Grid>
  );
}

export default PhoneNumberV2;
