import produce from "immer";
import { AuthActions } from "./actions";

export interface JWTPayload {
  id: number;
  lastNameEnUs: string;
  firstNameEnUs: string;
  personalDocumentId: string;
}

export interface AuthState {
  isLoading: boolean;
  isAdminAuthenticated: boolean;
  isStudentAuthenticated: boolean;
  user?: number;
}

const initialState: AuthState = {
  isLoading: true,
  isAdminAuthenticated: false,
  isStudentAuthenticated: false,
};

// eslint-disable-next-line default-param-last
export function authReducer(state: AuthState = initialState, action: AuthActions): AuthState {
  switch (action.type) {
    case "@@auth/ADMIN_VERIFIED_SUCCESS":
      console.log("ADMIN_VERIFIED_SUCCESS");
      return produce(state, (nextState) => {
        nextState.isAdminAuthenticated = true;
      });

    case "@@auth/STUDENT_LOGIN_SUCCESS":
      localStorage.setItem("token", action.token);
      return produce(state, (nextState) => {
        nextState.isStudentAuthenticated = true;
        nextState.user = action.user;
      });

    case "@@auth/STUDENT_AUTO_LOGIN_SUCCESS":
      return produce(state, (nextState) => {
        nextState.isStudentAuthenticated = true;
        nextState.user = action.userId;
        nextState.isLoading = false;
      });

    case "@@auth/STUDENT_AUTO_LOGIN_FAIL":
      localStorage.removeItem("token");
      return produce(state, (nextState) => {
        nextState.isStudentAuthenticated = false;
        nextState.isLoading = false;
      });

    case "@@auth/STUDENT_LOGOUT_SUCCESS":
      localStorage.removeItem("token");
      return produce(state, (nextState) => {
        nextState.isStudentAuthenticated = false;
      });

    default:
      return state;
  }
}
