import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Button, Menu, Typography } from "@mui/material";
import React, { useState, MouseEvent } from "react";
import AddInterviewStatusModal from "./AddInterviewStatusModal";

function interviewStatusMenu() {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleOpen = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <>
      <Button
        className="buttonGroupSent"
        variant="outlined"
        size="small"
        // aria-controls={openCustomizeView ? "basic-menu" : undefined}
        // aria-haspopup="true"
        // aria-expanded={openCustomizeView ? "true" : undefined}
        onClick={handleOpen}
      >
        INTERVIEW STATUS
        <ArrowDropDownIcon />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          style: {
            width: 300,
          },
        }}
      >
        <Typography
          sx={{
            mx: "16px",
            my: "8px",
            fontWeight: 400,
            fontSize: "10px",
            lineHeight: "120%",
            alignItems: "center",
            letterSpacing: "0.15px",
          }}
        >
          Add / edit the Interview status to identify the interview result of the candidate.
        </Typography>
        <AddInterviewStatusModal />
      </Menu>
    </>
  );
}

export default interviewStatusMenu;
