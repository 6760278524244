import React, { useRef, useState } from "react";
import EmailEditor from "react-email-editor";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { push } from "redux-first-history";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Breadcrumbs, Button, Grid, Link, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";
import { getApplicationListById } from "../../../api/applicationList";
import { editMessageTemplateById, getMessageTemplateById } from "../../../api/messageTemplate";
import { ApplicationList } from "../../../interfaces/applicationList";
import { MessageEmailEditorExport } from "../../../interfaces/message";

export interface EditMessageTemplateInput {
  name: string;
  html: string;
  json: object;
  type: string;
}

const cancelBtwStyle = {
  marginLeft: 2,
  color: "#606266",
  border: "1px solid #606266",
};

const createBtwStyle = {
  bgcolor: "#409EFF",
  marginLeft: 2,
  color: "#FFFFFF",
};

function EditTemplate() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const lastSchool = sessionStorage.getItem("lastSchool");
  const editTemplate = t("sendMessage.editTemplate");
  const templateTitle = t("sendMessage.templateTitle");
  const cancel = t("sendMessage.cancel");
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emailEditorRef = useRef<any | null>(null) as React.MutableRefObject<any | null>;
  const [isTemplateLoading, setIsTemplateLoading] = useState<boolean>(true);

  const [applicationListData, setApplicationListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });
  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setApplicationListData(result.data);
    }
  }

  const onReturn = () => {
    navigate(-1);
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<EditMessageTemplateInput>();

  const onSubmit: SubmitHandler<EditMessageTemplateInput> = async (data) => {
    const { templateId, applicationListId, formId } = params;
    if (templateId) {
      const id = parseInt(templateId);
      const result = await editMessageTemplateById(id, data);
      if (result.success) {
        if (applicationListId && formId) {
          dispatch(push(`/cms/school/${lastSchool}/application_list/${applicationListId}/dashboard/${formId}/message/template`));
        }
      }
    }
  };

  const exportHtml = () => {
    emailEditorRef.current?.editor.exportHtml(async (jsonData: MessageEmailEditorExport) => {
      const { html, design } = jsonData;
      setValue("html", html);
      setValue("json", design);
      setValue("type", "EMAIL");
      await handleSubmit(onSubmit)();
    });
  };

  const onReady = () => {
    const fetching = async () => {
      if (params && params.templateId && params.applicationListId) {
        const templateId = parseInt(params.templateId);
        const listId = parseInt(params.applicationListId);

        await fetchApplicationList(listId);

        const result = await getMessageTemplateById(templateId);

        if (result.success) {
          setValue("name", result.data.name);
          setValue("html", result.data.html);
          setValue("json", result.data.json);
          setValue("type", result.data.type);
          emailEditorRef.current?.editor.loadDesign(result.data.json);
        }
        setIsTemplateLoading(false);
      }
    };
    fetching();
  };

  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId) {
      const listId = parseInt(params.applicationListId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/summary`));
    }
  }

  function handleClick3(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}`));
    }
  }

  function handleClick4(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message`));
    }
  }

  // function handleClick5(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
  //     event.preventDefault();
  //     if (params && params.applicationListId && params.formId) {
  //         const listId = parseInt(params.applicationListId);
  //         const formId = parseInt(params.formId);
  //         dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message/create`));
  //     }
  // }

  function handleClick6(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (params && params.applicationListId && params.formId) {
      const listId = parseInt(params.applicationListId);
      const formId = parseInt(params.formId);
      dispatch(push(`/cms/school/${lastSchool}/application_list/${listId}/dashboard/${formId}/message/template`));
    }
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHK" ? applicationListData.titleZhHk : i18n.resolvedLanguage === "enUS" ? applicationListData.titleEnUs : null}
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick3}>
      Application
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick4}>
      Send Message
    </Link>,
    // <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick5}>
    //     Create Message
    // </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick6}>
      Message Template
    </Link>,
    <Typography key="3" color="primary.main">
      Edit Template
    </Typography>,
  ];

  return (
    <Grid sx={{ m: 2 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isTemplateLoading && (
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            {breadcrumbs}
          </Breadcrumbs>
        )}
        <Grid sx={{ my: 1, background: "white" }}>
          <Grid container sx={{ p: 2 }} className="outterContainer">
            {!isTemplateLoading && (
              <Grid item xs={12}>
                <div className="pageTitle">{editTemplate}</div>
                <Controller
                  name={"name" as const}
                  control={control}
                  rules={{ required: "Message Title is required" }}
                  defaultValue=""
                  render={({ field }) => <TextField {...field} fullWidth label={templateTitle} error={errors.name && true} helperText={errors.name && `${errors.name.message}`} />}
                />
              </Grid>
            )}

            <Grid item xs={12}>
              <EmailEditor ref={emailEditorRef} onReady={onReady} />
            </Grid>

            {!isTemplateLoading && (
              <Grid item xs={12} display="flex" justifyContent="flex-end" alignItems="center" className="buttonGroups">
                <Button sx={cancelBtwStyle} variant="outlined" size="small" onClick={onReturn}>
                  {cancel}
                </Button>
                <Button sx={createBtwStyle} variant="outlined" size="small" onClick={exportHtml}>
                  Update
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default EditTemplate;
