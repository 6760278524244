import { DuplicateMessageFormData } from "../interfaces/message";
import client from "./interceptors";

export async function getAllMessages(listId: number, stageId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/message`);
  const result = res.data;
  return result;
}

export async function createMessage(listId: number, stageId: number, data: FormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/message`, data);
  const result = res.data;
  return result;
}

export async function duplicateMessage(listId: number, stageId: number, data: DuplicateMessageFormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/message/duplicate`, data);
  const result = res.data;
  return result;
}

export async function insertMessageAttachment(listId: number, stageId: number, data: FormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/message/attachment`, data);
  const result = res.data;
  return result;
}

export async function sendMessage(listId: number, stageId: number, messageId: number) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/message/${messageId}/send`);
  const result = res.data;
  return result;
}

export async function createTestMessage(listId: number, stageId: number, data: any) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/message/test`, data);
  const result = res.data;
  return result;
}

export async function getMessageById(listId: number, stageId: number, messageId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/message/${messageId}`);
  const result = res.data;
  return result;
}

export async function updateMessageById(listId: number, stageId: number, messageId: number, data: FormData) {
  const res = await client.put(`applicationList/${listId}/applicationStage/${stageId}/message/${messageId}`, data);
  const result = res.data;
  return result;
}

export async function deleteMessageById(listId: number, stageId: number, messageId: number) {
  const res = await client.delete(`applicationList/${listId}/applicationStage/${stageId}/message/${messageId}`);
  const result = res.data;
  return result;
}
