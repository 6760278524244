import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Modal, Popover, Select, TextField } from "@mui/material";
import React, { ChangeEvent, useState } from "react";
import { Control, UseFormRegister, UseFormSetValue, UseFormGetValues, UseFormWatch, FieldErrors, useFieldArray, Controller } from "react-hook-form";
import LinkRoundedIcon from "@mui/icons-material/LinkRounded";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { CreateApplicationStageFormInput } from "./CreateApplicationForm";

interface Props {
  nestIndex: number;
  secondNestIndex: number;
  control: Control<CreateApplicationStageFormInput, object>;
  register: UseFormRegister<CreateApplicationStageFormInput>;
  setValue: UseFormSetValue<CreateApplicationStageFormInput>;
  getValues: UseFormGetValues<CreateApplicationStageFormInput>;
  watch: UseFormWatch<CreateApplicationStageFormInput>;
  errors: FieldErrors<CreateApplicationStageFormInput>;
}

const style = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
};

const useStyles = makeStyles(() => ({
  menuPaper: {
    maxHeight: "calc(100% - 100px)",
    bottom: 100,
  },
}));

function CustomDropdownSessionQuestions({ nestIndex, secondNestIndex, control, watch, setValue, getValues, errors }: Props) {
  const { t } = useTranslation();
  const classes = useStyles();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.dropdownQuestions.custom`,
  });

  const onAppend = () => {
    append({
      enUS: "",
      zhHK: "",
      points: 0,
      linkedQuestion: "",
    });
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  // Functions for point calculation
  const [optionPoint, setOptionPoint] = useState<null | HTMLElement>(null);
  const [selectedIndexForPoint, setSelectedIndexForPoint] = useState(-1);
  const [selectedIndexForLinkedQuestion, setSelectedIndexForLinkedQuestion] = useState(-1);

  const handleClickForOptionPoint = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setOptionPoint(event.currentTarget);
    setSelectedIndexForPoint(index);
  };

  const handleCloseForOptionPoint = () => {
    setOptionPoint(null);
  };

  const openForOptionPoint = Boolean(optionPoint);
  const optionPointId = openForOptionPoint ? "simple-popover" : undefined;

  // Functions for question linkage
  const [openOptionLinked, setOptionLinked] = useState(false);
  const handleOpenOptionLinked = (index: number) => {
    setOptionLinked(true);
    setSelectedIndexForLinkedQuestion(index);
  };
  const handleCloseOptionLinked = () => setOptionLinked(false);

  return (
    <Grid container>
      {fields.map((customDropdownQuestion, k) => {
        return (
          <Grid item xs={12} display="flex" alignItems="center" key={customDropdownQuestion.id}>
            <Grid container display="flex" alignItems="center" spacing={2}>
              <Grid item xs={1} sm={1} md={0.4} className="singleChoiceRadioButtonUncheckedIcon" display="flex" justifyContent="center">
                {k + 1}
              </Grid>

              <Grid item xs={5.5} sm={5.5} md={4}>
                <Controller
                  name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.dropdownQuestions.custom.${k}.enUS` as const}
                  control={control}
                  rules={{ required: `Option ${k + 1} (English) is required` }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      label={`${t("createForm.formContent.option")} ${k + 1} ${t("createForm.formContent.english")}`}
                      error={errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.dropdownQuestions?.custom?.[k]?.enUS && true}
                      helperText={
                        errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.dropdownQuestions?.custom?.[k]?.enUS !== undefined &&
                        `${errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.dropdownQuestions?.custom?.[k]?.enUS?.message}`
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={5.5} sm={5.5} md={4}>
                <Controller
                  name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.dropdownQuestions.custom.${k}.zhHK` as const}
                  control={control}
                  rules={{ required: `Option ${k + 1} (Chinese) is required` }}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      variant="standard"
                      label={`${t("createForm.formContent.option")} ${k + 1} ${t("createForm.formContent.chinese")}`}
                      error={errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.dropdownQuestions?.custom?.[k]?.zhHK && true}
                      helperText={
                        errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.dropdownQuestions?.custom?.[k]?.zhHK !== undefined &&
                        `${errors.fieldContent?.[nestIndex]?.sectionQuestion?.[secondNestIndex]?.dropdownQuestions?.custom?.[k]?.zhHK?.message}`
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={5} sm={5} md={2} display="flex" alignItems="center" justifyContent="center">
                <Button className="yesNoAddPointBtnContainer" variant="outlined" size="small">
                  <Grid container className="yesNoAddPointBtnElem">
                    {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].dropdownQuestions.custom[k].points > 0 ? null : (
                      <Grid
                        item
                        xs={3}
                        onClick={(event: React.MouseEvent<HTMLElement>) => {
                          handleClickForOptionPoint(event, k);
                        }}
                      >
                        <div className="yesNoAddPointBtn">
                          <AddIcon fontSize="small" />
                        </div>
                      </Grid>
                    )}
                    <Grid
                      item
                      xs={9}
                      onClick={(event: React.MouseEvent<HTMLElement>) => {
                        handleClickForOptionPoint(event, k);
                      }}
                    >
                      {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].dropdownQuestions.custom[k].points > 0
                        ? `${getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].dropdownQuestions.custom[k].points} point`
                        : t("createForm.formContent.addPoint")}
                    </Grid>
                    {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].dropdownQuestions.custom[k].points > 0 ? (
                      <Grid item xs={3}>
                        <div
                          className="yesNoRemovePointBtn"
                          onClick={() => {
                            watch([`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.dropdownQuestions.custom.${k}.points`]);
                            setValue(`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.dropdownQuestions.custom.${k}.points`, 0);
                          }}
                        >
                          <ClearIcon fontSize="small" />
                        </div>
                      </Grid>
                    ) : null}
                  </Grid>
                </Button>
                <Popover
                  id={optionPointId}
                  open={openForOptionPoint}
                  anchorEl={optionPoint}
                  onClose={handleCloseForOptionPoint}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <div className="yesNoQuestionsContainer">
                    <div>
                      <Controller
                        name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.dropdownQuestions.custom.${selectedIndexForPoint}.points` as const}
                        control={control}
                        rules={{
                          required: "Point is required",
                        }}
                        defaultValue={0}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            fullWidth
                            size="small"
                            label={t("createForm.formContent.answerPoint")}
                            inputProps={{ type: "number" }}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                              if (parseInt(e.target.value) >= 0) {
                                field.onChange(parseInt(e.target.value));
                              }
                            }}
                          />
                        )}
                      />
                    </div>
                    <div className="yesNoQuestionsElem">
                      <Button variant="contained" onClick={handleCloseForOptionPoint}>
                        {t("createForm.formContent.confirm")}
                      </Button>
                    </div>
                  </div>
                </Popover>
              </Grid>

              <Grid item xs={6} sm={6} md={0.8} alignItems="center" display="flex" className="singleChoiceLinkRemoveIcon">
                <LinkRoundedIcon
                  onClick={() => {
                    handleOpenOptionLinked(k);
                  }}
                />
                <Modal open={openOptionLinked} onClose={handleCloseOptionLinked} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                  <Box sx={style}>
                    <div className="yesNoModalTitle">{t("createForm.formContent.createLinkage")}</div>
                    <div className="yesNoModal">{t("createForm.formContent.linkageDes")}</div>
                    <div className="yesNoSelectedContainer">
                      <div className="yesNoSelectedTitle">{t("createForm.formContent.theSelectedAnswer")}</div>
                      {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].dropdownQuestions.custom[selectedIndexForLinkedQuestion] &&
                        getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].dropdownQuestions.custom[selectedIndexForLinkedQuestion].zhHK && (
                          <div className="yesNoSelectedAns">
                            {getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].dropdownQuestions.custom[selectedIndexForLinkedQuestion] &&
                              getValues().fieldContent[nestIndex].sectionQuestion[secondNestIndex].dropdownQuestions.custom[selectedIndexForLinkedQuestion].zhHK}
                          </div>
                        )}
                    </div>
                    <FormControl fullWidth required>
                      <InputLabel id="relatedQuestionLabel">{t("createForm.formContent.relatedSectionQuestion")}</InputLabel>
                      <Controller
                        name={`fieldContent.${nestIndex}.sectionQuestion.${secondNestIndex}.dropdownQuestions.custom.${selectedIndexForLinkedQuestion}.linkedQuestion` as const}
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            label="Related Section/Question"
                            id="relatedQuestionLabel"
                            variant="outlined"
                            MenuProps={{
                              classes: {
                                paper: classes.menuPaper,
                              },
                            }}
                          >
                            {getValues().fieldContent.map((fieldOption, i) => {
                              return (
                                <MenuItem key={i} value={i}>
                                  {i !== nestIndex && (
                                    <div>
                                      {t("createForm.formContent.question")} {i + 1}
                                      {fieldOption.singleQuestion.fieldQuestion.zhHK}
                                    </div>
                                  )}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Modal>
              </Grid>

              <Grid item xs={1} sm={1} md={0.8} alignItems="center" display="flex" className="singleChoiceLinkRemoveIcon">
                <ClearIcon
                  onClick={() => {
                    onRemove(k);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        );
      })}

      <Grid item xs={12} display="flex" alignItems="center" style={{ minHeight: 48 }}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item xs={1} sm={1} md={0.4} className="singleChoiceRadioButtonUncheckedIcon" display="flex" justifyContent="center">
            <RadioButtonUncheckedIcon />
          </Grid>
          <Grid item xs={11} sm={11} md={11.6}>
            <div className="singleChoiceAddMessageContainer">
              <Button onClick={onAppend} className="singleChoiceAddMessage">
                {t("createForm.formContent.addNewOption")}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default CustomDropdownSessionQuestions;
