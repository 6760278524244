/* eslint-disable react/no-array-index-key */
import React, { ReactNode } from "react";
import { FieldErrors, FieldValues, UseFormClearErrors, UseFormGetValues, UseFormRegister, UseFormSetError, UseFormSetValue, UseFormWatch } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectChangeEvent } from "@mui/material/Select";
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem } from "@mui/material";
import { AnswerSet, MultipleChoiceQuestion, MultipleLangAns } from "../../interfaces/createApplicationForm";
import { RoundSelect } from "../utils/CustomStyle";
import { SingleQuestion } from "./SubmissionForm";
import { LanguageEnum } from "../../models/languageEnum";
import QuestionComponent from "./QuestionComponent";

interface MultipleAnswerSetSubmissionProps {
  question: SingleQuestion;
  answerSets: AnswerSet[];
  answers: MultipleChoiceQuestion[];
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
}

interface AnswerSetsProps {
  question: SingleQuestion;
  answer: MultipleChoiceQuestion;
  sets: MultipleChoiceQuestion[];
  placeholder: MultipleLangAns;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
}

function AnswerSets({ question, answer, sets, placeholder, register, errors, watch, setError, clearErrors, setValue, getValues }: AnswerSetsProps) {
  const { i18n, t } = useTranslation();

  function checkDuplicate() {
    const { answers, answerSets } = question.multipleAnswerSet;

    const comparingArr = [];
    let temporaryArr = [];
    for (let i = 0; i < answers.length; i++) {
      const selectedAnswer = answers[i].enUS;
      for (let j = 0; j < answerSets.length; j++) {
        const selectedPlaceholder = answerSets[j].placeholder.enUS;
        const answerObj = getValues(`${question.id}.${selectedPlaceholder}.${selectedAnswer}`);
        temporaryArr.push(answerObj);
      }
      comparingArr.push(temporaryArr);
      temporaryArr = [];
    }

    let duplicate = false;
    for (let i = 0; i < comparingArr.length; i++) {
      for (let j = 1; j < comparingArr.length; j++) {
        let emptyString = false;
        if (i !== j && i < j) {
          const firstArray = comparingArr[i];
          const secondArray = comparingArr[j];
          for (const elem of firstArray) {
            if (elem === "") {
              emptyString = true;
            }
          }
          for (const elem of secondArray) {
            if (elem === "") {
              emptyString = true;
            }
          }
          if (JSON.stringify(comparingArr[i]) === JSON.stringify(comparingArr[j]) && !emptyString) {
            duplicate = true;
          }
        }
      }
    }
    return duplicate;
  }

  const handleChange = (event: SelectChangeEvent<unknown>, child: ReactNode, selectedPlaceholder: string, selectedAnswer: string) => {
    watch(`${question.id}.${selectedPlaceholder}.${selectedAnswer}`);
    setValue(`${question.id}.${selectedPlaceholder}.${selectedAnswer}`, event.target.value);
    const duplicate = checkDuplicate();
    if (duplicate) {
      setError(`${question.id}`, { type: "custom", message: "custom message" });
    } else {
      clearErrors(`${question.id}`);
    }
  };

  return (
    <>
      {question.mandatory === true ? (
        <FormControl style={{ minWidth: "49%" }} size="small" error={errors[question.id] && true} required>
          <InputLabel id="demo-simple-select-error-label">{i18n.resolvedLanguage === LanguageEnum.EN_US ? placeholder.enUS : placeholder.zhHK}</InputLabel>
          <RoundSelect
            {...register(`${question.id}.${placeholder.enUS}.${answer.enUS}`, {
              required: t("forms.required"),
            })}
            value={(getValues(`${question.id}.${placeholder.enUS}.${answer.enUS}`) || "").trimEnd()}
            label={i18n.resolvedLanguage === LanguageEnum.EN_US ? placeholder.enUS : placeholder.zhHK}
            onChange={(e, child) => {
              handleChange(e, child, placeholder.enUS, answer.enUS);
            }}
            fullWidth
          >
            {sets.map((setDetail: MultipleChoiceQuestion, index: number) => (
              <MenuItem key={index} value={setDetail.enUS.trimEnd()}>
                {i18n.resolvedLanguage === LanguageEnum.ZH_HK ? `${setDetail.zhHK}` : `${setDetail.enUS}`}
              </MenuItem>
            ))}
          </RoundSelect>
          <FormHelperText>{errors[question.id] && "Duplicate"}</FormHelperText>
        </FormControl>
      ) : (
        <FormControl style={{ minWidth: "49%" }} size="small" error={errors[question.id] && true}>
          <InputLabel id="demo-simple-select-error-label">{i18n.resolvedLanguage === LanguageEnum.EN_US ? placeholder.enUS : placeholder.zhHK}</InputLabel>
          <RoundSelect
            {...register(`${question.id}.${placeholder.enUS}.${answer.enUS}`)}
            value={(getValues(`${question.id}.${placeholder.enUS}.${answer.enUS}`) || "").trimEnd()}
            onChange={(e, child) => {
              handleChange(e, child, placeholder.enUS, answer.enUS);
            }}
            size="small"
            fullWidth
          >
            {sets.map((setDetail: MultipleChoiceQuestion, index: number) => (
              <MenuItem key={index} value={setDetail.enUS.trimEnd()}>
                {i18n.resolvedLanguage === LanguageEnum.ZH_HK ? `${setDetail.zhHK}` : `${setDetail.enUS}`}
              </MenuItem>
            ))}
          </RoundSelect>
          <FormHelperText>{errors[question.id] && "Duplicate"}</FormHelperText>
        </FormControl>
      )}
    </>
  );
}

function MultipleAnswerSetSubmission({ question, answerSets, answers, register, errors, setError, clearErrors, watch, setValue, getValues }: MultipleAnswerSetSubmissionProps) {
  const { i18n } = useTranslation(["translation"]);

  return (
    <>
      <QuestionComponent
        fieldQuestionZhHk={question.fieldQuestion.zhHK}
        fieldQuestionEnUs={question.fieldQuestion.enUS}
        mandatory={question.mandatory}
        descriptionZhHk={question.description?.zhHK}
        descriptionEnUs={question.description?.enUS}
        hyperlink={question.hyperlink}
        error={errors[question.id] && true}
      />
      {answers.map((answer: MultipleChoiceQuestion, index: number) => (
        <div key={index}>
          <div className="submissionNormalText submissionMultipleAns">
            {index + 1}
            {". "}
            {i18n.resolvedLanguage === LanguageEnum.ZH_HK ? `${answer.zhHK}` : `${answer.enUS}`}
          </div>

          <Grid sx={{ mb: 1 }} display="flex" justifyContent="space-between">
            {answerSets.map((answerSet: AnswerSet, nestedIndex: number) => (
              <AnswerSets
                key={nestedIndex}
                question={question}
                answer={answer}
                sets={answerSet.sets}
                placeholder={answerSet.placeholder}
                register={register}
                errors={errors}
                setError={setError}
                clearErrors={clearErrors}
                watch={watch}
                setValue={setValue}
                getValues={getValues}
              />
            ))}
          </Grid>
        </div>
      ))}
    </>
  );
}

export default MultipleAnswerSetSubmission;
