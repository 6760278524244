import "react-calendar/dist/Calendar.css";
import "./CreateMessage.css";
import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useRef, useState } from "react";
import Calendar from "react-calendar";
import { FileUploader } from "react-drag-drop-files";
import EmailEditor from "react-email-editor";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import TimeField from "react-simple-timefield";
import { push } from "redux-first-history";
import ClearIcon from "@mui/icons-material/Clear";
import CloseIcon from "@mui/icons-material/Close";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Box, Breadcrumbs, Button, FormControl, Grid, InputLabel, Link, MenuItem, Modal, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "../../../redux/hooks";
import { getApplicationListById } from "../../../api/applicationList";
import { findKeysForMailMergeFromAppicationStageId } from "../../../api/applicationStage";
import { createMessage, sendMessage } from "../../../api/message";
import { getMessageTemplate } from "../../../api/messageTemplate";
import { findSubmissionsAndUserByApplicationStageId } from "../../../api/submission";
import { ApplicationList } from "../../../interfaces/applicationList";
import { MessageTemplate } from "../../../interfaces/messageTemplate";
import { KeyForMailMerge, RecipientListForMultipleSelectDropdown, SubmissionOuterJoinUser } from "../../../interfaces/submission";
import { ApplicantStatusEnum } from "../../../models/applicantStatusEnum";
import SentTestMessage from "./SentTestMessage";
import formatBytes from "../../utils/formatFileSize";
import { MessageEmailEditorExport } from "../../../interfaces/message";
import VariableFormAnswerMenu from "./VariableFormAnswerMenu";

export interface CreateMessageData {
  name: string;
  type: string;
  recipients: RecipientListForMultipleSelectDropdown[];
  templateId: string;
  html: string;
  json: object;
  attachments: FileList;
  scheduledTime: null | Date;
  testRecipient: string;
}

export interface FormattedCreateMessageData {
  name: string;
  type: string;
  html: string;
  json: object;
  userIds: number[];
  scheduledTime: null | Date;
  testRecipient: string;
}

const sendTestMessage = {
  color: "#409EFF",
  marginLeft: 2,
};

const scheduleModalStyle = {
  position: "absolute" as const,
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  p: 3,
  borderRadius: 0.5,
};

function CreateMessage() {
  const lastSchool = sessionStorage.getItem("lastSchool");
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const params = useParams();
  const { applicationListId, formId } = params;
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const emailEditorRef = useRef<any | null>(null) as React.MutableRefObject<any | null>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const multiselectRef = useRef<any | null>(null) as React.MutableRefObject<any | null>;
  const createMessageTrans = t("sendMessage.createMessage");
  const messageTitle = t("sendMessage.messageTitle");
  const recipient = t("sendMessage.recipient");
  const messageTemplate = t("sendMessage.messageTemplate");
  const dragAndDropOrBrowse = t("sendMessage.dragAndDropOrBrowse");
  const schedule = t("sendMessage.schedule");
  const sendNow = t("sendMessage.sendNow");
  const cancel = t("sendMessage.cancel");
  const scheduleMessage = t("sendMessage.scheduleMessage");
  const whenSent = t("sendMessage.whenSent");
  const enterTime = t("sendMessage.enterTime");
  const hour = t("sendMessage.hour");
  const minutes = t("sendMessage.minutes");
  const messageDes = t("sendMessage.messageDes");
  const at = t("sendMessage.at");

  // Fetching API
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [messageTemplateData, setMessageTemplateData] = useState<MessageTemplate[]>([]);
  const [applicantsData, setApplicantsData] = useState<SubmissionOuterJoinUser[]>([]);
  const [applicantList, setApplicantList] = useState<RecipientListForMultipleSelectDropdown[]>([]);
  const [listData, setListData] = useState<ApplicationList>({
    id: -1,
    schoolCode: "",
    titleEnUs: "",
    titleZhHk: "",
    status: "",
    createdAt: "",
    updatedAt: "",
  });
  const [keys, setKeys] = useState<KeyForMailMerge[]>([]);

  async function fetchApplicationList(id: number) {
    const result = await getApplicationListById(id);
    if (result.success) {
      setListData(result.data);
    }
  }

  async function fetchGetMessageTemplate() {
    const result = await getMessageTemplate();
    if (result.success) {
      setMessageTemplateData(result.data);
    }
  }

  async function fetchSubmissionsAndUserByApplicationStageId(listId: number, stageId: number) {
    const result = await findSubmissionsAndUserByApplicationStageId(listId, stageId);
    if (result.success) {
      setApplicantsData(result.data);
    }
  }

  async function fetchFindKeysForMailMergeFromAppicationStageId(listId: number, stageId: number) {
    const result = await findKeysForMailMergeFromAppicationStageId(listId, stageId);
    if (result.success) {
      setKeys(result.data);
    }
  }

  useEffect(() => {
    if (applicationListId && formId) {
      const fetching = async () => {
        await fetchApplicationList(parseInt(applicationListId));
        await fetchGetMessageTemplate();
        await fetchSubmissionsAndUserByApplicationStageId(parseInt(applicationListId), parseInt(formId));
        await fetchFindKeysForMailMergeFromAppicationStageId(parseInt(applicationListId), parseInt(formId));
      };

      fetching();
    }
  }, [params]);

  useEffect(() => {
    const applicantsArray = [];
    if (applicantsData.length !== 0) {
      const pendingApplicants = applicantsData.filter((data) => data.applicationStatus === ApplicantStatusEnum.PENDING);
      const acceptedApplicants = applicantsData.filter((data) => data.applicationStatus === ApplicantStatusEnum.ACCEPTED);
      const rejectedApplicants = applicantsData.filter((data) => data.applicationStatus === ApplicantStatusEnum.REJECT);
      const waitingApplicants = applicantsData.filter((data) => data.applicationStatus === ApplicantStatusEnum.WAITING);

      if (pendingApplicants.length !== 0) {
        applicantsArray.push({
          applicants: pendingApplicants,
          key: "Pending Appplicants",
          status: "Pending",
        });
      }

      if (acceptedApplicants.length !== 0) {
        applicantsArray.push({
          applicants: acceptedApplicants,
          key: "Accepted Appplicants",
          status: "Accept",
        });
      }

      if (rejectedApplicants.length !== 0) {
        applicantsArray.push({
          applicants: rejectedApplicants,
          key: "Rejected Appplicants",
          status: "Reject",
        });
      }

      if (waitingApplicants.length !== 0) {
        applicantsArray.push({
          applicants: waitingApplicants,
          key: "Waiting List",
          status: "Waiting",
        });
      }

      for (const applicant of applicantsData) {
        applicantsArray.push({
          applicants: [applicant],
          key: `${applicant.user.id} - ${applicant.user.firstNameEnUs} ${applicant.user.lastNameEnUs} ${applicant.user.personalDocumentId} ${applicant.applicationStatus}`,
          status: "Individual",
        });
      }
      setApplicantList(applicantsArray);
    }
  }, [applicantsData]);

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
    setValue,
    setError,
    clearErrors,
    watch,
  } = useForm<CreateMessageData>({
    defaultValues: {
      name: "",
      type: "",
      recipients: [],
      templateId: "",
      html: "",
      json: {},
      attachments: undefined,
      scheduledTime: null,
      testRecipient: "",
    },
  });

  // Schedule message
  const [open, setScheduleOpen] = useState<boolean>(false);
  const scheduleOpen = () => setScheduleOpen(true);
  const scheduleClose = () => setScheduleOpen(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());
  const [inputTime, setInputTime] = useState<string>("00:00");
  const handleDateChange = (newValue: Date) => {
    setSelectedDate(newValue);
  };

  const onSubmit: SubmitHandler<CreateMessageData> = async (data) => {
    const recipientList: RecipientListForMultipleSelectDropdown[] = data.recipients;
    const userIds: number[] = [];

    if (recipientList.length === 0) {
      setError("recipients", { type: "required", message: "Receipient list is empty." });
    } else if (recipientList.length !== 0) {
      for (const list of recipientList) {
        if (list.status !== "Individual") {
          for (const applicant of list.applicants) {
            userIds.push(applicant.userId);
          }
        }
      }

      for (const list of recipientList) {
        if (list.status === "Individual") {
          const checkDuplicate = userIds.includes(list.applicants[0].userId);
          if (!checkDuplicate) {
            userIds.push(list.applicants[0].userId);
          }
        }
      }

      // const { templateId, attachments, recipients, ...formattedData } = data;
      // const form = { ...formattedData, userIds };
      const { name, type, html, json, scheduledTime, testRecipient } = data;

      const formData = new FormData();
      formData.append("userIds", JSON.stringify(userIds));
      formData.append("name", name);
      formData.append("type", type);
      formData.append("html", html);
      formData.append("json", JSON.stringify(json));
      formData.append("scheduledTime", JSON.stringify(scheduledTime));
      formData.append("testRecipient", testRecipient);
      if (data.attachments) {
        for (let i = 0; i < data.attachments.length; i++) {
          formData.append("attachments", data.attachments[i]);
        }
      }

      if (applicationListId && formId) {
        const message = await createMessage(parseInt(applicationListId), parseInt(formId), formData);

        if (message.success) {
          const { id: messageId } = message.data;
          const result = await sendMessage(parseInt(applicationListId), parseInt(formId), parseInt(messageId));
          if (result.success) {
            setScheduleOpen(false);
            dispatch(push(`/cms/school/${lastSchool}/application_list/${parseInt(applicationListId)}/dashboard/${parseInt(formId)}/message`));
          }
        }
      }
    }
  };

  const sendSchedule = () => {
    emailEditorRef.current?.editor.exportHtml(async (jsonData: MessageEmailEditorExport) => {
      const { html, design } = jsonData;
      setValue("type", "EMAIL");
      setValue("html", html);
      setValue("json", design);

      const year = selectedDate.getFullYear();
      const month = selectedDate.getMonth();
      const date = selectedDate.getDate();
      const hours = parseInt(inputTime.split(":")[0]);
      const mins = parseInt(inputTime.split(":")[1]);
      const scheduledTime = new Date(year, month, date, hours, mins);
      setValue("scheduledTime", scheduledTime);

      await handleSubmit(onSubmit)();
    });
  };

  const exportHtml = () => {
    emailEditorRef.current?.editor.exportHtml(async (jsonData: MessageEmailEditorExport) => {
      const { html, design } = jsonData;
      setValue("type", "EMAIL");
      setValue("html", html);
      setValue("json", design);

      await handleSubmit(onSubmit)();
    });
  };

  const onReturn = () => {
    navigate(-1);
  };

  const onReady = () => {
    setIsLoading(false);
  };

  const [files, setFiles] = useState<FileList | null>(null);
  const handleFileChange = (file: FileList) => {
    setFiles(file);
    setValue("attachments", file);
  };

  // Breadcrumbs
  function handleClick1(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    dispatch(push(`/cms/school/${lastSchool}/application_list`));
  }

  function handleClick2(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (applicationListId) {
      dispatch(push(`/cms/school/${lastSchool}/application_list/${parseInt(applicationListId)}/summary`));
    }
  }

  function handleClick3(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (applicationListId && formId) {
      dispatch(push(`/cms/school/${lastSchool}/application_list/${parseInt(applicationListId)}/dashboard/${parseInt(formId)}`));
    }
  }

  function handleClick4(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    event.preventDefault();
    if (applicationListId && formId) {
      dispatch(push(`/cms/school/${lastSchool}/application_list/${parseInt(applicationListId)}/dashboard/${parseInt(formId)}/message`));
    }
  }

  const breadcrumbs = [
    <Link underline="hover" key="1" color="inherit" href="/" onClick={handleClick1}>
      E-Admission
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick2}>
      {i18n.resolvedLanguage === "zhHK" ? listData.titleZhHk : i18n.resolvedLanguage === "enUS" ? listData.titleEnUs : null}
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick3}>
      Application
    </Link>,
    <Link underline="hover" key="2" color="inherit" href="/" onClick={handleClick4}>
      Send Message
    </Link>,
    <Typography key="3" color="primary.main">
      Create Message
    </Typography>,
  ];

  const fileTypes = ["xlsx", "XLSX", "pdf", "PDF"];

  return (
    <Grid sx={{ m: 2 }}>
      {!isLoading && (
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
          {breadcrumbs}
        </Breadcrumbs>
      )}

      <form onSubmit={handleSubmit(onSubmit)} method="post" encType="multipart/form-data">
        <Grid sx={{ my: 1, background: "white" }}>
          <Grid container sx={{ p: 2 }} className="outterContainer">
            {!isLoading && (
              <>
                <Grid item xs={12}>
                  <div className="pageTitle">{createMessageTrans}</div>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Controller
                      name={"name" as const}
                      control={control}
                      rules={{ required: "Message Title is required" }}
                      defaultValue=""
                      render={({ field }) => (
                        <TextField {...field} fullWidth label={messageTitle} error={errors.name && true} helperText={errors.name && `${errors.name.message}`} />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    {/* https://multiselect-react-dropdown.vercel.app/?path=/docs/multiselect-dropdown--arrray-of-objects */}

                    <Multiselect
                      ref={multiselectRef}
                      placeholder={recipient}
                      groupBy="status"
                      displayValue="key"
                      onSelect={function noRefCheck(selectedList: RecipientListForMultipleSelectDropdown[]) {
                        if (selectedList.length !== 0) {
                          clearErrors("recipients");
                        }
                        setValue("recipients", selectedList);
                        // TODO: Check Duplicate
                      }}
                      onRemove={function noRefCheck(selectedList: RecipientListForMultipleSelectDropdown[]) {
                        setValue("recipients", selectedList);
                      }}
                      options={applicantList}
                      showCheckbox
                      showArrow
                      hidePlaceholder
                      avoidHighlightFirstOption
                      style={{
                        searchBox: {
                          height: "56px",
                          borderColor: errors.recipients ? "#d32f2f" : null,
                        },
                      }}
                    />
                    <div className={errors.recipients && "formHelperText"}>{errors.recipients && "Recipients list is required"}</div>
                  </Grid>
                  <Grid item xs={6}></Grid>

                  <Grid item xs={6}>
                    {messageTemplateData.length !== 0 && (
                      <FormControl fullWidth>
                        <InputLabel id="messageTemplateLabel">{messageTemplate}</InputLabel>
                        <Controller
                          name={"templateId" as const}
                          defaultValue=""
                          control={control}
                          render={({ field }) => (
                            <Select
                              {...field}
                              onChange={(e: SelectChangeEvent<string>) => {
                                field.onChange(parseInt(e.target.value));
                                const id = parseInt(e.target.value);

                                const result = messageTemplateData.filter((message) => message.id === id);

                                if (result.length !== 0) {
                                  const content = result[0].json;
                                  emailEditorRef.current?.editor.loadDesign(content);
                                }
                              }}
                              id="messageTemplateLabel"
                              label="Message Template"
                            >
                              {messageTemplateData.map((msgTemplate) => {
                                return (
                                  <MenuItem key={msgTemplate.id} value={msgTemplate.id}>
                                    {msgTemplate.name}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          )}
                        />
                      </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={6}></Grid>

                  <Grid item xs={12}>
                    <VariableFormAnswerMenu keys={keys} />
                    {/* {keys.map((key) => (
                                            <Button
                                                variant="contained"
                                                key={key.id}
                                                onClick={() => {
                                                    // saveDesign(key.id);
                                                    navigator.clipboard.writeText(`{{${key.id}}}`);
                                                }}
                                                size="small"
                                                sx={{ m: 0.5 }}
                                            >
                                                {key.fieldQuestion} ({key.id})
                                            </Button>
                                        ))} */}
                  </Grid>
                </Grid>
              </>
            )}

            <Grid item xs={12} sx={{ mt: 1 }}>
              <Grid container>
                <Grid item xs={12}>
                  <EmailEditor ref={emailEditorRef} onReady={onReady} />
                </Grid>
              </Grid>
            </Grid>

            {!isLoading && (
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Grid container>
                  <Grid item xs={12}>
                    <div className="createMessageDragAndDropTitle">Upload Attachment (Max. 3 files, 2MB)</div>
                  </Grid>
                  <Grid item xs={12}>
                    <div>
                      <FileUploader
                        handleChange={handleFileChange}
                        name="file"
                        types={fileTypes}
                        multiple
                        label={dragAndDropOrBrowse}
                        hoverTitle="Drop here"
                        // eslint-disable-next-line react/no-children-prop
                        children={
                          <div>
                            <div className="fileUploaderContainer">
                              <div className="fileUploaderDragAndDropElem">
                                <CloudUploadIcon fontSize="large" />
                              </div>
                              <div className="fileUploaderDragAndDropElem">
                                {t("view.updateApplicantStatusButton.dragAndDropOr")}
                                <span className="fileUploaderDragAndDropBrowse">{t("view.updateApplicantStatusButton.browse")}</span>
                              </div>
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </Grid>

                  <Grid item xs={6}>
                    {files !== null &&
                      Array.from(files).map((file, index: number) => (
                        <div className="fileUploaderAttachmentContainer" key={file.name}>
                          <div className="fileUploaderAttachmentLeftElem">
                            <InsertDriveFileIcon className="fileUploaderAttachmentBlue fileUploaderAttachmentIcon" />
                            <div className="fileUploaderAttachmentBlue">{file.name}</div>
                            <div className="fileUploaderAttachmentFileSize">({formatBytes(file.size)})</div>
                          </div>
                          <div>
                            <CloseIcon
                              className="fileUploaderAttachmentRightElem"
                              onClick={() => {
                                const fileArr = Array.from(files);
                                fileArr.splice(index, 1);

                                const dt = new DataTransfer();
                                for (const singleFile of fileArr) {
                                  dt.items.add(singleFile);
                                }
                                const newFileList = dt.files;

                                setFiles(newFileList);
                                setValue("attachments", newFileList);
                              }}
                            />
                          </div>
                        </div>
                      ))}
                  </Grid>
                  <Grid item xs={6}></Grid>
                </Grid>
              </Grid>
            )}

            {!isLoading && (
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Grid container>
                  <Grid item xs={4} sm={4} md={6}>
                    <SentTestMessage
                      emailEditorRef={emailEditorRef}
                      {...{
                        control,
                        watch,
                        handleSubmit,
                        setValue,
                        getValues,
                        errors,
                        clearErrors,
                      }}
                    />
                  </Grid>
                  <Grid item xs={8} sm={8} md={6} display="flex" justifyContent="flex-end" alignItems="center">
                    <Button className="createMessageCancelBtn" variant="outlined" size="small" onClick={onReturn}>
                      {cancel}
                    </Button>
                    <>
                      <Button className="createMessageScheduleBtn" variant="outlined" size="small" onClick={scheduleOpen}>
                        {schedule}
                      </Button>

                      <Modal open={open} onClose={scheduleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                        <Box sx={scheduleModalStyle}>
                          <Grid container spacing={2}>
                            <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                              <div>{scheduleMessage}</div>
                              <Button variant="text" onClick={scheduleClose} className="modalClearBtn">
                                <ClearIcon />
                              </Button>
                            </Grid>
                            <Grid item xs={12} className="scheduleDescription">
                              {whenSent}
                            </Grid>
                            <Grid item xs={12} className="createMessageDateTimeContainer">
                              <div
                                style={{
                                  marginRight: 30,
                                }}
                              >
                                <Calendar locale="en-HK" onChange={handleDateChange} value={selectedDate} />
                              </div>

                              <div className="createMessageTimeContainer">
                                <div className="createMessageEnterTime">{enterTime}</div>
                                <div>
                                  <TimeField
                                    style={{
                                      border: "0px solid #666",
                                      fontSize: 56,
                                      width: 150,
                                      padding: "5px 8px",
                                      color: "#333",
                                      borderRadius: 3,
                                      backgroundColor: "rgba(33, 33, 33, 0.08)",
                                      margin: 10,
                                    }}
                                    onChange={(event, value) => {
                                      setInputTime(value);
                                    }}
                                  />
                                </div>

                                <div className="createMessageDateTimeContainer">
                                  <div className="scheduleHours">{hour}</div>
                                  <div className="scheduleMinutes">{minutes}</div>
                                </div>
                              </div>
                            </Grid>

                            {inputTime && (
                              <Grid item xs={12} className="createMessageTextContainer">
                                <div>{messageDes}</div>

                                <div className="blueTextForSchedule">
                                  {selectedDate.getFullYear()}/{selectedDate.getMonth() + 1}/{selectedDate.getDate()}
                                  <span className="marginLeft5">{at}</span>
                                  <span className="marginLeft5">{inputTime}</span>
                                </div>
                              </Grid>
                            )}
                            <Grid item xs={12} display="flex" justifyContent="flex-end">
                              <Button variant="text" onClick={scheduleClose} sx={sendTestMessage}>
                                {cancel}
                              </Button>
                              <Button variant="text" onClick={sendSchedule} sx={sendTestMessage}>
                                {scheduleMessage}
                              </Button>
                            </Grid>
                          </Grid>
                        </Box>
                      </Modal>
                    </>

                    <Button className="createMessageSendBtn" variant="contained" size="small" onClick={exportHtml}>
                      {sendNow}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
    </Grid>
  );
}

export default CreateMessage;
