import { Box, Button, Grid, Modal, Rating as RatingUI, Typography } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import modalStyle from "../../utils/modalStyle";
import { EvaluationForm, PointsTotal, Rating } from "../../../interfaces/interview";
import InterviewPerformanceRatingForm from "./InterviewPerformanceRatingForm";
import useWindowDimensions from "./useWindowDimensions";

interface InterviewPerformanceRatingModalProps {
  prefix: number;
  applicantId: number;
  answerPoints: PointsTotal;
  rating: (EvaluationForm | Rating)[];
}

function InterviewPerformanceRatingModal(props: InterviewPerformanceRatingModalProps) {
  const { prefix, applicantId, answerPoints, rating } = props;
  const { t } = useTranslation();
  const { height } = useWindowDimensions();
  const methods = useForm<Rating[][]>();
  // const { update } = useFieldArray({
  //     control: methods.control,
  //     name: "rating" as const,
  // })
  rating.forEach((value, index) => {
    if ("marks" in value) {
      methods.setValue(`${prefix}.${index}`, value);
    } else {
      // Object.entries(value).map((entry) => methods.setValue(`${index}.${entry[0]}` as const, entry[1]))
      // itemType: string;
      // itemTitle: MultipleLangAns;
      // fullMarks?: number;
      // description?: MultipleLangAns;
      // linearScale: LinearScale;
      // multipleChoice: MultipleChoiceAnswer;
      methods.setValue(`${prefix}.${index}.itemType`, value.itemType);
      methods.setValue(`${prefix}.${index}.itemTitle`, value.itemTitle);
      methods.setValue(`${prefix}.${index}.fullMarks`, value.fullMarks);
      methods.setValue(`${prefix}.${index}.description`, value.description);
      methods.setValue(`${prefix}.${index}.linearScale`, value.linearScale);
      methods.setValue(`${prefix}.${index}.multipleChoice`, value.multipleChoice);
    }
  });

  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    // methods.reset();
    setOpen(false);
  };

  const onSubmit: SubmitHandler<Rating[][]> = async (data) => {
    console.log("rating =", data);
  };

  return (
    <>
      <Grid style={{ display: "inline" }} onClick={handleOpen}>
        <Button className="buttonGroupSent" variant="outlined" size="small">
          {t("view.add")}
        </Button>
      </Grid>
      <Modal open={open} onClose={handleClose} aria-labelledby="parent-modal-title" aria-describedby="parent-modal-description">
        <Box sx={modalStyle}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                  <span style={{ fontWeight: 500, fontSize: "18px", lineHeight: "21px", letterSpacing: "0.15px" }}>Interview Performance Rating</span>
                  <Button variant="text" onClick={handleClose} className="modalClearBtn">
                    <ClearIcon />
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Grid container style={{ maxHeight: `${height - 200}px`, overflow: "scroll" }}>
                    <Grid
                      item
                      xs={12}
                      display="flex"
                      alignItems="center"
                      sx={{
                        fontWeight: 500,
                        fontSize: "14px",
                        lineHeight: "21px",
                        letterSpacing: "0.15px",
                        color: "#606266",
                      }}
                    >
                      <span>
                        {t("sendMessage.applicantId")}
                        {": "}
                        {`00000${applicantId}`.slice(-5)}
                      </span>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography
                        sx={{
                          bgcolor: "rgba(64, 158, 255, 0.05)",
                          borderRadius: "4px",
                          height: "28px",
                          fontWeight: 400,
                          fontSize: "12px",
                          lineHeight: "18px",
                          pl: 1.5,
                        }}
                        display="flex"
                        alignItems="center"
                      >
                        <span style={{ color: "#606266" }}>{t("interview.candidateAnswerPoint")}</span>
                        <span style={{ color: "#606266", paddingRight: "4px" }}>:</span>
                        <span style={{ color: "#1989FA" }}>{`${answerPoints.points} / ${answerPoints.total} ${t("interview.points")}`}</span>
                      </Typography>
                    </Grid>
                    <InterviewPerformanceRatingForm prefix={prefix} rating={methods.getValues()[prefix]} />
                  </Grid>
                </Grid>

                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <Button variant="text" onClick={handleClose}>
                    {t("view.cancel")}
                  </Button>
                  <Button variant="text" type="submit" onClick={handleClose}>
                    {t("view.submit")}
                  </Button>
                  <Button onClick={() => console.log(methods.getValues())}>get</Button>
                </Grid>
              </Grid>

              {/* <Grid container spacing={2}>
                                <Grid item xs={12} display="flex" justifyContent="space-between" alignItems="center" className="modalTitleContainer">
                                    <div>Interview Performance Rating</div>
                                    <Button variant="text" onClick={handleClose} className="modalClearBtn">
                                        <ClearIcon />
                                    </Button>
                                </Grid>
                                <Grid item xs={12}>
                                    {t("sendMessage.applicantId")}
                                    {": "}
                                    {`00000${applicantId}`.slice(-5)}
                                </Grid>
                                <Grid item xs={12} sx={{ bgcolor: "rgba(64, 158, 255, 0.05)" }}>
                                    <Typography>
                                        <span>{t("interview.candidateAnswerPoint")}</span>:{" "}
                                        <span style={{ color: "#1989FA" }}>{`${answerPoints.points} / ${answerPoints.total} ${t(
                                            "interview.points"
                                        )}`}</span>
                                    </Typography>
                                </Grid>
                                {rating.map((item, index) => (
                                    <Grid item xs={12} key={`${item.itemType}-${item.itemTitle.enUs}`}>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <span>{`${index + 1}. ${translate(i18n, item.itemTitle)}`}</span>
                                                {item.fullMarks && <span>{` (${item.fullMarks} Marks)`}</span>}
                                            </Grid>
                                            {item.description && (
                                                <Grid item xs={12}>
                                                    <span>{`${translate(i18n, item.description)}`}</span>
                                                </Grid>
                                            )}
                                            {item.fullMarks && (
                                                <Grid item xs={12}>
                                                    <Controller
                                                        name={`${index}.marks` as const}
                                                        control={methods.control}
                                                        rules={{ required: "Marks is required" }}
                                                        render={({ field }) => (
                                                            <TextField
                                                                {...field}
                                                                fullWidth
                                                                type="number"
                                                                // label="Marks"
                                                                error={methods.formState.errors?.[index]?.marks && true}
                                                                helperText={
                                                                    methods.formState.errors?.[index]?.marks &&
                                                                    `${methods.formState.errors?.[index]?.marks?.message}`
                                                                }
                                                                onChange={(event) => {
                                                                    if (
                                                                        event.target.value === "" ||
                                                                        (item.fullMarks &&
                                                                            parseInt(event.target.value) >= 0 &&
                                                                            parseInt(event.target.value) <= item.fullMarks)
                                                                    ) {
                                                                        field.onChange(event.target.value);
                                                                    }
                                                                }}
                                                                InputProps={{
                                                                    type: "number",
                                                                    endAdornment: <InputAdornment position="end">Mark(s)</InputAdornment>,
                                                                }}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            )}
                                            {item.itemType === "text" && (
                                                <Grid item xs={12}>
                                                    <Controller
                                                        name={`${index}.comments` as const}
                                                        control={methods.control}
                                                        // rules={{ required: " is required" }}
                                                        render={({ field }) => (
                                                            <TextareaAutosize
                                                                {...field}
                                                                style={{ width: "450px" }}
                                                                // type="text"
                                                                placeholder={t("interview.comments")}
                                                                // error={methods.formState.errors?.[index]?.comments && true}
                                                                // helperText={
                                                                //     methods.formState.errors?.[index]?.comments &&
                                                                //     `${methods.formState.errors?.[index]?.comments?.message}`
                                                                // }
                                                                onChange={(event) => field.onChange(event.target.value)}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            )}
                                            {item.itemType === "linearScale" && (
                                                <Grid item xs={12}>
                                                    <Controller
                                                        name={`${index}.rating` as const}
                                                        control={methods.control}
                                                        // rules={{ required: " is required" }}
                                                        render={({ field }) => (
                                                            <RatingUI
                                                                value={field.value}
                                                                onChange={(event, newValue) => {
                                                                    field.onChange(newValue);
                                                                }}
                                                                icon={<StarIcon color="primary" sx={{ paddingRight: "20px" }} />}
                                                                emptyIcon={<StarIcon sx={{ paddingRight: "20px" }} />}
                                                            />
                                                        )}
                                                    />
                                                </Grid>
                                            )}
                                            {item.itemType === "multipleChoice" && (
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset" variant="standard">
                                                        <FormGroup>
                                                            <Controller
                                                                name={`${index}.options`}
                                                                control={methods.control}
                                                                defaultValue={[]}
                                                                render={({ field }) => (
                                                                    <>
                                                                        {item.multipleChoice.option.map((option) => (
                                                                            <FormControlLabel
                                                                                key={`${item.itemType}-${option.enUs}-${option.zhHk}`}
                                                                                label={option.others ? t("forms.others") : translate(i18n, option)}
                                                                                control={
                                                                                    <Checkbox
                                                                                        value={translate(i18n, option)}
                                                                                        checked={field.value.some(
                                                                                            (existingValue) =>
                                                                                                existingValue === translate(i18n, option)
                                                                                        )}
                                                                                        onChange={(event, checked) => {
                                                                                            if (checked) {
                                                                                                field.onChange([...field.value, event.target.value]);
                                                                                            } else {
                                                                                                field.onChange(
                                                                                                    field.value.filter(
                                                                                                        (value) => value !== event.target.value
                                                                                                    )
                                                                                                );
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                }
                                                                            />
                                                                        ))}
                                                                    </>
                                                                )}
                                                            />
                                                        </FormGroup>
                                                    </FormControl>
                                                </Grid>
                                            )}
                                        </Grid>
                                    </Grid>
                                ))}
                                <Grid item xs={12} display="flex" justifyContent="flex-end">
                                    <Button variant="text" onClick={handleClose}>
                                        {t("view.cancel")}
                                    </Button>
                                    <Button variant="text" type="submit">
                                        {t("view.submit")}
                                    </Button>
                                </Grid>
                            </Grid> */}
            </form>
          </FormProvider>
        </Box>
      </Modal>
    </>
  );
}

export default InterviewPerformanceRatingModal;
