import produce from "immer";
import { ErrorActions } from "./actions";

export interface ErrorState {
  isError: boolean;
  error: object | null;
}

const initialState: ErrorState = {
  isError: false,
  error: null,
};

// eslint-disable-next-line default-param-last
export function errorReducer(state: ErrorState = initialState, action: ErrorActions): ErrorState {
  switch (action.type) {
    case "@@error/CREATE_ERROR":
      return produce(state, (nextState) => {
        nextState.isError = true;
        nextState.error = action.response;
      });
    default:
      return state;
  }
}
