import { SelectedRowObj, UpdateRemarkObj } from "../components/applicationForm/view/ViewApplicationFormDashboard";
import { submissionFormData } from "../redux/applications/thunk";
import client, { buildLink } from "./interceptors";

export async function findSubmissionsByApplicationStageId(listId: number, stageId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission`);
  const result = res.data;
  return result;
}

export async function findSubmissionsAndUserByApplicationStageId(listId: number, stageId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/users`);
  const result = res.data;
  return result;
}

export async function findSubmissionByStageIdAndUserId(listId: number, stageId: number, userId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/user/${userId}`);
  const result = res.data;
  return result;
}

export async function findSubmissionDetailsByStageIdAndUserId(listId: number, stageId: number, userId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/user/${userId}/details`);
  const result = res.data;
  return result;
}

export async function updateSubmissionStatusBySubmissionId(listId: number, stageId: number, data: SelectedRowObj) {
  const res = await client.put(`applicationList/${listId}/applicationStage/${stageId}/submission`, data);
  const result = res.data;
  return result;
}

export async function updateRemarksBySubmissionId(listId: number, stageId: number, data: UpdateRemarkObj) {
  const res = await client.put(`applicationList/${listId}/applicationStage/${stageId}/submission/remarks`, data);
  const result = res.data;
  return result;
}

export async function createSubmission(listId: number, stageId: number, data: submissionFormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/submission`, data);
  const result = res.data;
  return result;
}

export async function saveSubmission(listId: number, stageId: number, data: submissionFormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/submission/save`, data);
  const result = res.data;
  return result;
}

export async function createSubmissionAttachment(listId: number, stageId: number, submissionId: number, data: FormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}/attachments`, data);
  const result = res.data;
  return result;
}

export async function findSubmissionById(listId: number, stageId: number, submissionId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}`);
  const result = res.data;
  return result;
}

export async function findSubmissionAttachmentsById(listId: number, stageId: number, submissionId: number) {
  const res = await client.get(`applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}/attachments`);
  const result = res.data;
  return result;
}

export async function editSubmissionById(listId: number, stageId: number, submissionId: number, data: any) {
  const res = await client.put(`applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}`, data);
  const result = res.data;
  return result;
}

// export async function downloadSubmissionFiles(listId: number, stageId: number, submissionId: number, fileName: string) {
//   const lastSchoolCode = sessionStorage.getItem("lastSchool");
//   const token = sessionStorage.getItem(`token:${lastSchoolCode}`);
//   const timestamp = sessionStorage.getItem(`token:${lastSchoolCode}:timestamp`);
//   const jwt = sessionStorage.getItem(`token:${lastSchoolCode}:jwt`);

//   window.open(`/applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}/download/file/${fileName}?token=${token}&timestamp=${timestamp}&jwt=${jwt}`);
// }

// export function generateSubmissionExcel(listId: number, stageId: number, fromdate: number, todate: number) {
//   const lastSchoolCode = sessionStorage.getItem("lastSchool");
//   const token = sessionStorage.getItem(`token:${lastSchoolCode}`);
//   const timestamp = sessionStorage.getItem(`token:${lastSchoolCode}:timestamp`);
//   const jwt = sessionStorage.getItem(`token:${lastSchoolCode}:jwt`);

//   window.open(`/applicationList/${listId}/applicationStage/${stageId}/excel/fromdate/${fromdate}/todate/${todate}?token=${token}&timestamp=${timestamp}&jwt=${jwt}`);
// }

export async function downloadSubmissionFiles(listId: number, stageId: number, submissionId: number, fileName: string) {
  const url = `/applicationList/${listId}/applicationStage/${stageId}/submission/${submissionId}/download/file/${fileName}`;

  try {
    const response = await client.get(url, { responseType: "blob" }); // Set responseType to 'blob'

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    const urlBlob = window.URL.createObjectURL(blob);

    // Create a link element, set its href to the blob URL, and trigger a download
    const link = document.createElement("a");
    link.href = urlBlob;
    link.download = fileName; // Set the file name for the downloaded file
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.remove();
    window.URL.revokeObjectURL(urlBlob);
  } catch (error) {
    console.error("Download failed:", error);
  }
}

export async function generateSubmissionExcel(listId: number, stageId: number, fromdate: number, todate: number) {
  const url = `/applicationList/${listId}/applicationStage/${stageId}/excel/fromdate/${fromdate}/todate/${todate}`;

  try {
    const response = await client.get(url, { responseType: "blob" }); // Set responseType to 'blob'

    // Create a blob from the response data
    const blob = new Blob([response.data], { type: response.headers["content-type"] });
    const urlBlob = window.URL.createObjectURL(blob);

    // Create a link element, set its href to the blob URL, and trigger a download
    const link = document.createElement("a");
    link.href = urlBlob;
    link.download = `submission_${listId}_${stageId}.xlsx`; // Set the default file name for the downloaded file
    document.body.appendChild(link);
    link.click();

    // Clean up and remove the link
    link.remove();
    window.URL.revokeObjectURL(urlBlob);
  } catch (error) {
    console.error("Excel generation failed:", error);
  }
}

export async function uploadSubmissionExcel(listId: number, stageId: number, file: File) {
  const res = await client.post(`/applicationList/${listId}/applicationStage/${stageId}/excel`, { file });
  const result = res.data;
  return result;
}

export async function generateTemplateExcelForSubmissionStatus(listId: number, stageId: number) {
  window.open(buildLink(`/applicationList/${listId}/applicationStage/${stageId}/submission/excel/template`));
}

export async function generateCurrentExcelForSubmissionStatus(listId: number, stageId: number) {
  window.open(buildLink(`/applicationList/${listId}/applicationStage/${stageId}/submission/excel/current`));
}

export async function importApplicationStatusExcel(listId: number, stageId: number, data: FormData) {
  const res = await client.post(`applicationList/${listId}/applicationStage/${stageId}/submission/excel`, data);
  const result = res.data;
  return result;
}
