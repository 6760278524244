import { i18n } from "i18next";

export default (i: i18n, { enUs, zhHk }: { enUs: string; zhHk: string }) => {
  if (i.resolvedLanguage === "zhHK") {
    return zhHk;
  }
  if (i.resolvedLanguage === "enUS") {
    return enUs;
  }
  return "";
};
