import { Typography } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { GridFilterModel, GridToolbarContainer, GridToolbarFilterButton, GridToolbarQuickFilter } from "@mui/x-data-grid-pro";
import React from "react";
import { InterviewTimeSlot } from "../../../../interfaces/interview";
import AddTimeSlotModal from "../AddTimeSlotModal";
import TimeSlotFilterMenu from "../TimeSlotFilterMenu";

interface TimeSlotToolbarProps {
  timeSlots: InterviewTimeSlot[];
  onShowResult: (model: GridFilterModel) => void;
}

function TimeSlotToolbar(props: TimeSlotToolbarProps) {
  const { timeSlots, onShowResult } = props;

  return (
    <GridToolbarContainer sx={{ display: "block" }}>
      <Grid container>
        <Grid xs={6}>
          <Typography>
            {/* Search:{" "} */}
            <GridToolbarQuickFilter
            // placeholder=""
            //  InputProps={{}}
            //  sx={{ border: "1px solid #DCDFE6", borderRadius: "3px" }}
            />
          </Typography>
        </Grid>
        <Grid xs={6} display="flex" justifyContent="flex-end">
          <AddTimeSlotModal />
          <TimeSlotFilterMenu timeSlots={timeSlots} onShowResult={onShowResult} />
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );
}

export default TimeSlotToolbar;
